import React, { useState } from 'react';
import FontAwesomeIcon from './lib/FontAwesomeIcon';
import TableCell from './TableCell';
import Store from './globalState';
import {Column} from './index';

type Props<D> = {
  store: Store<D>,
  row: D,
  columns: Column<D>[],
  mobileCollapseCardRender?: (row: D, state: Store<D>) => JSX.Element,
  isRemote?: boolean;
  refreshTableData?: () => void
};

function MobileTableRow<D>(
  {
    store,
    row,
    columns,
    mobileCollapseCardRender,
    isRemote,
    refreshTableData
  }: Props<D>
) {
  const {columnOrderState, hiddenColumnsState} = store;
  const columnOrder = columnOrderState.get();
  const hiddenColumns = hiddenColumnsState.get();
  const [isCollapsed, setIsCollapsed] = useState(true);

  const hasCollapse = !!mobileCollapseCardRender;

  return (
    <div className='card'>
      <div className='mobile-card-body'>
        {
          hasCollapse && mobileCollapseCardRender ?
            <>
              <div style={{ display: 'flex' }}>
                <div style={{ flexGrow: 1 }}>
                  {mobileCollapseCardRender(row, store)}
                </div>
                <div
                  className='collapse-btn-container'
                  onClick={() => {
                    setIsCollapsed(!isCollapsed);
                  }}
                  style={{
                    transform: isCollapsed ? `rotate(0deg)` : `rotate(180deg)`,
                    transition: 'all 0.3s ease'
                  }}
                >
                  <FontAwesomeIcon icon={'angle-down'} />
                </div>
              </div>
            </>
            :
            null
        }
        <div
          style={{
            height: hasCollapse ? isCollapsed ? 0 : 'auto' : 'auto',
            overflow: 'hidden',
            transition: 'all 0.3s ease'
          }}
        >
          <div className={`${hasCollapse ? 'collapse-content' : ''}`}>
            <div className={`row`}>
              {
                columnOrder.map((colIndex) => {
                  const col = columns[colIndex];
                  const xPos = (colIndex + 1) / columns.length;
                  if (hiddenColumns.includes(colIndex)) {
                    return null;
                  } else {
                    return (
                      <div
                        className='col col-6 col-sm-4 col-md-3'
                        key={col.field}
                      >
                        <div className='mobile-col-title'>
                          {col.title}
                        </div>
                        <div>
                          <TableCell
                            col={col}
                            colIndex={colIndex}
                            row={row}
                            store={store}
                            xPos={xPos}
                            isMobile={true}
                            isRemote={isRemote}
                            refreshTableData={refreshTableData}
                          />
                        </div>
                      </div>
                    );
                  }
                })
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileTableRow;