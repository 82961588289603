import {TaxRecordFormData, TaxRecordServer} from "@interfaces/TaxRecord";
import {formatDateForServer, stringToDate} from "@common/basic";

export const convertServerDataToFormData = (server: TaxRecordServer) => {
  const fromData: TaxRecordFormData ={
    year: `${server.year}`,
    appraisedValue: `${server.appraisedValue}`,
    taxRate: `${server.taxRate}`,
    taxesOwed: `${server.taxesOwed}`,
    accountNumber: server.accountNumber ||'',
    content: server.content ||'',
    paymentDueDate: stringToDate(server.paymentDueDate)
  }
  return fromData;
}

export const convertFormDataToServerData = (formData: TaxRecordFormData) => {
  const requestData: TaxRecordServer = {
    year: +formData.year,
    appraisedValue: +formData.appraisedValue,
    taxRate: (+formData.taxRate) / 100,
    taxesOwed: +formData.taxesOwed,
    accountNumber: formData.accountNumber,
    content: formData.content,
    paymentDueDate: formatDateForServer(formData.paymentDueDate),
  };
  return requestData;
}