import getAdminApiPath from "@hornet-api/getAdminApiPath";
import axios from "axios";
import {DocumentObject} from "@interfaces/DocumentRequest";

export type PropertyFreeFormFormData = {
  documentName: string
}

export const fetchDocumentsByPropertyId = async (propertyId: number | string) => {
  const url = `${getAdminApiPath()}/property/${propertyId}/document`;
  const response = await axios.get(url);
  return response.data as DocumentObject[];
}

export const updateFreeFormTitle = async (propertyId: number | string, documentId: number, data: PropertyFreeFormFormData) => {
  const url = `${getAdminApiPath()}/property/${propertyId}/document/${documentId}`;
  await axios.patch(url, data);
}

export const toggleDocumentVisibility = async (propertyId: number | string, documentId: number) => {
  const url = `${getAdminApiPath()}/property/${propertyId}/document/${documentId}/toggleVisibility`;
  const response = await axios.patch(url, {});
  return response.data as DocumentObject;
}

export const addDocument = async (propertyId: number | string, data: FormData) => {
  const url = `${getAdminApiPath()}/property/${propertyId}/document`;
  await axios.post(url, data);
};
export const downloadPropertyFile = (propertyId: number) => {
  return `${getAdminApiPath()}/property/${propertyId}/document/downloadLegalAddressDocument`
}
export const viewPropertyFile = (propertyId: number) => {
  return `${getAdminApiPath()}/property/${propertyId}/document/viewLegalAddressDocument`
}

export const deletePropertyDocument = async (propertyId: number | string, documentId: number) => {
  const url = `${getAdminApiPath()}/property/${propertyId}/document/${documentId}`;
  await axios.delete(url);
};