import axios from 'axios';
import getUserApiPath from '../getUserApiPath';
import Message from '@interfaces/Message';
import {MessageCategoryEnum} from "@interfaces/GeneratedEnums";


const getMessageByCategory = (category: keyof typeof MessageCategoryEnum) => {
  const url = `${getUserApiPath()}/message/${encodeURIComponent(category)}`;
  return axios.get<Message>(url).then(res => res.data);

};

export default getMessageByCategory;
