/* eslint-disable no-console */
import React from 'react';
import {objCopy} from './lib/basic';
import {Column} from './index';
import Store from './globalState';
import style from './style.module.scss';
import _ from "lodash";

type CalcRowProps<D> = {
	columns: Column<D>[],
	filteredData: D[],
	store: Store<D>
	hasExpandable?: boolean;
}

export default function CalcRow<D>(props: CalcRowProps<D>) {
	const {columns, filteredData, store, hasExpandable} = props;
	const [hiddenColumns] = store.hiddenColumnsState.use();
	const [columnOrder] = store.columnOrderState.use();
	const [sumRow] = store.sumRowState.use();
	const hasSum = columns.some(c => c.sum);
	const actionColIndex = columns.findIndex(x => x.actionBar === true);
	const actionCol = actionColIndex >= 0 ? columns[actionColIndex] : null;

	if (hasSum) {
		return (
			<tr className={'sum-row'}>
				{filteredData && filteredData.length > 0 && hasExpandable && <td></td>}
				{columnOrder.map((colIndex) => {
					const col = columns[colIndex];
					if (col.actionBar) {
						return null;
					}
					if (filteredData && filteredData.length > 0 && !(hiddenColumns.includes(colIndex))) {
						if (col.sum) {
							let colSum = 0;
							let sumRowData = sumRow
							if (!sumRowData) {
								try {
									for (let i = 0; i < filteredData.length; i++) {
										const fieldValue = _.get(filteredData[i], col.field);
										colSum += typeof fieldValue === 'number' ? fieldValue : 0;
									}
								} catch (e) {
									console.log(e);
								}
								sumRowData = objCopy(filteredData[0]);
								_.set(sumRowData as Object, col.field, colSum);
							}
							return (<th
								key={colIndex}
								style={{
									textAlign: col.align ? col.align : 'left'
								}}>
								{col.render ? col.render(sumRowData) : _.get(sumRowData, col.field)}
							</th>);
						}
						return (<td key={colIndex}/>);
					}
					return null;
				})}
				{actionCol ? <td className={style.actionCell}/> : null}
			</tr>
		);
	}
	return null;
}
