import axios from 'axios';
import getAdminApiPath from "@hornet-api/getAdminApiPath";
import {ILineItem} from "@interfaces/loan/admin/budget";
import {ILineItemServer} from "@admin-ui/pages/Budget/components/LineItem/constant";

export const createLineItem = (budgetId: number, data: ILineItemServer) => {
  const url = `${getAdminApiPath()}/budget/${budgetId}/lineItem`;
  return axios.post<ILineItem>(url, data)
    .then((response) => response.data);
};
