import React, {useCallback, useMemo} from 'react';
import {FaAngleRight} from 'react-icons/fa';
import {selectedTopMenuItemState, transfersState} from './globalState';
import isAdminState from "@state/globalState/isAdminState";
import style from './NotificationTransfers.module.scss';
import Badge from './components/Badge';
import numeral from 'numeral';
import {startAchTransfer} from '@components/AchConfirmModal';
import {ACHStatusEnum} from '@interfaces/GeneratedEnums';
import Transfer from '@interfaces/Transfer';
import {countTransferNotification, refreshTransactions} from './utils';
import * as hornetColors from '@common/hornetColors';
import configurationState from "@state/globalState/configurationState";

const NotificationTransfers = () => {
  const [transfers, setTransfers] = transfersState.use();
  const isAdmin = isAdminState.useValue();
  const configuration = configurationState.useValue();

  const handleClick = async (id: number) => {
    if (!transfers) return;
    const transfer = transfers?.find((x) => x.id === id);
    if (transfer && configuration?.features?.ach) {
      startAchTransfer({
        ...transfer,
        onConfirm: async () => {
          await refreshTransactions();
          const newTransfers = transfersState.get();
          if (!(newTransfers && newTransfers.length > 0)) {
            // empty now, will be removed from sidebar
            selectedTopMenuItemState.set('tasks');
          }
        },
      });
    }
  };

  const cleanTransfers = useMemo(() => {
    if (!transfers) return null;
    const sortOrder: (keyof typeof ACHStatusEnum)[] = [
      'ACH_PENDING_AUTHORIZATION',
      'ACH_ERROR',
      'WIRE_TRANSFER_PENDING',
      'ACH_AUTHORIZED',
      'ACH_COMPLETE',
      'WIRE_COMPLETE',
    ];
    const i = (t: Transfer) => {
      const index = sortOrder.indexOf(t.transactionStatus);
      if (index === -1) return 1000;
      return index;
    };
    return transfers
      .filter((transfer) => {
        // return transfer.transactionStatus !== 'ACH_COMPLETE';
        return true;
      })
      .sort((a, b) => {
        if (i(a) > i(b)) return 1;
        if (i(a) < i(b)) return -1;
        return 0;
      });
  }, [transfers, isAdmin]);

  const getColor = useCallback((status: keyof typeof ACHStatusEnum) => {
    if (['ACH_AUTHORIZED'].includes(status)) {
      return hornetColors.hornetOrange;
    }
    if (['ACH_ERROR'].includes(status)) {
      return hornetColors.hornetRed;
    }
    if (['ACH_PENDING_AUTHORIZATION', 'WIRE_TRANSFER_PENDING'].includes(status)) {
      return hornetColors.hornetGreen;
    }
    return hornetColors.hornetDark;
  }, []);

  const getBadgeCount = useCallback((status: keyof typeof ACHStatusEnum) => {
    return countTransferNotification(status) ? 1 : 0;
  }, []);

  const getInvestorName = useCallback((transfer: Transfer) => {
    if (transfer.bank?.company) return transfer.bank.company.name;
    if (transfer.bank?.contact) return transfer.bank.contact.name;
    return 'Not Set';
  }, []);

  if (!cleanTransfers) return null;
  return (
    <div>
      {cleanTransfers.map((transfer, index) => {
        const forText = transfer.achCategory === 'FUND_ACH' ?
          'The Fund'
          :
          (
            transfer.achCategory === 'LPA_ACH' ?
              `Loan (${transfer.lpaInfo?.loan})`
              :
              `Loan Payment (${transfer.loanPaymentInfo?.loan})`
          );

        return (
          <div
            className={style.item}
            key={index}
            style={{
              opacity: transfer.transactionStatus === 'ACH_COMPLETE' ? 0.5 : 1,
            }}
            onClick={() => {
              handleClick(transfer.id);
            }}
          >
            <div className={style.left}>
              <div className={style.name}>{transfer.bank.bankNameFormatted}</div>

              <div className={style.statusBox}>
                <table>
                  <tbody>
                    <tr>
                      <th>AMOUNT:</th>
                      <td>${numeral(transfer.amount).format('0,0.00')}</td>
                    </tr>
                    <tr>
                      <th>PURPOSE:</th>
                      <td>{forText}</td>
                    </tr>
                    <tr>
                      <th>INVESTOR:</th>
                      <td>{getInvestorName(transfer)}</td>
                    </tr>
                    <tr>
                      <th>STATUS:</th>
                      <td
                        style={{
                          color: getColor(transfer.transactionStatus),
                        }}
                      >
                        {ACHStatusEnum[transfer.transactionStatus]}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className={style.badgeBox}>
              <div className={style.vAlign}>
                <Badge num={getBadgeCount(transfer.transactionStatus)} />
              </div>
            </div>
            <div className={style.sideArrow}>
              <div className={style.vAlign}>
                <FaAngleRight />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default NotificationTransfers;
