import React from 'react';
import {FaTasks} from 'react-icons/fa';
import {
  notificationSidebarIsOpenState,
  selectedTopMenuItemState,
  tasksMetaState,
} from '@components/NotificationSidebar/globalState';
import isAdminState from "@state/globalState/isAdminState";
import Badge from './Badge';
import style from './style.module.scss';
import classNames from 'classnames';

const MenuItem = () => {
  const isAdmin = isAdminState.useValue();
  const tasksMetaData = tasksMetaState.useValue();

  return (
    <div className='top-icon'>
      <div
        className={
          isAdmin ? classNames(style.menuItem, style.isAdmin) : style.menuItem
        }
        onClick={() => {
          selectedTopMenuItemState.set("tasks");
          notificationSidebarIsOpenState.set(true);
        }}
      >
        <FaTasks/>
        <div className={style.badgeHolder}>
          <Badge num={tasksMetaData?.totalUnresolvedCount}/>
        </div>
      </div>
    </div>
  );
};

export default MenuItem;
