import {Column} from '../index';
import moment from 'moment';
import {filterDateFormat} from '../FilterDate';
import _ from "lodash";
import numeral from "numeral";

export const filterData = <D>(
	data: D[],
	filters: string[],
	columns: Column<D>[]
): D[] => {
	const hasFilters = filters.some(f => f !== '');
	if (hasFilters) {
		return data.filter((dt) => {
			// go through each filter
			let isMatch = true;
			for (let i = 0; i < filters.length; i++) {
				const column = columns[i];
				const filter = filters[i];
				if (!column) {
					console.log('filter sort issue', {
						index: i,
						columns: columns,
						filters: filters
					})
					continue;
				}
				if (column.field) {
					const fieldArr = (column.field as string).split('|');
					// if there is a filterMatchType, use it. otherwise, if there is a lookup object, use exact matching
					// all others default to original contains matching
					const filterMatchType = column.filterMatchType || (column.lookup ? 'exact' : 'contains');
					if (
						filter.length > 0 &&
						!(fieldArr.some((f) => {
							const fieldValue = column.filterVal ?  column.filterVal(dt) : _.get(dt, f.trim());
							// handle numbers
							if (typeof fieldValue === 'number') {
								return filter.split('|').some((filterPart) => {
									try {
										// handle greater than
										if (filterPart.trim()[0] === '>') {
											let num = Number(filterPart.substring(1));
											num = isNaN(num) ? 0 : num;
											return fieldValue > num;
											// handle less than
										} else if (filterPart.trim()[0] === '<') {
											let num = Number(filterPart.substring(1));
											num = isNaN(num) ? 0 : num;
											return fieldValue < num;
											// handle equals
										} else if (filterPart.trim()[0] === '=') {
											let num = Number(filterPart.trim().substring(1));
											num = isNaN(num) ? 0 : num;
											return fieldValue === num;
										}
									} catch (e) {
										/* do nothing and fall through  */
									}
									if (filterMatchType === 'exact') return `${fieldValue}`.toLowerCase() === filterPart.trim().toLowerCase();
									if (filterMatchType === 'startsWith') return `${fieldValue}`.toLowerCase().indexOf(filterPart.trim().toLowerCase()) === 0;
									const precision = column.precision ?? 2;
									const format = `0${precision > 0 ? `.${Array.from({length: precision}, () => '0').join('')}` : ''}`;
									return numeral(fieldValue).format(format).includes(`${filterPart.replace(/[^\d.-]/g, '').trim()}`);
								});
							}

							return filter.split('&').every((filterAnd) => filterAnd.split('|').some((filterPart) => {
								// handle date
								if (column.date) {
									try {
										const filterArr = filter.split('-');
										// 02/01/2022-02/28/2022
										if (filterArr.length !== 2) {
											return true;
										}
										const s = moment(filterArr[0], filterDateFormat).startOf('day');
										const e = moment(filterArr[1], filterDateFormat).endOf('day');
										// if dates are invalid, return true to show all until we have valid dates
										if (!s.isValid() || !e.isValid()) {
											return true;
										}
										const d = moment(fieldValue as string);
										// add a day to end to get all from last day
										return d.isBetween(s, e, undefined, '[]');
									} catch (e) {
										// console.error(e);
										return true;
									}
								}
								filterPart = filterPart.trim();
								const negateResults = filterPart.startsWith('!')
								if (negateResults) {
									filterPart = filterPart.substring(1);
								}
								filterPart = filterPart.trim().toLowerCase();

								const filterPartReg = filterPart
									.replace(/[-[\]{}()*+?.,\\^$|#]/g, "\\$&")
									.replace(/[\s]/g, "(.*)");

								let matches: RegExpMatchArray | boolean | null;

								if (filterMatchType === 'exact') {
									matches = `${fieldValue}`.toLowerCase() === filterPart;
								} else if (filterMatchType === 'startsWith') {
									matches = `${fieldValue}`.match(new RegExp(`^${filterPartReg}`, 'ig'));
								} else {
									matches = `${fieldValue}`.match(new RegExp(filterPartReg, 'ig'));
								}
								return negateResults ? !matches || !fieldValue : matches;
							}));

						}))
					)
						isMatch = false;

				}
			}
			return isMatch;
		});
	}
	return data;

};

type SortValFn<D> = (row: D) => string | number;
export const sortData = <D>(
	data: D[],
	sortKey: string,
	sortAscending: boolean,
	columns: Column<D>[]
): D[] => {
	let sortVal: null | SortValFn<D> = null;
	for (let i = 0; i < columns.length; i++) {
		const column = columns[i];
		// if sort val function passed
		if (column.field && column.field === sortKey && column.sortVal)
			sortVal = column.sortVal;

		// if a lookup is passed, make a sortVal function for that lookup to use clean for sorting
		if (column.field && column.field === sortKey && column.lookup)
			sortVal = (x: D) => {
				try {
					const fieldValue = _.get(x, column.field)
					const sortVal = column.lookup ? column.lookup[fieldValue] : fieldValue;
					return typeof sortVal === 'number' ? sortVal : `${sortVal}`.toLowerCase();
				} catch (e) {
					console.error(`Lookup for column ${column.field} is invalid`)
					return '';
				}
			};
	}

	const sortMethod = (a: D, b: D) => {
		let aVal: string | number;
		let bVal: string | number;
		if (sortVal) {
			aVal = sortVal(a);
			bVal = sortVal(b);
		} else {
			const sortKeyArr = sortKey?.split('|');
			const sortItemA = sortKeyArr?.map(x => _.get(a, x)).filter(x => x !== '' && x !== null && x !== undefined)[0];
			const sortItemB = sortKeyArr?.map(x => _.get(b, x)).filter(x => x !== '' && x !== null && x !== undefined)[0];
			aVal = typeof sortItemA === 'number' ? sortItemA : `${sortItemA}`.toLowerCase();
			bVal = typeof sortItemB === 'number' ? sortItemB : `${sortItemB}`.toLowerCase();
		}
		if (aVal < bVal)
			return sortAscending ? -1 : 1;

		if (aVal > bVal)
			return sortAscending ? 1 : -1;

		return 0;
	};

	return data.sort(sortMethod);
};
