import React, { useMemo } from 'react';
import { BiTaskX } from 'react-icons/bi';
import { RiChatDeleteFill } from 'react-icons/ri';
import style from './Empty.module.scss';

type Props = {
  type: 'chat' | 'task'
};

const Empty = ({
  type
}: Props) => {
  const text = useMemo(() => {
    if (type === 'chat') {
      return 'No Chats';
    }
    if (type === 'task') {
      return 'No Tasks';
    }
    return 'Nothing here';
  }, [type]);

  const icon = useMemo(() => {
    if (type === 'chat') {
      return <RiChatDeleteFill />;
    }
    if (type === 'task') {
      return <BiTaskX />;
    }
    return <BiTaskX />;
  }, [type]);

  return (
    <div className={style.empty}>
      <div className={style.main}>
        <div className={style.iconBox}>
          {icon}
        </div>
        <div className={style.textBox}>
          {text}
        </div>
      </div>
    </div>
  )
}

export default Empty;