import {getBaseUri} from "@common/baseUri";
import axios from "axios";


export type ResetPasswordData = {
    password: string,
    password2: string,
    token: string,
}

const resetPassword = async (data:ResetPasswordData) => {
    const url = `${getBaseUri()}api/public/resetPassword`;
    const response = await axios.post(url, data);
    return response.data;
};

export default resetPassword;