
import axios from 'axios';
import { getBaseUri } from '@common/baseUri';

const sessionToToken = () => {
  return new Promise<string | null>((resolve, reject) => {
    const sttPath = '/login/sessionToStatelessAuth';
    let basePath = getBaseUri();
    let url = '';
    if (basePath) {
      if (basePath.endsWith('/')) {
        basePath = basePath.slice(0, -1);
      }
      url = `${basePath}${sttPath}`;
    } else {
      // may happen if on admin for now
      url = sttPath;
    }
    axios({
      method: 'get',
      url: url,
      // headers: getHeaders()
    }).then((response: any) => {
      if (response.data?.access_token) {
        resolve(response.data.access_token);
      } else {
        resolve(null);
      }
    }).catch((error) => {
      console.log(error);
      resolve(null);
    });

  })
}

export default sessionToToken;