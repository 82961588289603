import React, { useCallback, useState } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import exportCsv from './lib/exportCsv';
import {Column} from './Types';
import Store from './globalState';
import ExportToCsvTooltip from './ExportToCsvTooltip';
import {FaRegFileExcel, FaCircleNotch} from 'react-icons/fa';
import decryptObject from "@common/decryptObject";

type IExportToCsvButtonProps<D> = {
	tableName: string,
	columns: Column<D>[],
	sortedData: D[],
	store: Store<D>;
	isRemote?: boolean;
	exportDataFetcher?: () => Promise<D[]>;
}

function ExportToCsvButton<D>(
	{
		columns,
		sortedData,
		tableName,
		store,
		isRemote,
		exportDataFetcher
	}: IExportToCsvButtonProps<D>
) {
	const [isLoading, setIsLoading] = useState(false);

	const [hiddenColumns] = store.hiddenColumnsState.use();
	const [columnOrder] = store.columnOrderState.use();

	const decryptAndExport = (sortedData: D[]) => {
		return decryptObject(sortedData).then((decryptedData) => {
			exportCsv(
				columns,
				hiddenColumns,
				decryptedData,
				columnOrder,
				`${tableName.split(' ').join('_')}.csv`
			);
		})
	}

	const onExportToCsvClickRemoteHandler = useCallback(() => {
		if(isLoading) return;
		if (exportDataFetcher) {
			setIsLoading(true);
			exportDataFetcher().then((data) => {
				return decryptAndExport(data);
			}).finally(() => {
				setIsLoading(false);
			})
		}
	}, [exportDataFetcher, columns, hiddenColumns, columnOrder, tableName, isLoading])

	const handleOnExportToCsvClick = useCallback(() => {
		if(isLoading) return;
		setIsLoading(true);
		decryptAndExport(sortedData).finally(() => {
			setIsLoading(false);
		});
	}, [columns, hiddenColumns, sortedData, columnOrder, tableName, isLoading]);

	return (
		<OverlayTrigger placement="bottom-end" overlay={<ExportToCsvTooltip />}>
			<button
				className="btn btn-sm btn-success"
				disabled={isLoading}
				onClick={isRemote ?
					onExportToCsvClickRemoteHandler :
					handleOnExportToCsvClick}
			>
				{/*<FontAwesomeIcon icon="file-excel-o" />*/}
				{isLoading ? <FaCircleNotch className={'spin'} /> : <FaRegFileExcel />}
			</button>
		</OverlayTrigger>
	);
};

export default ExportToCsvButton;
