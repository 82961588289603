import React, {Fragment, useEffect} from 'react';

import UserStateLoader from '@controls/monitors/UserStateLoader';
import ProfileRefreshMonitor from '@controls/monitors/ProfileRefreshMonitor';
import ResizeMonitor from '@controls/monitors/ResizeMonitor';
import CanSavePresetsMonitor from '@controls/monitors/CanSavePresetsMonitor';
import TokenRefreshMonitor from '@controls/monitors/TokenRefreshMonitor';
import ProfileStateLoader from '@controls/data-loaders/ProfileStateLoader';
import ReportingLoader from '@controls/data-loaders/ReportingLoader';
import ConfigurationMonitor from '@controls/monitors/ConfigurationMonitor';
import ApplicationLayout from '@views/Layout/ApplicationLayout';
import ConfigurationLoader from '@controls/data-loaders/ConfigurationLoader';
import LoadingOverlay from '@components/LoadingOverlay';
import RollbarLoader from '@controls/data-loaders/RollbarLoader';
import setupInterceptor from '@common/setupInterceptor';
import Notice from '@components/Notice';
import 'react-datepicker/dist/react-datepicker.css';
import '../style/style.scss';
import AmIAccreditedModal from "@views/AmIAccredited/AmIAccreditedModal";
import setupCopyFixer from "@common/setupCopyFixer";
import UserLoader from "@controls/data-loaders/UserLoader";
import {useRecoilValue} from "recoil";
import configurationAtom from "@state/recoil/configurationAtom";
import clarity from "../react-clarity";


// setup the axios interceptor
setupInterceptor();

// setup copy replacer to clean up text
setupCopyFixer();

const App = () => {
	const configuration = useRecoilValue(configurationAtom);

	useEffect(() => {
		if (!configuration?.clarity_id) return;

		clarity(configuration?.clarity_id)
	}, [configuration?.clarity_id]);

	return (
		<Fragment>
			<UserLoader/>
			<ConfigurationLoader/>
			<ConfigurationMonitor/>
			<UserStateLoader/>
			<ProfileStateLoader/>
			<ResizeMonitor/>
			<ReportingLoader/>
			<RollbarLoader/>
			<ProfileRefreshMonitor/>
			<TokenRefreshMonitor/>
			<ApplicationLayout/>
			<LoadingOverlay/>
			<CanSavePresetsMonitor/>
			<Notice/>
			<AmIAccreditedModal/>
		</Fragment>
	);
};

export default App;
