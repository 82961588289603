
import { createGlobalState } from 'react-global-hooks';


// bootstrap breakpoints to match
export enum WindowScreenSizeEnum {
  'xs' = 0,
  'sm' = 576,
  'md' = 768,
  'lg' = 992,
  'xl' = 1200,
}

export type WindowScreenSize = keyof typeof WindowScreenSizeEnum;

export const windowScreenSizeState = createGlobalState('xl' as WindowScreenSize);
export const windowWidthState = createGlobalState(1920);
export const windowHeightState = createGlobalState(1080);
export const windowIsMobileState = createGlobalState(false);
