/* eslint-disable no-magic-numbers */
import axios from 'axios';
import getUserApiPath from '../getUserApiPath';
import {ContactBankAccountSubmit} from '@interfaces/BankAccount';
import BankAccountWithOwner from "@interfaces/BankAccountWithOwner";
import {payerTokenState} from "@pages/OneTimeLoanPaymentPage/global";
import getPublicApiPath from "@hornet-api/getPublicApiPath";

const updateBankAccountWithEntity = (
	entityId: number | null,
	bankAccount: ContactBankAccountSubmit,
) => {
	const token = payerTokenState.get();
	if (token) {
		let url = `${getPublicApiPath()}/loanPayment/oneTime/${encodeURIComponent(token)}/bankAccount/${bankAccount.id}`;

		return axios.put<BankAccountWithOwner>(url, bankAccount).then((response) => {
			return response.data
		})
	} else {
		let url = `${getUserApiPath()}/contact/bankAccount/updateWithEntity`;

		return axios.put<BankAccountWithOwner>(url, {
			entityId: entityId,
			bankAccount: bankAccount,
		}).then((response) => {
			return response.data
		})
	}

};

export default updateBankAccountWithEntity;
