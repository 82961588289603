import isAdmin, { isLegacy } from '@common/isAdmin';

type State = {
	baseUri: null | string
}

const state: State = {
	baseUri: null,
};

export const getBaseUri = () => {
	if (isAdmin() && isLegacy()) {
		return '/';
	} else {
		return state.baseUri;
	}
};

export const getCleanBaseUri = () => {
	let basePath = getBaseUri();
	let absoluteUrl: string;
	if (basePath) {
		if (basePath.endsWith('/')) {
			basePath = basePath.slice(0, -1);
		}
		return basePath;
	} else {
		return '';
	}
}

export const setBaseUri = (baseUri: string | null) => {
	state.baseUri = baseUri;
};

export default {
	getBaseUri,
	setBaseUri,
};
