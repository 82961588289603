import React, {useCallback} from 'react';
import {useRecoilState} from 'recoil';
import {selectedPropertyIdAtom} from '../../state/atoms';
import Pressable from "@admin/components/buttons/Pressable";
import FontAwesomeIcon from "@admin/components/icons/FontAwesomeIcon";

const styles = {marginRight: '10px'};

const NavigationButton = () => {
	const [selectedPropertyId, setSelectedPropertyId] = useRecoilState(selectedPropertyIdAtom);

	const handleOnBackClick = useCallback(() => {
		if (selectedPropertyId) return setSelectedPropertyId(null);
		window.history.back();
	}, [selectedPropertyId, setSelectedPropertyId]);

	return (
		<div style={styles}>
			<Pressable className="btn btn-primary" onClick={handleOnBackClick}>
				<FontAwesomeIcon icon="chevron-left"/>
			</Pressable>
		</div>
	);
};

export default NavigationButton;
