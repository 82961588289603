import {loadingRelease, loadingTrigger} from "@components/LoadingOverlay";
import fileDownloader from "@common/utils/fileDownloader";
import {downloadDocumentUrl, viewDocumentUrl} from "@hornet-api/contact/document/documentRequestAction";
import {alertDownloadDocError} from "@common/errors";
import {openShowDocumentModal} from "@components/ShowDocumentModal";

export const onDownloadClick = (id: number, fileName: string) => {
  const t = loadingTrigger();
  fileDownloader(downloadDocumentUrl(id), fileName)
    .catch(alertDownloadDocError)
    .finally(() => {
      loadingRelease(t);
    });
};

export const onViewClick = (id: number, name: string) => {
  openShowDocumentModal({url: viewDocumentUrl(id), name})
}

/**
 * Returns a name of file and its extension
 * @param filename
 */

export const getFilenameAndExtension = (filename: string) => {
  const lastDotIndex = filename.lastIndexOf('.');
  if (lastDotIndex !== -1) {
    const docName = filename.substring(0, lastDotIndex);
    const extension = filename.substring(lastDotIndex + 1);
    return {docName, extension};
  } else {
    return {docName: filename, extension: ''};
  }
}

/**
 * Converts a given MIME type to its corresponding file extension.
 *
 * @param {string} mimeType - The MIME type to convert.
 * @returns {string} The corresponding file extension.
 */
const mimeTypeToExtension = (mimeType: string): string => {
  switch (mimeType) {
    case 'image/jpeg':
    case 'image/jpg':
      return '.jpeg';
    case 'image/png':
      return '.png';
    case 'image/svg+xml':
      return '.svg';
    default:
      return '.jpeg';
  }
};

/**
 * Returns a filename with the given title and file extension based on the provided mimeType.
 *
 * @param {string} title - The title of the file.
 * @param {string} mimeType - The mimeType of the file.
 * @returns {string} - The generated filename.
 */
export const createFileName = (title: string, mimeType: string): string => {
  const extension = mimeTypeToExtension(mimeType);
  return `${title}${extension}`;
};
