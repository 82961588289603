import axios from 'axios'; // Import Axios for making HTTP requests.
import {Report} from "@interfaces/Report";
import getUserApiPath from "@hornet-api/getUserApiPath"; // Import the Report interface.

// Define an asynchronous function to get dashboards.
const getDashboards = async () => {
  // Construct the URL for the API request.
  const url = `${getUserApiPath()}/getInvestorDashboards`;
  // Use Axios to make a GET request to the constructed URL.
  // Include the "Authorization" header with the access token.
  const response = await axios.get(url);

  // Return the response data as an array of Report objects.
  return response.data as Report[];
};

export default getDashboards;  // Export the getDashboards function.
