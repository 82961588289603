import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import Table from 'react-bootstrap/Table';
import Pagination from './PaginationComponent';
import CalcRow from './CalcRow';
import FilterRow from './FilterRow';
import TopToolbar from './TopToolbar';
import TableHead from './TableHead';
import MobileFooter from './MobileFooter';
import MobileView from './MobileView';
import {filterData, sortData} from './lib/sortAndFilter';
import SettingsSaver from './lib/SettingsSaver';
import Store from './globalState';
import ResizeMonitor from './lib/ResizeMonitor';
import {Column as _Column, FilterProps, PaginationProps, RecKeyof} from './Types';
import axios, {CancelTokenSource} from "axios";
import {reduceToRemoteFilter} from "./lib/serverFilterUtil";
import checkColumns from './lib/checkColumns'
import decryptObject from "@common/decryptObject";
import {v4 as getUuid} from "uuid";
import TableRow from "@components/BootstrapSmartTable/TableRow";
import LoadingWithWrapper from "@components/Indicators/LoadingWithWrapper";
import NoData from "@components/Indicators/NoData";

const OPERATION_CANCEL_MESSAGE = "Operation canceled due to new request."

export interface Column<R> extends _Column<R> {
}

type Options = {
	pageSize?: number,
	pageSizeOptions?: number[]
}

export type RemoteDataResponse<D> = {
	data: D[],
	filteredCount: number,
	total: number,
	sumRow?: D
}

export type BootstrapSmartTableProps<D> = {
	name: string,
	columns: Column<D>[],
	data?: D[],
	/**
	 * This function should always be created using useCallback().
	 * Otherwise, it will make a recursive call that never ends.
	 *
	 * It is called whenever the filtered data is updated
	 * @param rowData
	 */
	handleFilteredDataChange?: (rowData: D[]) => void,
	isRemote?: boolean;
	remoteUrl?: string;
	showReload?: boolean;
	reload?: () => void;
	dataCleaner?: (rowData: D[]) => D[],
	options?: Options,
	isLoading?: boolean,
	showFilter?: boolean,
	showTopToolbar?: boolean,
	defaultSortKey?: [string, 'ascending' | 'descending'],
	defaultFilters?: [string, string][],
	defaultHiddenColumns?: string[],
	persistFilters?: boolean,
	mobileCards?: boolean, // sets to be mobile friendly
	expandable?: {
		render: (row: D) => React.ReactNode
	}
	// This can be removed
	mobileCollapseCardKeys?: string[], // prevents them from being shown additionally when sorting
	mobileCollapseCardRender?: (row: D, state: Store<D>) => JSX.Element,
}

export interface BootstrapSmartTableFnProps<D> extends BootstrapSmartTableProps<D> {
	store: Store<D>,
	settingsSaver: SettingsSaver<D>
}

const defaultOptions = {
	pageSize: 10,
	pageSizeOptions: [5, 10, 20, 50, 100]
};

function BootstrapSmartTableFn<D>(
	{
		persistFilters = true,
		...props
	}: BootstrapSmartTableFnProps<D>
) {

	const options = {
		pageSize: props.options && props.options.pageSize ? props.options.pageSize : defaultOptions.pageSize,
		pageSizeOptions: props.options && props.options.pageSizeOptions ? props.options.pageSizeOptions : defaultOptions.pageSizeOptions,
	};
	const {
		name,
		columns,
		store,
		handleFilteredDataChange,
		settingsSaver,
		isRemote,
		remoteUrl,
		showReload,
		reload,
		dataCleaner,
		showTopToolbar = true,
		expandable
	} = props;
	const data = props.data ?? [];
	const [currentPage, setCurrentPage] = store.currentPageState.use();
	const [pageSize, setPageSize] = store.pageSizeState.use();
	const [remoteData, setRemoteData] = store.dataState.use();
	const fetchingRemoteData = store.fetchingRemoteDataState.useValue();
	const [filteredCount, setFilteredCount] = store.filteredCountState.use();
	const [total, setTotal] = store.totalState.use();
	const [sumRow, setSumRow] = store.sumRowState.use();
	const [sortKey, setSortKey] = store.sortKeyState.use();
	const [sortAscending, setSortAscending] = store.sortAscendingState.use();
	const [columnOrder, setColumnOrder] = store.columnOrderState.use();

	const [filters, setFilters] = store.filtersState.use();
	const cancelTokenSource = useRef<CancelTokenSource>();

	const isLoading = props.isLoading || fetchingRemoteData.length > 0;
	const showFilter = props.showFilter;
	const defaultSortKey = props.defaultSortKey ? props.defaultSortKey : null;
	const defaultFilters = props.defaultFilters ? props.defaultFilters : null;

	const isMobile = props.store.windowIsMobileState.useValue();
	const useMobile = props.mobileCards && isMobile;
	const loadingTriggerRemoteData = () => {
		const uuid = getUuid();
		store.fetchingRemoteDataState.set([...store.fetchingRemoteDataState.get(), uuid]);
		return uuid
	}

	const loadingReleaseRemoteData = (t: string) => {
		store.fetchingRemoteDataState.set(store.fetchingRemoteDataState.get().filter(x => x !== t));
	}

	const requestRemoteData = useCallback(async (paging: PaginationProps, filters: FilterProps) => {
		if (!remoteUrl) {
			console.error('remoteUrl is mandatory for remote data')
			return {remoteData: [], filteredCount: 0, total: 0};
		}
		//Check if there are any previous pending requests
		if (cancelTokenSource.current) {
			cancelTokenSource.current.cancel(OPERATION_CANCEL_MESSAGE)
		}

		//Save the cancel token for the current request
		cancelTokenSource.current = axios.CancelToken.source()
		const response = await axios.post<RemoteDataResponse<D>>(remoteUrl, {
			paging, filters
		}, {
			cancelToken: cancelTokenSource.current.token
		}).catch((reason) => {
			if (reason.message !== OPERATION_CANCEL_MESSAGE) {
				console.error(reason);
			}
		});
		if (!response) return {remoteData: [], filteredCount: 0, total: 0};

		const remoteRawData = response.data.data || []
		const {filteredCount, total, sumRow} = response.data
		const remoteData = dataCleaner ? dataCleaner(remoteRawData) : remoteRawData;
		return {remoteData, filteredCount, total, sumRow};
	}, [remoteUrl, dataCleaner]);

	// check columns and throw alerts if there is an issue. Only runs on localhost
	useEffect(() => {
		checkColumns(name, columns, data);
	}, [name, columns, data]);


	// set base filters on load
	useEffect(() => {
		setPageSize(options.pageSize);
		let defaultHiddenColumns: number[] = [];
		if (props.defaultHiddenColumns && props.defaultHiddenColumns.length > 0) {
			// convert hidden column field names to indexes
			defaultHiddenColumns = props.defaultHiddenColumns.map(x => columns.findIndex(y => y.field === x)).filter(x => x > -1) as number[];
		}

		// set the defaults for the setting saver
		settingsSaver.defaults = {
			initialPageSize: defaultOptions.pageSize,
			pageSize: defaultOptions.pageSize,
			sortKey: defaultSortKey ? defaultSortKey[0] : columns[0].field,
			sortKeyAscending: defaultSortKey ? defaultSortKey[1] === 'ascending' : true,
			columnOrder: Object.keys(columns).map((c, i) => i),
			sortFilters: (defaultFilters || []).reduce((prev, next) => {
				const results = [...prev];
				const colIndex = (columns || []).map(col => col.field).indexOf(next[0] as RecKeyof<D>);
				if (colIndex >= 0) results[colIndex] = next[1];
				return results;
			}, new Array(columns.length).fill('')),
			hiddenColumns: defaultHiddenColumns,
			columnIds: columns.map(col => col.id),
			showActionBar: true
		}

		settingsSaver.initTable(persistFilters);
	}, []);

	const remoteDataFetcher = (paging: PaginationProps, filters: FilterProps) => {
		const t = loadingTriggerRemoteData();
		setRemoteData([]);
		setTotal(0);
		requestRemoteData(paging, filters).then(({remoteData, filteredCount, total, sumRow}) => {
			setRemoteData(remoteData);
			setFilteredCount(filteredCount);
			setTotal(total);
			setSumRow(sumRow || null);
		}).finally(() => {
			loadingReleaseRemoteData(t);
		});
	}

	const decryptAll = useCallback(() => {
		const t = loadingTriggerRemoteData();
		decryptObject(remoteData).then(remoteDataDecrypted => {
			setRemoteData(remoteDataDecrypted);
		}).finally(() => {
			loadingReleaseRemoteData(t);
		});
	}, [remoteData]);

	const exportDataFetcher = () => {
		return requestRemoteData(
			{
				page: 1,
				pageSize: filteredCount,
				sortBy: sortKey,
				sortOrder: sortAscending ? 'asc' : 'desc'
			},
			reduceToRemoteFilter(filters, columns)
		).then(({remoteData}) => {
			return remoteData
		});
	}

	const refreshTableData = useCallback(() => {
		remoteDataFetcher({
			page: currentPage,
			pageSize,
			sortBy: sortKey,
			sortOrder: sortAscending ? 'asc' : 'desc'
		}, reduceToRemoteFilter(filters, columns))
	}, [currentPage, pageSize, sortKey, sortAscending, filters, columns])

	useEffect(() => {
		if (isRemote && currentPage && pageSize && sortKey != '') {
			refreshTableData()
		}
	}, [currentPage, pageSize, sortKey, sortAscending, filters, columns, remoteUrl])

	const filteredData = useMemo(() => {
		return isRemote ? remoteData : filterData(data, filters, columns)
	}, [data, filters, columns, isRemote, remoteData]);

	const sortedData = useMemo(() => {
		return isRemote ? filteredData : sortData(filteredData, sortKey, sortAscending, columns)
	}, [filteredData, sortKey, sortAscending, columns, isRemote]);

	useEffect(() => {
		if (handleFilteredDataChange) {
			handleFilteredDataChange(filteredData);
		}
	}, [filteredData, handleFilteredDataChange]);

	let numGrow = 0;
	for (let i = 0; i < columns.length; i++) {
		if (columns[i].grow)
			numGrow += 1;
	}

	if (useMobile) {
		return (
			<>
				<MobileView
					isLoading={isLoading}
					store={store}
					sortedData={sortedData}
					columns={columns}
					mobileCollapseCardRender={props.mobileCollapseCardRender}
					isRemote={isRemote}
					refreshTableData={refreshTableData}
				/>
				<MobileFooter
					store={store}
					columns={columns}
				/>
				<div className='bst-mobile-footer-spacer' />
			</>
		)
	} // end mobile
	return (
		<>
			<div className="smart-table border rounded">
				{showTopToolbar && <TopToolbar
					initialPageSize={options.pageSize}
					columns={columns}
					pageSizeOptions={options.pageSizeOptions}
					sortedData={sortedData}
					tableName={name}
					store={store}
					settingsSaver={settingsSaver}
					isRemote={isRemote}
					exportDataFetcher={exportDataFetcher}
					reload={showReload ? (isRemote ? refreshTableData : reload) : null}
				/>}
				<Table responsive>
					<TableHead
						columns={columns}
						store={store}
						hasExpandable={!!expandable}
						settingsSaver={settingsSaver}
					/>
					<tbody>
					{
						showFilter && (isRemote || data.length > 0) ?
							<FilterRow columns={columns} store={store} hasExpandable={!!expandable}/>
							:
							null
					}
					{
						!isLoading && sortedData.map((row, index) => {
							const showIt = isRemote || currentPage === Math.floor((index) / pageSize) + 1;
							if (showIt) {
								return (
									<TableRow
										columnOrder={columnOrder}
										columns={columns}
										data={row}
										key={`tr-${index}`}
										index={index}
										store={store}
										isRemote={isRemote}
										refreshTableData={refreshTableData}
										decryptAll={decryptAll}
										expandable={expandable}
									/>
								);
							}
							return null;
						})
					}
					<CalcRow
						columns={columns}
						filteredData={filteredData}
						store={store}
						hasExpandable={!!expandable}
					/>
					</tbody>
				</Table>
				{
					sortedData.length === 0 || isLoading ?
						isLoading ?
							<LoadingWithWrapper/>
							:
							<NoData/>
						:
						null
				}
				<Pagination
					currentPage={currentPage}
					pageSize={pageSize}
					numItems={isRemote ? filteredCount : sortedData.length}
					totalItems={isRemote ? total : data.length}
					onChange={(newPage) => {
						setCurrentPage(newPage);
					}}
				/>
			</div>
		</>
	);
}

export default class BootstrapSmartTable<D> extends React.Component {
	props: BootstrapSmartTableProps<D>;
	store: Store<D>;
	settingsSaver: SettingsSaver<D>

	constructor(props: BootstrapSmartTableProps<D>) {
		super(props);
		this.props = props;
		this.store = new Store();
		this.settingsSaver = new SettingsSaver({
			tableName: this.props.name,
			store: this.store,
			columns: props.columns
		});
	}

	render() {
		return (<>
			<BootstrapSmartTableFn {...this.props} store={this.store} settingsSaver={this.settingsSaver} />
			<ResizeMonitor store={this.store} />
		</>);
	}
}
