import axios from 'axios';
import {getAccessToken} from '@common/token';
import getUserApiPath from '../getUserApiPath';


export interface DocumentRequestModalObjNew {
  new: boolean,
  documentType: 'FREEFORM',
  freeFormTitle?: string,
  onClose?: () => void
}

const uploadNewDocument = (
  docRequest: DocumentRequestModalObjNew,
  file: File
) => {
  return new Promise((resolve, reject) => {
    // todo do we need to expose this here?
    const url = `${getUserApiPath()}/contact/document/upload/?access_token=${getAccessToken()}`;
    const formData = new FormData();
    console.log('document', file);
    console.log('freeformTitle', docRequest.freeFormTitle ? docRequest.freeFormTitle : 'Unnamed');

    formData.append("document", file);
    formData.append("freeformTitle", docRequest.freeFormTitle ? docRequest.freeFormTitle : 'Unnamed');

    axios.post(
      url,
      formData
    ).then((response) => {
      resolve(response.data);
    }).catch(e => {
      reject(e);
    });
  });
}

export default uploadNewDocument;
