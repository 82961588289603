import React from 'react';
import Wrapper, {WrapperProps} from "@components/Indicators/Wrapper";
import Loading from "@components/Loading";
import {LoadingProps} from "@components/Loading/Loading";

const LoadingWithWrapper = ({absolute, ...props}: LoadingProps & WrapperProps) => {
	return (
		<Wrapper absolute={absolute}>
			<Loading {...props}/>
		</Wrapper>
	);
};

export default LoadingWithWrapper;
