import React, {useMemo} from 'react';
import classNames from 'classnames';
import style from './style.module.scss';
import {ITask} from '@interfaces/task';
import Badge from './Badge';
import isAdminState from "@state/globalState/isAdminState";
import {getNumTaskNotifications} from '../utils';
import {FaAngleRight} from 'react-icons/fa';
import {TaskStatusEnum} from "@interfaces/GeneratedEnums";

type Props = {
  task: ITask,
  onClick?: () => void
}

const TaskItem = ({task, onClick}: Props) => {
  const isAdmin = isAdminState.useValue();
  const statusClass = useMemo(() => {
    if (!task) return '';
    const classArr = [style.status];
    switch (task.taskStatus){
      case "ACTIVE":
        classArr.push(style.active);
        break;
      case "COMPLETE":
        classArr.push(style.complete);
        break
      case "HIDDEN":
        classArr.push(style.hidden);
        break
      case "PENDING":
        classArr.push(style.pending);
        break;
    }
    return classNames(classArr);
  }, [task, isAdmin]);

  const taskItemClass = useMemo(() => {
    if (!task) return '';
    const classArr = [style.taskItem];
    if (task.taskStatus === 'COMPLETE') {
      classArr.push(style.complete);
    }
    return classNames(classArr);
  }, [task, isAdmin]);

  if (!task) return null;

  //todo calculate this
  const numNotifications = getNumTaskNotifications(task);

  return (
    <div className={taskItemClass} onClick={onClick}>
      <div className={style.left}>
        {
          task.ownerName ?
            <div className={style.userName}>
              {task.ownerName}
            </div>
            :
            null
        }
        <div className={isAdmin ? '' : style.name}>
          {task.title}
        </div>
        {
          task.loanId ?
            <div className={style.loanId}>
              <div className={style.title}>LOAN:</div> {task.loanId}
            </div>
            :
            null
        }
        <div className={style.statusBox}>
          <div className={style.title}>
            STATUS:
          </div>
          <div className={statusClass}>
            {TaskStatusEnum[task.taskStatus]}
          </div>
        </div>
      </div>
      <div className={style.badgeBox}>
        <div className={style.vAlign}>
          <Badge num={numNotifications} />
        </div>
      </div>
      <div className={style.sideArrow}>
        <div className={style.vAlign}>
          <FaAngleRight />
        </div>
      </div>

    </div>
  );
}

export default TaskItem;