import React, {useCallback, useEffect, useRef, useState} from 'react';

import style from "./UploaderPage.module.scss";
import {useHistory, useParams} from "react-router-dom";
import {getPropertyById} from "@hornet-api/properties/getPropertiesList";
import {PropertyPage, PropertyRaw} from "@interfaces/Property";
import {ThumbnailImage} from "@admin-ui/pages/UploaderPage/ThumbnailImage";
import SearchBox from "@admin-ui/pages/UploaderPage/SearchBox";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AddressCard from "@admin-ui/pages/UploaderPage/AddressCard";
import {alertApiErrors} from "@common/errors";
import {PropertyView} from "@admin-ui/pages/UploaderPage/Property/PropertyImageView";
import {debounce} from "lodash";

import "./UploaderPage.scss";
import axios, {CancelTokenSource} from "axios";
import getAdminApiPath from "@hornet-api/getAdminApiPath";
import {FaCircleNotch} from "react-icons/fa";
import InfiniteScroll from "react-infinite-scroll-component";

type UploaderUrlParams = {
  action?: string;
  id?: string;
}

type UploadPageMetaData = {
  page: number;
  searchTextRefresh: string;
}

const OPERATION_CANCEL_MESSAGE = "Operation canceled due to new request."

const UploaderPage = () => {

  const history = useHistory();
  const {action, id} = useParams() as UploaderUrlParams;
  const [list, setList] = useState<PropertyRaw[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const [foundProperty, setFoundProperty] = useState<PropertyRaw>();
  const [totalCount, setTotalCount] = useState(0);
  const [pageMetaData, setPageMetaData] = useState<UploadPageMetaData>({page: -1, searchTextRefresh: ''});
  const [loading, setLoading] = useState<boolean>(false);

  const cancelTokenSource = useRef<CancelTokenSource>();

  const updatePropertyInList = (updatedProperty: PropertyRaw) => {
    setList(listOld => {
      return listOld.map((property) => {
        if (updatedProperty.id === property.id) {
          return updatedProperty;
        }
        return property
      })
    });
  }
  const updateSearchTextRefresh = debounce((value) => {
    setPageMetaData({searchTextRefresh: value, page: 0});
  }, 500);

  const handleChangeSearchText = useCallback((value) => {
    setSearchText(value);
    updateSearchTextRefresh(value);
  }, [setSearchText, setPageMetaData]);

  useEffect(() => {
    if(loading) return
    if (pageMetaData.page === -1) {
      loadNextPage();
      return;
    }
    if (pageMetaData.page === 0) {
      setList([]);
    }
    setLoading(true);
    if (cancelTokenSource.current) {
      cancelTokenSource.current.cancel(OPERATION_CANCEL_MESSAGE);
    }
    cancelTokenSource.current = axios.CancelToken.source();
    const url = `${getAdminApiPath()}/property/list-pages`;
    const params = {
      page: pageMetaData.page,
      search: pageMetaData.searchTextRefresh
    };
    axios.get(url, {
      params,
      cancelToken: cancelTokenSource.current.token
    }).then((response) => {
      const {data, totalCount} = response?.data as PropertyPage;
      setTotalCount(totalCount);
      setList(prevItems => [...prevItems, ...data]);
    }).catch(reason => {
      if (reason.message !== OPERATION_CANCEL_MESSAGE) {
        alertApiErrors(reason);
      }
    }).finally(() => setLoading(false));

  }, [pageMetaData]);

  const loadNextPage = useCallback(() => {
    setPageMetaData({...pageMetaData, page: pageMetaData.page + 1});
  }, [pageMetaData]);


  useEffect(() => {
    if (!id) {
      setFoundProperty(undefined);
      return
    }
    if (!['show'].find(a => a === action)) {
      return
    }
    const findProperty = list.find(p => p.id === Number(id));
    if (findProperty) {
      setFoundProperty(findProperty)
    } else {
      getPropertyById(id)
        .then(setFoundProperty)
        .catch(alertApiErrors);
    }
  }, [id, action, list]);

  const onSelectProperty = (id: number) => {
    history.push(`/uploader/show/${id}`);
  }

  return (
    <div id={'imageScrollable'} style={{overflow: "none"}} className={`r-adminuploader-propertyshowview ${style.container}`}>
      <section className="content">

        {
          action === 'show' && foundProperty ?
            <PropertyView setProperty={updatePropertyInList} property={foundProperty}/>
            :
            <Row>
              <Col>
                <SearchBox value={searchText} onChange={handleChangeSearchText}/>
                  <InfiniteScroll
                    scrollableTarget={'imageScrollable'}
                    dataLength={list.length}
                    next={loadNextPage}
                    hasMore={list.length < totalCount}
                    loader={
                    <div className="text-center overflow-hidden"><FaCircleNotch className='spin'/></div>
                  }
                  >
                    {
                      list.map((p, index) =>
                        <div key={`key-${index}`} className={`${style.propertyCard}`} id={`prop-${p.id}`}
                             onClick={() => onSelectProperty(p.id)}>
                          <div className={`${style.flexAndCenter}`}>
                            <ThumbnailImage primaryImageId={p.primaryImageId} images={p.images}/>
                            <AddressCard property={p}/>
                          </div>
                        </div>)
                    }
                  </InfiniteScroll>
              </Col>
            </Row>
        }
      </section>
    </div>
  );
}
export default UploaderPage;
