import {Column, FilterProps} from "../Types";
import moment from "moment";
import {filterDateFormat} from "@components/BootstrapSmartTable/FilterDate";
import {formatDateForServer} from "@common/basic";

export const reduceToRemoteFilter = <D>(filters: string[], columns: Column<D>[]) => {
    return filters.reduce((acc, filter, index) => {
        if (filter && filter.length > 0) {
            (columns[index].field as string).split('|').forEach(fieldKey => {
                if (columns[index].date) {
                    const [start, end] = filter.split('-')
                    const s = moment(start, filterDateFormat).startOf('day');
                    const e = moment(end, filterDateFormat).endOf('day');
                    // if dates are invalid, return true to show all until we have valid dates
                    if (!s.isValid() || !e.isValid()) {
                        return;
                    }
                    acc[fieldKey + 'Start'] = formatDateForServer(s, false);
                    acc[fieldKey + 'End'] = formatDateForServer(e, false);
                } else {
                    acc[fieldKey] = filter;
                }
            });
        }
        return acc
    }, {} as FilterProps)
}