/**
 * Generates the regular expression for search
 * Also reverse the string
 * e.g. adams lance / lance adams
 * @param searchText
 */
export const generateSearchRegExp = (searchText: string): {
  regExp: RegExp,
  regExpReverse: RegExp
} => {
  const escapeRegExp = (str: string): string => {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  const escapedSearchVal: string = escapeRegExp(searchText);
  const regExp: RegExp = new RegExp(escapedSearchVal.replace(/[\s]/g, "(.*)"), 'ig');
  const reversedVal: string = searchText.split(' ').reverse().join(' ');
  const regExpReverse: RegExp = new RegExp(escapeRegExp(reversedVal).replace(/[\s]/g, "(.*)"), 'ig');

  return {regExp: regExp, regExpReverse: regExpReverse};
}