import TwoFactorAuthenticationModal, {
  openTwoFactorAuthenticationModal,
  twoFactorAuthState
} from "@legacy/views/UserProfileLegacy/TwoFactorAuthenticationModal";
import {useEffect} from "react";
import {fetchUser2FA} from "@hornet-api/twoFactorAuth/user/fetchUser2FA";
import {useRecoilValue} from "recoil";
import isImpersonatingSelectorAtom from "@state/recoil/authentication/isImpersonatingSelectorAtom";

const TwoFactorAuthCheck = () => {

  const [user2fa, setUser2fa] = twoFactorAuthState.use();
  const isNonImpersonating = !useRecoilValue(isImpersonatingSelectorAtom);
  useEffect(() => {
    if (user2fa == null) {
      fetchUser2FA()
        .then((data) => {
          setUser2fa(data);
          const is2FaRequired = !data || !(data.isEnabled || data.isTurnedOffByAdmin);
          if (isNonImpersonating && is2FaRequired) {
            openTwoFactorAuthenticationModal();
          }
        })
    }
  }, [user2fa])
  return <TwoFactorAuthenticationModal/>
}

export default TwoFactorAuthCheck;