import React, {useMemo} from 'react';
import {FaRegMoneyBillAlt} from 'react-icons/fa';
import {
  notificationSidebarIsOpenState,
  selectedTopMenuItemState,
  transfersState,
} from '@components/NotificationSidebar/globalState';
import isAdminState from "@state/globalState/isAdminState";
import Badge from './Badge';
import style from './style.module.scss';
import classNames from 'classnames';
import {getNumTransferNotifications,} from '../utils';
import configurationState from "@state/globalState/configurationState";

const AdminTransfersItem = () => {
  const isAdmin = isAdminState.useValue();
  const transfers = transfersState.useValue();
  const configuration = configurationState.useValue();

  const numNotifications = useMemo(() => {
    let total = 0;
    // add transfers
    if (transfers && transfers.length > 0 && configuration?.features?.ach) {
      total += getNumTransferNotifications(transfers);
    }
    return total;
  }, [isAdmin, transfers, configuration]);

  if (!numNotifications) {
    return null;
  }

  return (
    <div className='top-icon'>
      <div
        className={
          isAdmin ? classNames(style.menuItem, style.isAdmin) : style.menuItem
        }
        onClick={() => {
          selectedTopMenuItemState.set("transfers");
          notificationSidebarIsOpenState.set(true);
        }}
      >
        <FaRegMoneyBillAlt/>
        <div className={style.badgeHolder}>
          <Badge num={numNotifications}/>
        </div>
      </div>
    </div>
  );
};

export default AdminTransfersItem;
