const styles = {
	style1: {
		margin: '20px 0',
		textAlign: 'center',
	},
	style2: {
		display: 'none',
	},
};

export default styles;
