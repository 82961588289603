/* eslint-disable no-console */
import Store from '../globalState';
import {Column, SaveObject} from '../Types';
import devConsole from '@common/devConsole';
import {getPathname} from "@hornet-api/smartTables";
import {getQueryParams} from "@common/basic";

type SettingSaverProps<D> = {
	tableName: string,
	store: Store<D>,
	columns: Column<D>[]
}

export default class SettingsSaver<D> {
	private readonly keyRoot: string;
	private saveKeys: string[] = [
		'sortKey',
		'sortKeyAscending',
		'sortFilters',
		'hiddenColumns',
		'columnOrder',
		'columnIds',
		'showActionBar',
	];

	defaults: SaveObject | null = null;

	loadedUrlObj: null | SaveObject = null;
	columns: Column<D>[];
	private store: Store<D>;

	constructor(props: SettingSaverProps<D>) {
		const cleanTableName = props.tableName.split(' ').join('_');
		this.keyRoot = `bst_${getPathname()}_${cleanTableName}`;
		this.store = props.store;
		this.columns = props.columns;

		// get settings
		const queryParams = getQueryParams();
		if ('bst' in queryParams) {
			try {
				const obj = JSON.parse(Buffer.from(queryParams.bst, 'base64').toString()) as { [id: string]: any };
				if (this.keyRoot in obj) {
					this.loadedUrlObj = obj[this.keyRoot];
				}
			} catch (e) {
				console.error('Failed to get query parameters from url', e);
			}
		}
	}

	initTable = (
		persistFilters: boolean
	) => {
		if (this.defaults === null) {
			throw 'Defaults not set'
		}

		// load url object if it exist
		if (this.loadedUrlObj) {
			this.updateSettings(this.loadedUrlObj, true);
		} else if (!persistFilters) {
			this.updateSettings(this.defaults);
		} else {
			this.updateSettings(this.getSavedSettings())
		}

		// set callbacks for settings
		this.store.hiddenColumnsState.onChange(() => this.saveSettings());
		this.store.filtersState.onChange(() => this.saveSettings());
		this.store.currentPageState.onChange(() => this.saveSettings());
		this.store.initialPageSizeState.onChange(() => this.saveSettings());
		this.store.pageSizeState.onChange(() => this.saveSettings());
		this.store.sortAscendingState.onChange(() => this.saveSettings());
		this.store.sortKeyState.onChange(() => this.saveSettings());
		this.store.columnOrderState.onChange(() => this.saveSettings());
		this.store.showActionBarState.onChange(() => this.saveSettings());

		// save migrated settings
		this.saveSettings();
	}

	private getItemKey = (key: string) => {
		return `${this.keyRoot}|${key}`;
	}

	getOption = (key: keyof SaveObject, defaultValue?: SaveObject[keyof SaveObject]) => {
		if (this.loadedUrlObj) {
			// if we ever loaded a URL object, then use the values in it and defaults
			if (key in this.loadedUrlObj) {
				return this.loadedUrlObj[key];
			} else {
				return defaultValue;
			}
		}
		// if no url object proceed as normal

		const itemKey = this.getItemKey(key);
		const stored = window.localStorage.getItem(itemKey);
		if (stored) {
			try {
				return JSON.parse(stored);
			} catch (e) {
				console.log('Error getting local storage', e);
				return defaultValue;
			}
		}
		return defaultValue;
	}

	saveSettings() {
		try {
			const settings = this.getSettings();
			devConsole.log(`Saving settings for ${this.keyRoot}`, settings);
			window.localStorage.setItem(this.keyRoot, JSON.stringify(settings));
		} catch (e) {
			console.log('Error saving local storage');
		}
		this.updateUrlParams();
	}

	getSavedSettings = (): SaveObject => {
		const stored = window.localStorage.getItem(this.keyRoot);
		if (stored) {
			try {
				return JSON.parse(stored);
			} catch (e) {
				console.log('Error getting local storage', e);
				return this.defaults as SaveObject;
			}
		} else {
			// try to recover from old method
			return {
				initialPageSize: this.defaults?.initialPageSize as number,
				pageSize: this.defaults?.pageSize as number,
				sortKey: this.getOption('sortKey', this.defaults?.sortKey) as string,
				sortKeyAscending: this.getOption('sortKeyAscending', this.defaults?.sortKeyAscending) as boolean,
				columnOrder: this.getOption('columnOrder', this.defaults?.columnOrder) as number[],
				sortFilters: this.getOption('sortFilters', this.defaults?.sortFilters) as string[],
				hiddenColumns: this.getOption('hiddenColumns', this.defaults?.hiddenColumns) as string[],
				showActionBar: this.getOption('showActionBar', this.defaults?.showActionBar) as boolean
			} as SaveObject;
		}
	}

	removeOption = (key: string) => {
		const itemKey = this.getItemKey(key);
		try {
			window.localStorage.removeItem(itemKey);
		} catch (e) {
			console.log('Error removing item from storage');
		}
		this.updateUrlParams();
	}

	removeAll = () => {
		for (let i = 0; i < this.saveKeys.length; i++)
			this.removeOption(this.saveKeys[i]);
	}

	updateUrlParams = () => {
		devConsole.log('updating url params');
		let newObj: { [p: string]: SaveObject } = {};
		let queryParams = getQueryParams();
		if ('bst' in queryParams) {
			try {
				newObj = JSON.parse(Buffer.from(queryParams.bst, 'base64').toString()) as { [id: string]: any };
			} catch (e) {
				console.error('Failed to get query parameters from url', e);
			}
		}

		newObj[this.keyRoot] = this.getSettings();

		queryParams.bst = Buffer.from(JSON.stringify(newObj)).toString('base64');

		// rebuild the query string
		let newQueryString = '';
		const keys = Object.keys(queryParams);
		for (let i = 0; i < keys.length; i++) {
			newQueryString += i === 0 ? '?' : '&';
			newQueryString += `${keys[i]}=${queryParams[keys[i]]}`
		}

		// set the query string without causing a redirect.
		window.history.replaceState({}, '', `${window.location.pathname}${newQueryString}`)
	}

	resetToDefault = () => {
		if (this.defaults) {
			this.store.sortKeyState.set(this.defaults.sortKey);
			this.store.sortAscendingState.set(this.defaults.sortKeyAscending);
			this.store.columnOrderState.set(this.defaults.columnOrder);
			this.store.filtersState.set(this.defaults.sortFilters);
			this.store.hiddenColumnsState.set(this.defaults.hiddenColumns as number[]);
			if (this.defaults.pageSize) {
				this.store.pageSizeState.set(this.defaults.pageSize);
			}
			if (this.defaults.initialPageSize) {
				this.store.initialPageSizeState.set(this.defaults.initialPageSize);
			}

			// clear selected preset
			this.store.selectedPresetIdState.set('');
			this.store.showActionBarState.set(true);
			this.saveSettings();
		} else {
			console.warn('SettingsSaver does not have defaults to reset to');
		}
	}

	getSettings = (): SaveObject => {
		return {
			version: 2,
			initialPageSize: this.store.initialPageSizeState.get(),
			pageSize: this.store.pageSizeState.get(),
			sortKey: this.store.sortKeyState.get(),
			sortKeyAscending: this.store.sortAscendingState.get(),
			columnOrder: this.store.columnOrderState.get(),
			sortFilters: this.store.filtersState.get(),
			hiddenColumns: this.store.hiddenColumnsState.get(),
			columnIds: this.columns.map(col => col.id),
			showActionBar: this.store.showActionBarState.get()
		};
	}

	updateSettings = (
		obj: SaveObject,
		hideNewColumns: boolean = false
	) => {
		const newColumnIds = this.columns.map(col => col.id);

		const columnsChanged = (() => {
			// we have new column ids
			if (obj.columnIds) {
				return JSON.stringify(obj.columnIds) !== JSON.stringify(newColumnIds);
			} else {
				// saved filters does not have column ids. See if lengths match
				return this.defaults?.sortFilters.length !== obj.sortFilters.length;
			}
		})();

		// handle upgrading hidden columns from v1 from columns to indexes
		let hiddenColumns = obj.hiddenColumns;
		if (!obj.version || obj.version < 2) {
			if (obj.columnIds) {
				// map through column ids and use old index to recover
				// hidden columns went from strings[] of fields to indexes[]
				hiddenColumns = hiddenColumns.map(field => {
					const newIndex = this.columns.findIndex(y => y.field === field);
					if (newIndex > -1) {
						const id = this.columns[newIndex].id;
						return obj.columnIds?.indexOf(id) as number;
					} else {
						// column no longer exists
						return undefined;
					}
				}).filter(x => x !== undefined) as number[];
			} else {
				if (columnsChanged) {
					// unfortunately, we cannot recover without and old index mapping.
					hiddenColumns = this.defaults?.hiddenColumns as number[];
				} else {
					// leave the columns as they are
				}
			}
		}

		if (obj.pageSize) {
			this.store.pageSizeState.set(obj.pageSize);
		}
		if (obj.initialPageSize) {
			this.store.initialPageSizeState.set(obj.initialPageSize);
		}
		if ('showActionBar' in obj) {
			this.store.showActionBarState.set(Boolean(obj.showActionBar));
		}

		this.store.sortKeyState.set(obj.sortKey);
		this.store.sortAscendingState.set(obj.sortKeyAscending);

		// load fields if columns didn't change
		if (!columnsChanged) {
			this.store.hiddenColumnsState.set(hiddenColumns as number[]);
		}
		if (!columnsChanged) {
			this.store.columnOrderState.set(obj.columnOrder);
		}
		if (!columnsChanged) {
			this.store.filtersState.set(obj.sortFilters);
		}

		// handle new system to use column ids to replace items
		if (obj.columnIds && columnsChanged) {
			const newIndexes = this.columns.map((x, i) => i);

			const addedIndexes = this.columns.map((x, i) => {
				if (obj.columnIds?.includes(x.id)) {
					// column exists
					return undefined;
				} else {
					// new column that was added since save
					return i;
				}
			}).filter(x => x !== undefined) as number[];

			// hiddenColumns
			let newHiddenColumns = (hiddenColumns as number[]).map(oldColIndex => {
				//@ts-ignore
				const id = obj.columnIds[oldColIndex];
				const newColIndex = newColumnIds.indexOf(id);
				if (newColIndex > -1) {
					return newColIndex;
				} else {
					// no longer in columns. undefined and filter out later
					return undefined;
				}
			}).filter(x => x !== undefined) as number[];

			if (hideNewColumns) {
				//add new columns to the hidden columns
				newHiddenColumns = [...newHiddenColumns, ...addedIndexes];

			}
			this.store.hiddenColumnsState.set(newHiddenColumns);

			// columnOrder
			let newColumnOrder = obj.columnOrder.map((oldColIndex) => {
				//@ts-ignore
				const id = obj.columnIds[oldColIndex];
				const newColIndex = newColumnIds.indexOf(id);
				if (newColIndex > -1) {
					return newColIndex;
				} else {
					// no longer in columns. undefined and filter out later
					return undefined;
				}
			}).filter(x => x !== undefined) as number[];

			// add unused new column ids to the end
			newColumnOrder = [...newColumnOrder, ...newIndexes.filter(x => !newColumnOrder.includes(x))];
			// sate the rebuilt column order
			this.store.columnOrderState.set(newColumnOrder);

			//sortFilters
			// make empty array of strings
			const newFilters = this.columns.map(() => '');
			// go through all filters and put them in new positions
			for (let i = 0; i < obj.sortFilters.length; i++) {
				const filter = obj.sortFilters[i];
				if (filter.length > 0) {
					//@ts-ignore
					const id = obj.columnIds[i];
					const newColIndex = newColumnIds.indexOf(id);
					if (newColIndex > -1) {
						newFilters[newColIndex] = filter;
					} else {
						// no longer in columns.
					}
				}
			}
			this.store.filtersState.set(newFilters);
		}

		this.updateUrlParams();

		return true;
	}

}
