import axios from 'axios';
import {getTaskUrl} from './util';

export type ContactListItem = {
  id: number;
  userId: number | null;
  username: string | null;
  name: string;
  fullAddresses: string | null;
  phoneNumbers: string | null;
  email: string;
  primaryPhoneNumber: string | null;
  property: string | null;
  hasInvestorProfile: boolean
};

const getContactList = async () => {
  let url = `${getTaskUrl()}/contact`;
  const response = await axios.get(url);
  return  response.data as ContactListItem[];
};

export default getContactList;
