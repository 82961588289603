import axios, {AxiosResponse} from "axios";
import getUserApiPath from "@hornet-api/getUserApiPath";
import {TwoFactorAuth} from "@interfaces/TwoFactorAuth";

const verifyAndActivate2FA = async (data: { otp2fa: string }) => {
  const url = `${getUserApiPath()}/2fa/verify`;
  const response: AxiosResponse<{
    success: boolean;
    message?: string;
    twoFactorAuth: TwoFactorAuth | null
  }> = await axios.put(url, data)
  return response.data
};

export default verifyAndActivate2FA;
