import hasAuthority from "@common/hasAuthority";
import React, {useMemo} from "react";
import {NavigationObjectItem} from "@components/Navigation/getNavigationObject";
import activeRoleState from "@state/globalState/activeRoleState";
import userRoleGroupsState from "@state/globalState/userRoleGroupsState";
import NavLinkMobile from "@components/Navigation/Mobile/NavLinkMobile";
import './SubMenuLinkMobile.scss';
import RoleGroupRoles from "@interfaces/RoleGroupRoles";

interface INavigationMenuRenderer {
  nav: NavigationObjectItem;
  onLinkClick: (e: React.MouseEvent) => void;
}

export const roleBasedChildrenRenderer = (nav: NavigationObjectItem, onLinkClick: (e: React.MouseEvent) => void, activeRole: number | null,
                                          userRoleGroups: RoleGroupRoles[] | null) => {
  return nav.children?.map((subNav, sIndex) => {
    if (subNav.hide) {
      return null;
    } else if (subNav?.divider) {
      return null;
    } else if (!activeRole && subNav.roles) {
      return null;
    } else if (activeRole && subNav.excludeRoles && (hasAuthority(subNav.excludeRoles, userRoleGroups, activeRole))) {
      return null;
    } else if (subNav.roles && activeRole && !(hasAuthority(subNav.roles, userRoleGroups, activeRole))) {
      return null;
    } else {
      return (
        <li className="side_link sub" key={sIndex}>
          <NavLinkMobile nav={subNav} linkClick={onLinkClick}/>
        </li>
      );
    }
  }).filter(x => x);
}

const NavigationMenuRenderer = ({nav, onLinkClick}: INavigationMenuRenderer) => {
  const activeRole = activeRoleState.useValue();
  const userRoleGroups = userRoleGroupsState.useValue();

  const roleBasedChildren = useMemo(() => {
    return roleBasedChildrenRenderer(nav, onLinkClick, activeRole, userRoleGroups);
  }, [nav, userRoleGroups, activeRole]);

  if (nav.hide) {
    return null;
  } else if (!activeRole && nav.roles) {
    return null;
  } else if (activeRole && nav.excludeRoles && (hasAuthority(nav.excludeRoles, userRoleGroups, activeRole))) {
    return null;
  } else if (nav.roles && activeRole && !(hasAuthority(nav.roles, userRoleGroups, activeRole))) {
    return null;
  } else if (roleBasedChildren?.length && nav.id) {
    return (
      <React.Fragment>
        <li className='group-title'>
          {nav.text}
        </li>
        {roleBasedChildren}
      </React.Fragment>
    )
  } else {
    return (
      <li className='side_link'>
        <NavLinkMobile nav={nav} linkClick={onLinkClick}/>
      </li>
    )
  }
}

export default NavigationMenuRenderer