import axios from 'axios';
import getAdminApiPath from '../getAdminApiPath';
import {PropertyServer} from '@interfaces/Property';

export const getPropertyById = async (id: number | string) => {
  const url = `${getAdminApiPath()}/property/${id}`;
  const response = await axios.get(url);
  return response.data as PropertyServer;
};


