import React from 'react';

import hornetLogoRegular from '@assets/images/hornet_logo_regular.svg'
import useInterval from "@common/useInterval";
import getRegistrationRoleGroups from "@hornet-api/public/getRegistrationRoleGroups";
import isMaintenanceState from "@state/globalState/isMaintenanceState";


const Maintenance = () => {
  useInterval(() => {
    // this api keeps checking if the server is up
    getRegistrationRoleGroups()
      .then(() => isMaintenanceState.set(false))
  }, 10000);

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-4"></div>
        <div className="col-md-4">
          <div className="text-center">
            <div
              className="logo"
              style={{
                marginTop: 100
              }}
            >
              <img src={hornetLogoRegular} alt={'Hornet Logo'}/>
            </div>
            <p className="lead" style={{ marginTop: 20 }}>
              Scheduled Maintenance
            </p>
            <p>
              We added some more awesomeness to our website and the nerds are uploading it right meow.  By the time you are done calling your friends and bragging about us, we should be back up and running.
            </p>

            <a
              className="btn btn-primary"
              href="#"
              id="reload-link"
              style={{
                textDecoration: 'none',
                marginTop: 20
              }}
              onClick={(e) => {
                e.preventDefault();
                window.location.reload();
              }}
            >
              TRY AGAIN
            </a>
          </div>

        </div>
        <div className="col-md-4"></div>
      </div>
    </div>
  )
}

export default Maintenance;
