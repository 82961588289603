import React, { useEffect, useCallback, useRef } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import SettingsSaver from './lib/SettingsSaver';
import {Column} from './Types';
import Store from './globalState';
import ShowHideColumns from './ShowHideColumns';
import ShowHideColumnsTooltip from './ShowHideColumnsTooltip';
import FontAwesomeIcon from './lib/FontAwesomeIcon';

import { Styles } from './interfaces';

type IShowHideColumnsButtonProps<D> = {
	columns: Column<D>[],
	store: Store<D>,
	settingsSaver: SettingsSaver<D>
}

const styles:Styles = {
	showHideColumnsButton: {
		marginRight: 5,
		position: 'relative'
	},
	showHideColumnsContainer: {
		display: 'inline-block',
		position: 'relative'
	},
};

function ShowHideColumnsButton<D>(
	{
		columns,
		store,
		settingsSaver
	}: IShowHideColumnsButtonProps<D>
) {
	let dropDownRef = useRef<HTMLDivElement>(null);

	const [hiddenColumns, setHiddenColumns] = store.hiddenColumnsState.use();
	const [showHideColumnsVisible, setShowHideColumnsVisible] = store.showHideColumnsVisibleState.use();

	const handleOutsideClickEvent = useCallback((event) => {
		const isClickInside = dropDownRef.current?.contains(event.target);
		if (!isClickInside) {
			setShowHideColumnsVisible(false);
			document.removeEventListener('click', handleOutsideClickEvent);
		}
	}, [setShowHideColumnsVisible]);

	const handleShowHideColumnsClick = useCallback(() => {
		setShowHideColumnsVisible(true);
	}, [setShowHideColumnsVisible]);

	const handleOnShowHideColumnsChange = useCallback((newHiddenColumns) => {
		console.log('hiddenColumns', newHiddenColumns);
		store.selectedPresetIdState.set('');
		setHiddenColumns(newHiddenColumns);
	}, [settingsSaver, setHiddenColumns]);

	useEffect(() => {
		if (showHideColumnsVisible && dropDownRef?.current) {
			// this is needed for state update
			window.requestAnimationFrame(() => {
				document.addEventListener('click', handleOutsideClickEvent);
			})
		}
		
		return () => {
			if (showHideColumnsVisible) {
				// remove any lingering listeners
				document.removeEventListener('click', handleOutsideClickEvent);
			}
		};
	}, [showHideColumnsVisible, dropDownRef, handleOutsideClickEvent]);

	return (
		<div style={styles.showHideColumnsContainer}>
			<OverlayTrigger placement="bottom-end" overlay={<ShowHideColumnsTooltip />}>
				<button
					className="btn btn-sm btn-primary"
					style={styles.showHideColumnsButton}
					onClick={handleShowHideColumnsClick}
				>
					<FontAwesomeIcon icon="eye-slash" />
				</button>
			</OverlayTrigger>
			<div ref={dropDownRef}>
				<ShowHideColumns
					columns={columns}
					hiddenColumns={hiddenColumns}
					isVisible={showHideColumnsVisible}
					onChange={handleOnShowHideColumnsChange}
				/>
			</div>
		</div>
	);
}

export default ShowHideColumnsButton;
