import React from 'react';
import Store from './globalState';
import MobileTableRow from './MobileTableRow';
import NoTableData from './NoTableData';
import LoadingTableData from './LoadingTableData';
import {Column} from './index';
import {Styles} from './interfaces';

type Props<D> = {
  store: Store<D>,
  isLoading?: boolean,
  sortedData: D[],
  columns: Column<D>[],
  mobileCollapseCardRender?: (row: D, state: Store<D>) => JSX.Element,
  isVisible: boolean,
  isRemote?: boolean;
  refreshTableData?: () => void
};

function MobileTable<D>(
  {
    store,
    isLoading,
    sortedData,
    columns,
    mobileCollapseCardRender,
    isVisible,
    isRemote,
    refreshTableData
  }: Props<D>
) {

  const styles: Styles = {
    container: {
      textAlign: 'center',
      position: 'relative',
      height: 120,
      width: '100%',
      display: isVisible ? 'block' : 'none'
    }
  };

  if (isLoading) {
    return (
      <div style={styles.container}>
        <LoadingTableData />
      </div>
    );
  }

  if (!isLoading && sortedData.length === 0) {
    return (
      <div style={styles.container}>
        <NoTableData />
      </div>
    );
  }

  return (
    <div
      style={{
        display: isVisible ? 'block' : 'none'
      }}
    >
      {
        sortedData?.map((row, index) => {
          return (
            <MobileTableRow
              key={index}
              store={store}
              row={row}
              columns={columns}
              mobileCollapseCardRender={mobileCollapseCardRender}
              isRemote={isRemote}
              refreshTableData={refreshTableData}
            />
          );
        })
      }
    </div>
  );
}

export default MobileTable;