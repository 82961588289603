import React from 'react';
import FontAwesomeIcon from './lib/FontAwesomeIcon';
import { Styles } from './interfaces';

const styles: Styles = {
	container: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		width: '80%',
		maxWidth: 300,
		transform: 'translateX(-50%) translateY(-50%)',
		opacity: 0.5
	}
};

const LoadingTableData = () => {
	return (
		<div style={styles.container}>
			<FontAwesomeIcon icon="circle-o-notch" spin size="2x" fixedWidth />
			<br />
			<span>Loading</span>
		</div>
	);
};

export default LoadingTableData;
