import React, { useEffect } from 'react';
import useLogoutHandler from "@legacy/views/auth/useLogoutHandler";

const LogoutView = () => {
  const logoutHandler = useLogoutHandler();
  useEffect(() => {
    logoutHandler();
  }, [logoutHandler]);
  return <></>;
};

export default LogoutView;
