import React from 'react';
import SettingsSaver from './lib/SettingsSaver';
import {Column} from './Types';
import Store from './globalState';
import ExportToCsvButton from './ExportToCsvButton';
import ResetFiltersButton from './ResetFiltersButton';
import ShowHideColumnsButton from './ShowHideColumnsButton';
import ShowHideActionBarButton from './ShowHideActionBarButton';
import PageSizeSettingButton from './PageSizeSettingButton';
import PresetSelector from './PresetSelector';
import ReloadTableDataButton from "@components/BootstrapSmartTable/ReloadTableDataButton";

type ITopToolbarProps<D> = {
	columns: Column<D>[],
	initialPageSize: number,
	pageSizeOptions: number[],
	sortedData: D[],
	tableName: string,
	store: Store<D>,
	settingsSaver: SettingsSaver<D>;
	isRemote?: boolean;
	exportDataFetcher?: () => Promise<D[]>;
	reload?: null | (() => void)
}

const styles = {
	container: {
		marginBottom: 20
	},
	reloadButton: {
		marginRight: 5
	}
};

function TopToolbar<D>(
	{
		columns,
		initialPageSize,
		pageSizeOptions,
		sortedData,
		tableName,
		store,
		settingsSaver,
		isRemote,
		exportDataFetcher,
		reload
	}: ITopToolbarProps<D>
) {

	return (
		<div className="row p-3">
			<div className="col text-left">
				<span>Show</span>
				<PageSizeSettingButton
					pageSizeOptions={pageSizeOptions}
					store={store}
					settingsSaver={settingsSaver}
					initialPageSize={initialPageSize}
				/>
				<PresetSelector
					tableName={tableName}
					store={store}
					settingsSaver={settingsSaver}
				/>
			</div>
			<div className="col-auto text-right">
				{reload && <ReloadTableDataButton reload={reload}/>}
				<ShowHideActionBarButton
					columns={columns}
					store={store}
					settingsSaver={settingsSaver}
				/>
				<ResetFiltersButton
					settingsSaver={settingsSaver}
				/>
				<ShowHideColumnsButton
					columns={columns}
					store={store}
					settingsSaver={settingsSaver}
				/>
				<ExportToCsvButton
					columns={columns}
					sortedData={sortedData}
					tableName={tableName}
					store={store}
					isRemote={isRemote}
					exportDataFetcher={exportDataFetcher}
				/>
			</div>
		</div>
	);
}

export default TopToolbar;
