import axios from 'axios';
import getAdminApiPath from "@hornet-api/getAdminApiPath";
import {IBudget} from "@interfaces/loan/admin/budget";
import {BudgetStatusEnum} from "@interfaces/GeneratedEnums";

export const updateBudgetStatus = async (id: number, status: keyof typeof BudgetStatusEnum) => {
  const url = `${getAdminApiPath()}/budget/${id}`;
  const response = await axios.patch(url, {status});
  return response.data as IBudget;
};
