import axios from 'axios';
import getAdminApiPath from '../getAdminApiPath';
import LoanParticipationAgreement from '@interfaces/LoanParticipationAgreement';

const getLPAs = async (contactId: number | string) => {
	const url = `${getAdminApiPath()}/contact/${contactId}/loans`;
	const response: any = await axios.get(url);
	//@ts-ignore
	return response.data.filter(x => x) as LoanParticipationAgreement[];
};

export default getLPAs;
