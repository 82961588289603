import axios from 'axios';
import { getCleanBaseUri } from '@common/baseUri';

const tokenToSession = () => {
  return new Promise<string | null>((resolve, reject) => {
    const url = getCleanBaseUri() + '/login/tokenToSessionAuthApi';
    axios({
      method: 'get',
      url: url
    })
      .then((response: any) => {
        // do nothing. Should have worked
        resolve(null);
      })
      .catch((error) => {
        console.log(error);
        resolve(null);
      });
  });
};

export default tokenToSession;
