import React, {useMemo} from 'react';
import {Column} from './index';
import Store from './globalState';
import MultiSelect from './MultiSelect';
import FontAwesomeIcon from './lib/FontAwesomeIcon';
import style from './style.module.scss';
import FilterDate from './FilterDate';

type FilterRowProps<D> = {
	columns: Column<D>[]
	store: Store<D>,
	hasExpandable?: boolean;
}

export default function FilterRow<D>(props: FilterRowProps<D>) {
	const {columns, store, hasExpandable} = props;
	const [hiddenColumns] = store.hiddenColumnsState.use();
	const setCurrentPage = store.currentPageState.set;
	const setEditField = store.editFieldState.set;
	const [filters, setFilters] = store.filtersState.use();
	const [columnOrder] = store.columnOrderState.use();
	const actionColIndex = columns.findIndex(x => x.actionBar === true);
	const actionCol = actionColIndex >= 0 ? columns[actionColIndex] : null;
	const visibleColumnOrder = useMemo(() => {
		return columnOrder.filter(x => !(hiddenColumns.includes(x)));
	}, [columnOrder, hiddenColumns, columns]);

	const getColumn = (colIndex: number) => {
		const col = columns[colIndex];
		const align = visibleColumnOrder.indexOf(colIndex) > visibleColumnOrder.length * 0.5 ? 'right' : 'left';
		if (col.filter) {
			if (col.lookup) {
				return (
					<div
						className=""
						style={{
							paddingRight: filters[colIndex] && filters[colIndex].length > 0 ? 29 : 0
						}}
					>
						<MultiSelect
							options={
								Object.keys(col.lookup).map((key) => {
									return (
										{
											//@ts-ignore
											label: col.lookup[key] as string,
											value: key
										}
									);
								})
							}
							// align={colIndex / columns.length > 0.5 ? 'right' : 'left'}
							align={align}
							//@ts-ignore
							value={
								//@ts-ignore
								Object.keys(col.lookup).reduce((prev, next) => {
									if (filters[colIndex] && filters[colIndex].split('|').some(v => v === next)) {
										return [
											...prev,
											{
												//@ts-ignore
												label: col.lookup[next] as string,
												value: next
											}
										];
									}
									return prev;
								}, [])
							}
							onChange={(newSelect: unknown[]) => {
								const newFilters = filters.map((filter, fi) => {
									if (fi === colIndex)
										//@ts-ignore
										return newSelect.map(x => x.value).join('|');

									return filter;
								}) as string[];

								setFilters(newFilters);
								setEditField(null);
								setCurrentPage(1);
								store.selectedPresetIdState.set('');
							}}
						/>
					</div>
				)
			}
			if (col.date) {
				return (
					<FilterDate
						colIndex={colIndex}
						store={store}
						align={align}
					/>
				);
			}

			// default text box return
			return (
				<div className="input-group">
					<input
						className={`form-control form-control-sm ${filters[colIndex].length > 0 ? 'border-right-0' : ''}`}
						type="text"
						value={filters[colIndex]}
						onChange={(event) => {
							const newFilters = filters.map((filter, fi) => {
								if (fi === colIndex)
									return event.target.value;

								return filter;
							}) as string[];
							setEditField(null);
							setCurrentPage(1);
							setFilters(newFilters);
							store.selectedPresetIdState.set('');
						}}
					/>
					{
						filters[colIndex] && filters[colIndex].length > 0 ?
							<div className="input-group-append bg-white border-left-0">
								<span
									className="input-group-text bg-transparent"
									onClick={() => {
										const newFilters = filters.map((filter, fi) => {
											if (fi === colIndex)
												return '';

											return filter;
										}) as string[];
										setEditField(null);
										setCurrentPage(1);
										setFilters(newFilters);
										store.selectedPresetIdState.set('');
									}}
								>
									<FontAwesomeIcon icon="times"/>
								</span>
							</div>
							:
							null
					}
				</div>
			); // end default

		} else {
			// no filter
			return null;
		}
	}
	return (
		<>
			<tr className="table-filter-row">
				{hasExpandable && <td></td>}
				{columnOrder.map((colIndex) => {
					const col = columns[colIndex];
					if (col.actionBar) {
						return null;
					}
					if (!(hiddenColumns.includes(colIndex))) {
						return (
							<td
								key={colIndex}
							>
								{getColumn(colIndex)}
							</td>
						);
					}
					return null;
				})}
				{actionCol ? <td className={style.actionCell}/> : null}
			</tr>
		</>
	);
}
