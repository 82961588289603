import axios from 'axios';
import getAdminApiPath from "@hornet-api/getAdminApiPath";
import {IBudget} from "@interfaces/loan/admin/budget";
import {BudgetStatusEnum} from "@interfaces/GeneratedEnums";

export const getBudgetList = async (status: null | (keyof typeof BudgetStatusEnum)[] = null) => {
  const url = `${getAdminApiPath()}/budget${status ? `?status=${status.join(',')}` : ''}`;
  const response = await axios.get(url);
  return response.data as IBudget[];
};
