import React, {useEffect} from 'react';
import configurationAtom from "@state/recoil/configurationAtom";
import {useRecoilValue} from "recoil";

// redirect to the legacy version with same url
const RerouteToLegacy = () => {
  const configuration = useRecoilValue(configurationAtom);
  useEffect(() => {
    if(configuration) {
      let newUrl = configuration.api_uri_legacy;
      if(newUrl.endsWith('/')) newUrl = newUrl.slice(0, -1);
      newUrl += `${window.location.pathname}${window.location.search}`;
      window.location.replace(newUrl);
    } else {
      console.log('No configuration');
    }
  }, []);

  return null;
}

export default RerouteToLegacy;