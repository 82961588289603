import {formatDateForServer} from "@common/basic";

const convertJsonToFormData = <T>(obj: { [K in keyof T]: T[K] | string | File | Date }, form?: FormData, namespace?: string) => {
  let fd = form || new FormData();
  let formKey;

  for (let property in obj) {
    if (obj.hasOwnProperty(property)) {
      if (namespace) {
        formKey = `${namespace}.${property}`;
      } else {
        formKey = property;
      }

      // if the property is an object, but not a File, use recursion.
      if (obj[property] instanceof Date) {
        fd.append(formKey, formatDateForServer(obj[property] as Date));
      } else if (
        typeof obj[property] === "object" &&
        !(obj[property] instanceof File)
      ) {
        convertJsonToFormData(obj[property], fd, formKey);
      } else {
        // if it's a string or a File object
        fd.append(formKey, obj[property] as string | Blob);
      }
    }
  }

  return fd;
};

export default convertJsonToFormData;