import {Column} from "@components/BootstrapSmartTable/Types";
import FontAwesomeIcon from "@components/Icons/FontAwesomeIcon/index";
import React from "react";
import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";
import {AnnouncementWithStatus} from "@interfaces/AnnouncementWithStatus";
import {formatDate} from "@common/basic";

const formatData = (str: string) => {
  return str.split(/\r?\n/).map((line, index) => {
    return <div key={index}>{line}</div>;
  });
};

export const getColumns = (
  updateAnnouncementReadStatus: (id: number, isRead: boolean) => void,
): Column<AnnouncementWithStatus>[] => {
  return ([
    {
      id: 'datCre',
      title: 'Date',
      field: 'dateCreated',
      filter: true,
      date: true,
      render: (row) => formatDate(row.dateCreated)
    },
    {
      id: 'con',
      title: 'Content',
      field: 'content',
      filter: true,
      render: (row) => {
        return <>{formatData(row.content)}</>
      }
    },
    {
      id: 'sta',
      title: 'Read Status',
      field: 'isRead',
      render: (row) => {
        return (
          <OverlayTrigger
            overlay={<Tooltip id={`tt-mark-${row.id}`}>Mark as {row.isRead ? 'Unread' : 'Read'}</Tooltip>}
          >
            <Button
              size={'sm'}
              variant={row.isRead ? 'primary' : 'danger'}
              onClick={() => {
                updateAnnouncementReadStatus(row.id, !row.isRead)
              }}
            >
              <FontAwesomeIcon icon="envelope"/>
            </Button>
          </OverlayTrigger>
        )
      }
    },
  ]);
};

