import React, {useMemo, useState} from 'react';
import Store from './globalState';
import {Column, DecryptAll} from './Types';
import TableCell from './TableCell';
import style from './style.module.scss';
import {IoChevronDownCircleOutline, IoChevronUpCircleOutline} from "react-icons/io5";


type TableRowProps<D> = {
  data?: D,
  index: number,
  visible?: boolean,
  columnOrder: number[],
  columns: Column<D>[],
  store: Store<D>,
  isRemote?: boolean;
  decryptAll?: DecryptAll,
  refreshTableData?: () => void;
  expandable?: {
    render: (row: D) => React.ReactNode
  }
}

function TableRow<D>(
  {
    data,
    index,
    visible = true,
    columnOrder,
    columns,
    store,
    isRemote,
    decryptAll,
    refreshTableData,
    expandable
  }: TableRowProps<D>
) {

  const [isExpended, setExpended] = useState<boolean>(false);
  const hiddenColumn = store.hiddenColumnsState.useValue();

  const actionBar = useMemo(() => {
    const actionColIndex = columns.findIndex(x => x.actionBar === true);
    const actionCol = actionColIndex >= 0 ? columns[actionColIndex] : null;
    if (!actionCol || !data) {
      return null;
    }

    return (
      <TableCell
        key={actionColIndex}
        col={actionCol}
        colIndex={actionColIndex}
        row={data}
        store={store}
        xPos={(actionColIndex + 1) / columns.length}
        isRemote={isRemote}
        refreshTableData={refreshTableData}
        decryptAll={decryptAll}
      />
    );
  }, [data, visible, columnOrder, columns, store]);

  const columnDisplay = useMemo(() => {
    if (!data || !visible || !columnOrder) return null;

    return columnOrder.map((colIndex) => {
      const col = columns[colIndex];
      if (!col) {
        return false;
      }
      const xPos = (colIndex + 1) / columns.length;
      if (col.actionBar) {
        return false;
      }
      return <TableCell
        key={colIndex}
        colIndex={colIndex}
        col={col}
        row={data}
        store={store}
        xPos={xPos}
        isRemote={isRemote}
        refreshTableData={refreshTableData}
        decryptAll={decryptAll}
      />;
    })
  }, [data, visible, columnOrder, columns, store]);

  const rowClassName = useMemo(() => {
    return `${style.tableRow} ${(index + 1) % 2 !== 0 ? style.odd : ''}`
  }, [index, expandable])

  if (columnDisplay === null) return null;
  return (
    <>
      <tr className={rowClassName}>
        {expandable && (
          <td>
            <span
              className={'expand-collapse-button'}
              onClick={() => {
                setExpended(!isExpended)
              }}
            >
              {isExpended ? (<IoChevronUpCircleOutline/>) : (<IoChevronDownCircleOutline/>)}
            </span>
          </td>
        )}
        {columnDisplay}
        {actionBar}
      </tr>
      {isExpended && data ? (
        <tr className={`${rowClassName} ${style.expanded}`}>
          <td className={'border-0'}></td>
          <td className={'border-0 px-0'}
              colSpan={columns.length - hiddenColumn.length}>{expandable?.render(data)}</td>
        </tr>
      ) : null}
    </>
  );
}

export default TableRow;
