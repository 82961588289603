import {getTaskUrl} from "@hornet-api/task/util";
import axios from "axios";
import {ReferralStatusEnum} from "@interfaces/GeneratedEnums";

export type IAmbReferralItem = {
  id: number;
  referralEmail: string;
  referralName: string;
  contactId: number | null;
  ambassadorId: number;
  ambassadorEmail: string;
  ambassadorName: string;
  status: keyof typeof ReferralStatusEnum;
}
export const getAmbassadorListData = async () => {
  const url = `${getTaskUrl()}/ambassador/listData`;
  const response = await axios.get(url);
  return response.data as IAmbReferralItem[];
}