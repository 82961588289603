import React from 'react';
import {Redirect} from "react-router-dom";
import {useRecoilValue} from "recoil";
import profileAtom from "@state/recoil/profileAtom";
import hasAuthority from "@common/hasAuthority";
import userRoleGroupsState from "@state/globalState/userRoleGroupsState";
import activeRoleState from "@state/globalState/activeRoleState";

type Props = {
  roles: string[],
  to?: string,
  children?: React.ReactChild | React.ReactChild[],
};

const RoleRedirect = (
  {
    roles,
    to = '/',
    children
  }: Props
) => {
  const activeRole = activeRoleState.useValue();
  const userRoleGroups = userRoleGroupsState.useValue();
  const profile = useRecoilValue(profileAtom);

  if(!profile || !activeRole) return <></>
  if (!hasAuthority(roles, userRoleGroups, activeRole)){
    return (
      <Redirect to={to}/>
    );
  }
  return (
    <>{children ? children : null}</>
  )

}

export default RoleRedirect