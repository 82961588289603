import axios from 'axios';
import getUserApiPath from "@hornet-api/getUserApiPath";
import {DashboardAccessEnum} from "@interfaces/GeneratedEnums";

/**
 * Fetches Dashboard Access Status
 *
 */
export const getDashboardAccess = () => {
  const url = `${getUserApiPath()}/contact/dashboardAccess`;
  return axios.get<{
    status: keyof typeof DashboardAccessEnum
  }>(url).then(res => res.data.status)
};
