import React, {useEffect, useState} from "react";
import {FaMicrophone, FaMicrophoneSlash, FaVolumeUp} from "react-icons/fa";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {ProgressBar} from "react-bootstrap";
import {Call} from "@twilio/voice-sdk";

export type VolumeVariantType = 'success' | 'danger' | 'warning' | 'info';

interface Props {
  onGoingCall: Call
}

const handleVolume = (volume: number, setColor: (color: VolumeVariantType) => void, setWidth: (width: number) => void) => {
  if (volume < 0.30) {
    setColor("success");
  } else if (volume < 0.65) {
    setColor("warning");
  } else {
    setColor("danger");
  }
  setWidth(Math.floor(volume * 100));
}

const VolumeProgressBar = ({onGoingCall}: Props) => {

  const [micProgress, setMicProgress] = useState<number>(0);
  const [speakerProgress, setSpeakerProgress] = useState<number>(0);
  const [micVariant, setMicVariant] = useState<VolumeVariantType>('info');
  const [speakerVariant, setSpeakerVariant] = useState<VolumeVariantType>('info');

  useEffect(() => {
    if (onGoingCall) {
      onGoingCall.on("volume", (inputVolume, outputVolume) => {
        handleVolume(inputVolume, setMicVariant, setMicProgress);
        handleVolume(outputVolume, setSpeakerVariant, setSpeakerProgress);
      });
    } else {
      handleVolume(0, setMicVariant, setMicProgress);
      handleVolume(0, setSpeakerVariant, setSpeakerProgress);
    }
  }, []);

  return (
    <>
      <Row>
        <Col className='col-2'>
          <span className="text-warning">
            {
              onGoingCall.isMuted() ?
                <FaMicrophoneSlash size="20"/> :
                <FaMicrophone size="20"/>
            }
          </span>
        </Col>
        <Col className='col-10'>
          <ProgressBar striped variant={micVariant} now={micProgress}/>
        </Col>
      </Row>
      <Row>
        <Col className='col-2'><FaVolumeUp/></Col>
        <Col className='col-10'>
          <ProgressBar striped variant={speakerVariant} now={speakerProgress}/>
        </Col>
      </Row>
    </>
  );
};

export default VolumeProgressBar;
