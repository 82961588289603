import React, {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import {createGlobalState} from "react-global-hooks";
import Button from "react-bootstrap/Button";

const allowedContentTypes = ['application/pdf', 'image', 'text'];

interface IViewDocumentModalState {
  file: File;
  onCancel?: () => Promise<void> | void;
}

export const viewDocumentModalState = createGlobalState<IViewDocumentModalState | null>(null);

export const showFileDocument = (modalState: IViewDocumentModalState) => {
  viewDocumentModalState.set(modalState);
};

const ViewDocumentModal = () => {
  const [modalState, setModalState] = viewDocumentModalState.use();
  const [fileContentUrl, setFileContentUrl] = useState<string>('');

  const handleClose = () => {
    modalState!.onCancel?.();
    setModalState(null);
  };

  useEffect(() => {
    if (!modalState?.file) return;
    if (!modalState.file.type.startsWith('text/csv') && allowedContentTypes.some((type) => modalState.file.type.startsWith(type))) {
      setFileContentUrl(URL.createObjectURL(modalState.file));
    } else {
      handleClose();
      alert('This file cannot be viewed.');
    }
    return () => {
      URL.revokeObjectURL(fileContentUrl);
    };
  }, [modalState]);

  if (!modalState?.file) {
    return null;
  }

  return (
    <Modal
      show={!!modalState}
      onHide={handleClose}
      id="viewDocumentModal"
      size='xl'
      className={'view-file-modal'}
    >
      <Modal.Header closeButton>
        <Modal.Title>Document: {modalState.file.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="position-relative">
        {fileContentUrl && (
          <iframe
            className="file-view-iframe"
            src={fileContentUrl}
            title={modalState.file.name}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ViewDocumentModal;
