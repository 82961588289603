import React from 'react';
import HornetIcon from '@components/Loading/HornetIcon';
import style from './LoadingOverlay.module.scss';
import classNames from 'classnames';

import { isLoadingState } from '../globalState';

const LoadingOverlay = () => {
  const isLoading = isLoadingState.useValue();
  return (
    <div className={isLoading ? classNames(style.overlay, style.active) : style.overlay}>
      <div className={style.iconBox}>
        <HornetIcon />
      </div>
    </div>
  );
}

export default LoadingOverlay;