/* eslint-disable indent */
import React, {useCallback} from 'react';
import Select, {GroupBase} from 'react-select';
import CreatableSelect from 'react-select/creatable';
import AsyncSelect, {AsyncProps} from "react-select/async";
import memoize from "lodash/memoize";

import styles from './styles.module.scss';
import "./BaseSelectBox.scss";

const BaseSelectBoxWithRef = React.forwardRef(
  function <T>(
    {
      randomId,
      onChange,
      isCreatable = false,
      className = '',
      ...rest
    }: AsyncProps<T, boolean, GroupBase<T>> & React.HTMLProps<HTMLElement> & {
      randomId?: string;
      isCreatable?: boolean,
      options?: T[],
      loadOptions?: (value: string) => Promise<T[]>,
      isOptionDisabled?: ((option: T) => boolean) & ((option: T, selectedValue: T[]) => boolean);
    },
    ref: React.ForwardedRef<unknown>
  ) {
    const handleOnChange = useCallback((value, action) => {
      if (typeof onChange === 'function') onChange(value, action);
    }, [onChange]);

    const getRegExp = memoize((rawInput) =>
      new RegExp(rawInput
        .replace(/[-[\]{}()*+?.,\\^$|#]/g, "\\$&")
        .replace(/\s/g, "(.*)"), 'ig')
    );

    const SelectComponent = rest.loadOptions ? AsyncSelect : isCreatable ? CreatableSelect : Select;

    return (
      <SelectComponent
        className={`base-select-box ${className || styles.container}`}
        classNamePrefix="base-select-box"
        onChange={handleOnChange}
        filterOption={(option, rawInput) => {
          const optionData = option.data as { search?: string }
          if (optionData?.search) {
            return Boolean(
              optionData.search.match(
                getRegExp(rawInput)
              )
            );
          } else {
            return Boolean(
              option.label.match(
                getRegExp(rawInput)
              )
            );
          }
        }}
        // Main Pal: Was time-consuming to remove this @ts-ignore. So, let's keep it for now.
        // @ts-ignore
        ref={ref}
        {...(rest.loadOptions ? {defaultOptions: true} : {})}
        {...rest}
        inputId={randomId}
        components={{ClearIndicator: () => null}}
        // NOTE: useful property to debug the style of an open menu
        // defaultMenuIsOpen={true}
      />
    );
  }
);

export default BaseSelectBoxWithRef;
