import React from 'react';
import Store from './globalState';
import MobileTable from './MobileTable';
import MobileVisibility from './MobileVisibility';
import MobileSort from './MobileSort';
import MobileFilter from './MobileFilter';
import {Column} from './index';

type Props<D> = {
  store: Store<D>,
  isLoading?: boolean,
  sortedData: D[],
  columns: Column<D>[],
  mobileCollapseCardRender?: (row: D, state: Store<D>) => JSX.Element,
  isRemote?: boolean;
  refreshTableData?: () => void
};

function MobileView<D>(
  {
    store,
    isLoading,
    sortedData,
    columns,
    mobileCollapseCardRender,
    isRemote,
    refreshTableData
  }: Props<D>
) {
  const {mobileViewState} = store;
  const mobileView = mobileViewState.useValue();
  return (
    <div className='smart-table-mobile smart-table'>
      <MobileTable
        store={store}
        isLoading={isLoading}
        columns={columns}
        sortedData={sortedData}
        mobileCollapseCardRender={mobileCollapseCardRender}
        isVisible={mobileView === 'table'}
        isRemote={isRemote}
        refreshTableData={refreshTableData}
      />
      {
        mobileView === 'visibility' ?
          <MobileVisibility store={store} columns={columns}/>
          :
          null
      }
      {
        mobileView === 'sort' ?
          <MobileSort store={store} columns={columns}/>
          :
          null
      }
      {
        mobileView === 'filter' ?
          <MobileFilter store={store} columns={columns}/>
          :
          null
      }

    </div>
  );
}

export default MobileView;