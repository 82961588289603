import React, {useMemo} from 'react';
import classNames from 'classnames';
import {
  chatsState,
  selectedChatState,
  selectedTaskState,
  selectedTopMenuItemState,
  tasksMetaState,
  tasksState,
  transfersState,
} from './globalState';
import isAdminState from "@state/globalState/isAdminState";
import style from './NotificationTopBar.module.scss';
import configurationState from "@state/globalState/configurationState";
import {getNumChatNotifications, getNumTransferNotifications} from './utils';
import AdminMenu from './components/AdminMenu';

import Badge, {BadgeSpace} from './components/Badge';

const NotificationTopBar = () => {
  const [selectedTopMenuItem, setSelectedTopMenuItem] = selectedTopMenuItemState.use();
  const [selectedTask, setSelectedTask] = selectedTaskState.use();
  const [selectedChat, setSelectedChat] = selectedChatState.use();
  const tasks = tasksState.useValue();
  const chatObj = chatsState.useValue();
  const tasksMetaData = tasksMetaState.useValue();
  const isAdmin = isAdminState.useValue();
  const transfers = transfersState.useValue();
  const configuration = configurationState.useValue();

  const numChatMessages = useMemo(() => {
    if (chatObj) {
      let count = 0;
      // if(chatObj.announcement)
      //   count += getNumChatNotifications(chatObj.announcement);
      if (chatObj.contactChat)
        count += getNumChatNotifications(chatObj.contactChat);
      if (chatObj.contactChats) {
        chatObj.contactChats.map((chat) => {
          count += getNumChatNotifications(chat);
        });
      }
      if (chatObj.loanChats) {
        chatObj.loanChats.map((chat) => {
          count += getNumChatNotifications(chat);
        });
      }
      // chatObj.entityChats.map((chat) => {
      //   count += getNumChatNotifications(chat);
      // });
      return count;
    } else {
      return 0;
    }
  }, [chatObj, isAdmin]);

  const numItems = useMemo(() => {
    let n = 2;
    if (transfers && transfers.length > 0 && configuration?.features?.ach) {
      n++;
    }
    return n;
  }, [transfers, tasks, isAdmin, configuration]);

  const tabWidth = Math.round((100 / numItems) * 100) / 100;
  const tabWidths = useMemo(() => {
    if(numItems === 2) {
      return [50,50,50];
    }
    if (numItems === 3) {
      return [30, 30, 40];
    }
    const avg = Math.round((100 / numItems) * 100) / 100;
    return [avg, avg, avg];
  }, [isAdmin, numItems]);
  // settings icon is 40px wide. Currently only shown on admin
  // we need to divide that by num items and separate them
  const calcReduce = isAdmin ? Math.floor(40 / numItems) : 0;
  return (
    <div className={isAdmin ? classNames(style.topBar, style.isAdmin) : style.topBar}>
      <div
        className={selectedTopMenuItem === 'messages' ? classNames([style.menuItem, style.active]) : style.menuItem}
        style={{
          width: calcReduce > 0 ? `calc(${tabWidths[0]}% - ${calcReduce}px)` : `${tabWidths[0]}%`,
        }}
        onClick={() => {
          if (selectedTopMenuItem !== 'messages') {
            setSelectedTopMenuItem('messages');
          }
          if (selectedChat !== null) {
            setSelectedChat(null);
          }
        }}
      >
        <BadgeSpace/> Chats <Badge num={numChatMessages}/>
      </div>
      <div
        className={
          selectedTopMenuItem === 'tasks'
            ? classNames([style.menuItem, style.active])
            : style.menuItem
        }
        style={{
          width: calcReduce > 0 ? `calc(${tabWidths[1]}% - ${calcReduce}px)` : `${tabWidths[1]}%`,
        }}
        onClick={() => {
          if (selectedTopMenuItem !== 'tasks') {
            setSelectedTopMenuItem('tasks');
          }
          if (selectedTask !== null) {
            setSelectedTask(null);
          }
        }}
      >
        {/* TODO Check this, Looks like it won't count the unread message count it */}
        <BadgeSpace/> Tasks <Badge num={tasksMetaData.totalUnresolvedCount}/>
      </div>
      {transfers && transfers.length > 0 && configuration?.features?.ach? (
        <div
          className={
            selectedTopMenuItem === 'transfers'
              ? classNames([style.menuItem, style.active])
              : style.menuItem
          }
          style={{
            width: calcReduce > 0 ? `calc(${tabWidths[2]}% - ${calcReduce}px)` : `${tabWidths[2]}%`,
          }}
          onClick={() => {
            if (selectedTopMenuItem !== 'transfers') {
              setSelectedTopMenuItem('transfers');
            }
          }}
        >
          <BadgeSpace/> Transfers <Badge num={getNumTransferNotifications(transfers)}/>
        </div>
      ) : null}
      <div className={style.clearfix}/>
      <AdminMenu/>
    </div>
  );
};

export default NotificationTopBar;