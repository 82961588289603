import {CompactLoan} from "@interfaces/Loan";


export const getCompactLoanDataForPublic = async (loanId: string) => {

  //TODO: Feature Coming Soon
  // const url = `${getCleanBaseUri()}/api/v1/public/loan/${encodeURIComponent(loanId)}/compact`;
  // return axios.get<CompactLoan>(url).then(res => res.data);

  return new Promise<CompactLoan>((resolve, reject) => {
    reject({message: "Feature Coming Soon"});
  });
};
