import getAdminApiPath from "@hornet-api/getAdminApiPath";
import axios from "axios";
import {TaxRecordServer} from "@interfaces/TaxRecord";
import {convertServerDataToFormData} from "@admin-ui/pages/PropertyPage/show/constant";

export const fetchTaxRecordsByPropertyId = async (propertyId: number | string) => {
  const url = `${getAdminApiPath()}/property/${propertyId}/propertyTax`;
  const response = await axios.get(url);
  return response.data as TaxRecordServer[];
}
export const createTaxRecord = async (propertyId: number | string, sendData: TaxRecordServer) => {
  const url = `${getAdminApiPath()}/property/${propertyId}/propertyTax`;
  const response = await axios.post(url, sendData);
  return response.data as TaxRecordServer;
}

export const fetchTaxRecordById = async (propertyId: number | string, taxRecordId: number) => {
  const url = `${getAdminApiPath()}/property/${propertyId}/propertyTax/${taxRecordId}`;
  const response = await axios.get(url);
  return convertServerDataToFormData(response.data as TaxRecordServer);
}
export const updateTaxRecord = async (propertyId: number | string, taxRecordId: number, sendData: TaxRecordServer) => {
  const url = `${getAdminApiPath()}/property/${propertyId}/propertyTax/${taxRecordId}`;
  const response = await axios.put(url, sendData);
  return response.data as TaxRecordServer;
}
export const deleteTaxRecord = async (propertyId: number | string, taxRecordId: number) => {
  const url = `${getAdminApiPath()}/property/${propertyId}/propertyTax/${taxRecordId}`;
  const response = await axios.delete(url);
  return response.data as TaxRecordServer;
}