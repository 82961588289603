import getAdminApiPath from "@hornet-api/getAdminApiPath";
import axios from "axios";
import DocumentRequest from "@interfaces/DocumentRequest";

export const cancelDocumentRequest = async (requestCode: string) => {
  const url = `${getAdminApiPath()}/documents/requestCancel/${requestCode}`;
  const response = await axios.put(url, {});
  return response.data as DocumentRequest;
};

export const approveDocumentRequest = async (documentId: number) => {
  const url = `${getAdminApiPath()}/documents/approveRequest/${documentId}`;
  const response = await axios.put(url, {});
  return response.data as DocumentRequest;
};

export const denyDocumentRequest = async (documentId: number) => {
  const url = `${getAdminApiPath()}/documents/denyRequest/${documentId}`;
  const response = await axios.put(url, {});
  return response.data as DocumentRequest;
};

export const toggleVisibility = async (documentId: number) => {
  const url = `${getAdminApiPath()}/documents/toggleVisibility/${documentId}`;
  const response = await axios.post(url, {});
  return response.data as DocumentRequest;
};

export const downloadDocumentUrl = (documentId: number) => {
  return `${getAdminApiPath()}/documents/download/${documentId}`;
};

export const viewDocumentUrl = (id: number) => {
  return `${getAdminApiPath()}/documents/view/${id}`
}

export const deleteDocumentRequest = async (documentId: number) => {
  const url = `${getAdminApiPath()}/documents/delete/${documentId}`;
  await axios.delete(url);
};
