import axios from 'axios';
import getAdminApiPath from "@hornet-api/getAdminApiPath";
import convertJsonToFormData from "@common/utils/form/convertJsonToFormData";
import {IDrawRequestServerData} from "@admin-ui/pages/Budget/components/DrawRequest/constant";

export const createDrawRequest = async (lineItemId: number, data: IDrawRequestServerData) => {
  const url = `${getAdminApiPath()}/budget/lineItem/${lineItemId}/drawRequest`;
  const formData = convertJsonToFormData(data);

  return axios.post(url, formData);
};