import React, {useEffect, useState} from "react";
import {Button} from "react-bootstrap";
import {FaDownload, FaEdit, FaRegEye, FaTrash} from "react-icons/fa";
import RenameAttachment from "@admin-ui/pages/LoanShowPage/components/RenameAttachment";
import {Attachment} from "@interfaces/task";
import {objCopy} from "@common/basic";
import {loadingRelease, loadingTrigger} from "@components/LoadingOverlay";
import {downloadFileByUrl} from "@common/utils/fileDownloader";
import {deleteTaskAttachments} from "@hornet-api/task/attachments";
import {alertApiErrors} from "@common/errors";
import {openShowDocumentModal} from "@components/ShowDocumentModal";
import {viewAttachmentUrl} from "@hornet-api/documents/viewAttachmentUrl";

type Props = {
  taskId: number;
  file: Attachment;
  files: Attachment[];
  setFiles: (files: Attachment[]) => void;
  isDeletable: boolean;
  isShow: boolean;
}

const Attachments = ({taskId, file, files, setFiles, isDeletable, isShow}: Props) => {
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (!isShow) {
      setIsEditing(false);
    }
  }, [isShow]);

  const fileDelete = (fileId: number) => {
    const t = loadingTrigger();
    deleteTaskAttachments(taskId, fileId)
      .then(() => setFiles(files.filter((file) => file.id !== fileId)))
      .catch(alertApiErrors)
      .finally(() => loadingRelease(t));
  };

  const handleRename = (fileId: number, filename: string) => {
    if (fileId && filename) {
      setIsEditing(false);
      const filesCopy = objCopy(files);
      const index = filesCopy.findIndex((file) => file.id === fileId);
      if (index !== -1) {
        filesCopy[index].fileName = filename;
      }
      setFiles(filesCopy);
    }
  }

  return (
    <tr key={file.id}>
      <td>
        {isEditing ? (
          <RenameAttachment
            taskId={taskId}
            fileId={file.id}
            filename={file.fileName}
            onComplete={handleRename}
            onCancel={() => setIsEditing(false)}
          />
        ) : (file.fileName)}
      </td>
      <td style={{whiteSpace: 'nowrap'}}>
        <Button
          variant="info"
          title="Rename"
          size='sm'
          className='mr-2'
          onClick={() => setIsEditing(true)}
        >
          <FaEdit/>
        </Button>
        <Button
          variant="primary"
          size='sm'
          title={`Download ${file.fileName}`}
          onClick={() => downloadFileByUrl(file.id.toString(), file.fileName)}
        >
          <FaDownload/>
        </Button>
        {isDeletable && (
          <Button
            variant="danger"
              size='sm'
            className="ml-2"
            title={`Delete ${file.fileName}`}
            onClick={() => {
              if (window.confirm('Are you sure?')) fileDelete(file.id);
            }}
          >
            <FaTrash/>
          </Button>
        )}
        <Button
          variant="success"
          size='sm'
          className="ml-2"
          title={`View`}
          onClick={() => {
            openShowDocumentModal({
              url: viewAttachmentUrl(file!.id),
              name: file!.fileName
            })
          }}
        >
          <FaRegEye/>
        </Button>
      </td>
    </tr>
  )
}

export default Attachments;
