/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {CSSProperties, useMemo, useState} from 'react';
import {Link} from 'react-router-dom';
import {useRecoilValue} from 'recoil';
import profileAtom from '@state/recoil/profileAtom';
import isImpersonatingSelectorAtom from '@state/recoil/authentication/isImpersonatingSelectorAtom';
import FontAwesomeIcon from '@components/Icons/FontAwesomeIcon/index';
import hornetCapitalLogo from '@images/hornet_logo_regular.svg';
import hornetCapitalWhiteIcon from '@images/hornet_icon_white.svg';
// bootstrap
import Navbar from 'react-bootstrap/Navbar';


import getNavigationObject, {NavigationObjectItem} from '../getNavigationObject';

import Contact from '@interfaces/Contact';
import ActiveRoleSelector from "@components/Navigation/ActiveRoleSelector";
import {openChat} from "@components/NotificationSidebar/utils";
import NavigationMenuRenderer from "@components/Navigation/Mobile/NavigationMenuRenderer";
import getContactUsNavObject from "@components/Navigation/getContactUsNavObject";

type Props = {
	onStopImpersonating: () => void,
	onLogout: () => void
}

const styles: { [p: string]: CSSProperties } = {
	notificationBadge: {
		position: "absolute",
		top: "0px",
		right: "0px",
		width: "18px",
		height: "18px",
		lineHeight: "18px",
		borderRadius: "50%",
		display: "inline-block",
		textAlign: "center",
		fontWeight: "normal",
		fontSize: "10px",
	},
	barsWrapper: {
		display: 'inline-block',
		cursor: 'pointer',
		width: 40,
		fontSize: 20,
		textAlign: 'center',
		position: 'relative'
	}
}

const NavigationMobile = (
	{
		onStopImpersonating,
		onLogout,
	}: Props
) => {
	//@ts-ignore
	const profile = useRecoilValue(profileAtom) as Contact;
	const isImpersonating = useRecoilValue(isImpersonatingSelectorAtom);
	const [showMenu, setShowMenu] = useState(false);

	const navigationObject = getNavigationObject(
		profile,
		isImpersonating,
		onLogout,
		onStopImpersonating
	);

	const contactUsObj = getContactUsNavObject();

	// used to show a Dot on the "bars" icon
	// we can also return total here, if needed
	const totalNotifications = useMemo(() => {
		const findNotification = (navItems: NavigationObjectItem[] | undefined): number => {
			return (
				navItems?.reduce((total, navItem) => {
					total = total + (navItem.countBadge || 0) + findNotification(navItem.children)
					return total;
				}, 0) || 0
			)
		}
		return findNotification(navigationObject);
	}, [navigationObject])

	const linkClick = () => {
		setShowMenu(false);
	}

	return (
		<>
			<Navbar
				bg="light"
				expand="lg"
				fixed="top"
				className="main-top-navbar"
			>
				{profile && (<div
					style={styles.barsWrapper}
					onClick={() => {
						setShowMenu(!showMenu);
					}}
				>
					<FontAwesomeIcon icon="bars"/>
					{
						totalNotifications > 0 &&
						(
							<span
								className={'badge badge-danger p-0'}
								style={styles.notificationBadge}
							>{totalNotifications}</span>
						)
					}
				</div>)}
				<div
					style={{
						flexGrow: 1,
						textAlign: 'center'
					}}
				>
					<Link style={{marginRight: 0}} className="navbar-brand" to="/">
						<img src={hornetCapitalLogo} alt="Hornet Capital"/>
					</Link>
				</div>
				{profile && (<div
					style={{
						width: 40,
					}}
				>{' '}</div>)}
			</Navbar>
			{profile && (<div className={`user_side_nav${showMenu ? ' open' : ''}`}>
				<div
					className='backdrop'
					onClick={() => {
						setShowMenu(false);
					}}
				></div>

				<div className='slider'>
					<div className='top_bar'>
						<div
							className='side'
							style={{
								cursor: 'pointer'
							}}
							onClick={() => {
								setShowMenu(false);
							}}
						>
							<FontAwesomeIcon icon="chevron-left"/>
						</div>
						<div className='center'>
							<img src={hornetCapitalWhiteIcon} alt=''/>
						</div>
						<div className='side'></div>

					</div>
					<div className='slider_body'>
						<ul>
							{
								navigationObject.map((nav, index) => (
									<NavigationMenuRenderer nav={nav} onLinkClick={linkClick} key={index}/>
								))
							}

							<ActiveRoleSelector isMobile={true}/>
							<NavigationMenuRenderer nav={contactUsObj} onLinkClick={linkClick}/>
							<li className='side_link'>
								<Link
									to={'#'}
									onClick={() => {
										linkClick();
										openChat();
									}}
								>
									Live Chat
								</Link>
							</li>
						</ul>
					</div>
				</div>
			</div>)}
		</>
	);
};

export default NavigationMobile;
