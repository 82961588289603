import Modal from "react-bootstrap/Modal";
import {createGlobalState} from "react-global-hooks";
import React, {useEffect, useRef, useState} from "react";
import getSystemConfigUser from "@hornet-api/systemConfigs/user/getSystemConfigUser";
import {loadingRelease, loadingTrigger} from "@components/LoadingOverlay";
import {alertApiErrors} from "@common/errors";
import getMessageByCategory from "@hornet-api/message/getMessageByCategory";
import {Col, Row} from "react-bootstrap";
import CopyToClickBoard from "@legacy/views/DashboardViewLegacy/component/SageFinancial/CopyToClickBoard";
import {fetchThirdParty2FA, ThirdParty2FA} from "@hornet-api/twoFactorAuth/user/fetchThirdParty2FA";
import {refreshSage2FAState} from "@components/NotificationSidebar/globalState";
import {timeFormat} from "@legacy/views/UserProfileLegacy/utils";
import "./SageEmbedVideo.scss"

interface SageFinancialModalState {
  onComplete?: () => void;
  onCancel?: () => Promise<void> | void;
}

export const sageFinancialModalState = createGlobalState<SageFinancialModalState | null>(null);

export const openSageFinancialModal = (modalState: SageFinancialModalState) => {
  sageFinancialModalState.set(modalState);
};
const SageFinancialModal = () => {
  const [modalState, setModalState] = sageFinancialModalState.use();
  const [htmlText, setHtmlText] = useState<string>("");
  const [pageUrl, setPageUrl] = useState<string>("");
  const [companyId, setCompanyId] = useState<string>("");
  const [userId, setUserId] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const toggleSage2FAState = refreshSage2FAState.useValue();
  const [sage2FA, setSage2FA] = useState<ThirdParty2FA>();
  const [countdown, setCountdown] = useState<number>(0);
  const timerRef = useRef<NodeJS.Timer>();

  useEffect(() => {
    if (!sage2FA) return;
    setCountdown(sage2FA.expirationTimeInSeconds || 0);
  }, [sage2FA]);

  useEffect(() => {
    if (countdown > 0) {
      timerRef.current = setTimeout(() => setCountdown(countdown - 1), 1000);
    }

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };

  }, [countdown]);


  useEffect(() => {
    if (!modalState) return;
    fetchThirdParty2FA('SAGE')
      .then(setSage2FA)
      .catch(alertApiErrors);
  }, [toggleSage2FAState, modalState]);

  useEffect(() => {
    if (!modalState) return;
    const t = loadingTrigger();
    Promise.all([
      getMessageByCategory("EDIT_SAGE_MODAL").then((res) => {
        setHtmlText(res.content);
      }),
      getSystemConfigUser('sage.pageUrl').then((res) => {
        setPageUrl(res.keyValue);
      }),
      getSystemConfigUser('sage.companyId').then((res) => {
        setCompanyId(res.keyValue);
      }),
      getSystemConfigUser('sage.userId').then((res) => {
        setUserId(res.keyValue);
      }),
      getSystemConfigUser('sage.password').then((res) => {
        setPassword(res.keyValue);
      }),
    ])
      .catch(alertApiErrors)
      .finally(() => loadingRelease(t));
  }, [modalState]);

  const handleClose = () => {
    modalState?.onCancel?.();
    setModalState(null);
  };

  return (<Modal
    show={!!modalState}
    onHide={handleClose}
    id={'sageFinancialModal'}
    size={'lg'}
  >
    <Modal.Header closeButton>
      <Modal.Title>Sage Financial</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div
        className={`change-video-size`}
        dangerouslySetInnerHTML={{__html: htmlText}}
      >
      </div>
      <hr className="my-4"/>
      <Row>
        <Col>
          <Row>
            <Col>
              <a href={`${pageUrl}?.company=${encodeURIComponent(companyId)}&.login=${encodeURIComponent(userId)}`}
                 target="_blank">
                Financial Login Page
              </a>
            </Col>
          </Row>
          <Row>
            <Col>
              <b>Password: </b>
              <span className={'mr-2'}>{password}</span>
              <CopyToClickBoard text={password}/>
            </Col>
          </Row>
        </Col>
        <Col>
          <b>Code: </b>
          {sage2FA && !!countdown && (
            <>
              <span className={'mr-2'}>{sage2FA.code}</span>
              <CopyToClickBoard text={sage2FA.code}/>
              <span style={{fontSize: 14}} className={'ml-2 font-italic'}>Valid for - {timeFormat(countdown)} </span>
            </>
          )}
        </Col>
      </Row>
    </Modal.Body>
  </Modal>)
}
export default SageFinancialModal;