import React from 'react';
import { createGlobalState } from 'react-global-hooks';
import { v4 as getUuid } from 'uuid';
import Loading from '@components/Loading';
import style from './AdminLoadingOverlay.module.scss';

export const loadingWindowState = createGlobalState([] as string[]);

// adds a uuid to the queue and returns it.
export const loadingTrigger = (prefix?: string) => {
  const uuid = `${prefix ?? ''}${getUuid()}`;
  const newState = [...loadingWindowState.get(), uuid];
  loadingWindowState.set(newState);
  return uuid;
}

export const loadingRelease = (uuid: string) => {
  const newState = [...loadingWindowState.get()];
  const index = newState.indexOf(uuid);
  if (index > -1) {
    newState.splice(index, 1);
  }
  loadingWindowState.set(newState);
}

export const loadingReleaseAll = () => {
  loadingWindowState.set([]);
}



const AdminLoadingOverlay = () => {
  const [loadingArr, setLoadingArr] = loadingWindowState.use();
  const isVisible = loadingArr.length > 0;
  // const isVisible = true;
  return (
    <div
      className={style.overlay}
      style={{
        pointerEvents: isVisible ? 'all' : 'none',
        transition: 'all 0.3s ease',
        opacity: isVisible ? 1 : 0
      }}
    >
      <Loading />
    </div>
  )
}


export default AdminLoadingOverlay;
