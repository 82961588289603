import {useMemo} from 'react';
import activeRoleState from '@state/globalState/activeRoleState';
import userRoleGroupsState from '@state/globalState/userRoleGroupsState';
import {UserInterfaceEnum} from "@interfaces/GeneratedEnums";

type UI = keyof typeof UserInterfaceEnum;

const uiSelector = (): UI | null => {
  const activeRole = activeRoleState.useValue();
  const userRoleGroups = userRoleGroupsState.useValue();
  const group = userRoleGroups?.find((group) => group.id === activeRole);
  return useMemo(() => {
    if (group && group.ui) {
      return group.ui;
    } else if (userRoleGroups?.length === 0) {
      // for users with no roles
      return 'USER';
    } else {
      return null;
    }
  }, [userRoleGroups, group]);
};

export default uiSelector;
