import axios from 'axios';
import getUserApiPath from '../getUserApiPath';

export interface SearchContactItem {
  id: number;
  firstName: string;
  middleName: string | null;
  lastName: string;
  nickname: string | null;
  email: string;
}

const searchContact = async (searchTerm: string, isEscrowOfficerActive: boolean = false) => {
  let url = `${getUserApiPath()}/contact/list`;
  let isSearchTerm = searchTerm && searchTerm.length > 0;
  if (isSearchTerm) {
    url += `?search=${searchTerm}`;
  }
  if (isEscrowOfficerActive) {
    if (isSearchTerm) {
      url += '&';
    } else {
      url += '?';
    }
    url += 'escrowOfficer=true';
  }

  const response = await axios.get(url);
  return response.data ? (response.data as SearchContactItem[]).filter((x) => x) : [];
};

export default searchContact;
