import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Preset, getPresets, deletePreset, canSavePresetsState, createPreset, updatePreset } from '@hornet-api/smartTables';
import SettingsSaver from './lib/SettingsSaver';
import FontAwesomeIcon from './lib/FontAwesomeIcon';
import Store from './globalState';
import {OverlayTrigger} from 'react-bootstrap';
import Tooltip from 'react-bootstrap/Tooltip';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import devConsole from '@common/devConsole';

const styles = {
  pageSizeDropdown: {
    display: 'inline-block',
    width: 'auto',
    margin: '0 5px',
    minWidth: '100px',
  },
};

type Props<D> = {
  tableName: string,
  store: Store<D>,
  settingsSaver: SettingsSaver<D>
}

function PresetSelector<D>(
  {
    tableName,
    store,
    settingsSaver,
  }: Props<D>
) {
  const [presets, setPresets] = store.presetsState.use();
  const [selectedPresetId, setSelectedPresetId] = store.selectedPresetIdState.use();
  const [updatePresetId, setUpdatePresetId] = useState('');
  const [createPresetName, setCreatePresetName] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const canSave = canSavePresetsState.useValue();
  const focusInput = useRef<HTMLInputElement>(null);

  // get presets
  useEffect(() => {
    getPresets(tableName).then((p) => {
      setPresets(p as Preset[]);
    })
  }, []);

  const presetOptions = useMemo(() => {
    return (
      presets.map((preset, index) => {
        return (
          <option key={index} value={preset.id}>{preset.name}</option>
        )
      })
    );
  }, [presets, selectedPresetId]);

  const closeModal = () => {
    setUpdatePresetId('');
    setCreatePresetName('');
    setShowModal(false);
    setIsUpdating(false);
  };


  // don't show if not a saver or no presets
  if (!canSave && presets.length === 0) {
    return null;
  }

  const isUpdateModal = updatePresetId !== '';

  const submitForm = () => {
    setIsUpdating(true);
    if (!isUpdating) {
      let promise: Promise<[Preset[], string]>;
      if (isUpdateModal) {
        const preset = presets.find(x => x.id === updatePresetId) as Preset;
        preset.settings = settingsSaver.getSettings();
        preset.name = createPresetName;
        promise = updatePreset(preset, tableName);
        devConsole.log('Updating preset')
      } else {
        promise = createPreset(createPresetName, settingsSaver.getSettings(), tableName);
        devConsole.log('Creating new preset')
      }
      promise.then(([p, newId]) => {
        getPresets(tableName).then((pr) => {
          setPresets(pr);
          setSelectedPresetId(newId);
          setIsUpdating(false);
          closeModal();
        })
      }).catch((e) => {
        console.error(e);
        setIsUpdating(false);
      })
    }

  }

  return (<>
    <span style={{ marginLeft: 8 }}>Presets</span>
    <select
      className="form-control form-control-sm"
      value={selectedPresetId}
      style={styles.pageSizeDropdown}
      onChange={(e) => {
        const preset = presets.find(x => x.id === e.target.value);
        if (preset) {
          setSelectedPresetId(preset.id);
          store.currentPageState.set(1);
          settingsSaver.updateSettings(preset.settings, true);
        } else if (e.target.value === '') {
          setSelectedPresetId('');
        } else {
          console.error('Preset not found');
        }
      }}
    >
      <option value=''/>
      {presetOptions}
    </select>

    {canSave ?
      <>
        <OverlayTrigger
          key={`${tableName}-create`}
          placement={'bottom'}
          overlay={
            <Tooltip id={`tooltip-${tableName}-create`}>
              Save Preset
            </Tooltip>
          }
        >
          <button
            className="btn btn-sm btn-success"
            style={{ marginRight: 5 }}
            onClick={() => {
              const preset = presets.find(x => x.id === selectedPresetId);
              if (preset) {
                setUpdatePresetId(preset.id);
                setCreatePresetName(preset.name);
              }
              setShowModal(true);
            }}
          >
            <FontAwesomeIcon icon='floppy-o' />
          </button>
        </OverlayTrigger>

        <OverlayTrigger
          key={`${tableName}-delete`}
          placement={'bottom'}
          overlay={
            <Tooltip id={`tooltip-${tableName}-delete`}>
              Delete Preset
            </Tooltip>
          }
        >
          <button
            className="btn btn-sm btn-danger"
            style={{ marginRight: 5 }}
            disabled={selectedPresetId === ''}
            onClick={() => {
              if (selectedPresetId !== '') {
                if (window.confirm(`Are you sure you want to delete this preset?`)) {
                  deletePreset(selectedPresetId, tableName).then((p) => {
                    setSelectedPresetId('');
                    setPresets(p)
                  })
                }
              }
            }}
          >
            <FontAwesomeIcon icon='trash' />
          </button>
        </OverlayTrigger>
      </>
      :
      null
    }

    <Modal
      show={showModal}
      onHide={closeModal}
      id={`${tableName}-preset-modal`}
      onEntered={() => {
        setTimeout(() => {
          if (focusInput.current) {
            focusInput.current.focus();
          }
        }, 300);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {isUpdateModal ? 'Update' : 'Create'} Preset
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <label>Preset to Update</label>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            submitForm();
          }}
        >
          <div className="input-group">
            <select
              className="form-control"
              value={updatePresetId}
              style={styles.pageSizeDropdown}
              onChange={(e) => {
                const presetId = e.target.value;
                setUpdatePresetId(presetId);
                setCreatePresetName(presets.find(x => x.id = presetId)?.name || '')
              }}
            >
              <option value=''>** NEW **</option>
              {presetOptions}
            </select>
          </div>

          <div className="form-group">
            <label style={{ marginTop: 10 }}>Name</label>
            <input
              type="text"
              className="form-control"
              value={createPresetName}
              ref={focusInput}
              onChange={(e) => {
                setCreatePresetName(e.target.value);
              }}
            />
          </div>
        </form>

      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          disabled={isUpdating}
          onClick={() => {
            submitForm();
          }}
        >
          {isUpdating ? <FontAwesomeIcon icon='circle-o-notch' spin={true} /> : isUpdateModal ? 'Update' : 'Create'}
        </Button>
        <Button variant="secondary" onClick={closeModal}>
          Cancel
        </Button>
      </Modal.Footer>

    </Modal>
  </>);
}
export default PresetSelector;