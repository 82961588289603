import axios from 'axios';
import {FirebaseSubscription} from "@common/serviceWorker";
import getMetaApiPath from "@hornet-api/getMetaApiPath";

const removeFirebaseSubscription = async (subscription: FirebaseSubscription) => {
  const url = `${getMetaApiPath()}/fcm/remove`;
  await axios.post(url, subscription);
};

export default removeFirebaseSubscription;
