import axios from 'axios';
import getAdminApiPath from "@hornet-api/getAdminApiPath";
import {ILineItem} from "@interfaces/loan/admin/budget";
import {ILineItemServer} from "@admin-ui/pages/Budget/components/LineItem/constant";
import {LineItemStatusEnum} from "@interfaces/GeneratedEnums";

type IApprovedAmountUpdate = {
  approvedAmount: number
}

type IStatusUpdate = {
  status: keyof typeof LineItemStatusEnum
}

type Param = IApprovedAmountUpdate | IStatusUpdate | ILineItemServer;

export const updateLineItem = (id: number, data: Param) => {
  const url = `${getAdminApiPath()}/budget/lineItem/${id}`;
  return axios.patch(url, data)
    .then((response) => response.data as ILineItem);
};
