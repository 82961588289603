import {getTaskUrl} from "@hornet-api/task/util";
import axios from "axios";

export type IBorrowerApplicationItem = {
  id: number;
  contact: number;
  contactName: string;
  company: number | null;
  companyName: string | null;
  loan: number | null;
  address: string | null;
}

export const getBorrowerApplicationListData = async () => {
  const url = `${getTaskUrl()}/borrower/application/listData`;
  const response = await axios.get(url);
  return response.data as IBorrowerApplicationItem[];
}