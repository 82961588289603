/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import {useRecoilValue} from 'recoil';
import profileAtom from '@state/recoil/profileAtom';
import isImpersonatingSelectorAtom from '@state/recoil/authentication/isImpersonatingSelectorAtom';
import hornetCapitalLogo from '@images/hornet_logo_regular.svg';
import ActiveRoleSelector from '../ActiveRoleSelector';

// bootstrap
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';


import getNavigationObject from '../getNavigationObject';

import Contact from '@interfaces/Contact';

import "./NavigationDesktop.scss";
import getContactUsNavObject from "@components/Navigation/getContactUsNavObject";
import NavigationMenuRenderer, {navDropDownState} from "@components/Navigation/Desktop/NavigationMenuRenderer";

type Props = {
	onStopImpersonating: () => void,
	onLogout: () => void
}

const getNavbarElement = (child: HTMLElement | null): HTMLElement | null => {
	if (!child || (child.tagName === 'NAV' && child.classList.contains('main-top-navbar'))) return child

	return getNavbarElement(child.parentElement);
}

const interceptClick = (event: MouseEvent) => {
	console.log('#### caught click')
	// navDropDownState.set(!!getNavbarElement(event.target as HTMLElement | null));
}

const NavigationDesktop = (
	{
		onStopImpersonating,
		onLogout,
	}: Props
) => {
	//@ts-ignore
	const profile = useRecoilValue(profileAtom) as Contact;
	const isImpersonating = useRecoilValue(isImpersonatingSelectorAtom);

	const navigationObject = getNavigationObject(
		profile,
		isImpersonating,
		onLogout,
		onStopImpersonating
	);

	const contactUsObj = getContactUsNavObject();

	const navDropDownStateValue = navDropDownState.useValue();

	useEffect(() => {
		document.addEventListener('click', interceptClick);

		return () => {
			document.removeEventListener('click', interceptClick);
		}
	}, []);

	return (
		<Navbar
			bg="light"
			expand="lg"
			fixed="top"
			className={`main-top-navbar ${navDropDownStateValue.length ? 'position-relative' : ''}`}
		>
			<Link className="navbar-brand" to="/">
				<img src={hornetCapitalLogo} alt="Hornet Capital"/>
			</Link>
			{profile && (
				<>
					<Navbar.Toggle aria-controls="basic-navbar-nav"/>

					<Navbar.Collapse id="basic-navbar-nav">
						<Nav className="mr-auto">
							{
								navigationObject.map((nav, index) => (
									<NavigationMenuRenderer nav={nav} key={index}/>
								))
							}
						</Nav>
						<Nav className="justify-content-end">
							<ActiveRoleSelector isMobile={false}/>
							<NavigationMenuRenderer nav={contactUsObj} alignRight={true}/>
						</Nav>
					</Navbar.Collapse>
				</>
			)}
		</Navbar>
	);
};

export default NavigationDesktop;
