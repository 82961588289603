import React, {useEffect, useMemo} from "react";
import getFirebaseConfig from "../../integrations/hornet-api/firebase/getFirebaseConfig";
import {useRecoilValue} from "recoil";
import profileAtom from "@state/recoil/profileAtom";
import Contact from "@interfaces/Contact";
import {getFirebaseVapidKey} from "@hornet-api/firebase";
import {FirebaseConfigType, firebaseNotifications, isFirebaseCompatible} from "@common/serviceWorker";
import {createGlobalState} from "react-global-hooks";


export const firebaseConfigGlobalState = createGlobalState(null as null | FirebaseConfigType);
export const vapidKeyGlobalState = createGlobalState(null as null | string);


export const FcmMessagingComponent = () => {
  const [firebaseConfig, setFirebaseConfig] = firebaseConfigGlobalState.use();
  const [vapidKey, setVapidKey] = vapidKeyGlobalState.use();
  const profile = useRecoilValue(profileAtom) as Contact | null;
  const hasProfile = useMemo(() => !!profile, [profile]);

  useEffect(() => {
    if (!hasProfile) return;
    getFirebaseConfig()
      .then(setFirebaseConfig)
      .catch((error) => console.log("FirebaseConfig Error", error));
    getFirebaseVapidKey().then(setVapidKey);
  }, [hasProfile]);

  useEffect(() => {
    try {
      if (!vapidKey || !firebaseConfig || (typeof Notification !== "undefined" && 'granted' !== Notification.permission)) return
      isFirebaseCompatible() && firebaseNotifications(vapidKey, firebaseConfig);
    } catch (e) {
      console.log('##### error in FCM: ', e);
    }

    // Handle incoming messages
  }, [vapidKey, firebaseConfig])


  return (<></>);
}
