import {getTaskUrl} from "@hornet-api/task/util";
import axios from "axios";
import {TaskStatusEnum} from "@interfaces/GeneratedEnums";

export type ITaskItem = {
  id: number;
  title: string;
  status: keyof typeof TaskStatusEnum;
  company: number | null;
  contact: number | null;
  ownerName: string;
  loan: number | null;
  property: number | null;
}

export const getTaskListData = async () => {
  const url = `${getTaskUrl()}/task/listData`;
  const response = await axios.get(url);
  return response.data as ITaskItem[];
}