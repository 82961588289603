import React, { useEffect, Fragment } from 'react';

import Rollbar from 'rollbar';
import configurationAtom from '@state/recoil/configurationAtom';
import { useRecoilValue } from 'recoil';
import authenticationBlobAtom from '@state/recoil/authentication/core/authenticationBlobAtom';


const RollbarLoader = () => {
  const configuration = useRecoilValue(configurationAtom);
  const authentication = useRecoilValue(authenticationBlobAtom);
  // load rollbar when configuration is loaded
  useEffect(() => {
    if (configuration && configuration.environment != 'development') {
      const rollbarConfig: Rollbar.Configuration = {
        accessToken: configuration.rollbar,
        captureUncaught: true,
        captureUnhandledRejections: true,
        captureIp: "anonymize",
        enabled: true,
        verbose: false,
        autoInstrument: true,
        scrubTelemetryInputs: true,
        includeItemsInTelemetry: true,
        payload: {
          environment: configuration.environment,
          routingFlow: "user",
          isLoggedIn: authentication.active_user ? true : false,
          person: authentication.active_user ? {
            //@ts-ignore
            id: authentication.active_user ? authentication.active_user?.sub : null,
            //@ts-ignore
            isEmulatingUser: authentication.active_user ? authentication.active_user?.isImpersonating : false,
            isAdmin: false,
            isSuperAdmin: false,
            isUser: false
          } : null
        }
      };

      const rollbar = new Rollbar(rollbarConfig);
      (window as any).rollbar = rollbar;
    }
  }, [configuration, authentication]);
  return null;
};

export default RollbarLoader;