import axios from 'axios';
import {AnnouncementWithStatus} from "@interfaces/AnnouncementWithStatus";
import getUserApiPath from "@hornet-api/getUserApiPath";

const getAnnouncements = async (fetchOnlyUnread: boolean) => {
  const url = `${getUserApiPath()}/chat/announcement/message?fetchOnlyUnread=${fetchOnlyUnread}`;

  const response = await axios.get(url);
  return response.data as AnnouncementWithStatus[];
};

export default getAnnouncements;
