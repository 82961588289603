import React from 'react';
import createLazyComponent from '@common/createLazyComponent';

const LoansListViewLazy = React.lazy(() => import('./LoansListView'));
const LoanParticipationViewLazy = React.lazy(() => import('./LoanParticipationView'));
const LoanViewLazy = React.lazy(() => import('./LoanView'));

export const LoansListView = createLazyComponent('LoansListView', LoansListViewLazy, null);
export const LoanParticipationView = createLazyComponent('LoanParticipationView', LoanParticipationViewLazy, null);
export const LoanView = createLazyComponent('LoanView', LoanViewLazy, null);
