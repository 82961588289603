import React from 'react';

// NOTE: When modifying this, remember that index.html in the
// public directory has a pre-rendered version of it with an
// additional noscript tag.
import hornetCapitalWhiteLogo from '@assets/images/white_hornet_logo.svg'

const ApplicationLoading = () => {
	return (
		<div className="_boot_loader">
			<img alt="Hornet Capital Icon" src={hornetCapitalWhiteLogo}/>
			<div className="shimmer">Loading Hornet Portal</div>
		</div>
	);
};

export default ApplicationLoading;
