import React from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {Link} from 'react-router-dom';

const RegisterPage = () => {
  return (<Container className="mt-5 text-center">
    <Row>
      <Col>
        <h2>This feature is only available to registered users.</h2>
        <p>
          Click on Register button to register your self
        </p>
        <Link className="btn btn-primary" to="/register">Register</Link>
      </Col>
    </Row>
  </Container>)
}

export default RegisterPage;