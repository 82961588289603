import getUserApiPath from "@hornet-api/getUserApiPath";
import axios from "axios";
import {todoListUpdated} from "@components/NotificationSidebar/globalState";

export type TodoState = {
  isNdaComplete:boolean;
  isPPMComplete: boolean;
  hasLoanInvestments: boolean;
  joinTheFundComplete: boolean;
};

const getTodo = async () => {
  const url = `${getUserApiPath()}/contact/todo`;
  const response = await axios.get(url);
  return response.data as TodoState;
};

export const updateTodo = () => {
  getTodo().then((data) => {
    todoListUpdated.set(data);
  });
};