import {useEffect} from 'react';
import {useRecoilState} from 'recoil';
import {setBaseUri} from '@common/baseUri';
import getConfiguration from '@server-api/getConfiguration';
import configurationAtom from '@state/recoil/configurationAtom';
import configurationState from '@state/globalState/configurationState';
import featuresState from "@state/globalState/featuresState";
import getFeatures from "@hornet-api/feature/getFeatures";
import {SystemConfigEnum} from "@interfaces/GeneratedEnums";
import timeZoneState from "@state/globalState/timeZoneState";
import getSystemConfigPublic from "@hornet-api/systemConfigs/public/getSystemConfigPublic";

const ConfigurationLoader = () => {
	const [configuration, setConfiguration] = useRecoilState(configurationAtom);

	useEffect(() => {
		configurationState.set(configuration);
	}, [configuration])

	useEffect(() => {
		getConfiguration().then((config) => {
			setConfiguration(config);
			setBaseUri(config?.api_uri_legacy ?? null);
			// ping the main server. axios will automatically redirect to maintenance page if needed.
			// ping();
			getFeatures().then((features) => {
				featuresState.set(features);
			}).catch(() => {
				/* do nothing */
			});
			getSystemConfigPublic(SystemConfigEnum.TIME_ZONE_KEY)
				.then((systemConfig) => timeZoneState.set(systemConfig.keyValue || ''))
				.catch(() => {
					// TimeZone config not found. set default blank string
					timeZoneState.set('');
				});
		}).catch(() => {
			/* do nothing */
		});
	}, [setConfiguration]);

	return null;
};

export default ConfigurationLoader;
