const fileNameCheckPrompt = (newFileName: string, oldFileName: string): boolean => {
  const oldExtension = oldFileName.match(/\.[^/.]+$/);
  const newExtension = newFileName.match(/\.[^/.]+$/);
  if (!newExtension) throw new Error("File Extension Required");
  if (oldExtension && (oldExtension[0] !== newExtension[0])) {
    return confirm(`Are you sure you want to change the file extension from "${oldExtension[0]}" to "${newExtension[0]}"?`)
  }
  return true
}

export default fileNameCheckPrompt;