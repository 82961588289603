import React, {ReactNode, useEffect, useMemo, useState} from 'react';
import {createGlobalState} from 'react-global-hooks';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Nango from "@nangohq/frontend";
import {loadingRelease, loadingTrigger} from "@components/LoadingOverlay";
import fetchNangoConfig, {INangoConfig, IProviderEnumKeyMap} from "@hornet-api/nango/fetchNangoConfig";
import {alertApiErrors} from "@common/errors";
import {ExternalOauthProviderEnum} from "@interfaces/GeneratedEnums";

interface IReAuthConnectionModalState {
  provider: keyof typeof ExternalOauthProviderEnum,
  connectionId: string,
  message?: ReactNode
}

export const reAuthExternalState = createGlobalState<IReAuthConnectionModalState | null>(null);

export const openReAuthConnectionModal = (modalState: IReAuthConnectionModalState) => {
  reAuthExternalState.set(modalState);
}

export const refreshNangoConfigState = createGlobalState<number | null>(null);

// we can call this function to refresh the nango config
// especially when it's config is updated from "System Config" page
export const triggerRefreshNangoConfig = () => {
  refreshNangoConfigState.set(Date.now());
}

const getNangoProviderConfigKey = (findProvider?: keyof typeof ExternalOauthProviderEnum, configKeys?: IProviderEnumKeyMap[]) => {
  return configKeys?.find(({provider}) => (provider === findProvider))?.providerConfigKey
}

const ReAuthConnectionModal = () => {
  const modalState = reAuthExternalState.useValue();
  const refreshConfigTime = refreshNangoConfigState.useValue();
  const [isLoadingConfig, setIsLoadingConfig] = useState<boolean>(false);
  const [nangoConfig, setNangoConfig] = useState<INangoConfig | null>(null);

  useEffect(() => {
    setIsLoadingConfig(true)
    fetchNangoConfig()
      .then(setNangoConfig)
      .catch(alertApiErrors)
      .finally(() => setIsLoadingConfig(false));
  }, [refreshConfigTime])

  const nango = useMemo(() => {
    if (!nangoConfig?.serverHost) return null;
    return new Nango({host: nangoConfig.serverHost});
  }, [nangoConfig?.serverHost])

  const onConfirm = () => {
    if (!nango || !nangoConfig) return;

    const providerConfigKey = getNangoProviderConfigKey(modalState?.provider, nangoConfig.configKeys);
    if (!providerConfigKey || !modalState?.connectionId) return;

    const t = loadingTrigger();
    try {
      nango.auth(providerConfigKey, modalState.connectionId)
        .then(() => {
          const backToPage = new URL(window.location.href);
          backToPage.searchParams.delete('bst'); // Deleting the bst parameter which contains very long data of the settings
          loadingTrigger();
          // TODO find how we can fix the 502 bad gateway while refreshing the connections list immediately
          setTimeout(() => {
            (window.top || window).location.href = backToPage.href
          }, 2000);
        })
        .catch((error) => {
          console.error(`There was an error in the OAuth flow for integration: ${error.message}`);
        });
    } catch (e) {
      console.error('#### error with nango modal: ', e);
    } finally {
      loadingRelease(t);
    }
  }

  const onCancel = () => reAuthExternalState.set(null)

  if (!modalState) return null;

  return (
    <Modal
      show
      onHide={onCancel}
      id={'reAuthConnectionModal'}
    >
      <Modal.Header closeButton>
        <Modal.Title>{ExternalOauthProviderEnum[modalState.provider]} Authentication</Modal.Title>
      </Modal.Header>
      <Modal.Body className={'position-relative'}>
        {
          modalState.message ||
          `${ExternalOauthProviderEnum[modalState.provider]} requires re-authentication. Would you like to start the process now?`
        }
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={onConfirm}
          disabled={!nango || isLoadingConfig}
        >{isLoadingConfig ? 'Loading...' : 'Authenticate'}</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ReAuthConnectionModal;
