import React, {useEffect, useState} from "react";
import decryptObject from "@common/decryptObject";
import {alertApiErrors} from "@common/errors";
import {FaCircleNotch, FaCopy} from "react-icons/fa";
import Button from "react-bootstrap/Button";
import isAdminState from "@state/globalState/isAdminState";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {copyTextToClipboard} from "@common/basic";


const DecryptButton = ({encryptedString = ''}: {
  encryptedString?: string | null
}) => {
  const isAdmin = isAdminState.get();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [decryptedString, setDecryptedString] = useState<string | null>('');
  const [showDecrypt, setShowDecrypt] = useState<boolean>(false);
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const handleDecrypt = () => {
    if (!showDecrypt && !decryptedString && encryptedString) {
      setIsLoading(true);
      decryptObject(encryptedString)
        .then(setDecryptedString)
        .catch(alertApiErrors)
        .finally(() => setIsLoading(false));
    }
    setShowDecrypt(!showDecrypt);
  }

  const handleCopyClick = async () => {
    try {
      if (decryptedString) {
        await copyTextToClipboard(decryptedString);
        setIsCopied(true)
      } else {
        const decryptedString = await decryptObject(encryptedString)
        await copyTextToClipboard(decryptedString);
        setIsCopied(true)
      }
    } catch (e) {
      console.log(e, ' Error')
    } finally {
      setTimeout(() => {
        setIsCopied(false)
      }, 1500)
    }
  }

  useEffect(() => {
    setShowDecrypt(false);
    setDecryptedString('');
  }, [encryptedString])

  if (isLoading) {
    return (<FaCircleNotch className='spin'/>)
  }

  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      gap: 15
    }}>
      <Button variant={'link'} className={'p-0'} onClick={handleDecrypt}>
        {showDecrypt ? decryptedString : '**********'}
      </Button>
      {
        isAdmin && (
          <OverlayTrigger
            overlay={
              <Tooltip
                id='copy-tooltip'
              >
                {isCopied ? 'Copied!' : 'Copy'}
              </Tooltip>
            }
          >
            <FaCopy
              onClick={handleCopyClick}
            />
          </OverlayTrigger>
        )
      }
    </div>

  )
};

export default DecryptButton;