import React, { useEffect, useState } from 'react';
import { Column } from '@components/BootstrapSmartTable';
import BootstrapSmartTable from '@admin/components/BootstrapSmartTable';
import AmbassadorAdmin from '@interfaces/AmbassadorAdmin';
import { Card } from 'react-bootstrap';
import numeral from 'numeral';
import { Link } from 'react-router-dom';

const moneyFormat = (val: number) => {
  return numeral(val).format('$0,0.00');
};

type Props = {
  ambassadors: AmbassadorAdmin[];
  loading: boolean;
  updateAmbassadors(): Promise<void>;
};

const AmbassadorsTable = ({ ambassadors, loading, updateAmbassadors }: Props) => {
  const columns: Column<AmbassadorAdmin>[] = [
    {
      id: 'name',
      title: 'Name',
      field: 'name',
      filter: true,
      render: (row) => <Link to={`/contact/show/${row.id}/ambassador`}>{row.name}</Link>,
    },
    {
      id: 'referralCount',
      title: 'Referrals',
      field: 'referralCount',
      filter: true,
      render: (row) => <>{row.referralCount}</>,
    },
    {
      id: 'amountPaid',
      title: 'Amount Paid',
      field: 'amountPaid',
      filter: true,
      render: (row) => <>{moneyFormat(row.amountPaid)}</>,
    },
    {
      id: 'amountOwed',
      title: 'Amount Owed',
      field: 'amountOwed',
      filter: true,
      render: (row) => <>{moneyFormat(row.amountOwed)}</>,
    },
    {
      id: 'amountDue',
      title: 'Amount Due',
      field: 'amountDue',
      filter: true,
      render: (row) => <>{moneyFormat(row.amountDue)}</>,
    },
  ];

  return (
    <>
      <Card>
        <Card.Header>
          <Card.Title>Ambassadors</Card.Title>
        </Card.Header>
        <Card.Body>
          <BootstrapSmartTable
            name="ambassadors"
            columns={columns}
            data={ambassadors}
            defaultFilters={[['taskStatus', 'PENDING']]}
            showFilter
            isLoading={loading}
            defaultSortKey={['taskStatus', 'descending']}
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default AmbassadorsTable;
