import axios from 'axios';
import getUserApiPath from '../getUserApiPath';
import Transfer from '@interfaces/Transfer';

const getTransactions = async () => {
  const url = `${getUserApiPath()}/contact/transaction`;
  const response = await axios.get(url);

  const data = response.data as Transfer[];
  return data.filter(x => x !== null) as Transfer[];

};

export default getTransactions;
