import axios from 'axios';
import {getTaskUrl} from './util';
import {Message} from '@interfaces/task';
import isAdminState from "@state/globalState/isAdminState";

export type GetMessageOptions = {
  taskId?: number,
  contactId?: number,
  companyId?: number,
  contactCompanyId?: number,
  isAnnouncement?: null | string
  loanId?: string,
}
const getMessages = async (
  {
    taskId,
    contactId,
    companyId,
    contactCompanyId,
    isAnnouncement = null,
    loanId
  }: GetMessageOptions
) => {
  let url = getTaskUrl();
  if (taskId) {
    if (companyId) {
      url += `/entity/${companyId}/task/${taskId}/chat/message`;
    } else if (contactId) {
      url += `/contact/${contactId}/task/${taskId}/chat/message`;
    } else if (contactCompanyId) {
      url += `/contact/entity/${contactCompanyId}/task/${taskId}/chat/message`;
    } else {
      url += `/contact/task/${taskId}/chat/message`;
    }
  } else {
    const isAdmin = isAdminState.get();
    // generic chat
    if (isAnnouncement) {
      url += '/chat/announcement/message';
    } else if (companyId) {
      url += `/entity/${companyId}/chat/message`;
    } else if (contactId) {
      url += `/contact/${contactId}/chat/message`;
    } else if (contactCompanyId) {
      url += `/contact/entity/${contactCompanyId}/chat/message`;
    } else if (loanId) {
      url += `/loan${isAdmin ? 'Admin' : ''}/${encodeURIComponent(loanId)}/chat/message`;
    } else {
      url += `/contact/chat/message`;
    }
  }

  const response = await axios.get(url);
  try {
    if(isAnnouncement) {
      // @ts-ignore
      return (response.data[isAnnouncement] as Message[]).filter(x => !!x);
    }
    // @ts-ignore
    return (response.data as Message[]).filter(x => !!x);
  } catch(e) {
    console.error('getMessages', e);
    console.error('getMessages: data', response.data);
    return [];
  }

};

export default getMessages;
