import React, {useRef, useState} from 'react';
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import {SmartInput, TextBox} from "@components/forms/react-hook-form-bootstrap";
import Button from "react-bootstrap/Button";
import {SubmitHandler, useForm} from "react-hook-form";
import {createGlobalState} from "react-global-hooks";
import createContact, {ContactForm} from "@hornet-api/contact/createContact";
import {SearchContactItem} from "@hornet-api/contact/searchContact";
import {FaCircleNotch} from 'react-icons/fa'
import useFromNull from "@common/useFromNull";
import {alertApiErrors} from "@common/errors";
import {refreshContactList} from "@components/NotificationSidebar/utils";

const emptyContactForm: ContactForm = {
  firstName: '',
  middleName: '',
  lastName: '',
  email: '',
  phone: '',
  sex: 'NO_ANSWER',
  maritalStatus: 'NO_ANSWER'
}


type Callback = (contact: SearchContactItem) => void | Promise<void>;

type ContactFormModal = {
  form: ContactForm,
  onDone?: Callback
}

export const newContactModalState = createGlobalState(null as null | ContactFormModal);

export const openCreateContactModal = ({callback, isEscrowOfficerActive = null}: {
  callback?: Callback,
  isEscrowOfficerActive?: boolean | null
}) => {
  const contactFormModal: ContactFormModal = {
    form: {...emptyContactForm},
    onDone: callback
  }
  if (isEscrowOfficerActive !== null) {
    contactFormModal.form.isEscrowOfficerActive = isEscrowOfficerActive;
  }
  newContactModalState.set(contactFormModal)
}

const CreateContactModal = () => {
  const [newContactForm, setNewContactForm] = newContactModalState.use();
  const firstFieldRef = useRef<HTMLInputElement>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {control, handleSubmit, reset} = useForm<ContactForm>();

  useFromNull(() => {
    if (!newContactForm) return
    reset(newContactForm.form)
  }, [newContactForm]);

  const onModalSubmit: SubmitHandler<ContactForm> = (data) => {
    if (isSubmitting) return;
    const submitForm = {...emptyContactForm, ...data};
    if (newContactForm?.form?.isEscrowOfficerActive) {
      submitForm.isEscrowOfficerActive = true;
    }
    setIsSubmitting(true);
    createContact(submitForm)
      .then(async (d) => {
        const m = newContactModalState.get()
        if (m?.onDone) {
          await m.onDone(d);
        }
        refreshContactList()
        setNewContactForm(null);
      })
      .catch((e) => {
        alertApiErrors(e)
        console.error(e);
      })
      .finally(() => setIsSubmitting(false));
  };

  return (
    <Modal
      show={!!newContactForm}
      onHide={() =>
        setNewContactForm(null)
      }
      onEntered={() => {
        setTimeout(() => {
          if (firstFieldRef.current) {
            firstFieldRef.current.focus();
          } else {
            console.log('No input ref')
          }
        }, 300)
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Create Contact Form
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate onSubmit={handleSubmit(onModalSubmit)}>
          <TextBox
            name={'firstName'}
            label={'First Name'}
            control={control}
            rules={{required: true}}
            ref={firstFieldRef}
          />
          <TextBox
            name={'middleName'}
            label={'Middle Name'}
            control={control}
          />
          <TextBox
            name={'lastName'}
            label={'Last Name'}
            control={control}
            rules={{required: true}}
          />
          <TextBox
            name={'email'}
            label={'Email'}
            type={'email'}
            control={control}
            rules={{
              required: true,
              pattern: {
                value: /^\S+@\S+\.\S+$/i,
                message: 'Invalid email address',
              },
            }}
          />

          <SmartInput
            name={'phone'}
            label={'Phone'}
            type={'phone'}
            control={control}
            rules={{ required: true }}
          />


          <Button type={'submit'} disabled={isSubmitting}>{isSubmitting ?
            <FaCircleNotch className={'spin'}/> : 'Save'}</Button>
          <Button type={'button'} variant={'secondary'} style={{marginLeft: 8}} onClick={() => {
            setNewContactForm(null)
          }}>Cancel</Button>
        </Form>
      </Modal.Body>
    </Modal>
  )
}
export default CreateContactModal;

