import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import { usersThatCanSavePresets, rolesThatCanSavePresets, canSavePresetsState } from '@hornet-api/smartTables';
import authenticationBlobAtom from '@state/recoil/authentication/core/authenticationBlobAtom';

const CanSavePresetsMonitor = () => {
  const authenticationBlob = useRecoilValue(authenticationBlobAtom);

  //@ts-ignore
  const canUserSavePresets = usersThatCanSavePresets.includes(authenticationBlob?.active_user?.sub);
  //@ts-ignore
  const canAdminUserSavePresets = usersThatCanSavePresets.includes(authenticationBlob?.active_user?.adminUser);
  //@ts-ignore
  const canRoleSavePresets = rolesThatCanSavePresets.some(x => authenticationBlob?.active_user?.roles.includes(x));
  //@ts-ignore
  const canAdminRoleSavePresets = rolesThatCanSavePresets.some(x => authenticationBlob?.active_user?.adminUserRoles?.includes(x))

  useEffect(() => {
    let newState = false;
    if (canUserSavePresets || canAdminUserSavePresets || canRoleSavePresets || canAdminRoleSavePresets) {
      newState = true;
    } else {
      newState = false;
    }
    if (newState !== canSavePresetsState.get()) {
      canSavePresetsState.set(newState);
    }
  }, [authenticationBlob]);
  return null;
}

export default CanSavePresetsMonitor;