import React, {useCallback, useEffect, useMemo} from 'react';
import getSideBarLinkObject, {SideBarLinkObject} from './sidebarLinkObject';
import SideBarItem from './SideBarItem';
import {sidebarExpandedState} from './sidebarStore';
import {useHistory} from "react-router-dom";
import userRoleGroupsState from "@state/globalState/userRoleGroupsState";
import {refreshBudgetCount, refreshDrawRequestCount} from "@components/NotificationSidebar/utils";

import hornetCapitalWhiteLogo from '@assets/images/hornet_logo_white.svg'

const styles = {
  image: {
    height: 40,
    margin: '5px auto',
    display: 'block'
  },
};

export default function SideBar() {
  const [sidebarExpanded] = sidebarExpandedState.use();
  const userRoleGroups = userRoleGroupsState.useValue();
  const history = useHistory();

  useEffect(() => {
    refreshBudgetCount();
    refreshDrawRequestCount();
  }, []);

  const userRoles = useMemo(() => {
    return userRoleGroups?.map(({roles}) => roles || []).flat() || [];
  }, [userRoleGroups]);

  const filterSidebarLinks: (x?: SideBarLinkObject[]) => SideBarLinkObject[] | undefined = useCallback((sidebarLinks?: SideBarLinkObject[]) => {
    return sidebarLinks
      ?.filter((sidebarLink) => (
        !sidebarLink.restrictedToRoles || userRoles.find(role => sidebarLink.restrictedToRoles?.includes(role))
      ))
      ?.map(topLink => ({
        ...topLink,
        subLinks: filterSidebarLinks(topLink.subLinks)
      }));
  }, [userRoles]);

  const sidebarLinks = getSideBarLinkObject()

  const sideBarItems = useMemo(() => {
    return filterSidebarLinks(sidebarLinks)?.map((topLink) => {
      return (
        <SideBarItem
          key={topLink.name}
          icon={topLink.icon}
          name={topLink.name}
          count={topLink.count}
          linkTo={topLink.linkTo}
          subLinks={topLink.subLinks}
          onClick={topLink.onClick}
          sideLink={topLink.sideLink}
          frameBreak={topLink.frameBreak || false}
        />
      );
    });
  }, [sidebarLinks, filterSidebarLinks]);

  const wrapperClassName = useMemo(() => (
    sidebarExpanded ? 'sidebar' : 'sidebar collapsed'
  ), [sidebarExpanded]);

  return (
    <div className={wrapperClassName}>
      <div className="top">
        <a href="/" onClick={(event) => {
            event.preventDefault();
            history.push('/');
        }}>
          <img
            src={hornetCapitalWhiteLogo}
            style={styles.image}
            alt={'Hornet Capital'}
          />
        </a>
      </div>
      <div className="main">
        {sideBarItems}
      </div>
    </div>
  );
}
