import {createGlobalState} from "react-global-hooks";
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import React, {useEffect, useMemo, useState} from "react";
import {SubmitHandler, useForm} from "react-hook-form";
import {Select, TextBox} from "@components/forms/react-hook-form-bootstrap";
import sendOTP2FA from "@hornet-api/twoFactorAuth/user/sendOTP2FA";
import verifyAndActivate2FA from "@hornet-api/twoFactorAuth/user/verifyAndActivate2FA";
import {alertApiErrors} from "@common/errors";
import SmartInput from "@components/forms/react-hook-form-bootstrap/SmartInput";
import {loadingRelease, loadingTrigger} from "@components/LoadingOverlay";
import {TwoFactorAuth, TwoFactorAuthConfig} from "@interfaces/TwoFactorAuth";
import {getTwoFactorAuthConfig} from "@hornet-api/twoFactorAuth/meta/getTwoFactorAuthConfig";
import {timeFormat} from "@legacy/views/UserProfileLegacy/utils";
import {addAlert} from "@components/Alert";
import VerificationFailedWarning from "@legacy/views/auth/TwoFactorAuthView/common/VerificationFailedWarning";
import OtpResendWarning from "@legacy/views/auth/TwoFactorAuthView/common/OtpResendWarning";
import useLogoutHandler from "@legacy/views/auth/useLogoutHandler";
import {fetchUser2FA} from "@hornet-api/twoFactorAuth/user/fetchUser2FA";
import getPersonalPhoneNumbers from "@hornet-api/profile/getPersonalPhoneNumbers";
import {PhoneNumber} from "@hornet-api/profile/profileInterfaces";
import {useRecoilValue} from "recoil";
import isImpersonatingSelectorAtom from "@state/recoil/authentication/isImpersonatingSelectorAtom";
import {otpCountdown} from "@legacy/views/auth/TwoFactorAuthView/common/otpCountdown";

export const twoFactorAuthState = createGlobalState<TwoFactorAuth | null>(null);

const twoFactorAuthenticationModalState = createGlobalState<boolean>(false);
export const openTwoFactorAuthenticationModal = () => {
  twoFactorAuthenticationModalState.set(true);
};

interface TwoFactorAuthFromData {
  phoneNumberSelection: string;
  newPhoneNumber: string;
  otp2fa: string;
}

const defaultTwoFactorAuthFromData: TwoFactorAuthFromData = {
  phoneNumberSelection: '',
  newPhoneNumber: '',
  otp2fa: '',
};

const TwoFactorAuthenticationModal = () => {
  const isImpersonating = useRecoilValue(isImpersonatingSelectorAtom);
  const [canCloseModal, setCanCloseModal] = useState<boolean>(true)
  const warningMessage = 'To provide your account with extra security, two-factor authentication is now required.'
  const [modalState, setModalState] = twoFactorAuthenticationModalState.use();
  const [isOtpSent, setIsOtpSent] = useState<boolean>(false);
  const [countdown, setCountdown] = useState<number>(0);
  const [twoFactorAuthConfig, setTwoFactorAuthConfig] = useState<TwoFactorAuthConfig>();
  const [user2FA, setUser2FA] = twoFactorAuthState.use();
  const [phoneNumbers, setPhoneNumbers] = useState<PhoneNumber[]>([]);

  const logoutHandler = useLogoutHandler();

  const phoneNumberOptions = useMemo(() => {
    const options = phoneNumbers?.reduce((options: { [p: string]: string }, {id, phoneNumber}) => {
      options[`${id}`] = phoneNumber;
      return options;
    }, {}) || {};

    options.NEW = 'New';
    return options;
  }, [phoneNumbers]);

  const {
    watch,
    control,
    handleSubmit,
    reset,
    getValues
  } = useForm<TwoFactorAuthFromData>();
  const phoneNumberSelection = watch('phoneNumberSelection');
  const newPhoneNumber = watch('newPhoneNumber');

  const resetLockedForSeconds = (countdown: number) => {
    setCountdown(countdown)
    if (!countdown && user2FA && modalState) {
      setUser2FA({
        ...user2FA,
        resendLockedForSeconds: 0
      })
    }
  }

  useEffect(() => {
    if (countdown > 0) {
      const countdownTimer = otpCountdown(countdown, resetLockedForSeconds);
      return () => {
        countdownTimer && clearInterval(countdownTimer)
      };
    }
  }, [isOtpSent, countdown]);

  useEffect(() => {
    const checkCanClose = isImpersonating ||( user2FA && (user2FA.isEnabled || user2FA.isTurnedOffByAdmin));
    setCanCloseModal(!!checkCanClose);
  }, [user2FA])

  useEffect(() => {
    // on close reset form data
    if (!modalState) {
      reset({...defaultTwoFactorAuthFromData});
      return;
    }
    const t = loadingTrigger();

    Promise.all([
      getTwoFactorAuthConfig()
        .then(setTwoFactorAuthConfig),
      fetchUser2FA()
        .then(setUser2FA),
      getPersonalPhoneNumbers()
        .then(setPhoneNumbers)
    ])
      .catch(alertApiErrors)
      .finally(() => {
        loadingRelease(t);
      });
  }, [modalState]);

  const handleClose = () => {
    setModalState(false);
    setIsOtpSent(false);
    setCountdown(0);
  };

  const confirmClose = () => {
    if (canCloseModal && confirm('Are you sure you want to cancel the setup process for Two Factor Authentication?')) {
      handleClose();
    }
  }

  const resendOtp = () => {
    if (countdown > 0) return;

    const t = loadingTrigger();
    sendOTP2FA({
      phoneNumber: phoneNumberSelection === 'NEW' ?
        newPhoneNumber
        : phoneNumberOptions[phoneNumberSelection]
    })
      .then(async (twoFactorAuth) => {
        if (twoFactorAuth) {
          setUser2FA(twoFactorAuth)
          if (countdown === 0) {
            setCountdown(twoFactorAuth.resendLockedForSeconds)
          }
        }
        setIsOtpSent(true);
        reset(getValues());
      })
      .catch((error) => {
        if (error.response.status === 429) {
          logoutHandler()
          return;
        }
        alertApiErrors(error)
      })
      .finally(() => {
        loadingRelease(t);
      });
  }

  const onSubmit: SubmitHandler<TwoFactorAuthFromData> = (data) => {
    if (!isOtpSent) {
      resendOtp()
      return;
    }

    const t = loadingTrigger();
    verifyAndActivate2FA({
      otp2fa: data.otp2fa
    }).then(({success, message, twoFactorAuth}) => {
      setUser2FA(twoFactorAuth);
      if (message) {
        addAlert({
          type: success ? "success" : "danger",
          content: message
        });
      }
      if (success) {
        handleClose();
      }
    }).catch((error) => {
      if (error.response.status === 429) {
        addAlert({
          type: "danger",
          content: error.response.data
        })
        logoutHandler()
        return;
      }
      alertApiErrors(error)
    }).finally(() => {
      loadingRelease(t);
    })
  }

  if (!modalState || !twoFactorAuthConfig) return null;

  const otpLength = twoFactorAuthConfig.codeLength;

  return (
    <Modal
      show={modalState}
      onHide={confirmClose}
      id={'twoFactorAuthenticationModal'}
    >
      <Modal.Header closeButton={canCloseModal}>
        <Modal.Title>Two Factor Authentication Set up</Modal.Title>
      </Modal.Header>
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body className={'position-relative'}>
          {
            !user2FA?.isEnabled &&
            (
              <Row>
                <Col className='text-danger'>{warningMessage}</Col>
              </Row>
            )
          }
          <Select
            name={'phoneNumberSelection'}
            label={'Select phone number'}
            control={control}
            options={phoneNumberOptions}
            emptyOption={'-- Please Select --'}
            showOnly={isOtpSent}
            rules={{
              required: true
            }}
          />
          {
            phoneNumberSelection === 'NEW' &&
            (
              <SmartInput
                type={'phone'}
                name={'newPhoneNumber'}
                label={'Phone Number'}
                control={control}
                showOnly={isOtpSent}
                rules={{
                  required: true,
                  validate: {
                    unique: (value?: string) => {
                      if (
                        value &&
                        Object.values(phoneNumberOptions)
                          .find(existing => existing.match(/\d+/g)?.join('')?.slice(-10) === value)
                      ) {
                        return "Already there in the list of phone numbers."
                      }
                      return true
                    }
                  }
                }}
              />
            )
          }
          {
            phoneNumberSelection &&
            (
              <OtpResendWarning
                maxResendsPerLock={twoFactorAuthConfig?.maxResendsPerLock}
                resendLockHours={twoFactorAuthConfig?.resendLockHours}
                resendCount={user2FA?.resendCount}
              />
            )
          }
          {
            phoneNumberSelection &&
            isOtpSent &&
            (
              <>
                <TextBox
                  name={'otp2fa'}
                  label={'Please enter your Authorization Code'}
                  control={control}
                  type={"number"}
                  maxLength={otpLength}
                  rules={{
                    required: true,
                    minLength: {value: otpLength, message: `It should be a ${otpLength} digit number`},
                    maxLength: {value: otpLength, message: `It should be a ${otpLength} digit number`}
                  }}
                />
                <VerificationFailedWarning
                  failureCount={user2FA?.failureCount}
                  maxFailureCount={twoFactorAuthConfig?.maxFailureCount}
                />

                <Button
                  disabled={countdown > 0}
                  onClick={resendOtp}
                  variant={'link'}
                  className={'pl-0 mb-3'}
                >
                  {`Resend Authorization Code${countdown > 0 ? ` in ${timeFormat(countdown)}` : ''}`}
                </Button>
              </>
            )
          }
          {
            !isOtpSent && countdown > 0 &&
            (
              <i className={'d-block text-danger'}>
                <small>
                  You will have to wait for the lock time {timeFormat(countdown)}
                </small>
              </i>
            )
          }
        </Modal.Body>
        <Modal.Footer>
          {canCloseModal && (
            <Button variant="secondary" onClick={confirmClose}>
              Close
            </Button>
          )}
          {
            isOtpSent && (
              <Button
                variant="info"
                onClick={() => {
                  setIsOtpSent(false);
                }}>
                Change Phone Number
              </Button>
            )}
          <Button
            variant="primary"
            type="submit"
            disabled={!isOtpSent && countdown > 0}
          >{isOtpSent ? 'Verify' : 'Send Code'}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}
export default TwoFactorAuthenticationModal;
