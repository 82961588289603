/* eslint-disable no-magic-numbers */
import { selector } from 'recoil';
import activeUserSelectorAtom from '../activeUserSelectorAtom';

const activeUserRolesSelectorAtom = selector({
	key: 'activeUserRolesSelectorAtom',
	default: [],
	get: ({ get }) => {
		const user = get(activeUserSelectorAtom);
		return user?.roles ?? [];
	},
});

export default activeUserRolesSelectorAtom;
