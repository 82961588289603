import axios, {AxiosResponse} from 'axios';
import getAdminApiPath from '../getAdminApiPath';
import {AssociatedLoan} from "@interfaces/AssociatedLoan";

const getContactAssociatedLoansAdmin = async (contactId: number | string) => {
  const url = `${getAdminApiPath()}/contact/${contactId}/associatedLoans`;
  const response: AxiosResponse<AssociatedLoan[]> = await axios.get(url);
  return response.data.filter((x) => x);
};

export default getContactAssociatedLoansAdmin;
