import axios from 'axios';
import {getBaseUri} from '@common/baseUri';

export type ForgotUsernameData = {
	email: string,
}

const forgotUsername = async (data: ForgotUsernameData) => {
	await axios.post<void>(`${getBaseUri()}api/public/forgotUsername`, data);
};
export default forgotUsername;
