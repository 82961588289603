import React, {useCallback, useEffect, useMemo, useState} from 'react';
import TaskItem from './components/TaskItem';
import {FaCircleNotch, FaPlus} from 'react-icons/fa';
import InfiniteScroll from "react-infinite-scroll-component";
import {
  companyListState,
  isAddingTaskFromSidebarState,
  selectedTaskState,
  selectedTopMenuItemState,
  tasksMetaState,
  tasksState,
  userListState
} from './globalState';
import style from './NotificationTasks.module.scss';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import {getUserCompanySelectGroups, triggerTaskRefresh} from './utils';
import Empty from './components/Empty';
import TaskDetail from './components/TaskDetail';
import BaseSelectBox from "@components/forms/BaseSelectBox";
import isAdminState from "@state/globalState/isAdminState";
import {getPaginateTasks} from "@hornet-api/task/getTasks";
import {addTaskModalState} from "@admin-ui/pages/LoanShowPage/components/AddTaskToLoanModal";

interface IUserOrCompanyOption {
  value: string,
  label: string
}

const NotificationTasks = () => {
  const tasks = tasksState.useValue();
  const [tasksMetaData, setMetaData] = tasksMetaState.use();
  const isAdmin = isAdminState.useValue();
  const selectedTopMenuItem = selectedTopMenuItemState.useValue();
  const [selectedTask, setSelectedTask] = selectedTaskState.use();
  const userList = userListState.useValue();
  const companyList = companyListState.useValue();

  const [selectedUserOrCompany, setSelectedUserOrCompany] = useState<null | IUserOrCompanyOption>(null);

  const showNotifications = useMemo(() => {
    return selectedTopMenuItem === 'tasks';
  }, [selectedTopMenuItem])

  const loadNextPage = useCallback(() => {
    setMetaData({...tasksMetaData, page: tasksMetaData.page + 1});
  }, [tasksMetaData]);

  useEffect(() => {
    if (tasksMetaData.page === -1) {
      return;
    }
    if (tasksMetaData.page === 0) {
      tasksState.set([]);
      loadNextPage();
      return;
    }

    const [type, idString] = selectedUserOrCompany?.value.split('-') || [];
    const data = {
      contact: type === 'CONTACT' ? `=${idString}` : undefined,
      entity: type === 'ENTITY' ? `=${idString}` : undefined,
      page: tasksMetaData.page
    };

    getPaginateTasks(data)
      .then(({data, ...taskMeta}) => {
        if (tasksMetaData.page === 1) {
          tasksState.set(data);
        } else {
          tasksState.set([...(tasks || []), ...data]);
        }

        setMetaData({...taskMeta, page: tasksMetaData.page})
      })
  }, [tasksMetaData.page]);

  const selectOptions = useMemo(() => {
    if (isAdmin) {
      return getUserCompanySelectGroups()
    } else {
      return [];
    }
  }, [userList, companyList, isAdmin]);

  if (!showNotifications) {
    return null;
  }

  // show task list
  if (selectedTask === null) {
    return (
      <div className={style.taskList}>
        {
          isAdmin ?
            <div className={style.adminHeader}>
              <div className={style.selectBox}>
                <BaseSelectBox
                  value={selectedUserOrCompany}
                  options={selectOptions}
                  isClearable={true}
                  onChange={(e: null | { value: string, label: string }) => {
                    setSelectedUserOrCompany(e);
                    triggerTaskRefresh();
                  }}
                />
              </div>
              <div className={style.tools}>
                <OverlayTrigger
                  placement="bottom-end"
                  overlay={<Tooltip id={`tt-add-chat`}>Add Task</Tooltip>}
                >
                  <button
                    className='btn btn-primary'
                    name='Add Task'
                    onClick={() => {
                      isAddingTaskFromSidebarState.set(true);
                      addTaskModalState.set({onClose: () => isAddingTaskFromSidebarState.set(false)});
                    }}
                  >
                    <FaPlus/>
                  </button>
                </OverlayTrigger>
              </div>
            </div>
            :
            null
        }
        {
          tasks && tasks.length > 0 ?
            (
              <InfiniteScroll
                scrollableTarget={'scrollableNotificationSidebar'}
                dataLength={tasks.length}
                next={loadNextPage}
                hasMore={tasks.length < tasksMetaData.total}
                loader={<div className="text-center overflow-hidden"><FaCircleNotch className='spin'/></div>}
                endMessage={
                  isAdmin ? (
                    <p className="text-center my-2">
                      <b>Yay! You have seen it all</b>
                    </p>
                  ) : null
                }
              >
                {
                  tasks.map((task, index) => {
                    if (task.taskStatus === 'HIDDEN') {
                      return null;
                    }
                    return (<TaskItem task={task} key={index} onClick={() => {
                      setSelectedTask(task.id)
                    }}/>);
                  })
                }
              </InfiniteScroll>
            )
            :
            <Empty type='task'/>
        }
      </div>
    );
  }

  return <TaskDetail />;
}

export default NotificationTasks;