import axios from 'axios';
import { getBaseUri } from '@common/baseUri';
import DocumentRequest from '@interfaces/DocumentRequest';

const uploadDocument = (
  docRequest: DocumentRequest,
  file: File
) => {
  return new Promise((resolve, reject) => {
    const url = `${getBaseUri()}legacy/documentRequest/completeDocumentRequest`;
    const formData = new FormData();

    formData.append("requestId", docRequest.requestCode);
    formData.append("document", file);
    formData.append("isClient", 'true');
    formData.append("documentType", docRequest.documentType);

    axios.post(
      url,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    ).then((response) => {
      resolve(response.data);
    }).catch(e => {
      reject(e);
    });
  });
}

export default uploadDocument;
