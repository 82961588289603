import axios from 'axios';
import {getTaskUrl} from './util';

import {UserBase} from '@interfaces/task';

const getAdminUsername = async () => {
  let url = getTaskUrl();
  url += `/adminInfo`;

  const response = await axios.get(url);
  return response.data as UserBase;
};

export default getAdminUsername;
