import axios from 'axios';
import getAdminApiPath from "@hornet-api/getAdminApiPath";

export type CompanyListItem = {
  id: number;
  name: string;
  fullAddresses: string | null;
  phoneNumbers: string | null;
  contacts: string | null;
  properties: string | null
};

const getCompanyList = async () => {
  let url = `${getAdminApiPath()}/entity`;
  const response = await axios.get(url);
  return response.data as CompanyListItem[];
};

export default getCompanyList;
