import Form from "react-bootstrap/Form";
import React, {FocusEventHandler, useMemo} from "react";
import {ControllerRenderProps, FieldValues} from "react-hook-form";
import style from "@components/forms/react-hook-form-bootstrap/style.module.scss";
import {SelectOptionProps} from "@components/forms/react-hook-form-bootstrap/interfaces";
import _ from "lodash";

type Option = {
  label: string,
  value?: string | number,
  disabled?: boolean
}

type Group = {
  label: string;
  options: Option[];
}

type GroupedOptions = {
  [p: string]: Group
}

const renderOptions = (options: Option[]) => {
  return options.map(({value, label, disabled}, index) => {
    return (
      <option key={index} value={value} disabled={disabled}>
        {label}
      </option>
    );
  })
}

const SelectControl = (
  {
    id,
    showOnly,
    emptyOption,
    showEmptyOption = true,
    isRequired,
    field,
    onChange,
    isInvalid,
    disabled,
    options,
    onBlur,
    onFocus,
  }: {
    id?: string;
    showOnly?: boolean;
    emptyOption?: string;
    showEmptyOption?: boolean;
    options: {
      [p: string]: string;
    } | SelectOptionProps[];
    onChange?: (val: string) => void | Promise<void>;
    disabled: boolean;
    isRequired: boolean;
    isInvalid: boolean;
    field: ControllerRenderProps<FieldValues, string>;
    onFocus?: FocusEventHandler | undefined;
    onBlur?: FocusEventHandler | undefined;
  }
) => {
  const groupedOptions = useMemo(() => {
    let groupKey = '';
    if (_.isArray(options)) {
      const useOptions = options as SelectOptionProps[]

      return useOptions.reduce((acc, {value, label, group, disabled}) => {
        group = group || '';
        if (!acc[group]) {
          acc[group] = {
            label: group,
            options: []
          };
        }
        acc[group || ''].options.push({value, label, disabled});
        return acc
      }, {} as GroupedOptions)
    } else {
      const useOptions = options as { [p: string]: string }

      return Object.keys(useOptions).reduce((acc, key) => {
        if (key.match(/group\d/)) {
          groupKey = key;
          acc[groupKey] = {
            label: useOptions[key],
            options: []
          };
        } else {
          acc[groupKey].options.push({value: key, label: useOptions[key]});
        }
        return acc
      }, {'': {label: '', options: []}} as GroupedOptions)
    }
  }, [options])

  const selectedOptionLabel = useMemo(() => {
    if (!field.value) {
      return null
    }
    const selectedValue = `${field.value}`

    let foundOptionLabel = null,
      foundOption;
    Object.keys(groupedOptions).forEach((groupKey) => {
      foundOption = groupedOptions[groupKey].options.find(({value}) => `${value}` === selectedValue)
      if (foundOption) {
        foundOptionLabel = foundOption.label;
        return false
      }
    })

    return foundOptionLabel
  }, [field.value, groupedOptions])

  return showOnly ?
    <div className={style.showOnlyValue}>{field.value && selectedOptionLabel}</div>
    :
    (
      <Form.Control
        id={id}
        required={isRequired}
        as="select"
        {...field}
        onChange={(event) => {
          if (onChange) {
            onChange(event.target.value);
          }
          field.onChange(event);
        }}
        // isValid={fieldState.isTouched && !isInvalid}
        isInvalid={isInvalid}
        disabled={disabled}
        onBlur={onBlur}
        onFocus={onFocus}
      >
        {showEmptyOption && <option value={''}>{emptyOption}</option>}
        {Object.keys(groupedOptions).map((groupKey, groupIndex) => {
          if (groupedOptions[groupKey].label) {
            return (
              <optgroup label={groupedOptions[groupKey].label} key={`g-${groupIndex}`}>
                {renderOptions(groupedOptions[groupKey].options)}
              </optgroup>
            );
          }

          // groupKey is blank string by default, as boolean that's false,
          // that's how it reaches here and works
          return renderOptions(groupedOptions[groupKey].options);
        })}
      </Form.Control>
    );
}

export default SelectControl;