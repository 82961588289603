import axios, { AxiosResponse } from 'axios';
import getAdminApiPath from '@hornet-api/getAdminApiPath';
import ReferralAdmin from '@interfaces/ReferralAdmin';

export interface UpdateReferral extends Partial<ReferralAdmin> {}

const updateReferral = async (
  referralId: number | string,
  data: UpdateReferral
): Promise<ReferralAdmin> => {
  let url = `${getAdminApiPath()}/ambassador/referral/${referralId}`;
  const response: AxiosResponse<ReferralAdmin> = await axios.put(url, data);
  return response.data;
};

export default updateReferral;
