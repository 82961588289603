import React from 'react';
import Store from './globalState';
import {Column} from './index';

type Props<D> = {
  store: Store<D>,
  columns: Column<D>[],
};

function MobileSort<D>(
  {
    store,
    columns,
  }: Props<D>
) {
  const {sortAscendingState, sortKeyState} = store;
  const [sortAscending, setSortAscending] = sortAscendingState.use();
  const [sortKey, setSortKey] = sortKeyState.use();

  return (
    <>
      <div className="form-group">
        <label htmlFor='sort-field'>Sort by</label>
        <select
          className="form-control"
          id="sort-field"
          value={sortKey}
          onChange={(e) => {
            setSortKey(e.target.value);
          }}
        >
          {
            columns?.map((col, index) => {
              return (
                <option key={index} value={col.field}>{col.title}</option>
              )
            })
          }
        </select>
        <div style={{
          textAlign: 'center',
          marginTop: 10
        }}>
          <div className="btn-group" role="group" style={{ width: `100%` }}>
            <button
              type="button"
              className={`btn ${sortAscending ? 'btn-primary' : 'btn-secondary'}`}
              onClick={() => {
                setSortAscending(true);
              }}
            >
              ASC
            </button>
            <button
              type="button"
              className={`btn ${!sortAscending ? 'btn-primary' : 'btn-secondary'}`}
              onClick={() => {
                setSortAscending(false);
              }}
            >
              DESC
            </button>
          </div>
        </div>
      </div>

    </>
  );
}

export default MobileSort;