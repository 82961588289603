import {BankAccountDepositMethodEnum, BankAccountTypeEnum} from "@interfaces/GeneratedEnums";
import {SelectOptionProps} from "@components/forms/react-hook-form-bootstrap/interfaces";
import BankAccountWithOwner from "@interfaces/BankAccountWithOwner";
import Contact from "@interfaces/Contact";
import {getFullName} from "@common/basic";
import {EntityListLightObj} from "@hornet-api/entities/getEntitiesListLight";

export interface IBankAccountForm {
  id?: number;
  entity: SelectOptionProps | null;
  bankName: string;
  nameOnAccount: string;
  accountType: keyof typeof BankAccountTypeEnum;
  depositMethod: keyof typeof BankAccountDepositMethodEnum;
  accountInformation: {
    accountNumber: string;
    routingNumber: string;
  };
}

export const defaultBankAccount: IBankAccountForm = {
  entity: null,
  bankName: '',
  nameOnAccount: '',
  accountType: 'CHECKING',
  depositMethod: 'WIRE_AND_ACH',
  accountInformation: {
    accountNumber: '',
    routingNumber: '',
  },
};

export const convertServerDataToFormData = (ba: BankAccountWithOwner, profile: Contact | null): IBankAccountForm => {
  return {
    ...ba,
    entity: {
      value: ba.entity?.id || 0,
      label: ba.entity?.name || getFullName(profile)!
    }
  }
}

export const getFormDataForEntity = (entityId: number, entityList: EntityListLightObj[]): IBankAccountForm => {
  const matchedEntity = entityList.find(e => e.id == entityId);
  return {
    ...defaultBankAccount,
    entity: {
      value: matchedEntity?.id,
      label: matchedEntity?.name!
    }
  }
}