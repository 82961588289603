import RoleGroupRoles from '@interfaces/RoleGroupRoles';

// determines if the user has the authority to view the component
const hasAuthority = (
  authorities: string[],
  userRoleGroups: RoleGroupRoles[] | null,
  activeRole: number | null
) => {
  const activeGroup = userRoleGroups?.find((x) => x.id === activeRole) ?? null;

  if (!activeGroup) return false;
  if (activeGroup.roles?.some((x) => authorities?.includes(x))) return true;
  return false;
};

export default hasAuthority;
