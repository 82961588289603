import {getTaskUrl} from "@hornet-api/task/util";
import axios from "axios";
import {ACHCategoryEnum, ACHStatusEnum} from "@interfaces/GeneratedEnums";

export type IAchItem = {
  id: number;
  company: number | null;
  contact: number | null;
  dateCreated: string;
  status: keyof typeof ACHStatusEnum;
  category: keyof typeof ACHCategoryEnum;
  amount: number;
  investor: string;
}

export const getAchListData = async () => {
  const url = `${getTaskUrl()}/transaction/listData`;
  const response = await axios.get(url);
  return response.data as IAchItem[];
}