import axios from "axios";
import getUserApiPath from "@hornet-api/getUserApiPath";
import {ThirdPartyNameEnum} from "@interfaces/GeneratedEnums";

export interface ThirdParty2FA {
  name: keyof typeof ThirdPartyNameEnum;
  expirationTimeInSeconds: number;
  code: string;
}

export const fetchThirdParty2FA = (key: keyof typeof ThirdPartyNameEnum) => {
  const url = `${getUserApiPath()}/2fa/thirdparty/${key}`;
  return axios.get<ThirdParty2FA>(url).then(res => res.data);
};