import axios from 'axios';
import getAdminApiPath from "@hornet-api/getAdminApiPath";

/**
 * Fetches created (unhandled) draw-request count
 *
 * Used to refresh DrawRequest Count on the left sidebar for admins
 */
export const getCreatedDrawRequestCount = async () => {
  const url = `${getAdminApiPath()}/drawRequest/countCreated`;
  const response = await axios.get(url);
  return response.data as { total: number };
};
