import axios, {AxiosResponse} from 'axios';
import {getCleanBaseUri} from '@common/baseUri';
import getAdminApiPath from '@hornet-api/getAdminApiPath';

type Feature = {
  feature: string;
  isEnabled: boolean;
};

type FeatureCategory = 'ach' | 'ambassador';

export const getIsFeatureEnabled = async (featureName: FeatureCategory) => {
  const url = `${getCleanBaseUri()}/api/public/features`;
  const response: AxiosResponse<Feature[]> = await axios.get(url);
  const achFeature = response.data.find((x) => x.feature === featureName);
  return !!achFeature && achFeature.isEnabled;
};

// Moving towards making "featureName" a string, can be handled from server-side if not found
export const updateFeatureEnabled = async (featureName: string, isEnabled: boolean) => {
  const url = `${getAdminApiPath()}/features`;
  const response: AxiosResponse<Feature> = await axios.put(
    url,
    {
      feature: featureName,
      isEnabled: isEnabled,
    }
  );
  return response.data.isEnabled;
};
