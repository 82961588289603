import {YesNoTextEnum} from "@interfaces/Enums";
import {PropertyFromData, PropertyServer} from "@interfaces/Property";
import {DocumentUpload} from "@interfaces/Document";
import {stringToDate} from "@common/basic";
import convertJsonToFormData from "@common/utils/form/convertJsonToFormData";

export const convertPropertyToFormData = (propertyFromData: PropertyFromData) => {
  const formData = convertJsonToFormData(propertyFromData);
  const {owner, mailingAddress, details} = propertyFromData;
  formData.append("ownedBy", `${owner?.value}`);
  formData.append('ownerCategory', owner?.entityType ? getOwnerCategory(owner.entityType) : '');
  // it is part of property
  formData.append('counties', JSON.stringify(mailingAddress.counties.map(county => county.value)));
  formData.append("details.isLeased", details.isLeased ? `${details.isLeased === YesNoTextEnum.Yes}` : '');
  return formData;
}

export const convertDocumentToFormData = (requestData: DocumentUpload) => convertJsonToFormData(requestData);

export const convertPropertyServerToFormData = (propertyServer: PropertyServer) => {
  const {mailingAddress, details} = propertyServer
  const requestData: PropertyFromData = {
    propertyType: propertyServer.propertyType,
    numberOfProperties: `${propertyServer.numberOfProperties ?? ''}`,
    owner: propertyServer.owner,
    legalAddress: propertyServer.legalAddress,
    legalAddressDocument: null,
    lotSize: propertyServer.lotSize,
    finalProperty: propertyServer.finalProperty,
    initialProperty: propertyServer.initialProperty,
    asIsCondition: propertyServer.asIsCondition,
    otherFeature: propertyServer.otherFeature,
    accessInfo: propertyServer.accessInfo,
    mailingAddress: {
      address1: mailingAddress.address1,
      address2: mailingAddress.address2,
      address3: mailingAddress.address3,
      state: mailingAddress.state,
      city: mailingAddress.city,
      zip: mailingAddress.zip,
      longitude: mailingAddress.longitude,
      latitude: mailingAddress.latitude,
      counties: propertyServer.counties?.map(item => ({
        value: item.id,
        label: item.name
      }))
    },
    details: {
      purchasePrice: `${details?.purchasePrice ?? ''}`,
      purchaseDate: details?.purchaseDate ? stringToDate(details?.purchaseDate) : null,
      paidHardConstructionCost: `${details?.paidHardConstructionCost ?? ''}`,
      asIsValuation: `${details?.asIsValuation ?? ''}`,
      constructionBudget: `${details?.constructionBudget ?? ''}`,
      afterRepairValue: `${details?.afterRepairValue ?? ''}`,
      isLeased: details?.isLeased === null ? null : details?.isLeased ? YesNoTextEnum.Yes : YesNoTextEnum.No,
      monthlyMarketRent: `${details?.monthlyMarketRent ?? ''}`,
      monthlyHOADues: `${details?.monthlyHOADues ?? ''}`,
      annualTaxesOwed: `${details?.annualTaxesOwed ?? ''}`,
      yearlyInsurancePremium: `${details?.yearlyInsurancePremium ?? ''}`,
      soldPrice: `${details?.soldPrice ?? ''}`,
      soldDate: details?.soldDate ? stringToDate(details?.soldDate) : null,
      monthlyOtherExpenses: `${details?.monthlyOtherExpenses ?? ''}`,
    }
  };
  return requestData;
}
export const getOwnerCategory = (ownerCategory: string) => ownerCategory == "CONTACT" ? "INDIVIDUAL" : "ENTITY"