import axios from 'axios';
import {FirebaseSubscription} from '@common/serviceWorker';
import getMetaApiPath from "@hornet-api/getMetaApiPath";

const saveFirebaseSubscription = async (recipientToken: string) => {
  const url = `${getMetaApiPath()}/fcm`;
  await axios.post(url, {recipientToken});
};

export default saveFirebaseSubscription;
