import React, {useCallback, useMemo, useRef, useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import {createGlobalState} from 'react-global-hooks';
import FontAwesomeIcon from '@components/Icons/FontAwesomeIcon';
import uploadDocument from '@hornet-api/documents/uploadDocument';
import uploadDocumentNew, {DocumentRequestModalObjNew} from '@hornet-api/documents/uploadDocumentNew';
import {useDropzone} from 'react-dropzone';
import DocumentRequest from '@interfaces/DocumentRequest';
import {AiOutlineDownload} from 'react-icons/ai';
import style from './DocumentRequestModal.module.scss';
import classNames from "classnames";

export interface DocumentRequestModalObj extends DocumentRequest {
  onClose?: () => void
}

export const documentRequestModalState = createGlobalState(null as DocumentRequestModalObj | DocumentRequestModalObjNew | null);


const DocumentRequestModal = () => {
  const [documentState, setDocumentState] = documentRequestModalState.use();
  const [isUploading, setIsUploading] = useState(false);
  const [fileIncrement, setFileIncrement] = useState(1);
  const [selectedFile, setSelectedFile] = useState(null as null | File);
  const [error, setError] = useState(null as null | string);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const isNew = documentState && 'new' in documentState && documentState.new ? true : false;

  const handleClose = () => {
    if (documentState && documentState.onClose) {
      documentState.onClose();
    }
    setFileIncrement(fileIncrement + 1);
    setDocumentState(null);
    setSelectedFile(null);
    setIsUploading(false);
    setError(null);
  };

  const submit = () => {
    if (documentState && !isUploading && selectedFile) {
      setError(null);
      setIsUploading(true);
      (
        'id' in documentState ?
          uploadDocument(documentState, selectedFile)
          : uploadDocumentNew(documentState, selectedFile)
      ).then(handleClose)
        .catch(() => {
          setIsUploading(false);
          setError('Something went wrong');
        })
    }
  }

  const title = useMemo(() => {
    if (documentState) {
      if (isNew) {
        return 'Upload New Document';
      }
      switch (documentState.documentType) {
        case 'NDA':
          return 'NDA'; // was blank on legacy
        case 'CREDIT':
          return 'Credit'; // Was blank on legacy
        case 'SUBSCRIPTION_AGREEMENT':
          return 'Subscription agreement'; // was blank on legacy
        case 'DRIVER_LICENSE':
          return 'Upload Driver License';
        case 'FREEFORM':
          return `Upload ${documentState.freeFormTitle || 'FREEFORM'}`
      }
    } else {
      return 'Document Request';
    }
  }, [documentState])

  const show = documentState !== null;
  const onDrop = useCallback((acceptedFiles: File[]) => {
    console.log('acceptedFiles', acceptedFiles);
    if (acceptedFiles && Array.isArray(acceptedFiles) && acceptedFiles.length > 0) {
      setSelectedFile(acceptedFiles[0]);
    }
  }, []);
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

  console.log(" were in the doc modal")

  const getDragText = () => {
    if (isDragActive) {
      return (
        <div
          className={style.dropText}
          onClick={() => {
            if (fileInputRef.current) {
              fileInputRef.current.click();
            }
          }}
        >
          <div
            className={style.blueText}
            style={{
              fontSize: 14,
              lineHeight: `14px`
            }}
          >
            Drop It Here
          </div>
        </div>
      )
    }
    if (selectedFile) {
      return (
        <div
          className={style.dropText}
          onClick={() => {
            if (fileInputRef.current) {
              fileInputRef.current.click();
            }
          }}
        >
          <div
            className={style.blueText}
            style={{
              fontSize: 14,
              lineHeight: `14px`
            }}
          >
            {selectedFile.name}
          </div>
        </div>
      )
    }
    return (
      <div
        className={style.dropText}
        onClick={() => {
          if (fileInputRef.current) {
            fileInputRef.current.click();
          }
        }}
      >
        <div
          className={style.blueText}
          style={{
            fontSize: 14,
            lineHeight: `14px`
          }}
        >
          Choose a File
        </div>
        <div
          style={{
            fontSize: 10,
            lineHeight: `10px`
          }}
        >
          or
        </div>
        <div
          style={{
            fontSize: 14,
            lineHeight: `14px`
          }}
        >
          drag it here
        </div>
      </div>
    )
  };

  const disabledReason = useMemo(() => {
    if (isUploading) {
      return 'Uploading';
    }
    if (selectedFile === null) {
      return 'No file selected';
    }
    if (isNew) {
      if (documentState) {
        if (!documentState.freeFormTitle) {
          return 'No title'
        }
        if ('freeFormTitle' in documentState && documentState.freeFormTitle.length < 3) {
          return 'No title';
        }
      }
    }
    return null;
  }, [isUploading, selectedFile, documentState])

  return (
    <Modal
      show={show}
      onHide={handleClose}
      id={'document-request-modal'}
      onEntered={() => {
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          error ?
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
            :
            null
        }
        {
          documentState ?
            <>
              <div
                className={isDragActive ? style.dropContainerDrag : style.dropContainer}
                {...getRootProps()}
              >
                <div className={style.dropContent}>
                  <div className={style.dropIcon}>
                    <AiOutlineDownload/>
                  </div>
                  {getDragText()}
                </div>
              </div>
              {
                isNew ?
                  <div className="form-group row mb-3 floating-form-group">
                    <div id={'title-scrollTo'}/>
                    <div className="col-12">
                      <div className={'input-group'}>
                        <input
                          id={'title'}
                          type="text"
                          className="form-control"
                          value={documentState && documentState.freeFormTitle ? documentState.freeFormTitle : ''}
                          onChange={(e) => {
                            const newDocState = {...documentState};
                            newDocState.freeFormTitle = e.target.value;
                            setDocumentState(newDocState);
                          }}
                        />
                        <label htmlFor={'title'} className={classNames(documentState.freeFormTitle ? 'has-value' : '')}><span>Title</span></label>
                      </div>
                    </div>
                  </div>
                  :
                  null
              }
              <div className="input-group">
                {/* <div className="custom-file" style={{ cursor: 'pointer' }}> */}
                <input
                  className="form-control"
                  type="file"
                  id='documentRequestModalInput'
                  name="document"

                  ref={fileInputRef}
                  key={fileIncrement}
                  onChange={(e) => {
                    if (e.target.files && e.target.files.length > 0) {
                      const file = e.target.files[0];
                      setSelectedFile(file)
                    } else {
                      setSelectedFile(null);
                    }
                  }}
                  style={{
                    display: 'none'
                  }}
                  required
                  {...getInputProps()}
                />
              </div>
            </>
            :
            null
        }


      </Modal.Body>
      <Modal.Footer>

        <Button
          variant="primary"
          disabled={Boolean(disabledReason)}
          onClick={() => {
            submit();
          }}
        >
          {isUploading ? <FontAwesomeIcon icon='circle-o-notch' spin={true}/> : 'Upload'}
        </Button>

        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
      </Modal.Footer>

    </Modal>
  );
};

export default DocumentRequestModal;
