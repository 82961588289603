import axios from "axios";
import {getCleanBaseUri} from "@common/baseUri";
import convertJsonToFormData from "@common/utils/form/convertJsonToFormData";

interface ITaskFileForm {
  taskId: number;
  files: File[];
}

const uploadMediaForTask = async (contactUuid: string, taskForm: ITaskFileForm[]) => {
  const formData = convertJsonToFormData({taskFiles: taskForm});
  const url = `${getCleanBaseUri()}/api/v1/public/contact/${contactUuid}/task`;
  return axios.post(url, formData);
}

export {uploadMediaForTask};