import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {WebSocketNotification} from "@hornet-api/websocket/notification";
import {
  refreshAdminContactInfo,
  refreshAnnouncements,
  refreshBudgetCount,
  refreshChatDetails,
  refreshChatObject,
  refreshDrawRequestCount,
  refreshTaskDetails,
  refreshTransactions,
  refreshUser2FAInfo,
  refreshUserBudgetNotificationCount,
  triggerTaskRefresh
} from "@components/NotificationSidebar/utils";
import Stomp, {Message} from "stompjs";
import {useRecoilValue} from "recoil";
import profileAtom from "@state/recoil/profileAtom";
import Contact from "@interfaces/Contact";
import {getBorrowerRole, getInvestorRole} from "@common/isRole";
import SockJS from "sockjs-client";
import {getCleanBaseUri} from "@common/baseUri";
import {getAccessToken} from "@common/token";
import {
  latestDeletedLoanBudgetByAdminState,
  notificationSidebarIsOpenState,
  refreshQueueListState,
  refreshSage2FAState
} from "@components/NotificationSidebar/globalState";
import configurationState from "@state/globalState/configurationState";
import {refreshFundingDashboardStatus} from "@controls/monitors/DashboardAccessMonitor";
import {updateTodo} from "@hornet-api/task/getTodoList";
import isMaintenanceState from "@state/globalState/isMaintenanceState";
import activeRoleState from "@state/globalState/activeRoleState";
import userRoleGroupsState from "@state/globalState/userRoleGroupsState";
import isImpersonatingSelectorAtom from "@state/recoil/authentication/isImpersonatingSelectorAtom";
import hasAuthenticatedSelectorAtom from "@state/recoil/authentication/hasAuthenticatedSelectorAtom";
import {refreshBorrowerInvoiceProgressState} from "@admin-ui/pages/Statements/BorrowerInvoicesPage/global";

const WebSocket = () => {
  const hasUser = useRecoilValue(hasAuthenticatedSelectorAtom);
  const isImpersonating = useRecoilValue(isImpersonatingSelectorAtom);
  const configuration = configurationState.useValue();
  const profile = useRecoilValue(profileAtom) as Contact | null;
  const [client, setClient] = useState<Stomp.Client | null>(null);
  const [retryCounter, setRetryCounter] = useState<number>(0);
  const activeRole = activeRoleState.useValue();
  const userRoleGroups = userRoleGroupsState.useValue();

  const activeGroup = useMemo(() => {
    return userRoleGroups?.find((x) => x.id === activeRole) ?? null;
  }, [userRoleGroups, activeRole]);

  const refreshChatDetailsIfOpen = () => {
    if (notificationSidebarIsOpenState.get()) {
      refreshChatDetails(false);
    }
  }

  const refreshTaskDetailsIfOpen = () => {
    if (notificationSidebarIsOpenState.get()) {
      refreshTaskDetails(false);
    }
  }

  const webSocketClient = () => {
    const socket = SockJS(`${getCleanBaseUri()}/stomp`);
    socket.addEventListener("close", (event) => {
      if (retryCounter < 2) {
        setTimeout(() => {
          setRetryCounter(counter => counter + 1);
        }, 2000);
      } else {
        isMaintenanceState.set(true);
      }
      console.log("=================onclose start======================")
      console.log(event);
      console.log("=================onclose end ======================")
    });
    return Stomp.over(socket);
  };

  const onMessage = useCallback((rawMessage: Message) => {
    const message = JSON.parse(rawMessage.body) as WebSocketNotification;
    setTimeout(() => {
      switch (message.type) {
        case "TASK":
          triggerTaskRefresh();
          refreshTaskDetailsIfOpen();
          break;
        case "CHAT":
          refreshChatObject(false);
          refreshChatDetailsIfOpen();
          break;
        case "TRANSACTION":
          refreshTransactions();
          break;
        case "DEFFER_NOTIFICATION_QUEUE":
          refreshQueueListState.set(Date.now());
          break;
        case "SAGE_2FA":
          refreshSage2FAState.set(Date.now());
          break;
        case "BUDGET":
          refreshBudgetCount();
          break;
        case "DRAW_REQUEST":
          refreshDrawRequestCount();
          break;
        case "USER_BUDGET_NOTIFICATION":
          refreshUserBudgetNotificationCount();
          break;
        case "USER_BUDGET_DELETED_NOTIFICATION":
          refreshUserBudgetNotificationCount();
          latestDeletedLoanBudgetByAdminState.set(message.loanId!);
          break;
        case "2FA":
          refreshAdminContactInfo(message.contactId!);
          break;
        case "USER_2FA":
          refreshUser2FAInfo();
          break;
        case "FUNDING_DASHBOARD_ACCESS_STATUS":
          refreshFundingDashboardStatus();
          break;
        case "UPDATE_TODO":
          updateTodo();
          break;
        case "ANNOUNCEMENTS_INVESTOR":
          if (getInvestorRole()) {
            refreshAnnouncements();
          }
          break;
        case "ANNOUNCEMENTS_BORROWER":
          if (getBorrowerRole()) {
            refreshAnnouncements();
          }
          break;
        case "BORROWER_INVOICE_PROGRESS":
          refreshBorrowerInvoiceProgressState.set(Date.now());
          break;
      }
    }, 500);
  }, []);

  /**
   * This function is not called on its topic. onMessage is the only one that works.
   */
  const onAnnouncement = useCallback((rawMessage: Message) => {
    const message = JSON.parse(rawMessage.body) as WebSocketNotification;
    setTimeout(() => {
      switch (message.type) {
        case "ANNOUNCEMENTS_INVESTOR":
          if (getInvestorRole()) {
            refreshAnnouncements();
          }
          break;
        case"ANNOUNCEMENTS_BORROWER":
          if (getBorrowerRole()) {
            refreshAnnouncements();
          }
          break;
        default:
          console.error(JSON.stringify(message));
      }
    }, 500);
  }, []);

  useEffect(() => {
    if (!profile?.username || !hasUser) {
      if (client?.connected) {
        client.disconnect(() => {
          console.log('Disconnected web-socket after user logout');
        });
      }
      return;
    }

    if (client?.connected) {
      return;
    }

    const thisClient = webSocketClient();
    const headersConfig = {Authorization: `Bearer ${getAccessToken()}`}
    thisClient.connect(headersConfig, () => {

      thisClient.subscribe(`/topic/announcement`, onAnnouncement, headersConfig);
      thisClient.subscribe(`/topic/messages/${profile?.username}`, onMessage, headersConfig);
      setTimeout(() => {
        refreshTaskDetailsIfOpen();
        triggerTaskRefresh();
        profile?.hasUser && refreshChatObject(false);
        refreshChatDetailsIfOpen();
        refreshTransactions();
        setRetryCounter(0);
      }, 1000);
      setClient(thisClient);

    });
  }, [client?.connected, retryCounter, profile, hasUser]);

  return (<></>);
}

export default WebSocket;
