import React, {useState} from "react";
import {ITab, ITabStatus, SearchTabEnum} from "@admin-ui/pages/SearchPage/constant";


type Props = {
  tabs: ITab[];
  onChange: (tabs: ITab[]) => void;
};


const GlobalSearchTab = ({tabs, onChange}: Props) => {
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const [dropTarget, setDropTarget] = useState<keyof typeof SearchTabEnum | null>(null);

  const toggleTabAndSetActive = (key: keyof typeof SearchTabEnum) => {
    let updatedTabs = tabs.map(tab =>
      tab.tab === key ? {...tab, status: tab.status === ITabStatus.ACTIVE ? ITabStatus.INACTIVE : ITabStatus.ACTIVE} : tab
    );

    if (key === 'ALL') {
      const anyInactive = tabs.some(tab => tab.status === ITabStatus.INACTIVE);
      updatedTabs = updatedTabs.map(tab => ({...tab, status: anyInactive ? ITabStatus.ACTIVE : ITabStatus.INACTIVE}));
    } else {
      const anyInactive = tabs.some(tab => tab.tab === key && tab.status === ITabStatus.INACTIVE);
      const allActiveExceptAll = updatedTabs.every(tab => tab.tab === 'ALL' || tab.status === ITabStatus.ACTIVE);
      const allTabIndex = updatedTabs.findIndex(tab => tab.tab === 'ALL');
      const allTabStatus = allActiveExceptAll ? ITabStatus.ACTIVE : ITabStatus.INACTIVE;
      if (anyInactive || (allTabIndex !== -1 && updatedTabs[allTabIndex].status !== allTabStatus)) {
        updatedTabs = updatedTabs.map(tab =>
          tab.tab === 'ALL' ? {...tab, status: allTabStatus} : tab
        );
      }
    }
    onChange(updatedTabs);
  };

  const onDragStart = (event: React.DragEvent<HTMLDivElement>, id: keyof typeof SearchTabEnum): void => {
    // Prevent dragging if the tab id is "ALL"
    if (id === "ALL") {
      event.preventDefault();
      return;
    }
    event.dataTransfer.setData("id", id);
  };

  const onDragOver = (event: React.DragEvent<HTMLDivElement>, dropTabId: keyof typeof SearchTabEnum): void => {
    event.preventDefault();
    setIsDraggingOver(true);
    setDropTarget(dropTabId);
  };

  const onDragLeave = (): void => {
    setIsDraggingOver(false);
    setDropTarget(null);
  };

  const onDrop = (event: React.DragEvent<HTMLDivElement>, dropTabId: keyof typeof SearchTabEnum): void => {
    setIsDraggingOver(false);
    setDropTarget(null);
    const dragTabId = event.dataTransfer.getData("id") as keyof typeof SearchTabEnum;

    // Prevent dropping if the dropTabId is "ALL"
    if (dropTabId === "ALL") {
      return;
    }

    // Calculate the new order of tabs
    const newTabs = [...tabs];
    const dragTabIndex = newTabs.findIndex(tab => tab.tab === dragTabId);
    const dropTabIndex = newTabs.findIndex(tab => tab.tab === dropTabId);

    if (dragTabIndex !== -1 && dropTabIndex !== -1) {
      const draggedTab = newTabs[dragTabIndex];
      newTabs.splice(dragTabIndex, 1);
      newTabs.splice(dropTabIndex, 0, draggedTab);
      onChange(newTabs);
    }
  };

  return (
    <div style={{marginRight:'5px'}}>
      {tabs.map((tab) => (
        <div
          className={`btn btn-primary draggableTab mt-1 d-block ${tab.status === ITabStatus.ACTIVE ? '' : 'disabled'}`}
          key={tab.tab}
          draggable={tab.tab !== "ALL"} // Make draggable only if id is not "ALL"
          onDragStart={(event) => onDragStart(event, tab.tab)}
          onDragOver={(event) => onDragOver(event, tab.tab)}
          onDragLeave={onDragLeave}
          onDrop={(event) => onDrop(event, tab.tab)}
          onClick={() => toggleTabAndSetActive(tab.tab)}
          style={{
            backgroundColor: isDraggingOver && dropTarget === tab.tab ? 'lightblue' : ''
          }} // Change background color if dragging over
          tabIndex={0}
        >
          {SearchTabEnum[tab.tab]}
        </div>
      ))}
    </div>
  );
};

export default GlobalSearchTab;
