import React, { useMemo } from 'react';
import './styles.scss';

const PropertyAddress = ({
	street1 = '',
	street2 = '',
	city = '',
	state = '',
	zip = '',
	style = {}
}) => {

	const addressLine2 = useMemo(() => (street2 ? (<div>{street2}</div>) : null), [street2]);

	return (
		<div className="r-adminuploader-propertyaddress" style={style}>
			<div>{street1}</div>
			{addressLine2}
			<div>
				<span>{city},&nbsp;</span>
				<span>{state}&nbsp;</span>
				<span>{zip}</span>
			</div>
		</div>
	);
};

export default PropertyAddress;
