export const states: { [p: string]: string } = {
	'AL': 'ALABAMA',
	'AK': 'ALASKA',
	'AZ': 'ARIZONA',
	'AR': 'ARKANSAS',
	'CA': 'CALIFORNIA',
	'CO': 'COLORADO',
	'CT': 'CONNECTICUT',
	'DC': 'DISTRICT OF COLUMBIA',
	'DE': 'DELAWARE',
	'FL': 'FLORIDA',
	'GA': 'GEORGIA',
	'HI': 'HAWAII',
	'ID': 'IDAHO',
	'IL': 'ILLINOIS',
	'IN': 'INDIANA',
	'IA': 'IOWA',
	'KS': 'KANSAS',
	'KY': 'KENTUCKY',
	'LA': 'LOUISIANA',
	'ME': 'MAINE',
	'MD': 'MARYLAND',
	'MA': 'MASSACHUSETTS',
	'MI': 'MICHIGAN',
	'MN': 'MINNESOTA',
	'MS': 'MISSISSIPPI',
	'MO': 'MISSOURI',
	'MT': 'MONTANA',
	'NE': 'NEBRASKA',
	'NV': 'NEVADA',
	'NH': 'NEW HAMPSHIRE',
	'NJ': 'NEW JERSEY',
	'NM': 'NEW MEXICO',
	'NY': 'NEW YORK',
	'NC': 'NORTH CAROLINA',
	'ND': 'NORTH DAKOTA',
	'OH': 'OHIO',
	'OK': 'OKLAHOMA',
	'OR': 'OREGON',
	'PA': 'PENNSYLVANIA',
	'RI': 'RHODE ISLAND',
	'SC': 'SOUTH CAROLINA',
	'SD': 'SOUTH DAKOTA',
	'TN': 'TENNESSEE',
	'TX': 'TEXAS',
	'UT': 'UTAH',
	'VT': 'VERMONT',
	'VA': 'VIRGINIA',
	'WA': 'WASHINGTON',
	'WV': 'WEST VIRGINIA',
	'WI': 'WISCONSIN',
	'WY': 'WYOMING'
};

export const statesTitleCase = {
	"AL": "Alabama",
	"AK": "Alaska",
	"AZ": "Arizona",
	"AR": "Arkansas",
	"CA": "California",
	"CO": "Colorado",
	"CT": "Connecticut",
	"DC": "District of Columbia",
	"DE": "Delaware",
	"FL": "Florida",
	"GA": "Georgia",
	"HI": "Hawaii",
	"ID": "Idaho",
	"IL": "Illinois",
	"IN": "Indiana",
	"IA": "Iowa",
	"KS": "Kansas",
	"KY": "Kentucky",
	"LA": "Louisiana",
	"ME": "Maine",
	"MD": "Maryland",
	"MA": "Massachusetts",
	"MI": "Michigan",
	"MN": "Minnesota",
	"MS": "Mississippi",
	"MO": "Missouri",
	"MT": "Montana",
	"NE": "Nebraska",
	"NV": "Nevada",
	"NH": "New Hampshire",
	"NJ": "New Jersey",
	"NM": "New Mexico",
	"NY": "New York",
	"NC": "North Carolina",
	"ND": "North Dakota",
	"OH": "Ohio",
	"OK": "Oklahoma",
	"OR": "Oregon",
	"PA": "Pennsylvania",
	"RI": "Rhode Island",
	"SC": "South Carolina",
	"SD": "South Dakota",
	"TN": "Tennessee",
	"TX": "Texas",
	"UT": "Utah",
	"VT": "Vermont",
	"VA": "Virginia",
	"WA": "Washington",
	"WV": "West Virginia",
	"WI": "Wisconsin",
	"WY": "Wyoming"
}

export const statesAbbr = {
	'AL': 'AL',
	'AK': 'AK',
	'AZ': 'AZ',
	'AR': 'AR',
	'CA': 'CA',
	'CO': 'CO',
	'CT': 'CT',
	'DC': 'DC',
	'DE': 'DE',
	'FL': 'FL',
	'GA': 'GA',
	'HI': 'HI',
	'ID': 'ID',
	'IL': 'IL',
	'IN': 'IN',
	'IA': 'IA',
	'KS': 'KS',
	'KY': 'KY',
	'LA': 'LA',
	'ME': 'ME',
	'MD': 'MD',
	'MA': 'MA',
	'MI': 'MI',
	'MN': 'MN',
	'MS': 'MS',
	'MO': 'MO',
	'MT': 'MT',
	'NE': 'NE',
	'NV': 'NV',
	'NH': 'NH',
	'NJ': 'NJ',
	'NM': 'NM',
	'NY': 'NY',
	'NC': 'NC',
	'ND': 'ND',
	'OH': 'OH',
	'OK': 'OK',
	'OR': 'OR',
	'PA': 'PA',
	'RI': 'RI',
	'SC': 'SC',
	'SD': 'SD',
	'TN': 'TN',
	'TX': 'TX',
	'UT': 'UT',
	'VT': 'VT',
	'VA': 'VA',
	'WA': 'WA',
	'WV': 'WV',
	'WI': 'WI',
	'WY': 'WY'
};
