import axios from 'axios';
import {getTaskUrl} from './util';
import {Task} from '@interfaces/task';
import {getAccessToken} from '@common/token';

export type AttachFilesProp = {
  contactId?: number,
  companyId?: number,
  contactCompanyId?: number,
  files: File[] | null,
  taskId: number,
  isInternal?: boolean
}
const attachFile = async (
  {
    contactId,
    companyId,
    contactCompanyId,
    files,
    taskId,
    isInternal = false
  }: AttachFilesProp
) => {
  let url = getTaskUrl();
  if (companyId) {
    url += `/entity/${companyId}/task/${taskId}/attachment`;
  } else if (contactId) {
    url += `/contact/${contactId}/task/${taskId}/attachment`;
  } else if (contactCompanyId) {
    // user side
    url += `/contact/entity/${contactCompanyId}/task/${taskId}/attachment`;
  } else {
    // user side
    url += `/contact/task/${taskId}/attachment`;
  }

  const formData = new FormData();
  if (files && files.length > 0) {
    files.forEach((attachment) => {
      formData.append("taskAttachments", attachment);
    });
  }
  formData.append("isInternal", isInternal ? 'true' : 'false');

  const response = await axios.post(
    url,
    formData
  );
  return response.data as Task;
};

export type GetLinkProp = {
  contactId?: number,
  companyId?: number,
  contactCompanyId?: number,
  taskId: number,
  attachmentId: number,
  view?: boolean,
  download?: boolean,
};

export const getLinkUrl = (
  {
    contactId,
    companyId,
    contactCompanyId,
    taskId,
    attachmentId,
    download,
  }: GetLinkProp
) => {
  // default to view url
  let url = getTaskUrl();
  const tail = download ? 'download' : 'view';
  const urlEnd = `/task/${taskId}/attachment/${attachmentId}/${tail}`;
  if (companyId) {
    url += `/entity/${companyId}${urlEnd}`;
  } else if (contactId) {
    url += `/contact/${contactId}${urlEnd}`;
  } else if (contactCompanyId) {
    // user side
    url += `/contact/entity/${contactCompanyId}${urlEnd}?access_token=${getAccessToken()}`;
  } else {
    //User side
    url += `/contact${urlEnd}?access_token=${getAccessToken()}`
  }
  return url;
}


export default attachFile;
