import axios from 'axios';
import getAdminApiPath from "@hornet-api/getAdminApiPath";
import {IBudgetDetail} from "@interfaces/loan/admin/budget";
import {sortBudgetChildren} from "@hornet-api/loans/admin/budget/utils";

export const getBudgetById = async (id: number) => {
  const url = `${getAdminApiPath()}/budget/${id}`;
  const response = await axios.get(url);
  return sortBudgetChildren(response.data as IBudgetDetail);
};
