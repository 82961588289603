import axios from 'axios';
import {getTaskUrl} from './util';
import {LogMessageTypeEnum,} from '@interfaces/GeneratedEnums';
import isAdminState from "@state/globalState/isAdminState";

export type SendMessageOptions = {
  id?: number; // sent when a chat message is being edited
  taskId?: number,
  content?: string,
  type?: 'LOG' | 'CHAT',
  logType?: keyof typeof LogMessageTypeEnum,
  contactId?: number,
  companyId?: number,
  contactCompanyId?: number,
  isAnnouncement?: null | string,
  loanId?: string,
}
const sendMessage = async (
  {
    id,
    taskId,
    content,
    type = 'CHAT',
    logType = 'FILE_UPLOAD',
    contactId,
    companyId,
    contactCompanyId,
    isAnnouncement = null,
    loanId
  }: SendMessageOptions
) => {
  let url = getTaskUrl();
  if (isAnnouncement) {
    url += `/chat/announcement/message`;
  } else if (taskId) {
    if (companyId) {
      url += `/entity/${companyId}/task/${taskId}/chat/message`;
    } else if (contactId) {
      url += `/contact/${contactId}/task/${taskId}/chat/message`;
    } else if (contactCompanyId) {
      url += `/contact/entity/${contactCompanyId}/task/${taskId}/chat/message`;
    } else {
      url += `/contact/task/${taskId}/chat/message`;
    }
  } else {
    const isAdmin = isAdminState.get();
    // general chat
    if (companyId) {
      url += `/entity/${companyId}/chat/message`;
    } else if (contactId) {
      url += `/contact/${contactId}/chat/message`;
    } else if (contactCompanyId) {
      url += `/contact/entity/${contactCompanyId}/chat/message`;
    } else if (loanId) {
      url += `/loan${isAdmin ? 'Admin' : ''}/${encodeURIComponent(loanId)}/chat/message`;
    } else {
      url += `/contact/chat/message`;
    }
  }

  let data: any = {
    type: type,
  };
  if (id) {
    data.id = id
  }
  if (content) {
    data.content = content;
  }
  if(isAnnouncement){
    data.announcement = isAnnouncement
  }

  if (type === 'LOG') {
    data.logType = logType;
  }

  const response = await (id ? axios.put : axios.post)(url, data);
  try {
    return response.data
  } catch(e) {
    console.error('sendMessage', e);
    console.error('sendMessage: data', response);
    return [];
  }
};

export default sendMessage;