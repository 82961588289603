import React, { useState, useEffect, useMemo } from 'react';
import style from '@admin-ui/pages/InvestorPage/InvestorPage.module.scss';
import Toggle from '@components/Toggle';
import { getIsFeatureEnabled, updateFeatureEnabled } from '@hornet-api/feature/enableFeatures';
import AmbassadorsTable from '@admin-ui/pages/AmbassadorPage/AmbassadorsTable';
import ReferralsTable from '@admin-ui/pages/AmbassadorPage/ReferralsTable';
import Widget from '@admin-ui/components/ambassador/Widget';
import numeral from 'numeral';
import getReferralList from '@hornet-api/ambassador/getReferralList';
import ReferralAdmin from '@interfaces/ReferralAdmin';
import AmbassadorAdmin from '@interfaces/AmbassadorAdmin';
import getAmbassadorListAdmin from '@hornet-api/ambassador/getAmbassadorListAdmin';
import PaymentsTable from '@admin-ui/pages/AmbassadorPage/PaymentsTable';

const AmbassadorPage = () => {
  const [ambassadorEnabled, setAmbassadorEnabled] = useState(false);
  const [toggleLoading, setToggleLoading] = useState(false);

  // referral
  const [referrals, setReferrals] = useState([] as ReferralAdmin[]);
  const [isLoadingReferrals, setIsLoadingReferrals] = useState(false);

  // ambassador
  const [ambassadors, setAmbassadors] = useState([] as AmbassadorAdmin[]);
  const [isLoadingAmbassadors, setIsLoadingAmbassadors] = useState(false);

  const updateReferrals = async () => {
    setIsLoadingReferrals(true);
    const r = await getReferralList();
    setReferrals(r);
    setIsLoadingReferrals(false);
  };

  const updateAmbassadors = async () => {
    setIsLoadingAmbassadors(true);
    const r = await getAmbassadorListAdmin();
    setAmbassadors(r);
    setIsLoadingAmbassadors(false);
  };

  const updateBoth = () => {
    return new Promise<void>((resolve) => {
      Promise.all([updateReferrals(), updateAmbassadors()]).then(() => {
        resolve();
      });
    });
  }

  useEffect(() => {
    updateReferrals();
    updateAmbassadors();
    setToggleLoading(true);
    getIsFeatureEnabled('ambassador').then((enabled) => {
      setAmbassadorEnabled(enabled);
      setToggleLoading(false);
    });
  }, []);

  const widgetData = useMemo(() => {
    const d = {
      totalReferrals: 0,
      totalEarned: 0,
      paidToDate: 0,
      totalUnpaid: 0,
      totalDue: 0,
    };
    ambassadors.forEach((a) => {
      d.totalReferrals += a.referralCount;
      d.totalEarned += a.amountOwed + a.amountPaid;
      d.paidToDate += a.amountPaid;
      d.totalUnpaid += a.amountOwed;
      d.totalDue += a.amountDue;
    });
    return d;
  }, [ambassadors]);

  const referralsToPay = useMemo(() => {
    let rtp: ReferralAdmin[] = [];
    ambassadors?.forEach((a) => {
      // @ts-ignore
      if(a?.ambassadorReferralsToPay?.length > 0) {
        // @ts-ignore
        rtp = [...rtp, ...a.ambassadorReferralsToPay]
      }
    });
    return rtp;
  }, [ambassadors])

  return (
    <div className={style.container}>
      <section className="content-header">
        <div className="row">
          <div className="col">
            <div className="header-title">
              <div className={'pull-right'}>
                <Toggle
                  isOn={ambassadorEnabled}
                  title={'Is Enabled'}
                  isLoading={toggleLoading}
                  onChange={async (newVal) => {
                    setToggleLoading(true);
                    setAmbassadorEnabled(newVal);
                    const newState = await updateFeatureEnabled('ambassador', newVal);
                    setToggleLoading(false);
                    setAmbassadorEnabled(newState);
                  }}
                  style={{ textAlign: 'right' }}
                />
              </div>
              <h1>Ambassador</h1>
              <small>Manage the Ambassador Program</small>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="row">
          <div className="col-lg-12 pinpin">
            <div className="row">
              <Widget title="Total Referrals" value={widgetData.totalReferrals} />
              <Widget
                title="Total Earned"
                value={numeral(widgetData.totalEarned).format('$0,0.00')}
              />
              <Widget
                title="Paid to Date"
                value={numeral(widgetData.paidToDate).format('$0,0.00')}
              />
              <Widget title="Total Unpaid" value={numeral(widgetData.totalDue).format('$0,0.00')} />
              <Widget title="Total Due" value={numeral(widgetData.totalDue).format('$0,0.00')} />
            </div>
            <ReferralsTable
              referrals={referrals}
              tableName={'Referrals'}
              loading={isLoadingReferrals}
              updateReferrals={updateBoth}
            />
            <AmbassadorsTable
              ambassadors={ambassadors}
              loading={isLoadingAmbassadors}
              updateAmbassadors={updateAmbassadors}
            />
            <PaymentsTable
              referrals={referrals}
              triggerRefresh={updateBoth}
              isLoading={isLoadingReferrals}
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default AmbassadorPage;
