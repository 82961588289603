import axios from 'axios';
import getMetaApiPath from "@hornet-api/getMetaApiPath";
import {FirebaseConfigType} from "@common/serviceWorker";

const getFirebaseConfig = async () => {
  const url = `${getMetaApiPath()}/fcm/client-config`;
  const response = await axios.get<FirebaseConfigType>(url);
  return response.data
};

export default getFirebaseConfig;
