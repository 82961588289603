import axios from 'axios';
import getAdminApiPath from "@hornet-api/getAdminApiPath";
import {Attachment} from "@interfaces/task";
import {sortAttachments} from "@hornet-api/loans/admin/drawRequest/document/util";

export const getDrawRequestDocuments = async (drawRequestId: number) => {
  const url = `${getAdminApiPath()}/drawRequest/${drawRequestId}/documents`;

  const response = await axios.get<Attachment[]>(url)
  return sortAttachments(response.data)
};
