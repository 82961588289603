import axios from 'axios';
import {FirebaseSubscription} from '@common/serviceWorker';
import getMetaApiPath from "@hornet-api/getMetaApiPath";
type ServerResponse={
  isActive: boolean
}
const checkFirebaseSubscription = async (subscription: FirebaseSubscription) => {
  const url = `${getMetaApiPath()}/fcm/check`;
  const response = await axios.post<ServerResponse>(url, subscription);
  return response.data.isActive;
};

export default checkFirebaseSubscription;
