/* eslint-disable no-console */
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import {getQueryEmail, getQueryRoles} from './utils';
import registerUser, {RegistrationData} from '@hornet-api/auth/registerUser';
import {alertApiErrors, getAxiosErrors} from '@common/errors';
import hornetCapitalLogo from '@assets/images/hornet_logo_regular.svg';
import getRegistrationRoleGroups, {RoleGroup} from "@hornet-api/public/getRegistrationRoleGroups";
import {SubmitErrorHandler, SubmitHandler, useForm} from "react-hook-form";
import {onInvalidHandler} from "@components/forms/react-hook-form-bootstrap/utils";
import {Button, Form} from "react-bootstrap";
import {Select, TextBox} from "@components/forms/react-hook-form-bootstrap";
import {isEmailValid, passwordValidator} from "@common/utils/validators";
import blueFooterImage from "@images/blue_footer_image.svg";
import {getQueryParams} from "@common/basic";

const RegisterView = () => {
  const [roleGroups, setRoleGroups] = useState([] as RoleGroup[]);
  const [emailSent, setEmailSent] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState([] as string[]);
  const firstFieldRef = useRef<HTMLInputElement>(null);
  const {firstName, lastName, roles, invite} = getQueryParams()

  // focus on first field
  useEffect(() => {
    setTimeout(() => {
      if (firstFieldRef.current) {
        firstFieldRef.current.focus();
      }
    }, 500)
  }, []);

  // get role groups that can register
  useEffect(() => {
    const filteredRoles = roles ? getQueryRoles(roles) : ''
    if (!filteredRoles) {
      getRegistrationRoleGroups()
        .then(setRoleGroups)
        .catch(alertApiErrors);
    }
    reset({
      firstName: firstName || '',
      lastName: lastName || '',
      username: '',
      email: invite ? getQueryEmail(invite) : '',
      password: '',
      password2: '',
      roles: filteredRoles
    })
  }, [firstName, lastName, invite, roles]);

  // create select options from role groups with an empty option -1 "Select a role"
  const roleOptions = useMemo(() => {
    return roleGroups.reduce((result: { [p: number]: string }, rg) => {
      result[rg.id] = rg.name;
      return result;
    }, {});
  }, [roleGroups]);

  const {
    handleSubmit,
    control,
    reset,
    watch
  } = useForm<RegistrationData>();

  const password = watch('password');

  const onInvalid: SubmitErrorHandler<RegistrationData> = (errs) => {
    onInvalidHandler(errs)
  }
  const onSubmit: SubmitHandler<RegistrationData> = async (data) => {
    if (isSubmitting) return;
    setErrors([]);
    if (data.username === data.password) {
      setErrors(["Password cannot be the same as the username."]);
      return;
    }
    setIsSubmitting(true)
    try {

      await registerUser(data);
      setEmailSent(true);
    } catch (e) {
      const errors = getAxiosErrors(e);
      setErrors(errors);
      console.log(e);
    } finally {
      setIsSubmitting(false);
    }
  }
  return (
    <div className="register-container">
      <div className="container">
        <img src={hornetCapitalLogo} alt="Hornet Capital" className="logo"/>
        {
          errors.length > 0 ?
            <div className="alert alert-warning" role="alert">
              {errors.map((error, index) => {
                return (
                  <li key={index}>
                    {error}
                  </li>
                )
              })}
            </div>
            :
            null
        }
        {
          emailSent ?
            <h4>Your account registration email was sent, close this browser tab and check your mail, which will
              open a new one!</h4>
            : <Form className="login-form" noValidate onSubmit={handleSubmit(onSubmit, onInvalid)} id="registerForm">
              <h4 className={'text-center'}>Create your account</h4>
              <TextBox
                name={'firstName'}
                control={control}
                label={'First Name'}
                rules={{required: true}}
              />

              <TextBox
                name={'lastName'}
                control={control}
                label={'Last Name'}
                rules={{required: true}}
              />

              <TextBox
                name={'username'}
                control={control}
                label={'Username'}
                rules={{required: true}}
              />

              <TextBox
                name={'email'}
                control={control}
                label={'E-mail'}
                disabled={!!invite}
                rules={{
                  required: true,
                  validate: {
                    validEmail: (email?: string) => email && isEmailValid(email) ? true : 'Please provide a valid email address.'
                  }
                }}
              />
              <div className={'row'}>
                <div className="col-lg-6">
                  <TextBox
                    name={'password'}
                    control={control}
                    label={'Password'}
                    type={'password'}
                    rules={{
                      required: true,
                      validate: (p?: string) => p && passwordValidator(p)
                    }}
                  />
                </div>

                <div className="col-lg-6">
                  <TextBox
                    name={'password2'}
                    control={control}
                    label={'Confirm Password'}
                    type={'password'}
                    rules={{
                      required: true,
                      validate: {
                        passwordValidation: (p2?: string) => p2 && p2 === password ? true : 'Passwords do not match.'
                      }
                    }}

                  />
                </div>
              </div>
              {
                roles ? null :
                  <div className={'row'}>
                    <div className="col-lg-12">
                      <Select
                        options={roleOptions}
                        name={'roles'}
                        control={control}
                        label={'What best describes YOU?'}
                        rules={{required: true}}
                      />
                    </div>
                  </div>
              }
              <div className="text-center">
                <Button
                  type="submit"
                  name="Login"
                  variant={'warning'}
                  className="btn btn-block"
                >Create Account
                </Button>
              </div>
            </Form>}
        <div className={'mt-4'}>Already have an account? <Link to={'/login'}>Sign in</Link></div>
      </div>
      <div className="footer" style={{backgroundImage: `url( ${blueFooterImage})`}}></div>
    </div>
  );
};

export default RegisterView;
