import axios from 'axios';
import {SystemConfigServer} from '@interfaces/SystemConfig';
import getUserApiPath from '@hornet-api/getUserApiPath';

const getSystemConfigUser = async (keyName: string) => {
  const url = `${getUserApiPath()}/config/${keyName}`;
  const response = await axios.get(url);
  return response.data as SystemConfigServer;
};

export default getSystemConfigUser;
