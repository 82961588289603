import React, { useMemo } from 'react';
import { FeatureKey } from '@interfaces/Feature';
import featuresState from '@state/globalState/featuresState';

const isFeatureEnabledSelector = (featureName: FeatureKey) => {
  const features = featuresState.useValue();
  return useMemo(() => {
    const feat = features.find((x) => x.feature === featureName);
    if (!feat) return false;
    return feat.isEnabled;
  }, [features]);
};

export default isFeatureEnabledSelector;
