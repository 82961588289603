import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import SettingsSaver from './lib/SettingsSaver';
import ResetFiltersTooltip from './ResetFiltersTooltip';
import FontAwesomeIcon from './lib/FontAwesomeIcon';

type IResetFiltersButtonProps<D> = {
	settingsSaver: SettingsSaver<D>,
};

const styles = {
	resetButton: {
		marginRight: 5
	},
};

function ResetFiltersButton<D>(
	{
		settingsSaver,
	}: IResetFiltersButtonProps<D>
) {

	const handleOnResetClick = () => {
		settingsSaver.resetToDefault();
	};

	return (
		<OverlayTrigger placement="bottom-end" overlay={<ResetFiltersTooltip/>}>
			<button
				className="btn btn-sm btn-secondary"
				style={styles.resetButton}
				onClick={handleOnResetClick}
			>
				<FontAwesomeIcon icon="filter"/>
			</button>
		</OverlayTrigger>
	);
};

export default ResetFiltersButton;
