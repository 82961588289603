import React from 'react';
import ApplicationLoadingView from '@views/ApplicationLoadingView';
import Maintenance from '@views/Maintenance';
import IframeModal from '@components/IframeModal';
import AchConfirmModal from '@components/AchConfirmModal';
import DocumentRequestModal from '@components/DocumentRequestModal';
import {BrowserRouter as Router} from 'react-router-dom';
import RoutesAll from '@routes/RoutesAll';
import './ApplicationLayout.scss';
import isMaintenanceState from '@state/globalState/isMaintenanceState';
import WebSocket from "@components/WebSocket";
import AddEditBankAccountModal, {
  addEditBankAccountModalState
} from "@components/modals/AddEditBankModal/AddEditBankAccountModal";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import ShowDocumentModal from "@components/ShowDocumentModal";
import {FcmMessagingComponent} from "@components/FcmMessagingComponent";

const ApplicationLayout = () => {
  const isMaintenance = isMaintenanceState.useValue();
  const addEditBankAccount = addEditBankAccountModalState.useValue();

  if (isMaintenance) {
    return (
      <Maintenance/>
    );
  }

  return (
    <React.Fragment>
      <Router>
        <RoutesAll/>
      </Router>
      <ApplicationLoadingView/>
      <ToastContainer/>
      <IframeModal/>
      <AchConfirmModal/>
      {addEditBankAccount && <AddEditBankAccountModal/>}
      <DocumentRequestModal/>
      <ShowDocumentModal/>
      <WebSocket/>
    </React.Fragment>
  );
};

export default React.memo(ApplicationLayout);
