import React, {useEffect} from 'react';
import classNames from 'classnames';
import NotificationTopBar from './NotificationTopBar';
import NotificationMessages from './NotificationMessages';
import NotificationTasks from './NotificationTasks';
import NotificationTransfers from './NotificationTransfers';
import AddFileModal from './components/AddFileModal';
import {isAddingTaskFromSidebarState, notificationSidebarIsOpenState, selectedTopMenuItemState,} from './globalState';
import isAdminState from "@state/globalState/isAdminState";
import {setBodyNonScrollable, setBodyScrollable} from '@common/window';
import style from './NotificationSidebar.module.scss';
import LoadingOverlay from './components/LoadingOverlay';
import DataLoader from './DataLoader';
import AddTaskToLoanModal from "@admin-ui/pages/LoanShowPage/components/AddTaskToLoanModal";
import {triggerTaskRefresh} from "@components/NotificationSidebar/utils";

const NotificationSidebar = () => {
  const [isOpen, setIsOpen] = notificationSidebarIsOpenState.use();
  const isAdmin = isAdminState.useValue();
  const selectedTopMenuItem = selectedTopMenuItemState.useValue();
  const isAddingTask = isAddingTaskFromSidebarState.useValue()

  useEffect(() => {
    // set the body to non-scrollable when opening and restore when closing
    if (isOpen) {
      setBodyNonScrollable();
    } else {
      setBodyScrollable();
    }
  }, [isOpen]);

  return (<>
    <div
      className={isOpen ? classNames([style.backdrop, style.open]) : style.backdrop}
      onClick={() =>
        setIsOpen(false)
      }
    />
    <div className={isOpen ? classNames([style.sidebar, style.open]) : style.sidebar}>
      <NotificationTopBar/>
      <div id="scrollableNotificationSidebar" className={isAdmin ? classNames(style.main, style.isAdmin) : style.main}>
        {selectedTopMenuItem === 'messages' ? <NotificationMessages/> : null}
        <NotificationTasks/>
        {selectedTopMenuItem === 'transfers' ? <NotificationTransfers/> : null}

      </div>
      <LoadingOverlay/>
    </div>
    <AddFileModal/>
    {isAddingTask && <AddTaskToLoanModal loan={undefined} onComplete={() => {
      triggerTaskRefresh()
    }}/>}
    <DataLoader/>
  </>);
}

export default NotificationSidebar;