import React, {useEffect} from 'react';
import {useRecoilValue} from 'recoil';
import {Redirect, Route, Switch, useHistory, useLocation, useRouteMatch} from 'react-router-dom';

import ApplicationLoading from '@components/ApplicationLoading';
import makeRoutePath from './makeRoutePath';

import isForwardingState from '@state/globalState/isForwardingState';

import isUserRoleSelectorAtom from '@state/recoil/authentication/roles/isUserRoleSelectorAtom';
import appReadyStateSelectorAtom from '@state/recoil/appReadyStateSelectorAtom';
import configurationAtom from '@state/recoil/configurationAtom';
import hasAuthenticatedSelectorAtom from '@state/recoil/authentication/hasAuthenticatedSelectorAtom';
import authenticationBlobAtom from '@state/recoil/authentication/core/authenticationBlobAtom';
import profileAtom from '@state/recoil/profileAtom';
import Contact from '@interfaces/Contact';

// Non auth
import RegisterView from '@legacy-views/auth/RegisterView';
import ForgotPassword from '@legacy-views/auth/ForgotPassword';
import ForgotUsername from '@legacy-views/auth/ForgotUsername';
import LoginDisplayViewLegacy from '@legacy-views/auth/LoginDisplayView';

import redirectToState from '@state/globalState/redirectToState';

import RoutesUser from './RoutesUser';
import activeRoleState from "@state/globalState/activeRoleState";
import LogoutView from "@legacy/views/auth/LogoutView";
import uiSelector from "@state/globalState/uiSelector";
import ResetPassword from "@legacy/views/auth/ResetPassword/ResetPassword";
import TwoFactorAuthView from "@legacy/views/auth/TwoFactorAuthView";
import featuresState from "@state/globalState/featuresState";
import NoAuthRedirect from "@routes/NoAuthRedirect";
import VoiceCallModal from "@admin-ui/pages/VoiceCallPage/VoiceCallModal";
import OneTimeLoanPaymentPage from "@pages/OneTimeLoanPaymentPage";
import PublicUserLayout from "@views/Layout/PublicUserLayout";
import UserPendingTask from "@pages/public/UserPendingTask";
import RoutesAdmin from "@routes/RoutesAdmin";
import UserPayoffRequestForm from "@legacy/views/UserLoanView/UserPayoffRequest/UserPayoffRequestForm";

const RoutesAll = () => {
  const { url } = useRouteMatch();
  const location = useLocation();
  const profile = useRecoilValue(profileAtom) as Contact | null;
  const isReady = useRecoilValue(appReadyStateSelectorAtom) as boolean;
  const hasUser = useRecoilValue(hasAuthenticatedSelectorAtom);
  const configuration = useRecoilValue(configurationAtom);
  const isUserRole = useRecoilValue(isUserRoleSelectorAtom);
  const authenticationBlob = useRecoilValue(authenticationBlobAtom);
  const isForwarding = isForwardingState.useValue();
  const activeRole = activeRoleState.useValue();
  const history = useHistory();
  const ui = uiSelector();
  const features = featuresState.useValue();

  const redirectTo = redirectToState.useValue();

  // const isLoggedIn = profile && hasUser ? true : false;
  const isLoggedIn = !!hasUser;

  // environment monitor
  useEffect(() => {
    const environment = {
      profile: profile,
      features: features,
      hasUser: hasUser,
      isReady: isReady,
      activeRole: activeRole,
      configuration: configuration,
      isUserRole: isUserRole,
      authenticationBlob: authenticationBlob
    };
    // add to window so we can access it if needed
    (window as any).environment = environment;
    if (configuration?.environment === 'development') {
      // good for testing state
      console.log('environment', environment);
    }
  }, [profile, hasUser, isReady, configuration, isUserRole, authenticationBlob, activeRole])

  useEffect(() => {
    if (redirectTo) {
      console.log(`Redirecting to ${redirectTo}`);
      history.push(redirectTo);
      redirectToState.set(null);
    }
  }, [redirectTo]);

  if (!isReady || isForwarding || (isReady && hasUser && !profile && isUserRole)) {
    return (
      <Switch location={location}>
        <Route path={makeRoutePath(url, '/logout')} exact><LogoutView/></Route>
        <Route path="*"><ApplicationLoading/></Route>
      </Switch>
    )
  }

  if (!isLoggedIn) {
    return (
      <Switch location={location}>
        <Route path={makeRoutePath(url, '/register')} exact><RegisterView /></Route>
        <Route path={makeRoutePath(url, '/login')} exact><LoginDisplayViewLegacy /></Route>
        <Route path={makeRoutePath(url, '/logout')} exact><LogoutView /></Route>
        <Route path={makeRoutePath(url, '/forgotPassword')} exact><ForgotPassword /></Route>
        <Route path={makeRoutePath(url, '/forgotUsername')} exact><ForgotUsername/></Route>
        {/* One Time Use Loan Payment handler */}
        <Route path={makeRoutePath(url, '/payer/loanPayment/show/:token')} exact>
          <PublicUserLayout>
            <OneTimeLoanPaymentPage/>
          </PublicUserLayout>
        </Route>
        <Route path={makeRoutePath(url, 'register/resetPassword')} exact><ResetPassword /></Route>
        {/* old routes redirects */}
        <Route path={makeRoutePath(url, '/register/register')} exact><Redirect to={'/register'} /></Route>
        <Route path={makeRoutePath(url, '/login/auth')} exact><Redirect to={'/login'} /></Route>
        <Route path={makeRoutePath(url, '/register/forgot')} exact><Redirect to={'/forgot'} /></Route>
        <Route path={makeRoutePath(url, '/pending-task/:userUuid')} exact><UserPendingTask/></Route>
        <Route path={makeRoutePath(url, '/payoff-request/:loanId')} exact>
          <PublicUserLayout>
            <UserPayoffRequestForm/>
          </PublicUserLayout>
        </Route>
        {/* send to login as a catch all */}
        <Route path="*"><NoAuthRedirect /></Route>
      </Switch>
    );
  }

  // Any user with 2FA enabled and 2FA-Jailed
  if (isLoggedIn && ui === 'AUTH') {
    return (
      <Switch location={location}>
        <Route path={makeRoutePath(url, '/logout')} exact><LogoutView/></Route>
        <Route path={makeRoutePath(url, '/login/verify')} exact><TwoFactorAuthView /></Route>
      </Switch>
    )
  }

  // user
  if (isLoggedIn && ui === 'USER') {
    return <RoutesUser/>
  }

  // admins dont have profiles
  if (isLoggedIn && ui === 'ADMIN') {
    return (
      <Switch location={location}>
        <Route path={makeRoutePath(url, '/voice-call/:contactId')} exact><VoiceCallModal/></Route>
        <Route path={makeRoutePath(url, '/pending-task/:userUuid')} exact><UserPendingTask/></Route>
        <Route path="*"><RoutesAdmin/></Route>

      </Switch>
    )
  }

  return <ApplicationLoading />
};

export default RoutesAll;
