import React, {CSSProperties, useMemo} from "react";
import {Link} from "react-router-dom";
import FontAwesomeIcon from "@components/Icons/FontAwesomeIcon/index";
import {NavigationObjectItem} from "@components/Navigation/getNavigationObject";
import Dropdown from "react-bootstrap/Dropdown";
import Nav from "react-bootstrap/Nav";

interface NavItemProps {
  nav: NavigationObjectItem;
  Component: typeof Dropdown.Item | typeof Nav.Link;
  styles?: {
    Component?: CSSProperties,
    CounterBadge?: CSSProperties
  };
  linkClick?: (e: React.MouseEvent) => void;
  classes?: {
    Component?: string,
    CounterBadge?: string,
  };
}

const NavItem = (props: NavItemProps) => {
  const {nav, Component, styles, linkClick, classes} = props;

  const linkProps = useMemo(() => {
    return nav?.to?.startsWith('/') ? {as: Link} : {href: nav.to || '#', active: false}
  }, [nav?.to])

  return (
    <Component
      className={classes?.Component}
      {...linkProps}
      to={nav.to || '#'}
      style={{...styles?.Component, ...nav.style}}
      onClick={(event: any) => {
        if (nav.onClick) {
          event.preventDefault();
          nav.onClick();
        }
        linkClick && linkClick(event)
      }}
      target={nav.target ?? undefined}
    >
      {nav.icon ? <><FontAwesomeIcon icon={nav.icon}/> </> : null}
      {nav.text}
      {
        nav.countBadge ?
          (
            <span
              className={`badge badge-danger ${classes?.CounterBadge}`}
              style={styles?.CounterBadge}
            >{nav.countBadge}</span>
          )
          : null
      }
    </Component>
  )
}

export default NavItem;