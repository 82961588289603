import React, {useEffect, useMemo, useState} from 'react';
import {createGlobalState} from 'react-global-hooks';
import {FaCircleNotch} from 'react-icons/fa';
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import {SubmitHandler, useForm} from "react-hook-form";
import {useHistory} from "react-router-dom";
import {TaxRecordFormData, TaxRecordServer} from "@interfaces/TaxRecord";
import {Date, Select, TextBox} from "@components/forms/react-hook-form-bootstrap";
import {SelectOptionProps} from "@components/forms/react-hook-form-bootstrap/interfaces";
import moment from "moment";
import {createTaxRecord, fetchTaxRecordById, updateTaxRecord} from "@hornet-api/properties/taxRecords";
import {alertApiErrors} from "@common/errors";
import {convertFormDataToServerData} from "@admin-ui/pages/PropertyPage/show/constant";
import {loadingRelease, loadingTrigger} from "@components/LoadingOverlay";

interface AddTaxRecordModalState {
  propertyId: number
  exitingRecordYears: number[]
  id?: number;
  showOnly?: boolean;
  onComplete: (action: 'SAVE' | 'UPDATE' | "DELETE", newTaxRecord: TaxRecordServer) => Promise<void> | void;
}

const defaultTaxRecordForm: TaxRecordFormData = {
  year: '',
  appraisedValue: '',
  taxRate: '',
  taxesOwed: '',
  accountNumber: '',
  paymentDueDate: null,
  content: ''
};

const addTaxRecordModalState = createGlobalState(null as null | AddTaxRecordModalState);

export const openAddEditTaxRecordModal = (modalState: AddTaxRecordModalState) => {
  addTaxRecordModalState.set({
    ...modalState
  });
};

const AddEditTaxRecordModal = () => {
  const history = useHistory();
  const [modalState, setModalState] = addTaxRecordModalState.use();
  const [taxRecordFormData, setTaxRecordFormData] = useState<TaxRecordFormData>();
  const editingId = modalState?.id
  const showOnly = modalState?.showOnly
  const propertyId = modalState?.propertyId;
  const exitingRecordYears = modalState?.exitingRecordYears;

  const {
    control,
    handleSubmit,
    reset
  } = useForm<TaxRecordFormData>();
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (editingId && propertyId) {
      const t = loadingTrigger();
      fetchTaxRecordById(propertyId, editingId)
        .then(setTaxRecordFormData)
        .catch(alertApiErrors)
        .finally(() => loadingRelease(t));
    }
  }, [editingId, propertyId])

  const yearList = useMemo(() => {
    const currentYear = moment().year();
    const years: SelectOptionProps[] = [];
    for (let year = currentYear; year >= currentYear - 30; year--) {
      if (taxRecordFormData && year === +taxRecordFormData?.year) {
        years.push({
          value: year,
          label: `${year}`
        });
      } else {
        const disabled = !!exitingRecordYears?.find(a => a === year);
        years.push({
          value: year,
          label: `${disabled ? `${year} already has a tax record.` : year}`,
          disabled: disabled
        });
      }
    }
    return years
  }, [exitingRecordYears,taxRecordFormData, modalState ]);

  useEffect(() => {
    if (taxRecordFormData) {
      reset(taxRecordFormData);
    }
  }, [taxRecordFormData])

  const handleClose = () => {
    setTaxRecordFormData(undefined);
    setModalState(null);
    reset(defaultTaxRecordForm);
    history.push(`/property/show/${propertyId}`);
  };

  const onSubmit: SubmitHandler<TaxRecordFormData> = async (data) => {
    if (!propertyId) return
    if (!modalState || isSaving) return;
    setIsSaving(true);
    const requestData = convertFormDataToServerData(data);
    // call update function if it's in edit mode, otherwise call create function
    (
      editingId ?
        updateTaxRecord(propertyId, editingId, requestData)
        : createTaxRecord(propertyId, requestData)
    )
      .then((data) => modalState.onComplete(editingId ? "UPDATE" : "SAVE", data))
      .then(handleClose)
      .catch(alertApiErrors)
      .finally(() => setIsSaving(false));
  };

  return (
    <Modal
      show={!!modalState}
      onHide={handleClose}
      id={'addTaxRecordModal'}
      size={'lg'}
    >
      <Modal.Header closeButton>
        <Modal.Title>{showOnly ? 'Show' : editingId ? 'Edit' : 'Add'} Tax Record</Modal.Title>
      </Modal.Header>
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body className={'position-relative'}>
          <Row>
            <Col>
              <Select
                name={'year'}
                label={'Year'}
                emptyOption={'-Choose Year-'}
                control={control}
                options={yearList}
                rules={{
                  required: true
                }}
              />
            </Col>
            <Col>
              <TextBox
                name={'accountNumber'}
                label={'Account Number'}
                control={control}
              /></Col>
          </Row>
          <Row>
            <Col>
              <TextBox
                name={'appraisedValue'}
                label={'Appraised Value'}
                control={control}
                rules={{
                  required: true
                }}
                type={'number'}
                step={0.01}
                placeholder={'0.00'}
              />
            </Col>
            <Col>
              <TextBox
                name={'taxRate'}
                label={'Tax Rate(%)'}
                control={control}
                rules={{
                  required: true
                }}
                type={'number'}
                step={0.000001}
                description={'Tax Rate as a Percentage'}
                placeholder={'0.000000'}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextBox
                name={'taxesOwed'}
                label={'Taxes Owed'}
                control={control}
                rules={{
                  required: true
                }}
                type={'number'}
                step={0.01}
                placeholder={'0.00'}
              />
            </Col>
            <Col>
              <Date
                name={'paymentDueDate'}
                label={'Payment Due Date'}
                control={control}
              />
            </Col>
          </Row>
          <TextBox
            name={'content'}
            label={'Note'}
            control={control}
            as={'textarea'}
            step={0.01}
            rows={10}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            disabled={isSaving}
            type="submit"
          >{isSaving ? <FaCircleNotch className={'spin'}/> : 'Save'}</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AddEditTaxRecordModal;
