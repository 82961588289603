
const allowedImageMimeTypes = [
	'image/jpeg',
	'image/png',
	'image/svg+xml',
	"image/bmp",
	"image/webp",
];

export default allowedImageMimeTypes;
