import React, {ChangeEvent, useCallback} from 'react';

import style from "./UploaderPage.module.scss"
import {FaTimes} from "react-icons/fa";

interface Props {
  value: string;
  onChange: (value: string) => void;
}

const clearButton = (clearValue: (event: React.MouseEvent) => void) =>
  <div role="button" onClick={clearValue} className="btn btn-secondary" aria-disabled="false"
       style={{cursor: "pointer"}}>
    <FaTimes/>
  </div>;

const SearchBox = ({value, onChange,}: Props) => {
  const handleOnSearchChanged = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      if (typeof onChange === 'function') onChange(newValue);
    },
    [onChange]
  );

  const handleOnClearClick = useCallback(
    (event: React.MouseEvent) => {
      if (event?.preventDefault) event.preventDefault();
      if (typeof onChange === 'function') onChange('');
    },
    [onChange]
  );


  return (
    <>
      <div className={`${style.searchBox} input-group`}>
        <input
          type="text"
          className="form-control"
          placeholder="Search"
          value={value}
          onChange={handleOnSearchChanged}
        />
        <div className="input-group-append">
          {clearButton(handleOnClearClick)}
        </div>
      </div>
    </>
  );
};

export default SearchBox;
