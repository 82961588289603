import Form from 'react-bootstrap/Form';
import React, {useMemo} from 'react';
import style from './style.module.scss';
import classNames from 'classnames';

import {Props} from './interfaces';

import {getErrorMessage} from './utils';
import InputSSN from '@components/inputs/InputSSN';
import InputPhone from '@components/inputs/InputPhone';
import InputCurrency from '@components/inputs/InputCurrency';
import {Col, Row} from "react-bootstrap";
import {Controller} from "@components/forms/react-hook-form-bootstrap/Controller";
import InputEin from "@components/inputs/InputEin";

export interface SmartInputProps extends Props {
  type: 'ssn' | 'phone' | 'currency' | 'ein';
  onChange?: (val: string) => void | Promise<void>;
  renderOnlyControl?: boolean
  showClearBtn?: boolean
  allowNegative?: boolean
}

export type FormControlElement = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;

export const SmartInput = React.forwardRef(
  (
    {
      name,
      rules,
      label,
      description,
      type,
      allowNegative,
      shouldUnregister,
      defaultValue = '',
      control,
      onChange,
      disabled = false,
      getDefaultFunction,
      showOnly,
      hideLabel = false,
      horizontalLayout,
      rightElement,
      renderOnlyControl,
      showClearBtn = true
    }: SmartInputProps,
    ref
  ) => {
    const dv = getDefaultFunction ? getDefaultFunction(name, defaultValue, type) : defaultValue;
    const isRequired = Boolean(rules?.required);

    const requiredString = rules?.required && typeof rules?.required === 'string' ? rules.required : 'This field is required';
    if (isRequired) {
      switch (type) {
        case "ssn":
          rules = {
            ...rules,
            minLength: 9,
            maxLength: 9,
          };
          break;
        case "currency":
          rules = {
            validate: {
              validNumber: (value: string) => {
                if (value && isNaN(Number(value))) {
                  return "Please enter a valid number"
                }
                return true
              },
              ...rules?.validate,
              isRequired: value => value === null || value === undefined || value === '' ? requiredString : true
            }
          };
          break;
        case "phone":
        case "ein":
          rules = {
            ...rules,
            minLength: type === "phone" ? 10 : 9,
          };
          break;
        default:
          break;
      }
    }
    // console.log(`rules ${name}:`, rules)

    const InputComponent = useMemo(() => {
      if (type === 'ssn') {
        return InputSSN;
      }
      if (type === 'phone') {
        return InputPhone;
      }
      if (type === 'ein') {
        return InputEin;
      }
      if (type === 'currency') {
        return InputCurrency;
      }
    }, [type]) as typeof InputPhone | typeof InputSSN | typeof InputEin | typeof InputCurrency;
    return (
      <Controller
        name={name}
        control={control}
        defaultValue={dv}
        rules={rules}
        shouldUnregister={shouldUnregister}
        render={({
                   field,
                   fieldState
                 }) => {

          const ControlElement = (
            <InputComponent
              controlId={`form-${field.name}`}
              hideLabel={hideLabel}
              showClearBtn={showClearBtn}
              required={isRequired}
              {...field}
              description={description}
              className={showOnly ? style.showOnlyValue : 'form-control'}
              showOnly={showOnly}
              label={label}
              onChange={(event) => {
                if (onChange) {
                  onChange(event.target.value);
                }
                field.onChange(event);
              }}
              {...(type === 'currency' ? {allowNegative: allowNegative} : {})}
              disabled={disabled}
              // @ts-ignore
              ref={ref}
              error={fieldState.error ? getErrorMessage(fieldState.error) : null}
              showErrorMessage={!renderOnlyControl}
            />
          )

          if (renderOnlyControl) {
            return ControlElement
          }
          return (<>
              <Form.Group
                as={Row}
                className={classNames(style.formInput, 'mb-3 floating-form-group ')}
                controlId={`form-${field.name}`}
              >
                <div id={`${name}-scrollTo`}/>
                {
                  showOnly && <Form.Label className={'show-only'} column sm={horizontalLayout ? 4 : 12}>
                        <span className={fieldState.error ? 'text-danger' : ''}>{label}</span>
                    </Form.Label>
                }
                <Col sm={horizontalLayout ? 8 : 12}>
                  {ControlElement}
                  {rightElement}
                </Col>
              </Form.Group>
            </>
          );
        }}
      />
    );
  }
);

export default SmartInput;
