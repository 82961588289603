import getAdminApiPath from "@hornet-api/getAdminApiPath";
import axios from "axios";
import {alertApiErrors} from "@common/errors";
import {loadingRelease, loadingTrigger} from "@components/LoadingOverlay";
import {actAsUserWithToken} from "@server-api/index";

type ImpersonateData = {
  /**
   * temporary token to switch the user
   */
  token: string;
  /**
   * current admin username (we also have it in the UI)
   */
  adminUser: string;
  /**
   * comma separated admin roles
   */
  adminRoles: string;
}

const impersonateUser = (username: string, isContact: boolean = false) => {
  const params = (new URLSearchParams(window.location.search));
  params.delete('bst'); // Deleting the bst parameter which contains very long data of the settings
  const paramsStr = params.toString()
  const updatedRef = `${window.location.origin}${window.location.pathname}${paramsStr ? `?${paramsStr}` : ''}`;

  const t = loadingTrigger();

  const url = `${getAdminApiPath()}/user/impersonate`;
  axios.post<ImpersonateData>(url, {
    username,
    isContact
  })
    .then((response) => response.data)
    .then(({token, adminRoles, adminUser}) => {
      actAsUserWithToken(username, token, adminUser, adminRoles.split(','), isContact).then((data) => {
        window.localStorage.setItem('impersonateReturnTo', updatedRef);
        console.log('actAsUserWithToken', data);
        // redirect without the url
        window.location.href = '/'
      }).catch((e) => {
        console.log(e);
      })
    })
    .catch(alertApiErrors)
    .finally(() => loadingRelease(t));
};

export default impersonateUser;
