import {useMemo} from 'react';
import {useRecoilValue} from 'recoil';
import profileAtom from '@state/recoil/profileAtom';
import Contact from '@interfaces/Contact';

/**
 * Returns true if the referral is roadblocked
 */
const isReferralRoadblockedSelector = () => {
  const profile = useRecoilValue(profileAtom) as Contact | null;
  return useMemo(() => {
    if (!profile?.referral) {
      return false;
    }
    return ['PROCESSING','EMAIL_SENT', 'EMAIL_OPENED',  'REGISTERED_IN_PORTAL'].includes(
      profile.referral.status
    );
  }, [profile]);
};

export default isReferralRoadblockedSelector;
