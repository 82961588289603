import React, {useMemo} from 'react';
import activeRoleState from '@state/globalState/activeRoleState';
import NavDropdown from 'react-bootstrap/NavDropdown';
import {Link, useHistory} from 'react-router-dom';
import userRoleGroupsState from '@state/globalState/userRoleGroupsState';
import style from './ActiveRoleSelectorAdmin.module.scss';
import setActiveRoleForUser from "@hornet-api/roles/setActiveRoleForUser";

// @ts-ignore
const ActiveRoleSelectorAdmin = ({isMobile}) => {
  const history = useHistory();
  const [activeRole, setActiveRole] = activeRoleState.use();
  const userRoleGroups = userRoleGroupsState.useValue();

  const activeGroup = useMemo(() => {
    return userRoleGroups?.find((x) => x.id === activeRole) ?? null;
  }, [userRoleGroups, activeRole]);
  
  if (!userRoleGroups || userRoleGroups?.length < 2) {
    return null;
  }
  return (
    <NavDropdown
      className={style.dropdown}
      title={isMobile ? 'Role' : activeGroup?.name ?? ''}
      id="role-selector-admin-nav-dd"
    >
      {userRoleGroups.map((group, index) => {
        return (
          <NavDropdown.Item
            as={Link}
            //@ts-ignore
            to={'#'}
            key={index}
            className={activeRole === group.id ? 'active' : ''}
            onClick={(event) => {
              event.preventDefault();

              if (group.id === activeRole) return;
              setActiveRoleForUser(group.id).then(() => {
                setActiveRole(group.id);
                window.localStorage.setItem('activeRole', '' + group.id);
                history.push('/');
              })
            }}
          >
            {group.name}
          </NavDropdown.Item>
        );
      })}
    </NavDropdown>
  );
};

export default ActiveRoleSelectorAdmin;
