import React from 'react';
import RedBox from './RedBox';

// until error boudaries are supported in functional components
// this one needs to remain as a class component. error boundaries
// are the only reason class components have not been deprecated.

export default class ErrorBoundary extends React.Component {
	/* eslint-disable-next-line no-unused-vars*/
	static getDerivedStateFromError(error) {
		return { hasError: true };
	}

	constructor(props) {
		super(props);
		this.state = {
			hasError: false,
		};
	}

	/* eslint-disable-next-line no-unused-vars*/
	componentDidCatch(error, info) {
		window.console.error(error);
	}

	render() {
		if (this.state.hasError) return <RedBox />;
		return this.props.children;
	}
}
