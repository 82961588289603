import {useEffect} from "react";
import {createGlobalState} from "react-global-hooks";
import {getDashboardAccess} from "@hornet-api/contact/user/getDashboardAccess";
import {useRecoilState} from "recoil";
import profileAtom from "@state/recoil/profileAtom";


export const fundingDashboardStatusState = createGlobalState(0);

export const refreshFundingDashboardStatus = () => {
  fundingDashboardStatusState.set(Date.now())
}

const DashboardAccessMonitor = () => {
  const fundingDashboardStatusLoadingParam = fundingDashboardStatusState.useValue();
  const [profile, setProfile] = useRecoilState(profileAtom);

  useEffect(() => {
    if (fundingDashboardStatusLoadingParam && profile?.investorProfile) {
      getDashboardAccess().then((status) => {
        if (profile?.investorProfile) {
          setProfile({
            ...profile,
            investorProfile: {
              ...profile.investorProfile,
              fundingDashboardAccess: status
            }
          });
        }
      });
    }
  }, [fundingDashboardStatusLoadingParam])

  return null;
}

export default DashboardAccessMonitor;