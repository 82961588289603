import React, { Suspense } from 'react';
import ErrorBoundary from '@components/ErrorBoundary';

const createLazyComponent = (
	name: string, 
	Component: React.LazyExoticComponent<() => JSX.Element | null>, 
	fallback = null,
	...props: undefined[]
) => {
	const LazyComponent = (props: any) => {
		return (
			<ErrorBoundary>
				<Suspense fallback={fallback}>
					<Component />
				</Suspense>
			</ErrorBoundary>
		);
	};

	LazyComponent.displayName = `${name}LazyComponent`;
	return LazyComponent;
};

export default createLazyComponent;
