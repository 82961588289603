import React from 'react';
import classNames from 'classnames';
import classStyle from './style.module.scss';
import {FaCircleNotch} from "react-icons/fa";

type Props = {
  title?: string;
  isOn: boolean;
  className?: string;
  onChange?: (newVal: boolean) => void;
  isLoading?: boolean;
  disabledReason?: string;
  style?: React.CSSProperties;
  size?: 'medium'
};

const Toggle = ({isOn, className, onChange, isLoading, title, style, disabledReason, size}: Props) => {
  let containerClass = classStyle.container;
  let toggleClass = classStyle.toggle;
  if (className) {
    containerClass = classNames(containerClass, className);
  }
  if (isOn) {
    containerClass = classNames(containerClass, classStyle.on);
  }
  if (isLoading !== undefined && isLoading) {
    containerClass = classNames(containerClass, classStyle.isLoading);
  }
  if (disabledReason !== undefined && disabledReason) {
    containerClass = classNames(containerClass, classStyle.disabled);
  }
  if (size) {
    toggleClass = classNames(toggleClass, classStyle[size]);
  }
  return (
    <div
      className={containerClass}
      style={style ? style : {}}
      onClick={() => {
        if (isLoading) return;
        if (disabledReason) return alert(disabledReason);
        if (onChange) onChange(!isOn);
      }}
    >
      {title ? <div className={classStyle.title}>{title}</div> : null}
      <div className={toggleClass}>
        <div className={classStyle.nob}></div>
      </div>
      { isLoading ? <div className={classStyle.loadingBox}><FaCircleNotch className={'spin'} /></div> : null}
    </div>
  );
};

export default Toggle;
