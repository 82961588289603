import React, {JSXElementConstructor, ReactElement, ReactNode, useEffect, useMemo, useRef, useState} from 'react';
import classNames from 'classnames';
import numeral from 'numeral';
import {currency, mergeRefs} from '@common/basic';
import {getNewCursorPos, getRawCursorPos} from '@components/inputs/inputUtils';
import {Form, InputGroup} from "react-bootstrap";
import {FormControlElement} from "@components/forms/react-hook-form-bootstrap/SmartInput";
import {FaExclamation, FaTimes} from "react-icons/fa";

type Props = {
  className?: string;
  name?: string;
  value: string;
  required?: boolean;
  placeholder?: string;
  onChange?: (e: React.ChangeEvent<FormControlElement>) => void;
  error?: string | null;
  showOnly?: boolean;
  label?: string | ReactElement<any, string | JSXElementConstructor<any>> | undefined
  showClearBtn?: boolean
  description?: ReactNode,
  showErrorMessage?: boolean
  allowNegative?: boolean;
};

const regEx = /[^\d.-]/g;

export const getCurrencyDisplayValue = (value: string | number, allowNegative: boolean = false) => {
  value = `${value}`;
  if (value.length === 0) return '';
  const isNegative = value.startsWith('-');
  if (allowNegative && value === '-') {
    return value;
  }
  let format = '0,0';
  let end = '';
  let splits = value.split('.');
  if (splits.length > 1) {
    if (splits[1].length === 0) {
      end += '.';
    } else {
      format += '.';
    }
    if (splits[1].length > 0) format += '0';
    if (splits[1].length > 1) format += '0';
  }
  const positiveValue = Math.abs(numeral(value).value() ?? 0);
  if (!allowNegative) {
    value = positiveValue;
  }
  return (allowNegative && isNegative && positiveValue === 0 ? '-' : '') + numeral(value).format(format) + end;
}

const InputCurrency = React.forwardRef(
  (
    {
      name,
      className,
      value,
      placeholder,
      onChange,
      required = false,
      error,
      showOnly,
      label,
      showClearBtn = true,
      description,
      showErrorMessage = true,
      allowNegative = false,
    }: Props,
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    const [keyupCount, setKeyupCount] = useState<number>(0);
    const [rawCursor, setRawCursor] = useState(null as number | null);
    const localRef = useRef<HTMLInputElement>(null);
    const displayValue = useMemo(() => {
      return getCurrencyDisplayValue(`${value}`.trim().replace(regEx, ''), allowNegative);
    }, [value, allowNegative]);
    const [isFocus, setIsFocus] = useState<boolean>(false);

    useEffect(() => {
      if (localRef.current) {
        let newCursor = rawCursor ? getNewCursorPos(displayValue, rawCursor, regEx) : rawCursor;
        if (displayValue === '$') newCursor = 1;
        localRef.current.setSelectionRange(newCursor, newCursor);
      }
    }, [localRef, rawCursor, displayValue, keyupCount]);

    if (showOnly) {
      return <InputGroup>
        <div className={className}>{currency(displayValue)}</div>
      </InputGroup>
    }

    return (
      <>
        <InputGroup className={classNames('with-pre-group', error ? 'is-invalid' : '')}>
          <InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
          <Form.Control
            type="text"
            ref={mergeRefs(localRef, ref)}
            name={name}
            required={required}
            className={error ? classNames(className, 'is-invalid') : className}
            value={displayValue}
            placeholder={placeholder ? placeholder : 'x,xxx.xx'}
            onChange={(e) => {
              const inputElement = e.target as HTMLInputElement;
              // remove any non digits
              const displayValue = getCurrencyDisplayValue(
                `${inputElement.value}`.replace(/(?<=\..*)\./g, '').trim().replace(regEx, ''),
                allowNegative
              );
              const additionalCursorMove = displayValue.length - inputElement.value.length;
              if (inputElement.selectionStart) {
                setRawCursor(getRawCursorPos(displayValue, inputElement.selectionStart + additionalCursorMove, regEx));
                setKeyupCount(prev => prev + 1);
              } else {
                setRawCursor(null);
              }
              if (onChange) {
                e.target.value = displayValue.replace(/,/g, '');
                onChange(e);
              }
            }}
            onBlur={() => {
              setTimeout(() => {
                setIsFocus(false);
              }, 200);
            }}
            onFocus={() => {
              setIsFocus(true)
            }}
          />
          {
            label &&
              <Form.Label className={classNames(value ? 'has-value' : '')}>
                  <span className={error ? 'text-danger' : ''}>{label}</span>
              </Form.Label>
          }
          {
            !showOnly && showClearBtn && <>
              {
                error && <div className="border border-danger rounded-circle invalid-circle">
                      <FaExclamation className={'text-danger'} size={11}/>
                  </div>
              }
              {
                isFocus && value && name && showClearBtn ?
                  <div className="cross-clear"
                       onClick={() => onChange && onChange({target: {value: ''}} as React.ChangeEvent<FormControlElement>)}>
                    <FaTimes size={20}/>
                  </div> : <div className="cross-clear-hide"></div>
              }
              </>
          }
        </InputGroup>
        {showErrorMessage && error ? <div className="invalid-feedback text-left">{error}</div> : null}
        {description && <small>{description}</small>}
      </>
    );
  }
);

export default InputCurrency;
