import React, {useState} from "react";
import {FaCheck, FaTimes} from "react-icons/fa";
import {renameAttachedFile} from "@hornet-api/task/renameFile";
import {loadingRelease, loadingTrigger} from "@components/LoadingOverlay";
import {alertApiErrors} from "@common/errors";
import {getFilenameAndExtension} from "@common/utils/fileUtil";

type Props = {
  taskId: number;
  fileId: number;
  filename: string;
  onComplete: (fileId: number, filename: string) => void;
  onCancel: () => void;
}

const RenameAttachment = ({taskId, fileId, filename, onComplete, onCancel}: Props) => {
  const {docName, extension} = getFilenameAndExtension(filename);
  const [newFileName, setNewFileName] = useState(docName);

  const renameAttachFile = async () => {
    const t = loadingTrigger();
    const completeName = `${newFileName}.${extension}`;
    renameAttachedFile({taskId, fileId, completeName})
      .then((data) => onComplete(fileId, data.filename))
      .catch(alertApiErrors)
      .finally(() => loadingRelease(t));

  };

  return (
    <div>
      <input
        value={newFileName}
        onChange={(e) => setNewFileName(e.target.value)}
      />
      <span>{`.${extension}`}</span>
      <span
        role='button'
        className='ml-2'
        title='Save'
        onClick={renameAttachFile}
      >
        <FaCheck/>
      </span>
      <span
        role='button'
        className='ml-2'
        title='Cancel'
        onClick={onCancel}
      >
        <FaTimes/>
      </span>
    </div>
  );
};

export default RenameAttachment;
