import style from "./Wrapper.module.scss";
import React, {ReactChildren, ReactElement} from "react";

export type WrapperProps = {
  absolute?: boolean
}

const Wrapper = ({children, absolute}: WrapperProps & { children: ReactChildren | ReactElement }) => {
  return (
    <div className={absolute ? style.wrapperAbsolute : style.wrapper}>
      {children}
    </div>
  );
};

export default Wrapper;