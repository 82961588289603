import axios, {AxiosResponse} from "axios";
import {TwoFactorAuthAtLogin} from "@interfaces/TwoFactorAuth";
import getMetaApiPath from "@hornet-api/getMetaApiPath";

export const fetchUser2FA = async () => {
  const url = `${getMetaApiPath()}/2fa/fetchUser2FA`;
  const response: AxiosResponse<{
    twoFactorAuth: TwoFactorAuthAtLogin | null
  }> = await axios.get(url);
  return response.data.twoFactorAuth;
};