import React, {useMemo, useState} from "react";
import Button from "react-bootstrap/Button";
import style from "@admin/shared/AdminSidebar/SearchBox.module.scss";
import {FaHistory} from "react-icons/fa";
import {createGlobalState} from "react-global-hooks";
import userRoleGroupsState from "@state/globalState/userRoleGroupsState";
import {generateLink, globalSearchDataState} from "@admin-ui/pages/SearchPage/constant";
import {Link} from "react-router-dom";


const toolTipLength: number = 50;
export const recentSearchTextState = createGlobalState<string>('')

const RecentSearch = () => {
  const [isRecentSearchOpen, setIsRecentSearchOpen] = useState(false)
  const globalSearchState = globalSearchDataState.useValue()
  const userRoleGroups = userRoleGroupsState.useValue();

  const hasMultipleRoles = useMemo(() => {
    return (userRoleGroups?.length || 0) > 1;
  }, [userRoleGroups]);

  const handleClick = () => {
    setIsRecentSearchOpen(true)
  }

  const recentSearch = useMemo(() => {
    return globalSearchState['RS']
  }, [globalSearchState])

  const handleOnBlurClick = () => {
    setTimeout(() => {
      setIsRecentSearchOpen(false);
    }, 100);
  };

  return (
    <div className="recent-search position-relative">
      <div className="d-flex mt-1">
        <Button className="btn btn-light position-relative ml-auto"
                onBlur={handleOnBlurClick}
                onClick={handleClick}><span
          className={`${style.recentSearchBtn} ${hasMultipleRoles ? style.reduced : ''} mr-1`}>Recent Searches</span>
          <FaHistory/></Button>
      </div>
      {recentSearch && isRecentSearchOpen && <div
          className={`mt-2 ${style.dropdown} ${style.recentSearchDropdown}`}>
        {Object.keys(recentSearch).map(primaryId => {
          const {nestedEntityType, text} = recentSearch[primaryId];
          return (
            <Link
              to={generateLink(primaryId, nestedEntityType!)}
              className={`${style.item} ${style.recentSearchLink}`}
              key={`${nestedEntityType}-${primaryId}`}
              onClick={() => {
                recentSearchTextState.set('');
              }}
              title={text.length > toolTipLength ? text : ''}>{text}</Link>
          )
        })}
      </div>
      }
    </div>
  )
}

export default RecentSearch