import axios, {AxiosResponse} from 'axios';
import getAdminApiPath from '@hornet-api/getAdminApiPath';
import ReferralAdmin from "@interfaces/ReferralAdmin";

const getReferralList = async (): Promise<ReferralAdmin[]> => {
  const url = `${getAdminApiPath()}/ambassador/referrals`;
  const response: AxiosResponse<ReferralAdmin[]> = await axios.get(url);
  return response.data;
};

export default getReferralList;
