export enum DateFormats {
  FULL = 'MM/DD/YYYY hh:mm:ss A',
  SEMI = 'MM/DD/YYYY hh:mm A',
  DATETIME = 'MM/DD/YYYY hh:mm',
  DATE = 'MM/DD/YYYY',
  MONTH_FULL_DATE = 'MMMM DD, YYYY',
  MONTH_SHORT_DATE = 'MMM DD, YYYY',
  SERVER_DATE_ONLY_FORMAT = 'YYYY-MM-DD',
  SERVER_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss',
  SEMI_HYPHEN = 'yyyy-MM-dd hh:mm:ss a'
}