import React, {ForwardedRef} from 'react';
import Tooltip from 'react-bootstrap/Tooltip';

const ExportToCsvTooltip = React.forwardRef((props, ref: ForwardedRef<HTMLDivElement>) => {
	return (
		<Tooltip ref={ref} id="export-csv-btn" {...props}>Export to CSV</Tooltip>
	);
});

export default ExportToCsvTooltip;
