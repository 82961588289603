import axios from 'axios';
import getAdminApiPath from '../getAdminApiPath';
import {InitCallData} from "@interfaces/VoiceCall";

const prepareInitCallData = async () => {
  const url = `${getAdminApiPath()}/voice`;
  const response = await axios.get(url);
  return response.data as InitCallData;
};

export default prepareInitCallData;
