import React, { useCallback, useEffect, useRef, useState } from 'react';
import FontAwesomeIcon from '../../../../components/icons/FontAwesomeIcon';

const ControlsButton = ({
	primary = true,
	isPublic = true,
	canDelete = true,
	allowMakePrimary = true,
	onDeleteClick,
	onMakePrimaryClick,
	onSaveClick,
	onPrivacyToggle,
	disabled = false
}) => {
	const containerRef = useRef();
	const [isOpen, setIsOpen] = useState(false);

	const handleOnButtonClick = useCallback((event) => {
		if (event.preventDefault) event.preventDefault();
		if (event.stopPropagation) event.stopPropagation();
		setIsOpen((state) => (!state));
	}, [setIsOpen]);

	const handleAnywhereClick = useCallback((event) => {
		if (containerRef.current && containerRef.current.contains(event.target) === false)
			setIsOpen(false);
	}, [containerRef]);

	const handleOnPrivacyToggle = useCallback((event) => {
		if (event.preventDefault) event.preventDefault();
		if (event.stopPropagation) event.stopPropagation();
		if (typeof onPrivacyToggle === 'function') onPrivacyToggle();
		setIsOpen(false);
	}, [onPrivacyToggle, setIsOpen]);

	const handleOnDeleteClick = useCallback((event) => {
		if (event.preventDefault) event.preventDefault();
		if (event.stopPropagation) event.stopPropagation();
		if (!canDelete) return;
		if (typeof onDeleteClick === 'function') onDeleteClick();
		setIsOpen(false);
	}, [onDeleteClick, setIsOpen, canDelete]);

	const handleOnMakePrimaryClick = useCallback((event) => {
		if (event.preventDefault) event.preventDefault();
		if (event.stopPropagation) event.stopPropagation();
		if (!allowMakePrimary) return;
		if (typeof onMakePrimaryClick === 'function') onMakePrimaryClick();
		setIsOpen(false);
	}, [onMakePrimaryClick, setIsOpen, allowMakePrimary]);

	const handleOnSaveClick = useCallback((event) => {
		if (event.preventDefault) event.preventDefault();
		if (event.stopPropagation) event.stopPropagation();
		if (typeof onSaveClick === 'function') onSaveClick();
		setIsOpen(false);
	}, [onSaveClick, setIsOpen]);

	useEffect(() => {
		const handler = handleAnywhereClick;
		const options = {
			capture: true,
			once: false,
			passive: true,
		};
		window.document.addEventListener('click', handleAnywhereClick, options);
		return () => {
			if (handler)
				window.document.removeEventListener('click', handleAnywhereClick, options);
		};
	}, [handleAnywhereClick]);

	return (
		<div className="btn-group" ref={containerRef}>
			<button
				disabled={disabled}
				onClick={handleOnButtonClick}
				type="button"
				className="btn btn-outline-secondary dropdown-toggle"
				data-toggle="dropdown"
				aria-haspopup="true"
				aria-expanded={isOpen ? 'true' : 'false'}>
				Actions
			</button>
			<div 
				className={`dropdown-menu${isOpen ? ' show' : ''}`}
				x-placement="bottom-start"
				style={{
					position: 'absolute',
					transform: 'translate3d(0px, 38px, 0px)',
					top: '0px',
					left: '0px',
					willChange: 'transform'
				}}
			>
				
				<button className={`dropdown-item${!allowMakePrimary ? ' disabled' : ''}`} type="button" disabled={!allowMakePrimary} onClick={handleOnMakePrimaryClick}>
					<FontAwesomeIcon icon="bookmark-o" fixedWidth /> Make Default Image
				</button>
				<button className={`dropdown-item${primary ? ' disabled' : ''}`} type="button" disabled={primary} onClick={handleOnPrivacyToggle}>
					<FontAwesomeIcon icon={isPublic ? 'user-times' : 'user'} fixedWidth /> { isPublic ? 'Make Private' : 'Make Public' }
				</button>
				<div className="dropdown-divider" />
				<button className="dropdown-item" type="button" onClick={handleOnSaveClick}>
					<FontAwesomeIcon icon="download" fixedWidth /> Save Image
				</button>
				<div className="dropdown-divider" />
				<button className={`dropdown-item${!canDelete ? ' disabled' : ''}`} type="button" disabled={!canDelete} onClick={handleOnDeleteClick}>
					<FontAwesomeIcon icon="trash-o" fixedWidth /> Delete Image
				</button>
			</div>
		</div>
	);
};

export default ControlsButton;
