import axios from 'axios';
import {getTaskUrl} from './util';
import {ChatObject} from '@interfaces/task';

export type GetChatOptions = {
  contactId?: number,
  companyId?: number,
  loanId?: string,
  selectedChat?: number,
  isAdmin?: boolean
}
const getChatObject = async (
  {
    companyId,
    contactId,
    loanId,
    selectedChat,
    isAdmin,
  }: GetChatOptions
) => {
  let url = getTaskUrl();
  if (isAdmin) {
    // get the task for admin
    url += `/chat`;
  } else {
    // get the task for current user
    url += `/chat`;
  }

  const urlSearchParams = new URLSearchParams();
  if (companyId) urlSearchParams.append('company', `${companyId}`);
  if (contactId) urlSearchParams.append('contact', `${contactId}`);
  if (loanId) urlSearchParams.append('loan',`${encodeURIComponent(loanId)}`);
  if (selectedChat) urlSearchParams.append('selectedChat', `${selectedChat}`);

  const response = await axios.get(`${url}?${urlSearchParams.toString()}`);
  return response.data as ChatObject;
};

export default getChatObject;
