import React, {useEffect, useRef, useState} from 'react';
import {Link, Redirect, useLocation} from "react-router-dom";
import {alertApiErrors} from "@common/errors";
import hornetCapitalLogo from "@images/hornet_logo_regular.svg";
import resetPassword, {ResetPasswordData} from "@hornet-api/auth/resetPassword";
import {SubmitErrorHandler, SubmitHandler, useForm} from "react-hook-form";
import {onInvalidHandler} from "@components/forms/react-hook-form-bootstrap/utils";
import {Button, Form, InputGroup} from "react-bootstrap";
import {FaKey} from "react-icons/fa";
import {TextBox} from "@components/forms/react-hook-form-bootstrap";
import {passwordValidator} from "@common/utils/validators";
import {loadingRelease, loadingTrigger} from "@components/LoadingOverlay";


function useQuery() {
  const {search} = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ResetPassword = () => {
  const [submitted, setSubmitted] = useState(false);
  const firstFieldRef = useRef<HTMLInputElement>(null);
  const query = useQuery();
  const token = query.get("t");

  const {
    handleSubmit,
    control,
    reset,
    watch
  } = useForm<ResetPasswordData>();

  const password = watch('password');

  // focus on first field
  useEffect(() => {
    setTimeout(() => {
      if (firstFieldRef.current) {
        firstFieldRef.current.focus();
      }
    }, 500)
    reset({
      password: '',
      password2: '',
      token: token || ''
    })
  }, []);

  const onInvalid: SubmitErrorHandler<ResetPasswordData> = (errs) => {
    onInvalidHandler(errs)
  }
  const onSubmit: SubmitHandler<ResetPasswordData> = async (data) => {
    const triggerId = loadingTrigger();
    try {
      await resetPassword(data);
      setSubmitted(true);
    } catch (error) {
      alertApiErrors(error);
    } finally {
      loadingRelease(triggerId);
    }
  }


  if (!token) {
    return <Redirect to="/login"/>
  }


  return (

    <div className="login-wrapper">
      <div className="container-center">
        <div className="login-area">
          <div className="login-logo">
            <img src={hornetCapitalLogo} alt="Hornet Capital"/>
          </div>
          <div className="card panel-custom">
            <div className="card-heading custom_head">
              <div className="view-header">
                <div className="header-title">
                  <h3>Password Reset</h3>
                  <small>
                    <strong>
                      {
                        submitted ?
                          <div>Password has been successfully reset</div>
                          : <div>Please fill out this form to reset your password</div>
                      }

                    </strong>
                  </small>
                </div>
              </div>
            </div>
            {
              submitted ?
                null :
                <div className="card-body card_body_text">
                  <Form autoComplete={'off'} noValidate onSubmit={handleSubmit(onSubmit, onInvalid)}>
                    <p>
                      Please enter your new password. Your new password must be at
                      least 8 characters and include at least one letter, number, and a special
                      character: !@#$%^&
                    </p>
                    <div className="form-group">
                      <TextBox
                        name={'password'}
                        control={control}
                        label={'Password'}
                        placeholder={"New Password"}
                        type={'password'}
                        rules={{
                          required: true,
                          validate: (p?: string) => p && passwordValidator(p)
                        }}
                        leftElement={
                          <InputGroup.Prepend>
                            <InputGroup.Text>
                              <FaKey/>
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                        }
                        description={'Your hard to guess new password'}
                      />
                      <TextBox
                        name={'password2'}
                        control={control}
                        label={'Repeat Password'}
                        type={'password'}
                        rules={{
                          required: true,
                          validate: {
                            passwordValidation: (p2?: string) => p2 && p2 === password ? true : 'Passwords do not match.'
                          }
                        }}
                        leftElement={
                          <InputGroup.Prepend>
                            <InputGroup.Text>
                              <FaKey/>
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                        }
                        description={'Please repeat your password'}
                      />
                      <div>
                        <Button
                          variant={'primary'}
                          type="submit"
                          className="btn btn-block"
                        >
                          Reset Password
                        </Button>
                      </div>
                    </div>
                  </Form>
                </div>
            }
          </div>
          <div className="below-card">
            <Link to="/login">Back to Login</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
