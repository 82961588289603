import axios, {AxiosResponse} from "axios";
import getUserApiPath from "@hornet-api/getUserApiPath";
import {TwoFactorAuth} from "@interfaces/TwoFactorAuth";

const sendOTP2FA = async (data: { phoneNumber: string }) => {
  const url = `${getUserApiPath()}/2fa/sendOTP`;
  const response: AxiosResponse<TwoFactorAuth | null> = await axios.put(url, data)
  return response.data
};

export default sendOTP2FA;
