import React, { Fragment } from 'react';
import SideBar from './SideBar';
import TopBar from './TopBar';
import { isInIframeState } from '../../globalState';
import NotificationSidebar from '@components/NotificationSidebar';

const AdminSidebar = () => {
	const isInIframe = isInIframeState.useValue();
	if(isInIframe) {
		return null;
	}
	return (
		<Fragment>
			<SideBar />
			<TopBar />
			<NotificationSidebar />
		</Fragment>
	);
};

export default AdminSidebar;
