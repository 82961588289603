import React, { useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { createGlobalState } from 'react-global-hooks';

type NoticeObj = {
  title?: string,
  text: string | React.ReactNode,
  onClose?: () => void
}

export const noticeState = createGlobalState(null as null | NoticeObj);

export const addNotice = (n: NoticeObj) => {
  noticeState.set(n);
};

const Notice = () => {
  const [notice, setNotice] = noticeState.use();
  const closeRef = useRef<HTMLButtonElement>();
  const handleClose = () => {
    if (notice?.onClose) {
      notice.onClose();
    }
    setNotice(null);
  };
  return (
    <Modal
      show={notice !== null}
      onHide={handleClose}
      id={'noticeModal'}
      onEntered={() => {
        setTimeout(() => {
          if (closeRef.current) {
            closeRef.current.focus();
          }
        }, 300)
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>{notice?.title ? notice.title : 'Notice'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {notice?.text}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          //@ts-ignore
          ref={closeRef}
          onClick={handleClose}
        >
          Close
        </Button>
      </Modal.Footer>

    </Modal>
  )
}

export default Notice;