import React, {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import devConsole from "@common/devConsole";

const RedirectLocalStorage = () => {
  const history = useHistory();
  useEffect(() => {
    // redirect if we have a redirect in local storage
    const redirect = localStorage.getItem('NoAuthRedirect');
    if (redirect) {
      devConsole.log('Redirecting to', redirect);
      history.push(redirect);
      localStorage.removeItem('NoAuthRedirect');
    }
  }, []);
  return <></>;
};

export default RedirectLocalStorage;
