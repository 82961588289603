/* eslint-disable no-magic-numbers */
import { selector } from 'recoil';
import authenticationBlobAtom from './core/authenticationBlobAtom';

const isImpersonatingSelectorAtom = selector({
	key: 'isImpersonatingSelectorAtom',
	default: false,
	get: ({ get }) => {
		const authentication = get(authenticationBlobAtom);
		if (authentication.impersonation_user) return true;
		if (authentication?.active_user?.isImpersonating) return true;
		return false;
	},
});

export default isImpersonatingSelectorAtom;
