/* eslint-disable indent */
import React, {useCallback} from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import AsyncSelect from "react-select/async";
import memoize from "lodash/memoize";

import styles from './styles.module.scss';
import "./BaseSelectBox.scss";

const BaseSelectBox = (
	{
		onChange,
		isCreatable = false,
		className = '',
		...rest
	}
) => {
	const handleOnChange = useCallback((value, action) => {
		if (typeof onChange === 'function') onChange(value, action);
	}, [onChange]);

    const getRegExp = memoize((rawInput) =>
        new RegExp(rawInput
            .replace(/[-[\]{}()*+?.,\\^$|#]/g, "\\$&")
			.replace(/\s/g, "(.*)"), 'ig')
    );

    const SelectComponent = rest.loadOptions ? AsyncSelect : isCreatable ? CreatableSelect : Select;

	return (
			<SelectComponent
				className={`base-select-box ${className || styles.container}`}
				classNamePrefix="base-select-box"
				onChange={handleOnChange}
				filterOption={(option, rawInput) => {
					if (option.data?.search) {
						return Boolean(
							option.data.search.match(
								getRegExp(rawInput)
							)
						);
					} else {
						return Boolean(
							option.label.match(
								getRegExp(rawInput)
							)
						);
					}
				}}
				{...(rest.loadOptions ? {defaultOptions: true} : {})}
				{...rest}
				// NOTE: useful property to debug the style of an open menu
				// defaultMenuIsOpen={true}
			/>
	);
};

export default BaseSelectBox;
