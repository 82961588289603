import React, {useEffect, useMemo} from 'react';
import activeRoleState from "@state/globalState/activeRoleState";
import activeUserSelectorAtom from "@state/recoil/authentication/activeUserSelectorAtom";
import {useRecoilValue} from 'recoil';
import NavDropdown from "react-bootstrap/NavDropdown";
import {Link, useHistory} from "react-router-dom";
import profileAtom from "@state/recoil/profileAtom";
import userRoleGroupsState from "@state/globalState/userRoleGroupsState";
import setActiveRoleForUser from "@hornet-api/roles/setActiveRoleForUser";
import {refreshAnnouncements} from "@components/NotificationSidebar/utils";

type Props = {
  isMobile?: boolean
}

const ActiveRoleSelector = ({isMobile = false}: Props) => {
  const activeUser = useRecoilValue(activeUserSelectorAtom);
  const profile = useRecoilValue(profileAtom);
  const [activeRole, setActiveRole] = activeRoleState.use();
  const userRoleGroups = userRoleGroupsState.useValue();
  const history = useHistory();

  const activeGroup = useMemo(() => {
    return userRoleGroups?.find(x => x.id === activeRole) ?? null;
  }, [userRoleGroups, activeRole]);

  if (!userRoleGroups || userRoleGroups?.length < 2) {
    return null;
  }
  if(isMobile) {
    return (
      <>
        <li className='group-title'>
          User Role
        </li>
        {
          userRoleGroups.map((group, index) => {
            return (
              <li className='side_link sub' key={index}>
                <a
                  href={'#'}
                  key={index}
                  className={activeRole === group.id ? 'selected' : ''}
                  onClick={(event) => {
                    event.preventDefault();

                    if (group.id === activeRole) return;
                    // let backend know about the change
                    setActiveRoleForUser(group.id).then(() => {
                      setActiveRole(group.id);
                      history.push('/')
                    });
                  }}
                >
                  {group.name}
                </a>
              </li>
            );
          })
        }
      </>
    )
  }
  return (
    <NavDropdown alignRight={!isMobile} title={activeGroup?.name ?? ''} id="role-selector-nav-dd">
      {
        userRoleGroups.map((group, index) => {
          return (
            <NavDropdown.Item
              as={Link}
              //@ts-ignore
              to={'#'}
              key={index}
              className={activeRole === group.id ? 'active' : ''}
              onClick={(event) => {
                event.preventDefault();

                if (group.id === activeRole) return;
                // let backend know about the change
                setActiveRoleForUser(group.id).then(() => {
                  setActiveRole(group.id);
                  history.push('/')
                });
              }}
            >
              {group.name}
            </NavDropdown.Item>
          );
        })
      }
    </NavDropdown>
  );
};

export default ActiveRoleSelector;