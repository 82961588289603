import React, { useMemo } from 'react';
import activeUserSelector from '@state/globalState/user/activeUserSelector';
import { isLegacy } from '@common/isAdmin';

const isSuperAdminSelector = () => {
  const activeUser = activeUserSelector();
  return useMemo(() => {
    if (isLegacy()) {
      return !!window?.role?.isSuperAdmin;
    } else {
      if (activeUser) {
        return activeUser.roles.includes('ROLE_SUPERADMIN');
      } else {
        return false;
      }
    }
  }, [activeUser]);
};

export default isSuperAdminSelector;
