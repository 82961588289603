import {CSSProperties, useEffect, useMemo, useState} from 'react';
import Contact from '@interfaces/Contact';
import {getNumTaskNotifications, getNumTransferNotifications} from "@components/NotificationSidebar/utils";
import {
  announcementsState,
  reportsState,
  tasksState,
  transfersState,
  userBudgetNotificationState
} from "@components/NotificationSidebar/globalState";
import isReferralRoadblockedSelector from "@state/globalState/isReferralRoadblockedSelector";
import isFeatureEnabledSelector from "@state/globalState/isFeatureEnabledSelector";
import {openWireInstructionModalState} from "@components/Navigation/WireInstructionModal";
import {openSageFinancialModal} from "@legacy/views/DashboardViewLegacy/component/SageFinancial/SageFinancialModal";
import {copyTextToClipboard} from "@common/basic";
import {alertApiErrors} from "@common/errors";
import {loadingRelease, loadingTrigger} from "@components/LoadingOverlay";
import {getApplicationConfigByName} from "@hornet-api/applicationConfig/user";
import {IJsonKeyValue} from "@interfaces/IApplicationConfig";
import {wiringObject} from "@components/AchConfirmModal/AchConfirmModalUtils";


export interface NavigationObjectItem {
  icon?: string,
  text?: string,
  to?: string,
  id?: string,
  target?: string,
  onClick?: () => void
  children?: NavigationObjectItem[],
  style?: CSSProperties,
  divider?: boolean,
  hide?: boolean,
  roles?: string[]
  countBadge?: number;
  excludeRoles?: string[]
}

const getNavigationObject = (
  profile: Contact | null,
  isImpersonating: boolean,
  onLogout: () => void,
  onStopImpersonating: () => void,
): NavigationObjectItem[] => {
  const isAmbassadorProgramEnabled = isFeatureEnabledSelector('ambassador');
  const isBudgetFeatureEnabled = isFeatureEnabledSelector('budget');
  const isReferralRoadblocked = isReferralRoadblockedSelector();
  const userBudgetNotification = userBudgetNotificationState.useValue();
  const isSageFeatureEnabled = isFeatureEnabledSelector('sage');
  const [videoLibraries, setVideoLibraries] = useState<NavigationObjectItem[]>();
  const [socialMediaLinks, setSocialMediaLinks] = useState<NavigationObjectItem[]>();
  const [addresses, setAddresses] = useState<NavigationObjectItem[]>();
  const [cusips, setCusips] = useState<NavigationObjectItem[]>();
  const announcements = announcementsState.useValue();

  const announcementCountStatus = useMemo(() => {
    return announcements.filter(({isRead}) => !isRead).length
  }, [announcements]);

  // add additional hide nav items here when needed
  const hideNav = isReferralRoadblocked;

  const tasks = tasksState.useValue();
  const transfers = transfersState.useValue();

  useEffect(() => {
    if (!profile) return;
    const t = loadingTrigger();
    Promise.all([
      getApplicationConfigByName('YOUTUBE_LIBRARY').then((appConfig) => {
        if (appConfig.type === 'JSON') {
          const videoLibraries = JSON.parse(appConfig.configValue).map((config: IJsonKeyValue, index: number) => ({
            id: index,
            text: config.label,
            onClick: () => {
              window.open(config.value, "_blank");
            }
          }));
          setVideoLibraries(videoLibraries);
        }
      }),
      getApplicationConfigByName('SOCIAL_MEDIA_LINKS').then((appConfig) => {
        if (appConfig.type === 'JSON') {
          const socialMediaLinks = JSON.parse(appConfig.configValue).map((config: IJsonKeyValue, index: number) => ({
            id: index,
            text: config.label,
            onClick: () => {
              window.open(config.value, "_blank");
            }
          }));
          setSocialMediaLinks(socialMediaLinks);
        }
      }),
      getApplicationConfigByName('ADDRESSES').then((appConfig) => {
        if (appConfig.type === 'JSON') {
          const addresses = JSON.parse(appConfig.configValue).map((config: IJsonKeyValue, index: number) => ({
            id: index,
            text: config.value
          }));
          setAddresses(addresses);
        }
      }),
      getApplicationConfigByName('CUSIPS').then((appConfig) => {
        if (appConfig.type === 'JSON') {
          const cusips = JSON.parse(appConfig.configValue).map((config: IJsonKeyValue, index: number) => ({
            id: index,
            text: config.label,
            onClick: () => {
              copyTextToClipboard(config.value).then(() => alert(`Copied ${config.value} to Clipboard`))
            }
          }));
          setCusips(cusips);
        }
      }),
    ])
      .catch(alertApiErrors)
      .finally(() => loadingRelease(t));
  }, [profile]);

  const numTasksNotifications = useMemo(() => {
    let total = 0;
    tasks?.map((task) => {
      total += getNumTaskNotifications(task);
    });
    return total;
  }, [tasks]);

  const numTransfersNotifications = useMemo(() => {
    return getNumTransferNotifications(transfers || []);
  }, [transfers]);

  const isFundingDashboardApproved = useMemo(() => {
    return profile?.investorProfile?.fundingDashboardAccess === 'APPROVED'
  }, [profile]);

  const reports = reportsState.useValue(); // Access reports from the state


  const dashboardItem = useMemo(() => {
    const dashboardItem: NavigationObjectItem = {
      text: 'Dashboard',
      id: 'dashboardDropdown',
      roles: ['ROLE_NON_ACCREDITED_INVESTOR', 'ROLE_ACCREDITED_INVESTOR'],
      hide: hideNav,
      to: '/dashboard/'
    };

    let dashboardChildren = [
      {
        text: 'Dashboard',
        to: '/dashboard/',
        icon: 'tachometer',
        roles: ['ROLE_ACCREDITED_INVESTOR'],
      },
      {
        text: 'Fund Map',
        to: '/fundMap',
        icon: 'money',
        roles: ['ROLE_ACCREDITED_INVESTOR'],
      }
    ];
    const status = profile?.investorProfile?.fundingDashboardAccess;
    if (status && status !== "APPROVED") {
      dashboardItem.children = [{
        text: 'Request Access',
        to: '/dashboard',
        icon: 'tachometer',
        roles: ['ROLE_NON_ACCREDITED_INVESTOR', 'ROLE_ACCREDITED_INVESTOR'],
      }]

      return dashboardItem
    }

    dashboardItem.children = dashboardChildren;

    if (!reports?.length) {
      return dashboardItem;
    }

    if (reports.length == 1) {
      dashboardItem.to = `/dashboard/${reports[0].id}`
      dashboardChildren[0].text = reports[0].description;
      dashboardChildren[0].to = `/dashboard/${reports[0].id}`
    } else {
      dashboardItem.text = 'Dashboards'

      // Remove the first item, it's already there in the reports.
      dashboardChildren.shift();

      // If reports is not null and has items, modify the children
      dashboardChildren = [
        ...reports.map((report) => ({
          text: report.description,
          icon: 'money',
          to: `/dashboard/${report.id}`, // You may need to adjust the URL structure as needed
          roles: ['ROLE_ACCREDITED_INVESTOR']
        })),
        ...dashboardChildren,
      ];
    }

    dashboardItem.children = dashboardChildren;

    return dashboardItem
  }, [profile?.investorProfile?.fundingDashboardAccess, reports]);

  const wiringPdf = wiringObject.sub
  const wireObjDownload = wiringPdf.download;

  const name = `${profile?.firstName} ${profile?.lastName}`;

  return [
    {
      text: 'Home',
      to: '/',
      hide: hideNav,
    },

    {
      text: 'Loans',
      to: '/userLoan/myLoans',
      roles: ['ROLE_BORROWER', 'ROLE_LENDER', 'ROLE_USER_SEARCHABLE_AS_ESCROW_OFFICER'],
      hide: hideNav,
    },
    {
      text: 'Loan Payments',
      to: '/userLoan/myLoanPayments',
      roles: ['ROLE_BORROWER'],
      hide: hideNav,
    },
    {
      text: 'Budgets',
      to: '/userLoan/budgets',
      roles: ['ROLE_BORROWER'],
      hide: (hideNav || !isBudgetFeatureEnabled),
      countBadge: userBudgetNotification.length,
    },
    {
      text: 'Investments',
      id: 'investmentsDropdown',
      roles: ['ROLE_NON_ACCREDITED_INVESTOR', 'ROLE_ACCREDITED_INVESTOR'],
      hide: hideNav,
      children: [
        {
          text: 'Hornet Capital Fund',
          to: '/userContact/investor',
          icon: 'money',
          roles: ['ROLE_ACCREDITED_INVESTOR'],
        },
        {
          text: 'Loan Participations',
          to: '/userLoan/myLoans',
          icon: 'handshake-o',
          roles: ['ROLE_NON_ACCREDITED_INVESTOR', 'ROLE_ACCREDITED_INVESTOR'],
        }
      ]
    },
    {
      text: 'Opportunities',
      id: 'opportunitiesDropdown',
      roles: ['ROLE_NON_ACCREDITED_INVESTOR', 'ROLE_ACCREDITED_INVESTOR'],
      hide: hideNav,
      children: [
        {
          text: 'Join Hornet Capital Fund',
          to: '/userContact/createInvestment',
          icon: 'money',
          hide: !isFundingDashboardApproved,
          roles: ['ROLE_ACCREDITED_INVESTOR'],
        }, {
          text: 'Partner on a specific Loan',
          to: '/userLoan',
          icon: 'university',
          roles: ['ROLE_NON_ACCREDITED_INVESTOR', 'ROLE_ACCREDITED_INVESTOR'],
        }
      ]
    },
    {
      text: 'Tasks',
      to: '/myTasks',
      countBadge: numTasksNotifications,
      hide: hideNav,
    },
    {
      text: 'Resources',
      id: 'resources',
      hide: hideNav,
      children: [
        dashboardItem, // Add the modified "Dashboard" item
        {
          text: 'Documents',
          id: 'investorDocument',
          to: '/userContact/documents',
          hide: hideNav
        },
        {
          text: 'Ambassador Program',
          to: '/ambassador',
          hide: !(profile?.investorProfile?.isEligibleForAmbassadorProgram) || hideNav || !isAmbassadorProgramEnabled,
          roles: ['ROLE_NON_ACCREDITED_INVESTOR', 'ROLE_ACCREDITED_INVESTOR'],
        },
        {
          text: 'Entities',
          to: '/userContact/entities',
          hide: hideNav,
        },
        {
          text: 'Bank',
          id: 'myBankDropdown',
          hide: hideNav,
          children: [
            {
              text: 'Bank Accounts',
              to: '/bankAccounts',
              icon: 'university',
            },
            {
              text: 'Transactions',
              to: '/transactions',
              icon: 'money',
              countBadge: numTransfersNotifications,
            },
            {
              text: 'Recurring Payments',
              to: '/recurringPayments',
              icon: 'money',
              roles: ['ROLE_BORROWER'],
            },
            {
              text: 'Recurring Investments',
              to: '/recurringInvestments',
              icon: 'money',
              roles: ['ROLE_NON_ACCREDITED_INVESTOR', 'ROLE_ACCREDITED_INVESTOR'],
            },
            {
              text: 'Wire Instructions',
              icon: 'file-text',
              hide: hideNav,
              children: [
                {
                  text: 'View Wire Instructions',
                  hide: hideNav,
                  onClick: () => {
                    openWireInstructionModalState({show: true})
                  }
                },
                {
                  text: 'Download Wire Instructions',
                  hide: hideNav,
                  onClick: () => {
                    window.open(wireObjDownload, "_blank");
                  }
                }
              ],
            }
          ]
        },
        {
          text: 'Video Library',
          id: 'videoLibrary',
          children: videoLibraries,
          roles: ['ROLE_NON_ACCREDITED_INVESTOR', 'ROLE_ACCREDITED_INVESTOR'],
        },
        {
          text: 'Real Time Accounting',
          id: 'realTimeAccount',
          roles: ['ROLE_NON_ACCREDITED_INVESTOR', 'ROLE_ACCREDITED_INVESTOR'],
          onClick: () => {
            if (!isSageFeatureEnabled) {
              alert("Sage Feature is Disabled");
              return
            }
            if (!isFundingDashboardApproved) {
              alert("Funding Dashboard is not Approved");
              return
            }
            openSageFinancialModal({})
          }
        },
        {
          text: 'Mailing & Physical Address',
          id: 'mailingPhysicalAddress',
          children: addresses
        },
        {
          text: 'Social Media Links',
          id: 'socialMediaLinks',
          children: socialMediaLinks
        },
        {
          text: 'CUSIPS',
          id: 'cusips',
          to: '/userLoan/myLoans',
          children: cusips,
          roles: ['ROLE_NON_ACCREDITED_INVESTOR', 'ROLE_ACCREDITED_INVESTOR'],
        },
        {
          text: 'Announcements',
          to: '/chat/announcement',
          hide: hideNav,
          countBadge: announcementCountStatus
        }
      ]
    },
    {
      text: name,
      id: 'userNavDropdown',
      children: [
        {
          text: 'Profile',
          to: '/userContact/profile',
          icon: 'male',
          hide: hideNav,
        },
        {
          divider: true
        },
        {
          hide: !isImpersonating,
          text: 'Stop Impersonating',
          onClick: () => {
            onStopImpersonating();
          },
          icon: 'user-times',
        },
        {
          text: 'Logout',
          icon: 'sign-out',
          onClick: () => {
            onLogout();
          }
        },
      ]
    },
  ];
};

export default getNavigationObject;
