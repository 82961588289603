
class DevConsole {
  private isDev = process.env.NODE_ENV === 'development';
  assert(...x: any) { if (this.isDev) console.assert(...x) }
  clear() { if (this.isDev) console.clear() }
  count(...x: any) { if (this.isDev) console.count(...x) }
  countReset(...x: any) { if (this.isDev) console.countReset(...x) }
  debug(...x: any) { if (this.isDev) console.debug(...x) }
  dir(...x: any) { if (this.isDev) console.dir(...x) }
  dirxml(...x: any) { if (this.isDev) console.dirxml(...x) }
  error(...x: any) { if (this.isDev) console.error(...x) }
  group(...x: any) { if (this.isDev) console.group(...x) }
  groupCollapsed(...x: any) { if (this.isDev) console.groupCollapsed(...x) }
  groupEnd() { if (this.isDev) console.groupEnd() }
  info(...x: any) { if (this.isDev) console.info(...x) }
  log(...x: any) { if (this.isDev) console.log(...x) }
  table(...x: any) { if (this.isDev) console.table(...x) }
  time(...x: any) { if (this.isDev) console.time(...x) }
  timeEnd(...x: any) { if (this.isDev) console.timeEnd(...x) }
  timeLog(...x: any) { if (this.isDev) console.timeLog(...x) }
  trace(...x: any) { if (this.isDev) console.trace(...x) }
  warn(...x: any) { if (this.isDev) console.warn(...x) }
  profile(...x: any) { if (this.isDev) console.profile(...x) }
  profileEnd(...x: any) { if (this.isDev) console.profileEnd(...x) }
  timeStamp(...x: any) { if (this.isDev) console.timeStamp(...x) }
}

//@ts-ignore
const devConsole = new DevConsole() as Console;

export default devConsole;

