import axios from 'axios';
import getAdminApiPath from '../getAdminApiPath';
import {County} from "@interfaces/State";
import isAdmin from "@common/isAdmin";
import getUserApiPath from "@hornet-api/getUserApiPath";

const getCountyList = async (stateId: number) => {
  const url = `${getAdminApiPath()}/state/${stateId}/county`;
  const response = await axios.get(url);
  return response.data as County[];
};

export default getCountyList;

export const getCountyByAbbreviation = async (abbreviation: string) => {
  const url = isAdmin() ? `${getAdminApiPath()}/state/abbreviation/${abbreviation}/county` : `${getUserApiPath()}/state/abbreviation/${abbreviation}/county`;
  const response = await axios.get(url);
  return response.data as County[];
};