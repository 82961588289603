const clarity = (id) => {
	((function (c, l, a, r, i) {
		c[a] =
			c[a] ||
			function () {
				(c[a].q = c[a].q || []).push(arguments);
			};
		const t = l.createElement(r);
		t.async = 1;
		t.src = "https://www.clarity.ms/tag/" + i;
		const y = l.getElementsByTagName(r)[0];
		y.parentNode.insertBefore(t, y);
	})(window, document, "clarity", "script", id));
};

export default clarity;
