/* eslint-disable no-console */
import React, {useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import forgotPassword, {ForgotPasswordData} from '@hornet-api/auth/forgotPassword';
import {alertApiErrors} from '@common/errors';
import hornetCapitalLogo from '@assets/images/hornet_logo_regular.svg';
import {TextBox} from "@components/forms/react-hook-form-bootstrap";
import {SubmitErrorHandler, SubmitHandler, useForm} from "react-hook-form";
import {Button, Form} from "react-bootstrap";
import {onInvalidHandler} from "@components/forms/react-hook-form-bootstrap/utils";
import {loadingRelease, loadingTrigger} from "@components/LoadingOverlay";
import blueFooterImage from "@images/blue_footer_image.svg";

const ForgotPassword = () => {
  const [submitted, setSubmitted] = useState<boolean>(false);
  const firstFieldRef = useRef<HTMLInputElement>(null);

  // focus on first field
  useEffect(() => {
    setTimeout(() => {
      if (firstFieldRef.current) {
        firstFieldRef.current.focus();
      }
    }, 500);
    reset({username: ''});
  }, []);

  const {
    handleSubmit,
    control,
    reset
  } = useForm<ForgotPasswordData>();

  const onInvalid: SubmitErrorHandler<ForgotPasswordData> = (errs) => {
    onInvalidHandler(errs)
  }

  const onSubmit: SubmitHandler<ForgotPasswordData> = async (data) => {
    const triggerId = loadingTrigger();
    try {
      await forgotPassword(data);
      setSubmitted(true);
    } catch (error) {
      alertApiErrors(error);
    } finally {
      loadingRelease(triggerId);
    }
  }

  return (
    <div className="forgot-password-container">
      <div className="container">
        <img src={hornetCapitalLogo} alt="Hornet Capital" className="logo"/>
        <Form className="login-form" autoComplete={'off'} noValidate onSubmit={handleSubmit(onSubmit, onInvalid)}>
          {
            submitted ?
              <h4 className={'text-center'}>Please Check Your Email</h4>
              :
              <>
                <h4 className={'text-center'}>Password Reset</h4>
                <small className={'text-center'}><strong>Please fill the form to recover your password</strong></small>
              </>
          }

          {
            submitted ?
              null :
              <>
                <p className={'mb-0 mt-4'}>Please provide your username. If valid, you will receive directions on how to reset your password
                  via email.</p>
                <TextBox
                  name={'username'}
                  control={control}
                  label={'Username'}
                  placeholder={'Please enter your username'}
                  rules={{
                    required: true
                  }}
                  description={'Your registered username'}
                />
                <div className="text-center">
                  <Button
                    type="submit"
                    name="Reset Password"
                    variant={'warning'}
                    className="btn btn-block"
                  >Reset Password
                  </Button>
                </div>
              </>
          }
        </Form>
        <div className={'mt-5'}><Link to={'/login'}>Back to Login</Link></div>

      </div>
      <div className="footer" style={{backgroundImage: `url( ${blueFooterImage})`}}></div>
    </div>
  );
};

export default ForgotPassword;
