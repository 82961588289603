import axios from 'axios';
import getAdminApiPath from "@hornet-api/getAdminApiPath";
import {SelectOptionProps} from "@components/forms/react-hook-form-bootstrap/interfaces";

const listTitleOfficeOptions = async () => {
  const url = `${getAdminApiPath()}/entity/titleOffice`;
  const response = await axios.get(url);
  return response.data as SelectOptionProps[];
};

export default listTitleOfficeOptions