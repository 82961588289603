import axios from 'axios';
import {getTaskUrl} from './util';
import {LoanStageEnum} from "@interfaces/GeneratedEnums";

export type LoanListItem = {
  id: string,
  primaryProperty: string | null
  loanStage: keyof typeof LoanStageEnum
};

const getLoanList = async () => {
  let url = `${getTaskUrl()}/loan/list`;
  const response = await axios.get(url);
  return response.data as LoanListItem[];
};

export default getLoanList;
