/* eslint-disable no-console */
/* eslint-disable no-magic-numbers */
import {useCallback} from 'react';
import {useSetRecoilState} from 'recoil';
import authenticationBlobAtom from '@state/recoil/authentication/core/authenticationBlobAtom';
import * as Auth from '@server-api/index';
import {updateUserRoleGroups} from "@state/globalState/userRoleGroupsState";
import {getCleanBaseUri} from "@common/baseUri";

const useSignInUser = () => {
	const setCurrentUser = useSetRecoilState(authenticationBlobAtom);

	const signInUser = useCallback(async (username, password) => {
		try {
			const user = await Auth.signinUser(username, password);
			await updateUserRoleGroups();
			// if is super admin. Automatically log into super admin dashboard
			// if (user.length > 0 && user[0].roles && user[0].roles.includes('ROLE_SUPERADMIN')) {
			// 	// is super admin. redirect to the dashboard
			// 	window.location.href = `${getBaseUri()}login/tokenToSessionAuth?access_token=${user[0].token}`;
			// } else {
			console.info('Welcome user', user[0].sub);
			const newState = {
				active_user: user.length === 2 ? user[1] : user[0],
				impersonation_user: user.length === 2 ? user[0] : null,
			};
			setCurrentUser((state) => ({
				...state,
				...newState,
			}));
			window.requestAnimationFrame(() => {
				window.location = `${getCleanBaseUri()}/login/tokenToSessionAuth?access_token=${newState.active_user.token}`;
			});
		} catch (oe) {
			return Promise.reject(oe);
		}
	}, [setCurrentUser]);

	return signInUser;
};

export default useSignInUser;
