import React, {useState} from 'react';
import Tooltip from "react-bootstrap/Tooltip";
import Button from "react-bootstrap/Button";
import {copyTextToClipboard} from "@common/basic";
import {TbClipboardCopy} from "react-icons/tb";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

type Props = {
  text: string;
}

const CopyToClickBoard = ({text}: Props) => {
  const [isClicked, setClicked] = useState<boolean>(false);

  return (
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip id="hover-tooltip">{isClicked ? 'Copied' : 'Click to Copy'}</Tooltip>}
    >
      <Button
        size={'sm'}
        className={'p-0'}
        variant={''}
        onBlur={() => setClicked(false)}
        onClick={() => {
          setClicked(true);
          copyTextToClipboard(text);
        }}>

        <TbClipboardCopy size={22}/>
      </Button>
    </OverlayTrigger>)
}

export default CopyToClickBoard;