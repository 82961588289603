import React, {JSXElementConstructor, ReactElement, ReactNode, useMemo, useState} from 'react';
import classNames from 'classnames';
import {Form, InputGroup} from "react-bootstrap";
import {FormControlElement} from "@components/forms/react-hook-form-bootstrap/SmartInput";
import {FaExclamation, FaTimes} from "react-icons/fa";
import {currency} from "@common/basic";

type Props = {
  className?: string;
  name?: string;
  value: string;
  placeholder?: string;
  onChange?: (e: React.ChangeEvent<FormControlElement>) => void;
  label?: string | ReactElement | undefined
  showOnly?: boolean
  error?: string | null;
  required?: boolean
  description?: ReactNode
};

const InputEin = React.forwardRef(
  (
    {name, className, value, placeholder, onChange, label, showOnly, error, required, description}: Props,
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    value = value.trim().replace(/[^\d]/g, '');
    const [isFocus, setIsFocus] = useState<boolean>(false);
    const invalidReason = useMemo(() => {
      if (error) {
        return error;
      }
      if (!value) {
        return null;
      } else if (value.length !== 9) {
        return 'This is not a valid EIN number';
      }
    }, [value, error]);

    const displayValue = useMemo(() => {
      let dv = value;
      if (dv.length < 3) return dv;
      return `${dv.slice(0, 2)}-${dv.slice(2, 9)}`;
    }, [value]);

    if (showOnly) {
      return <InputGroup>
        <div className={className}>{displayValue}</div>
      </InputGroup>
    }
    return (<>
        <div className="input-group">
          <Form.Control
            ref={ref}
            type="text"
            name={name}
            required={required}
            className={invalidReason ? classNames(className, 'is-invalid') : className}
            value={displayValue}
            placeholder={placeholder ? placeholder : 'XX-XXXXXXX'}
            onChange={(e) => {
              if (onChange) {
                // remove any non digits
                e.target.value = e.target.value.trim().replace(/[^\d]/g, '').slice(0, 9);
                onChange(e);
              }
            }}
            onBlur={() => {
              setTimeout(() => {
                setIsFocus(false);
              }, 200);
            }}
            onFocus={() => {
              setIsFocus(true)
            }}
          />
          {
            label &&
              <Form.Label className={classNames(value ? 'has-value' : '')}>
                  <span className={invalidReason ? 'text-danger' : ''}>{label}</span>
              </Form.Label>
          }
          {
            !showOnly && <>
              {
                invalidReason && <div className="border border-danger rounded-circle invalid-circle">
                      <FaExclamation className={'text-danger'} size={11}/>
                  </div>
              }
              {
                isFocus && value && name ?
                  <div className="cross-clear"
                       onClick={() => onChange && onChange({target: {value: ''}} as React.ChangeEvent<FormControlElement>)}>
                    <FaTimes size={20}/>
                  </div> : <div className="cross-clear-hide"></div>
              }
              {
                invalidReason ?
                  <div className="invalid-feedback">
                    {invalidReason}
                  </div>
                  :
                  null
              }
              </>
          }
        </div>
        {description && <small>{description}</small>}</>
    );
  }
);

export default InputEin;
