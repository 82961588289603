import getAdminApiPath from "@hornet-api/getAdminApiPath";
import axios from "axios";

export type FileProps = {
  taskId: number;
  fileId: number;
  completeName: string;
}

type FileData = {
  filename: string;
}

export const renameAttachedFile = async ({taskId, fileId, completeName}: FileProps) => {
  const url = `${getAdminApiPath()}/task/${taskId}/file/${fileId}/rename`
  return axios.post<FileData>(url, {filename: completeName}).then(res => res.data);
}