import {Column} from '../Types';
import _ from "lodash";

const checkColumns = <D>(tableName: string, columns: Column<D>[], data: D[]) => {
  // only run these checks when running locally
  if (window.location.hostname === 'localhost') {
    let errors: string[] = [];
    let usedIds: string[] = [];

    // check against first item
    columns.forEach((col) => {
      // check to make sure that field names exist in the data. Due to the render function this is often
      // overlooked and does not show up as a problem until user tries to export as it keys off this.
      if (data && data.length > 0 && col.field && !col.actionBar && col.field !== 'actions' && !col.ignoreColumnCheck) {
        const colFields = (col.field as string).split('|');
        colFields.forEach((field) => {
          if (!(field in data[0]) && !_.hasIn(data[0], field)) {
            errors.push(
              `Cannot find key "${col.field}" on Column ID "${col.id}" in provided data. Keys must exist in data or exports will break.`
            );
          }
        });
      }

      // check to make sure all ids are unique
      if (col.id !== null && usedIds.includes(col.id) && !col.actionBar) {
        errors.push(`Column ID: "${col.id}" is already used. Each ID must be unique.`);
      }
      usedIds.push(col.id);
    });

    if (errors.length > 0) {
      // only alert on development
      alert(`BootstrapSmartTableError - Table "${tableName}"\n\n${errors.join('\n\n')}`);
    }
  }
};

export default checkColumns;
