/**
 * Checks validity of the given routing number
 * Valid Example: 110000000
 * @param routingNumber
 */
export const validateRoutingNumber = (routingNumber: string) => {
  const routingRegex = RegExp('^((0[0-9])|(1[0-2])|(2[1-9])|(3[0-2])|(6[1-9])|(7[0-2])|80)([0-9]{7})$')
  const specialChars = /^\d+$/.test(routingNumber);
  if (routingNumber.length !== 9) {
    return 'Routing number must be 9 digits';
  }else if (!specialChars) {
    return 'Routing number must be numbers only';
  }else if (!routingRegex.test(routingNumber)) {
    return 'Routing number is invalid';
  }
  const check1 = 3 * (parseInt(routingNumber[0]) + parseInt(routingNumber[3]) + parseInt(routingNumber[6]));
  const check2 = 7 * (parseInt(routingNumber[1]) + parseInt(routingNumber[4]) + parseInt(routingNumber[7]));
  const check3 = parseInt(routingNumber[2]) + parseInt(routingNumber[5]) + parseInt(routingNumber[8]);
  const checkSum = (check1 + check2 + check3) % 10 === 0;
  if (!checkSum) {
    return 'Routing number is invalid';
  }
}