import axios from 'axios';
import getAdminApiPath from '../getAdminApiPath';
import DocumentRequest from '@interfaces/DocumentRequest';

const getDocuments = async (contactId: number | string) => {
	const url = `${getAdminApiPath()}/contact/${contactId}/documents`;
	const response: any = await axios.get(url);
	let docs: DocumentRequest[] = [...response.data.entityDocs, ...response.data.personalDocs, ...response.data.loanDocs];
	docs = docs.sort((a , b) => {
		if(a.dateCreated > b.dateCreated) return -1;
		if(a.dateCreated < b.dateCreated) return 1;
		return 0;
	})
	//@ts-ignore
	return docs.filter(x => x) as DocumentRequest[];
};

export default getDocuments;
