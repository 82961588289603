import axios from 'axios';
import getAdminApiPath from '../getAdminApiPath';
import {VoiceCallActionEnum} from "@interfaces/GeneratedEnums";

export interface VoiceCallState {
  callSid: string | undefined,
  recordSid: string | undefined,
  actionStatus: keyof typeof VoiceCallActionEnum
}

const updateVoiceCallActions = async (voiceCallLogId: number, data: VoiceCallState) => {
  const url = `${getAdminApiPath()}/voice/${voiceCallLogId}/actions`;
  const response = await axios.put(url, data);
  return response.data as VoiceCallState;
};

export default updateVoiceCallActions;
