import {atom} from 'recoil';
import AuthenticationBlob from "@interfaces/AuthenticationBlob";

export const defaultAuthenticationBlob = () => {
  return {
    active_user: null,
    impersonation_user: null,
  }
};

const authenticationBlobAtom = atom({
  key: 'appReadyStateAtom',
  default: defaultAuthenticationBlob() as AuthenticationBlob,
});

export default authenticationBlobAtom;
