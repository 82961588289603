import axios from 'axios';
import {SystemConfigServer} from '@interfaces/SystemConfig';
import getPublicApiPath from "@hornet-api/getPublicApiPath";

const getSystemConfigPublic = async (keyName: string) => {
  const url = `${getPublicApiPath()}/config/${keyName}`;
  const response = await axios.get(url);
  return response.data as SystemConfigServer;
};

export default getSystemConfigPublic;
