import React from 'react';
import BootstrapSmartTable from '@admin/components/BootstrapSmartTable';
import { Column } from '@components/BootstrapSmartTable';
import ReferralAdmin from '@interfaces/ReferralAdmin';
import Card from 'react-bootstrap/Card';
import numeral from 'numeral';
import updateReferral from '@hornet-api/ambassador/updateReferralAdmin';
import moment from 'moment';
import { Link } from 'react-router-dom';

type Props = {
  referrals: ReferralAdmin[];
  triggerRefresh: () => void;
  isLoading: boolean;
};

type Payment = {
  referralId: number;
  dateEarned: string;
  datePaid: string;
  referralFeeAmount: number;
  ambassador: {
    id: number;
    name: string;
  } | null;
};

const PaymentsTable = ({ referrals, triggerRefresh, isLoading }: Props) => {
  const payments: Payment[] = React.useMemo(() => {
    return referrals
      .map((referral) => {
        if (!referral.status || referral.status !== 'REFERRAL_FEE_PAID') {
          return null;
        }
        return {
          referralId: referral.id,
          dateEarned: referral.dateEarned ? moment(referral.dateEarned).format('MM/DD/YYYY') : '',
          datePaid: referral.datePaid ? moment(referral.datePaid).format('MM/DD/YYYY') : '',
          referralFeeAmount: Number(referral.referralFeeAmount ?? '0'),
          ambassador: referral.ambassador ?? null,
        };
      })
      .filter((x) => x);
  }, [referrals]) as Payment[];
  const columns: Column<Payment>[] = [
    {
      id: 'dateEarned',
      title: 'Earned',
      field: 'dateEarned',
      filter: true,
      date: true,
      onEdit: async (row) => {
        await updateReferral(row.referralId, { dateEarned: row.dateEarned });
        triggerRefresh();
      },
    },
    {
      id: 'datePaid',
      title: 'Date Paid ',
      field: 'datePaid',
      filter: true,
      date: true,
      onEdit: async (row) => {
        await updateReferral(row.referralId, { dateEarned: row.datePaid });
        triggerRefresh();
      },
    },
    {
      id: 'referralFeeAmount',
      title: 'Referral Fee Amount',
      field: 'referralFeeAmount',
      filter: true,
      render: (row) => (
        <>{row.referralFeeAmount ? numeral(row.referralFeeAmount).format('$0,0.00') : ''}</>
      ),
      onEdit: async (row) => {
        await updateReferral(row.referralId, { referralFeeAmount: row.referralFeeAmount });
        triggerRefresh();
      },
    },
    {
      id: 'ambassador',
      title: 'Ambassador',
      field: 'ambassador.name',
      filter: true,
      render: (row) =>
        row.ambassador?.id ? (
          <Link to={`/contact/show/${row.ambassador.id}/ambassador`}>{row.ambassador.name}</Link>
        ) : (
          ''
        ),
    },
  ];

  return (
    <>
      <Card>
        <Card.Header>
          <Card.Title>Payments</Card.Title>
        </Card.Header>
        <Card.Body>
          <BootstrapSmartTable
            name={'paymentsTable'}
            columns={columns}
            data={payments}
            // defaultFilters={[['taskStatus', 'PENDING']]}
            showFilter
            isLoading={isLoading}
            defaultHiddenColumns={['militaryStatus', 'militaryBranch']}
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default PaymentsTable;
