import { selector } from 'recoil';
import propertyCollectionAtom from './property-collection';
import selectedPropertyIdAtom from './selected-property-id';

const selectedPropertySelector = selector({
	key: 'selectedPropertySelector',
	default: null,
	get: ({ get }) => {
		const propertyList = get(propertyCollectionAtom);
		const propertyId = get(selectedPropertyIdAtom);

		if (propertyId === null) return null;

		return propertyList.find(o => o.id === propertyId);
	}
});

export default selectedPropertySelector;
