import { useRef, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { setAccessToken } from '@common/token';
import accessTokenSelectorAtom from './accessTokenSelectorAtom';

const useBearerTokenRef = () => {
	const bearerTokenRef = useRef();
	const token = useRecoilValue(accessTokenSelectorAtom);
	const state = useMemo(() => {
		setAccessToken(token);
		bearerTokenRef.current = token;
		return {
			bearerTokenRef,
			hasBearerToken: token !== null,
		};
	}, [bearerTokenRef, token]);
	return state;
};

export default useBearerTokenRef;
