import axios from 'axios';
import { getCleanBaseUri } from '@common/baseUri';
import RoleGroupRoles from '@interfaces/RoleGroupRoles';

const getUserRoles = async () => {
  const url = `${getCleanBaseUri()}/api/v1/meta/access`;
  const response = await axios.get(url);
  return (response.data as RoleGroupRoles[]).filter(x => !!x);
};

export default getUserRoles;
