import React from 'react';
import FontAwesomeIcon from "@admin/components/icons/FontAwesomeIcon";

import style from './NoData.module.scss';
import Wrapper from "@components/Indicators/Wrapper";

const NoData = () => {
	return (
		<Wrapper>
			<div className={style.centerMiddle}>
				<FontAwesomeIcon icon="file-o" size="2x"/><br/>
				No Data
			</div>
		</Wrapper>
	);
};

export default NoData;
