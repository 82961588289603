import {useEffect, useMemo, useState} from "react";
import {activateUserChat} from "@components/NotificationSidebar/utils";
import {NavigationObjectItem} from "@components/Navigation/getNavigationObject";
import getMessageByCategory from "@hornet-api/message/getMessageByCategory";
import Contact from "@interfaces/Contact";
import {useRecoilValue} from "recoil";
import profileAtom from "@state/recoil/profileAtom";

const getContactUsNavObject = () => {
  const [contactUsRaw, setContactUsRaw] = useState(null as string | null);
  const profile = useRecoilValue(profileAtom) as Contact;

  useEffect(() => {
    if (!profile) return;
    getMessageByCategory('CONTACT_US_DROPDOWN').then((res) => {
      setContactUsRaw(res.content);
    });
  }, [profile])

  return useMemo(() => {
    const obj: NavigationObjectItem = {
      text: 'Contact Us',
      id: 'contactUsDropdown',
      children: []
    };

    if (contactUsRaw) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(contactUsRaw, "text/html");
      const links = doc.querySelectorAll('li a');
      links.forEach((link) => {
        const childObj: NavigationObjectItem = {
          text: link.textContent ?? 'N/A',
          to: link.getAttribute('href') ?? '#',
        };
        const roles = link.className.split(' ').filter((className) => className.startsWith('ROLE_'));
        if (roles.length > 0) {
          childObj.roles = roles;
        }
        if (link.textContent?.toLowerCase().includes('chat now')) {
          childObj.onClick = () => {
            activateUserChat();
          }
        }
        // get target
        const target = link.getAttribute('target');
        if (target && target.length > 0) {
          childObj.target = target;
        }
        obj.children?.push(childObj);
      })
    }
    return obj;
  }, [contactUsRaw]);
}

export default getContactUsNavObject