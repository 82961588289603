import React, {useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import forgotUsername, {ForgotUsernameData} from '@hornet-api/auth/forgotUsername';
import {alertApiErrors} from '@common/errors';
import hornetCapitalLogo from '@assets/images/hornet_logo_regular.svg';
import {TextBox} from "@components/forms/react-hook-form-bootstrap";
import {SubmitErrorHandler, SubmitHandler, useForm} from "react-hook-form";
import {Button, Form} from "react-bootstrap";
import {onInvalidHandler} from "@components/forms/react-hook-form-bootstrap/utils";
import {loadingRelease, loadingTrigger} from "@components/LoadingOverlay";
import blueFooterImage from "@images/blue_footer_image.svg";
import {isEmailValid} from "@common/utils/validators";


const ForgotUsername = () => {
  const [submitted, setSubmitted] = useState(false);
  const firstFieldRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setTimeout(() => {
      if (firstFieldRef.current) {
        firstFieldRef.current.focus();
      }
    }, 500)
    reset({email: ''});
  }, []);

  const {
    handleSubmit,
    control,
    reset
  } = useForm<ForgotUsernameData>();

  const onInvalid: SubmitErrorHandler<ForgotUsernameData> = (errs) => {
    onInvalidHandler(errs)
  }

  const onSubmit: SubmitHandler<ForgotUsernameData> = async (data) => {
    const triggerId = loadingTrigger();
    try {
      await forgotUsername(data)
      setSubmitted(true);
    } catch (error) {
      alertApiErrors(error);
    } finally {
      loadingRelease(triggerId);
    }
  }
  return (
    <div className="forgot-username-container">
      <div className="container">
        <img src={hornetCapitalLogo} alt="Hornet Capital" className="logo"/>
        <Form className="login-form" autoComplete={'off'} noValidate onSubmit={handleSubmit(onSubmit, onInvalid)}>
          {
            submitted ?
              <h4 className={'text-center'}>Please Check Your Email</h4>
              :
              <>
                <h4 className={'text-center'}>USERNAME RECOVERY</h4>
                <small className={'text-center'}><strong>Please fill the form to recover your username</strong></small>
              </>
          }

          {
            submitted ?
              null :
              <>
                <p className={'mb-0 mt-4'}>Please provide your email. If valid, you will receive Username via email.</p>
                <TextBox
                  name={'email'}
                  control={control}
                  label={'Email'}
                  placeholder={'Please enter your email'}
                  rules={{
                    required: true,
                    validate: {
                      validEmail: (email?: string) => email && isEmailValid(email) ? true : 'Please provide a valid email address.'
                    }
                  }}
                  description={'Your registered email'}
                />
                <div className="text-center">
                  <Button
                    type="submit"
                    name="Recover Your Username"
                    variant={'warning'}
                    className="btn btn-block"
                  >Recover Your Username
                  </Button>
                </div>
              </>
          }


        </Form>
        <div className={'mt-5'}><Link to={'/login'}>Back to Login</Link></div>

      </div>
      <div className="footer" style={{backgroundImage: `url( ${blueFooterImage})`}}></div>
    </div>
  );
};

export default ForgotUsername;
