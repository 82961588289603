import axios from 'axios';
import getUserApiPath from '../getUserApiPath';
import {Address} from "@admin/api/profileInterfaces";
import {LoanStageEnum} from "@interfaces/GeneratedEnums";

type LenderAddress = Address & {
  loanStage: keyof typeof LoanStageEnum | null;
}

const getHornetLenderPropertiesList = () => {
  const url = `${getUserApiPath()}/contact/properties/hornetLender`;
  return axios.get<LenderAddress[]>(url).then(res => res.data)
};

export default getHornetLenderPropertiesList;