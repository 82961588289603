import { selector } from 'recoil';
import propertyCollectionAtom from './property-collection';
import propertySearchTermAtom from './property-search-term';

// filter abstracted
const propertySearchFilter = (searchTerm) => (property) => {
	// nothing entered
	if (!searchTerm || searchTerm.length === 0) return true;

	const lowerSearchTerm = searchTerm.toLowerCase();

	if (property.address1 && property.address1.toLowerCase().indexOf(lowerSearchTerm, 0) > -1) return true;

	if (property.address2 && property.address2.toLowerCase().indexOf(lowerSearchTerm, 0) > -1) return true;
	
	if (property.city && property.city.toLowerCase().indexOf(lowerSearchTerm, 0) > -1) return true;
	
	if (property.state && property.state.toLowerCase().indexOf(lowerSearchTerm, 0) > -1) return true;
	
	if (property.zip && property.zip.toLowerCase().indexOf(lowerSearchTerm, 0) > -1) return true;
	
	return false;
};

// the property list after search term filters have been applied
const filteredPropertyCollectionSelector = selector({
	key: 'filteredPropertyCollectionSelector',
	default: [],
	get: ({ get }) => {
		const searchTerm = get(propertySearchTermAtom);
		const propertyList = get(propertyCollectionAtom);
		return propertyList.filter(propertySearchFilter(searchTerm));
	},
});

export default filteredPropertyCollectionSelector;
