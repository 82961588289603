/* eslint-disable no-magic-numbers */
import { selector } from 'recoil';
import authenticationBlobAtom from './core/authenticationBlobAtom';

const activeUserSelectorAtom = selector({
	key: 'activeUserSelectorAtom',
	default: null,
	get: ({ get }) => {
		const authentication = get(authenticationBlobAtom);
		if (authentication.impersonation_user) return authentication.impersonation_user;
		return authentication.active_user;
	},
});

export default activeUserSelectorAtom;
