import React from 'react';
import {toast} from "react-toastify";

type AlertTypes = 'success' | 'danger' | 'warning' | 'info';
type AlertContent = React.ReactNode | string | JSX.Element;

export type AlertState = {
	type: AlertTypes;
	content: AlertContent;
}

export const addAlert = (alert: AlertState) => {
	// NOTE: We are keeping 'danger' to avoid making changes into many files
	toast(alert.content, {type: alert.type === 'danger' ? 'error' : alert.type, theme: "colored"});
}