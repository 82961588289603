import React from "react";

interface Props {
  failureCount?: number,
  maxFailureCount?: number,
}

const VerificationFailedWarning = ({failureCount = 0, maxFailureCount = 0}: Props) => {
  return (failureCount || 0) > 0 ?
    <i
      className={`d-block mb-3 ${(failureCount || 0) + 1 >= (maxFailureCount || 0) ? 'text-danger' : ''}`}>
      <small>
        Account will be locked after {maxFailureCount > failureCount ? maxFailureCount : (failureCount + 1)} invalid Code verification attempts.<br/>
        Your attempts: {failureCount}
      </small>
    </i>
    :
    null;
}

export default VerificationFailedWarning;