import React, { useMemo } from 'react';
import authenticationBlobState from '@state/globalState/authenticationBlobState';

const activeUserSelector = () => {
  const authenticationBlob = authenticationBlobState.useValue();
  return useMemo(() => {
    return authenticationBlob?.impersonation_user ?? authenticationBlob?.active_user ?? null;
  }, [authenticationBlob]);
};

export default activeUserSelector;
