import {IOption} from "@components/forms/intefaces";
import Contact from "@interfaces/Contact";
import Address from "@interfaces/Address";
import {CompactLoan} from "@interfaces/Loan";
import {formatDateForServer} from "@common/basic";
import {PayoffRequestReasonEnum} from "@interfaces/GeneratedEnums";

interface IPayoffRequestForm {
  requesterName: string;
  requesterEmail: string;
  subjectProperty: Address;
  loanAliasId: string;
  loanServicer: string;
  requestedPayoffDate: Date | null;
  payoffReason: keyof typeof PayoffRequestReasonEnum | null;
  primaryBorrower: string;
  selectAuthorisedSigner: IOption | null;
  authorisedSigner: {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
  }
  authorizedSignerTitle: string;
  escrowOfficer: IOption | null;
  newEscrowOfficer: {
    firstName: string;
    lastName: string;
    email: string
  } | null;
  buyerName: string | null;
  salePrice: number | null;
  otherReason: string | null;
}

interface NewEscrowOfficerCommand {
  firstName: string;
  lastName: string;
  email: string;
}

interface IPayoffRequestForContact {
  loanServicer: string;
  requestedPayoffDate: string;
  payoffReason: keyof typeof PayoffRequestReasonEnum;
  authorizedSignerTitle: string;
  escrowOfficer?: number;
  newEscrowOfficer?: NewEscrowOfficerCommand;
  authorisedSigner: {
    firstName: string;
    email: string;
  }
  buyerName: string | null;
  salePrice: number | null;
  otherReason: string | null;
}

interface ICompanyContacts {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  position: string;
  isAllowedToSignDocuments: boolean;
  hasUser: boolean;
}


const defaultFormData: IPayoffRequestForm = {
  requesterName: "",
  requesterEmail: "",
  subjectProperty: {
    addressType: 'MAILING',
    address1: '',
    address2: '',
    address3: '',
    city: '',
    state: '',
    zip: ''
  },
  loanAliasId: "",
  loanServicer: "Hornet Servicing",
  requestedPayoffDate: null,
  payoffReason: null,
  primaryBorrower: "",
  selectAuthorisedSigner: null,
  authorisedSigner: {
    id: -1,
    firstName: "",
    lastName: "",
    email: "",
  },
  authorizedSignerTitle: "",
  escrowOfficer: null,
  newEscrowOfficer: null,
  buyerName: null,
  salePrice: null,
  otherReason: null
}

const convertServerDataToFormDataForProfile = (profile: Contact | null, loan: CompactLoan): IPayoffRequestForm => {
  let escrowOfficer = null
  if (loan.escrowOfficers.length === 1) {
    escrowOfficer = {value: loan.escrowOfficers[0].id, label: loan.escrowOfficers[0].name}
  }
  return {
    ...defaultFormData,
    escrowOfficer: escrowOfficer,
    ...(loan.primaryAddress && {subjectProperty: loan.primaryAddress}),
    loanAliasId: loan.aliasId,
    loanServicer: loan.loanServicer || defaultFormData.loanServicer,
    authorizedSignerTitle: loan.contactSignerTitle,
    primaryBorrower: loan.primaryBorrower,
    ...(profile && {
      requesterName: profile.name,
      requesterEmail: profile.email,
      ...(!loan.isEscrowOfficer && {
        authorisedSigner: {
          id: profile.id,
          firstName: profile.firstName,
          lastName: profile.lastName,
          email: profile.email
        }
      })
    })
  }
}

const convertFormToServerDataForContact = (formData: IPayoffRequestForm): IPayoffRequestForContact => {
  return {
    authorizedSignerTitle: formData.authorizedSignerTitle,
    loanServicer: formData.loanServicer,
    payoffReason: formData.payoffReason!,
    requestedPayoffDate: formatDateForServer(formData.requestedPayoffDate),
    newEscrowOfficer: formData.escrowOfficer?.value === -1 && formData.newEscrowOfficer ? formData.newEscrowOfficer : undefined,
    escrowOfficer:formData. escrowOfficer?.value !== -1 ? formData.escrowOfficer?.value : undefined,
    authorisedSigner: formData.authorisedSigner,
    buyerName: formData.buyerName,
    salePrice: formData.salePrice,
    otherReason: formData.otherReason,
  }
}

export type {IPayoffRequestForm, IPayoffRequestForContact, ICompanyContacts}
export {
  defaultFormData,
  convertServerDataToFormDataForProfile,
  convertFormToServerDataForContact
}