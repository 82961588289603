import React, {useRef, useState} from 'react';
import isAdminState from "@state/globalState/isAdminState";
import {FaCircleNotch, FaCog} from 'react-icons/fa';
import Loading from '@components/Loading';
import style from './AdminMenu.module.scss';

import getAdminUsername from '@hornet-api/task/getAdminUsername';
import updateAdminName from '@hornet-api/task/updateAdminName';
import {Button, Form, Modal} from "react-bootstrap";
import {SubmitErrorHandler, SubmitHandler, useForm} from "react-hook-form";
import {onInvalidHandler} from "@components/forms/react-hook-form-bootstrap/utils";
import {TextBox} from "@components/forms/react-hook-form-bootstrap";
import {alertApiErrors} from "@common/errors";
import {addAlert} from "@components/Alert";

type AdminNameForm = {
  adminName: string | null
}
const AdminMenu = () => {
  const isAdmin = isAdminState.useValue();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const firstFieldRef = useRef<HTMLInputElement>(null);

  const {
    handleSubmit,
    control,
    reset
  } = useForm<AdminNameForm>();
  const onInvalid: SubmitErrorHandler<AdminNameForm> = (errs) => {
    onInvalidHandler(errs)
  }
  const onSubmit: SubmitHandler<AdminNameForm> = async (data: AdminNameForm) => {
    if (!data.adminName || isSubmitting) return;
    setIsSubmitting(true);
    try {
      await updateAdminName(data.adminName);
      addAlert({type: "success", content: "Display name updated successfully."})
    } catch (e) {
      alertApiErrors(e);
    } finally {
      setIsSubmitting(false);
      setIsOpen(false);
    }
  }
  const refreshName = async () => {
    setIsLoading(true);
    try {
      const res = await getAdminUsername();
      reset({adminName: res.name});
    } catch (e) {
      alertApiErrors(e);
    } finally {
      setIsLoading(false);
    }
  }

  const handleClose = () => {
    setIsOpen(false);
  };

  if (!isAdmin) {
    return null;
  }

  return (<>
    <div
      className={style.buttonBox}
      onClick={() => {
        setIsOpen(!isOpen);
      }}
    >
      <FaCog/>
    </div>
    <Modal
      show={isOpen}
      onHide={handleClose}
      id={'attach-file-task-modal'}
      onEnter={() => {
        refreshName();
      }}
      onEntered={() => {
        setTimeout(() => {
          if (firstFieldRef.current) {
            firstFieldRef.current.focus();
          }
        }, 300)
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Admin Settings
        </Modal.Title>
      </Modal.Header>
      <Form noValidate onSubmit={handleSubmit(onSubmit, onInvalid)}>
        <Modal.Body>
          {
            isLoading ?
              <div className={style.loadingBox}>
                <Loading/>
              </div>
              :
              <TextBox
                name={'adminName'}
                control={control}
                label={'Display Name'}
                rules={{required: true}}
              />
          }
        </Modal.Body>
        <Modal.Footer>

          <Button
            variant="primary"
            disabled={isLoading || isSubmitting}
            type={'submit'}
          >
            {isSubmitting ? <FaCircleNotch className='spin'/> : 'Save'}
          </Button>

          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>

      </Form>

    </Modal>
  </>);
}

export default AdminMenu;