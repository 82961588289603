import React, {CSSProperties, useMemo} from "react";
import {NavigationObjectItem} from "@components/Navigation/getNavigationObject";
import Dropdown from "react-bootstrap/Dropdown";
import Nav from "react-bootstrap/Nav";
import NavLink from "@components/Navigation/NavLink";
import NavDropdown from "react-bootstrap/NavDropdown";
import {
  DropdownNavigationTitle,
  roleBasedChildrenRenderer
} from "@components/Navigation/Desktop/NavigationMenuRenderer";
import activeRoleState from "@state/globalState/activeRoleState";
import userRoleGroupsState from "@state/globalState/userRoleGroupsState";
import './SubMenuLink.scss';

interface NavItemProps {
  nav: NavigationObjectItem,
  Component: typeof Dropdown.Item | typeof Nav.Link
}

const styles: { [p: string]: CSSProperties } = {
  notificationBadge: {
    position: "absolute",
    top: "15px",
    width: "18px",
    height: "18px",
    lineHeight: "18px",
    borderRadius: "50%",
    display: "inline-block",
    textAlign: "center",
    fontWeight: "normal",
    fontSize: "10px",
  }
}

const NavItemDesktop = (props: NavItemProps) => {
  const {nav, Component} = props;
  const activeRole = activeRoleState.useValue();
  const userRoleGroups = userRoleGroupsState.useValue();

  const roleBasedChildren = useMemo(() => {
    return roleBasedChildrenRenderer(nav, activeRole, userRoleGroups);
  }, [nav, userRoleGroups, activeRole]);

  if (nav.children?.length) {
    return (
      <NavDropdown show onClick={() => {
      }} className={"subMenuLink"} title={
        <DropdownNavigationTitle nav={nav}/>} id={nav.id + "-" + nav.text}>
        {roleBasedChildren}
      </NavDropdown>
    )
  } else {
    return (
      <NavLink
        Component={Component}
        nav={nav}
        classes={{CounterBadge: 'p-0'}}
        styles={{
          Component: {position: 'relative'},
          CounterBadge: styles.notificationBadge
        }}
      />
    )
  }
}

export default NavItemDesktop;