import { selector } from 'recoil';
import activeUserRolesSelectorAtom from './activeUserRolesSelectorAtom';

const isUserRoleSelectorAtom = selector({
	key: 'isUserRoleSelectorAtom',
	default: false,
	get: ({ get }) => {
		const roles = get(activeUserRolesSelectorAtom);
		return roles.includes('ROLE_USER');
		// return true;
	},
});

export default isUserRoleSelectorAtom;
