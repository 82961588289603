import React, { useCallback, useMemo } from 'react';
import shouldRespondToKeyPress from './shouldRespondToKeyPress';

const Pressable = ({
	data,
	children,
	onClick,
	onKeyPress,
	ariaLabel,
	style,
	disabled = false,
	...props
}) => {

	const clickEnabled = useMemo(() => (
		!disabled && typeof onClick === 'function'
	), [disabled, onClick]);

	const keyPressEnabled = useMemo(() => (
		!disabled && typeof onKeyPress === 'function'
	), [disabled, onKeyPress]);

	const interfaceEnabled = useMemo(() => (
		clickEnabled || keyPressEnabled
	), [clickEnabled, keyPressEnabled]);

	const containerStyle = useMemo(() => ({
		...(style || {}),
		cursor: clickEnabled ? 'pointer' : 'default',
	}), [clickEnabled, style]);

	const handleOnClick = useCallback((event) => {
		if (event.preventDefault) event.preventDefault();
		if (event.stopPropagation) event.stopPropagation();
		
		if (clickEnabled) onClick(data);
		
	}, [onClick, data, clickEnabled]);

	const handleOnKeyPress = useCallback((event) => {
		if (shouldRespondToKeyPress(event.which)) {
			if (event.preventDefault) event.preventDefault();
			if (event.stopPropagation) event.stopPropagation();

			if (clickEnabled) onClick(data);
			return true;
		}
		if (keyPressEnabled && typeof onKeyPress === 'function') {
			if (event.persist) event.persist();
			return onKeyPress(event, data);
		}
	}, [onClick, onKeyPress, data, clickEnabled, keyPressEnabled]);

	return (
		<div
			role="button"
			tabIndex={0}
			aria-label={ariaLabel}
			{...props}
			style={containerStyle}
			disabled={!interfaceEnabled}
			aria-disabled={!interfaceEnabled}
			onClick={handleOnClick}
			onKeyPress={handleOnKeyPress}
		>
			{children}
		</div>
	);
};

export default Pressable;
