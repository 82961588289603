/* eslint-disable no-magic-numbers */
import React, { useEffect } from 'react';
import { useSetRecoilState, useRecoilState, useRecoilValue } from 'recoil';
import * as Auth from '@server-api/index';
import userStateReadyAtom from '@state/recoil/userStateReadyAtom';
import authenticationBlobAtom, { defaultAuthenticationBlob } from '@state/recoil/authentication/core/authenticationBlobAtom';
import activeRoleState from "@state/globalState/activeRoleState";
import configurationAtom from "@state/recoil/configurationAtom";
import authenticationBlobState from "@state/globalState/authenticationBlobState";
import userRoleGroupsState from "@state/globalState/userRoleGroupsState";
import getUserRoleGroups from "@hornet-api/getUserRoleGroups";

const UserStateLoader = () => {
	const setReady = useSetRecoilState(userStateReadyAtom);
	const [authenticationBlob, setAuthenticationBlobAtom] = useRecoilState(authenticationBlobAtom);
	const [activeRole, setActiveRole] = activeRoleState.use();
	const configuration = useRecoilValue(configurationAtom);
	const [userRoleGroups, setUserRoleGroups] = userRoleGroupsState.use();

	useEffect(() => {
		authenticationBlobState.set(authenticationBlob);
	}, [authenticationBlob]);

	useEffect(() => {
		Auth.getUser().then(async (user) => {
			if (!user || user.length === 0) return Promise.reject(new Error('No Active User'));
			const active_user = user.length === 2 ? user[1] : user[0];
			const impersonation_user = user.length === 2 ? user[0] : null;
			setAuthenticationBlobAtom((state) => ({
				...state,
				active_user,
				impersonation_user,
			}));
			setReady(true);
		}).catch(() => {
			setAuthenticationBlobAtom(defaultAuthenticationBlob());
			setActiveRole(null);
			setReady(true);
		});
	}, [setReady, setAuthenticationBlobAtom, setActiveRole]);

	// get user roles when we have user and configuration
	useEffect(() => {
	if(configuration && userRoleGroups === null && authenticationBlob.active_user) {
		getUserRoleGroups().then((groups) => {
			setUserRoleGroups(groups);
		}).catch((e) => {
			console.log(e);
			setUserRoleGroups(null);
		});
	}
	}, [configuration, userRoleGroups, authenticationBlob]);

	return null;
};

export default React.memo(UserStateLoader);
