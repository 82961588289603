export const getRawCursorPos = (str: string, cursorPos: number, regEx: RegExp) => {
  let rawPos = 0;
  if (!cursorPos) return 0;
  for (let i = 0; i < str.length; i++) {
    if (!regEx.test(str[i])) {
      rawPos++;
    }
    if (i === cursorPos - 1) return rawPos;
  }
  return rawPos;
};

export const getNewCursorPos = (str: string, rawCursorPos: number, regEx: RegExp) => {
  let rawPos = 0;
  for (let i = 0; i < str.length; i++) {
    if (!regEx.test(str[i])) {
      rawPos++;
    }
    if (rawPos === rawCursorPos) return i + 1;
  }
  return str.length;
};
