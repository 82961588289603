import { disableNumberScroll } from '@common/basic';

const setupCopyFixer = () => {
  //@ts-ignore
  if (!window.copyListnerAdded) {
    document.addEventListener('copy', (event) => {
      const selection = document.getSelection();
      try {
        // remove tabs and trim
        //@ts-ignore
        const newD = selection?.toString().trim();
        console.log({
          copied: selection?.toString(),
          replacing: newD,
        });
        //@ts-ignore
        event.clipboardData.setData('text/plain', newD);
        event.preventDefault();
      } catch (e) {
        console.log(e);
      }
    });
    //@ts-ignore
    window.copyListnerAdded = true;

    disableNumberScroll();
  }
};

export default setupCopyFixer;
