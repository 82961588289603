import axios from 'axios';
import getAdminApiPath from "@hornet-api/getAdminApiPath";
import {addAlert} from "@components/Alert";

const deleteTaskAdmin = (taskId: number) => {
  const url = `${getAdminApiPath()}/task/${taskId}`;

  return axios.delete(url).then((res => {
    addAlert({
      type: 'success',
      content: 'Task has been successfully deleted.'
    })
  }));
};

export default deleteTaskAdmin;
