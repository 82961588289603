export type WiringInstruction = {
  name: string
  abaRouting: string,
  checkingAccount: string,
  forCreditTo: string,
  bankName: string,
  download: string,
}

export type WiringObject = {
  sub: WiringInstruction,
  lpa: WiringInstruction
}

export const wiringObject: WiringObject = {
  // @ts-ignore
  sub: { // subscription agreement
    name: 'Subscription Account',
    download: 'https://hornet-properties.sfo3.cdn.digitaloceanspaces.com/Documents/HC%20SUBSCRIPTION%204000%20-%20PlainsCapital%20ACH%20&%20Wire%20Instructions%20-%20Hornet%20CAPITAL.pdf',
  },
  //@ts-ignore
  lpa: {
    name: 'Partnership Account',
    download: 'https://hornet-properties.sfo3.cdn.digitaloceanspaces.com/Documents/HC%20SUBSCRIPTION%204000%20-%20PlainsCapital%20ACH%20&%20Wire%20Instructions%20-%20Hornet%20CAPITAL.pdf',

  }
}