/* eslint-disable no-magic-numbers */
import axios from 'axios';
import getUserApiPath from '../getUserApiPath';
import BankAccountWithOwner from '@interfaces/BankAccountWithOwner';
import {payerTokenState} from "@pages/OneTimeLoanPaymentPage/global";
import getPublicApiPath from "@hornet-api/getPublicApiPath";

const getBankAccount = async (id: number | string) => {
	const token = payerTokenState.get();
	const fund_url = token ? `${getPublicApiPath()}/loanPayment/oneTime/${encodeURIComponent(token)}/bankAccount/${id}` : `${getUserApiPath()}/contact/bankAccount/${id}`;
	const response = await axios.get(fund_url);
	return response.data as BankAccountWithOwner;
};

export default getBankAccount;
