/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-no-bind */
import React, { useState, useRef } from 'react';
import { objCopy } from './lib/basic';
import FontAwesomeIcon from './lib/FontAwesomeIcon';

export type MultiSelectOption = {
	label: string,
	value: string | number
}

type MultiSelectProps = {
	options: MultiSelectOption[],
	value: MultiSelectOption[],
	align?: 'right' | 'left',
	onChange: (newValue: MultiSelectOption[]) => void
}

export default function MultiSelect(props: MultiSelectProps) {
	const {options, value, onChange} = props;
	const searchBoxRef = useRef<HTMLInputElement>(null);
	const dropDownRef = useRef<HTMLDivElement>(null);
	const align = props.align ? props.align : 'left';
	const [isOpen, setIsOpen] = useState(false);
	const [selectAll, setSelectAll] = useState(false);
	const [elemId] = useState(`${Math.floor(Math.random() * 10001)}`);
	const [searchString, setSearchString] = useState('');
	return (
		<div
			className="multi-select input-group"
			style={{
				marginRight: value.length > 0 ? 31 : 0
			}}
		>
			<div
				className="ms-text"
				onClick={() => {
					if (!isOpen) {
						if (searchBoxRef && searchBoxRef.current) {
							setTimeout(() => {
								searchBoxRef.current?.focus();
							}, 200);
						}
						setIsOpen(true);
						const listenerFunction = (event: MouseEvent) => {
							const isClickInside = dropDownRef.current?.contains(event.target as Node);
							if (!isClickInside) {
								setIsOpen(false);
								document.removeEventListener('click', listenerFunction);
							}
						};
						// remove any lingering listeners
						document.removeEventListener('click', listenerFunction);
						// bind un-click listener
						if (dropDownRef.current) {
							// timeout needed to prevent double firing
							setTimeout(() => {
								document.addEventListener('click', listenerFunction);
							}, 500);
						}
					}
				}}
			>
				{
					value.map(v => v.label).join('|')
				}
			</div>
			<div className="caret">
				<FontAwesomeIcon icon="caret-down" />
			</div>
			{value.length > 0 ?
				<div style={{height: "inherit", top: -1}} className="ms-clear-btn input-group-append bg-white">
					<span
						className="input-group-text bg-transparent border-left-0"
						onClick={() => {
							setSelectAll(false);
							setIsOpen(false);
							onChange([]);
						}}
					>
						<FontAwesomeIcon icon="times"/>
					</span>
				</div>
				:
				null
			}
			<div
				className="ms-dropdown"
				ref={dropDownRef}
				style={{
					display: isOpen ? 'block' : 'none',
					right: align === 'right' ? 0 : 'auto',
					left: align === 'left' ? 0 : 'auto',
				}}
			>
				<div className="ms-search">
					<div className="input-group">
						<input
							className="form-control form-control-sm border-right-0"
							type="text"
							ref={searchBoxRef}
							value={searchString}
							onChange={(event) => {
								setSearchString(event.target.value);
							}}
						/>
						<div className="input-group-append bg-white border-left-0">
							<span
								className="input-group-text bg-transparent"
								onClick={() => {
									setSearchString('');
								}}
							>
								<FontAwesomeIcon icon="times"/>
							</span>
						</div>
					</div>
				</div>
				<div className="ms-selects">
					{searchString === '' ?
						<>
							<div className="form-check">
								<input
									type="checkbox"
									className="form-check-input"
									id={`col-sel${elemId}-check-all`}
									checked={selectAll}
									onChange={(event) => {
										if (event.target.checked) {
											const newValue = objCopy(options);
											setSelectAll(true);
											onChange(newValue);
										} else {
											setSelectAll(false);
											onChange([]);
										}
									}}
								/>
								<label
									className="form-check-label"
									htmlFor={`col-sel${elemId}-check-all`}
								>
									Select All
								</label>
							</div>
							<hr />
						</>
						:
						null
					}
					{
						options.map((option, index) => {
							if (
								option.label.toLowerCase().includes(searchString.toLowerCase())
							) {
								return (
									<div className="form-check" key={index}>
										<input
											type="checkbox"
											className="form-check-input"
											id={`col-sel${elemId}-check${index}`}
											checked={value.some(v => v.label === option.label && v.value === option.value)}
											value={option.value}
											onChange={(event) => {
												if (event.target.checked) {
													const newValue = objCopy(value);
													newValue.push(objCopy(option));
													onChange(newValue);
												} else {
													let newValue = objCopy(value);
													newValue = newValue.filter(x => x.value !== option.value && x.label !== option.label);
													onChange(newValue);
													setSelectAll(false);
												}
											}}
										/>
										<label
											className="form-check-label"
											htmlFor={`col-sel${elemId}-check${index}`}
										>
											{option.label}
										</label>
									</div>
								);
							}
							return null;
						})
					}
				</div>
			</div>
		</div>
	);
}
