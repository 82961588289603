import { atom } from 'recoil';

// raw property collection state. should only
// be used directly by things setting the
// entire collection. should not be read from
// by anything other than other atom selectors
const propertyCollectionAtom = atom({
	key: 'propertyCollectionAtom',
	default: [],
});

export default propertyCollectionAtom;
