import axios from 'axios';
import { getBaseUri } from '@common/baseUri';

export type ForgotPasswordData = {
  username: string,
}

const forgotPassword = async (data:ForgotPasswordData) => {
	const url = `${getBaseUri()}api/public/forgotPassword`;
	const response = await axios.post(url, data);
	return response.data;
};

export default forgotPassword;
