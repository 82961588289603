import getAdminApiPath from "@hornet-api/getAdminApiPath";
import axios from "axios";
import {AssociatedLoanForPropertyServer} from "@interfaces/Loan";

export const associatedLoanForProperty = async (propertyId: number | string) => {
  const url = `${getAdminApiPath()}/property/${propertyId}/loans`;
  const response = await axios.get(url);
  return response.data as AssociatedLoanForPropertyServer[];
};
export const updateAssociatedLoanForPropertyPosition = async (propertyId: number | string, deedOfTrustLienId: number, newPosition: number) => {
  const url = `${getAdminApiPath()}/property/${propertyId}/loans/${deedOfTrustLienId}/updatePosition`;
  const response = await axios.patch(url, {position: newPosition});
  return response.data as AssociatedLoanForPropertyServer[];
};