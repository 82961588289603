import axios, {AxiosResponse} from 'axios';
import getAdminApiPath from '@hornet-api/getAdminApiPath';
import AmbassadorAdmin from '@interfaces/AmbassadorAdmin';

const getAmbassadorListAdmin = async (): Promise<AmbassadorAdmin[]> => {
  const url = `${getAdminApiPath()}/ambassador/ambassadors`;
  const response: AxiosResponse<AmbassadorAdmin[]> = await axios.get(url);
  return response.data;
};

export default getAmbassadorListAdmin;
