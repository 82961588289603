import React from 'react';
import style from './NotFoundView.module.scss';

const NotFoundView = () => {
	return (
		<div className={style.container}>
			Not Found
		</div>
	);
};

export default NotFoundView;
