import React, {useMemo} from 'react';
import {Column} from './index';
import Store from './globalState';
import SettingsSaver from './lib/SettingsSaver';
import FontAwesomeIcon from './lib/FontAwesomeIcon';
import style from './style.module.scss';

type TableHeadProps<D> = {
  columns: Column<D>[],
  store: Store<D>,
  settingsSaver: SettingsSaver<D>
  hasExpandable?: boolean;
}

function arrayMove(arr: unknown[], oldIndex: number, newIndex: number) {
  const newArr = [...arr];
  if (newIndex >= newArr.length) {
    let k = newIndex - newArr.length + 1;
    while (k--)
      newArr.push(undefined);

  }
  newArr.splice(newIndex, 0, newArr.splice(oldIndex, 1)[0]);
  return newArr; // for testing
}

export default function TableHead<D>(props: TableHeadProps<D>) {
  const {columns, store, settingsSaver, hasExpandable} = props;
  const [hiddenColumns] = store.hiddenColumnsState.use();

  const setEditField = store.editFieldState.set;
  const [sortKey, setSortKey] = store.sortKeyState.use();
  const [sortAscending, setSortAscending] = store.sortAscendingState.use();
  const [columnOrder, setColumnOrder] = store.columnOrderState.use();
  const actionColIndex = columns.findIndex(x => x.actionBar === true);
  const actionCol = actionColIndex >= 0 ? columns[actionColIndex] : null;

  const visibleColumnOrderIndexes = useMemo(() => (
    columnOrder.reduce((prev, next) => {
      if (!(hiddenColumns.includes(next))) {
        return [
          ...prev,
          next,
        ];
      }
      return prev;
    }, [] as number[])
  ), [columnOrder, hiddenColumns, columns]);

  const columnsComponents = useMemo(() => (
    columnOrder.map((index, orderIndex) => {
      const col = columns[index];
      if (col.actionBar) {
        return null;
      }
      if (!(hiddenColumns.includes(index))) {
        return (
          <th key={index} style={{position: 'relative'}}>
            <div
              className="th-body"
              style={{
                cursor: col.field ? 'pointer' : 'default',
                textAlign: col.align ? col.align : 'left',
              }}
              onClick={() => {
                if (col.field) {
                  let sortAsc = sortAscending;
                  if (col.field === sortKey) {
                    sortAsc = !sortAscending
                    setSortAscending(sortAsc);
                    setEditField(null);
                  } else {
                    setSortAscending(true);
                    setSortKey(col.field);
                    setEditField(null);
                  }
                  props.store.selectedPresetIdState.set('');
                }
              }}
            >
              {col.title}
              {
                col.field === sortKey ?
                  <div
                    style={{
                      position: 'absolute',
                      right: 7,
                      bottom: 0,
                      transform: 'translateY(-50%)',
                      fontSize: '0.8rem',
                      opacity: 0.8

                    }}
                  >
                    <FontAwesomeIcon fixedWidth icon={sortAscending ? 'sort-amount-asc' : 'sort-amount-desc'}/>
                  </div>
                  :
                  null
              }
            </div>
            <div
              className="head-tools"
            >
              <div
                className="head-tool-btn"
                onClick={() => {
                  // the complexity of this is to move backwards past hidden columns
                  const currentVisibleColumnOrderIndex = visibleColumnOrderIndexes.indexOf(index);
                  const newVisibleColumnOrderIndex = Math.max(0, currentVisibleColumnOrderIndex - 1);
                  const newIndex = columnOrder.indexOf(visibleColumnOrderIndexes[newVisibleColumnOrderIndex]);
                  const newColumnOrder = arrayMove(columnOrder, orderIndex, newIndex) as number[];
                  setColumnOrder(newColumnOrder);
                  props.store.selectedPresetIdState.set('');
                }}
              >
                <FontAwesomeIcon icon="chevron-left"/>
              </div>
              <div style={{flexGrow: 1}}/>
              <div
                className="head-tool-btn"
                onClick={() => {
                  // find the next visible index spot
                  const currentVisibleColumnOrderIndex = visibleColumnOrderIndexes.indexOf(index);
                  const newVisibleColumnOrderIndex = Math.min(visibleColumnOrderIndexes.length - 1, currentVisibleColumnOrderIndex + 1);
                  const newIndex = columnOrder.indexOf(visibleColumnOrderIndexes[newVisibleColumnOrderIndex]);
                  const newColumnOrder = arrayMove(columnOrder, orderIndex, newIndex) as number[];
                  setColumnOrder(newColumnOrder);
                  props.store.selectedPresetIdState.set('');
                }}
              >
                <FontAwesomeIcon icon="chevron-right"/>
              </div>
            </div>
          </th>
        );
      }
      return null;
    })
  ), [columnOrder, hiddenColumns, columns, sortKey, settingsSaver, sortAscending, visibleColumnOrderIndexes, setColumnOrder, setEditField, setSortAscending, setSortKey]);
  return (
    <thead>
    <tr>
      {hasExpandable && <th key={'expandable-header'} style={{width: 0}}></th>}
      {columnsComponents}
      {actionCol ? <th key={'action-header'} className={style.actionCell}/> : null}
    </tr>
    </thead>
  );
}

