import React, {useEffect} from 'react';
import uiSelector from "@state/globalState/uiSelector";
import isAdminState from "@state/globalState/isAdminState";

const UserLoader = () => {
  const ui = uiSelector();

  // handle isAdmin boolean
  useEffect(() => {
    if(ui === 'ADMIN') {
      if(!isAdminState.get()) {
        isAdminState.set(true);
      }
    } else {
      if(isAdminState.get()) {
        isAdminState.set(false);
      }
    }
  }, [ui]);

  return <></>;
};

export default UserLoader;
