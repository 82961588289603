import React from "react";

interface Props {
  maxResendsPerLock?: number;
  resendLockHours?: number;
  resendCount?: number;
}

const OtpResendWarning = ({maxResendsPerLock = 0, resendLockHours = 0, resendCount = 0}: Props) => {
  if (!maxResendsPerLock) {
    return null;
  }

  return (
    <i className={'d-block mb-3'}>
      <small>
        You can request your Authorization Code {maxResendsPerLock} times in a row.
        <br/>
        After the last attempt, you will have to wait for {resendLockHours} hours
        {
          resendCount > 0 &&
          <span
            className={resendCount + 1 >= (maxResendsPerLock || 0) ? 'text-danger' : ''}
          >
            <br/>
            Your resend-attempts: {resendCount}
          </span>
        }
      </small>
    </i>
  )
}

export default OtpResendWarning;