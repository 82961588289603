import axios from 'axios';
import getAdminApiPath from "@hornet-api/getAdminApiPath";
import convertJsonToFormData from "@common/utils/form/convertJsonToFormData";
import {IDrawRequestServerData} from "@admin-ui/pages/Budget/components/DrawRequest/constant";
import {DrawRequestStatusEnum, PaymentMethodEnum} from "@interfaces/GeneratedEnums";

type AmountUpdate = {
  amount: number;
}

type StatusUpdate = {
  status: keyof typeof DrawRequestStatusEnum;
}

type DescriptionUpdate = {
  description: string;
}

type LineItemCategoryUpdate = {
  lineItemId: number;
}

type FileUpdate = {
  documentsToUpload: File[];
}

type PaymentMethod = {
  paymentMethod: keyof typeof PaymentMethodEnum;
}

type Param =
  AmountUpdate
  | StatusUpdate
  | DescriptionUpdate
  | IDrawRequestServerData
  | LineItemCategoryUpdate
  | PaymentMethod
  | FileUpdate;

interface IDrawRequestBeforeApprovalResponse {
  message: string;
}

export const getWarningBeforeApproval = async (drawRequestId: number) => {
  const url = `${getAdminApiPath()}/drawRequest/${drawRequestId}/beforeApproval`;
  return axios.get<IDrawRequestBeforeApprovalResponse>(url).then(res => res.data.message);
}

export const updateDrawRequest = async (id: number, data: Param) => {
  const url = `${getAdminApiPath()}/drawRequest/${id}`;
  const formData = convertJsonToFormData(data as IDrawRequestServerData);

  return axios.patch(url, formData);
};