import Modal from "react-bootstrap/Modal";
import {Form} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import React, {useEffect, useState} from "react";
import {createGlobalState} from "react-global-hooks";
import getMessageByCategory from "@hornet-api/message/getMessageByCategory";
import "./WireInstructionEmbed.scss"

interface WireInstructionModalState {
  show?: boolean
  onCancel?: () => Promise<void> | void
}

export const wireInstructionModalState = createGlobalState(null as null | WireInstructionModalState);

export const openWireInstructionModalState = (modalState: WireInstructionModalState) => {
  wireInstructionModalState.set(modalState);
};
const WireInstructionModal = () => {
  const [modalState, setModalState] = wireInstructionModalState.use();
  const [htmlText, setHtmlText] = useState<string>("");

  const handleClose = () => {
    modalState!.onCancel?.();
    setModalState(null);
  };

  useEffect(() => {
    getMessageByCategory("EDIT_WIRE_INSTRUCTIONS_MODAL").then((res) => {
      setHtmlText(res.content);
    })
  }, []);

  return (<Modal
    show={!!modalState}
    onHide={handleClose}
    id={'addEditVendorCompanyModal'}
    size={'lg'}
  >
    <Modal.Header closeButton>
      <Modal.Title>Wire Instructions</Modal.Title>
    </Modal.Header>
    <Form noValidate>
      <Modal.Body className={'position-relative change-video-size'}>
        <div dangerouslySetInnerHTML={{__html: htmlText}}></div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Form>
  </Modal>)
}

export default WireInstructionModal;