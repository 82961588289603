import { selectorFamily } from 'recoil';
import propertyCollectionAtom from './property-collection';

// property item by id subscriptions
const propertyByIdSelector = selectorFamily({
	key: 'propertyByIdSelector',
	get: propertyId => ({ get: getRecoilValue }) => {
		const properties = getRecoilValue(propertyCollectionAtom) || [];
		return properties.find(p => p.id === propertyId);
	},
	set: propertyId => ({ get: getRecoilValue, set: setRecoilValue }, newValue) => {
		const properties = getRecoilValue(propertyCollectionAtom) || [];
		const itemIndex = properties.findIndex(p => p.id === propertyId);
		if (itemIndex >= 0) {
			const property = {...(properties[itemIndex])};
			let nextValue = newValue;
			if (typeof nextValue === 'function') {
				nextValue = newValue(property);
				if (nextValue === undefined) throw new Error('Returned state from an atom can not be undefined');
			}

			if (nextValue === null) {
				const newProperties = [...properties];
				newProperties.splice(itemIndex, 1);
				setRecoilValue(propertyCollectionAtom, newProperties);
				return;
			}
			const nextState = [...properties];
			nextState.splice(itemIndex, 1, {...nextValue});
			setRecoilValue(propertyCollectionAtom, nextState);
		}
	},
});

export default propertyByIdSelector;
