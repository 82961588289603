import { selector } from 'recoil';
import activeUserSelectorAtom from './activeUserSelectorAtom';

const hasAuthenticatedSelectorAtom = selector({
	key: 'hasAuthenticatedSelectorAtom',
	default: false,
	get: ({ get }) => {
		const user = get(activeUserSelectorAtom);
		if (!user) return false;
		return true;
	},
});

export default hasAuthenticatedSelectorAtom;
