import React, {useCallback} from 'react';
import {windowIsMobileState} from '@state/globalState/windowSize';
import BootstrapSmartTable from "@components/BootstrapSmartTable";
import {getColumns} from "./tableData"
import {loadingRelease, loadingTrigger} from "@components/LoadingOverlay";
import {addAlert} from "@components/Alert";
import changeAnnouncementReadStatus from "@hornet-api/chat/changeAnnouncementReadStatus";
import {alertApiErrors} from "@common/errors";
import {Button} from "react-bootstrap";
import {
  announcementsState,
  fetchOnlyUnreadState,
  isLoadingAnnouncementsState,
  refreshAnnouncementsState
} from "@components/NotificationSidebar/globalState";
import style from './AnnouncementView.module.scss'
import classNames from "classnames";

const AnnouncementView = () => {
  const isMobile = windowIsMobileState.useValue();
  const announcements = announcementsState.useValue();
  const [fetchOnlyUnread, setFetchOnlyUnread] = fetchOnlyUnreadState.use();
  const isLoading = isLoadingAnnouncementsState.useValue();

  // If ID is not given, then it is going to work for all the announcements
  const updateAnnouncementReadStatus = useCallback((id: number | null, isRead: boolean) => {
    const t = loadingTrigger();
    changeAnnouncementReadStatus(id, isRead)
      .then(() => {
        refreshAnnouncementsState.set(Date.now());
        addAlert({
          type: 'success',
          content: `Announcement${id ? '' : 's'} successfully marked as ` + (isRead ? "Read" : 'Unread')
        });
      })
      .catch(alertApiErrors)
      .finally(() => loadingRelease(t));
  }, [fetchOnlyUnread, announcements])

  return (
    <>
      <section className="content-header">
        <div className="row">
          <div className="col">
            <div className="header-title">
              <h1>Announcements</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="row">
          <div className="col-12">
            <div className={isMobile ? '' : 'card'}>
              <div className="card-header">
                <div className={'row'}>
                  <div className={'col-sm-12 col-md-8'}>
                    <Button
                      variant="link"
                      className={'mr-2'}
                      onClick={() => updateAnnouncementReadStatus(null, true)}
                    >
                      Mark all as Read
                    </Button>
                    <Button
                      variant="link"
                      className={'mr-2'}
                      onClick={() => updateAnnouncementReadStatus(null, false)}
                    >
                      Mark all as Unread
                    </Button>
                  </div>
                  <div className={'col-sm-12 col-md-4 text-right'}>
                    <Button
                      variant="primary"
                      onClick={() => {
                        setFetchOnlyUnread(!fetchOnlyUnread)
                      }}
                    >
                      Show All {fetchOnlyUnread ? '' : 'Unread'} Announcements
                    </Button>
                  </div>
                </div>
              </div>
              <div className={classNames(isMobile ? '' : 'card-body', style.announcementTable)}>
                <BootstrapSmartTable
                  name="Announcements"
                  columns={getColumns(updateAnnouncementReadStatus)}
                  data={announcements || []}
                  mobileCards={true}
                  defaultSortKey={['id', 'descending']}
                  options={{
                    pageSize: 20
                  }}
                  showFilter
                  isLoading={isLoading}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AnnouncementView;
