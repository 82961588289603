import axios from 'axios';
import PhoneNumber from "@interfaces/PhoneNumber";
import getAdminApiPath from "@hornet-api/getAdminApiPath";

const getPhoneNumberList = async (contactId: number | string) => {
  const url = `${getAdminApiPath()}/contact/${contactId}/phoneNumber`;
  const response = await axios.get(url);
  return response.data as PhoneNumber[];
};

export default getPhoneNumberList;