import axios, {AxiosResponse} from 'axios';
import getAdminApiPath from '../getAdminApiPath';
import InvestmentWithTransactions from '@interfaces/InvestmentWithTransactions';

const getInvestments = async (contactId: number | string) => {
	const url = `${getAdminApiPath()}/contact/${contactId}/investments`;
	const response: AxiosResponse<InvestmentWithTransactions[]> = await axios.get(url);
	return response.data.filter(x => x);
};

export default getInvestments;
