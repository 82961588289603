import React from 'react';
import FontAwesomeIcon from './lib/FontAwesomeIcon';
import Store from './globalState';
import {Column} from './index';
import MultiSelect, {MultiSelectOption} from './MultiSelect';

type Props<D> = {
  store: Store<D>,
  columns: Column<D>[],
};

function MobileFilter<D>(
  {
    store,
    columns,
  }: Props<D>
) {
  const [hiddenColumns] = store.hiddenColumnsState.use();
  const [currentPage, setCurrentPage] = store.currentPageState.use();
  const [editField, setEditField] = store.editFieldState.use();
  const [filters, setFilters] = store.filtersState.use();
  const [columnOrder] = store.columnOrderState.use();
  return (
    <>
      {columnOrder?.map((colIndex) => {
        const col = columns[colIndex];
        if (!(hiddenColumns.includes(colIndex))) {
          return (
            <div
              key={colIndex}
              style={{
                marginBottom: 10
              }}
            >
              {
                col.filter ?
                  col.lookup ?
                    <div
                      className=""
                      style={{
                        paddingRight: filters[colIndex] && filters[colIndex].length > 0 ? 29 : 0
                      }}
                    >
                      <MultiSelect
                        options={
                          Object.keys(col.lookup).map((key) => {
                            return (
                              {
                                label: `${(col.lookup || {})[key]}`,
                                value: key
                              }
                            );
                          })
                        }
                        align={colIndex / columns.length > 0.5 ? 'right' : 'left'}
                        value={
                          Object.keys(col.lookup).reduce((prev, next) => {
                            if (filters[colIndex] && filters[colIndex].split('|').some(v => v === next)) {
                              return [
                                ...prev,
                                {
                                  label: (col.lookup || {})[next] as string,
                                  value: next
                                }
                              ];
                            }
                            return prev;
                          }, [] as MultiSelectOption[])
                        }
                        onChange={(newSelect: MultiSelectOption[]) => {
                          const newFilters = filters.map((filter, fi) => {
                            if (fi === colIndex) {
                              return newSelect.map(x => x.value).join('|');
                            }

                            return filter;
                          }) as string[];

                          setFilters(newFilters);
                          setEditField(null);
                          setCurrentPage(1);
                        }}
                      />
                    </div>
                    :
                    <div className="input-group">
                      <input
                        className="form-control bst-filter"
                        type="text"
                        value={filters[colIndex]}
                        placeholder={col.title}
                        onChange={(event) => {
                          const newFilters = filters.map((filter, fi) => {
                            if (fi === colIndex)
                              return event.target.value;

                            return filter;
                          }) as string[];
                          setEditField(null);
                          setCurrentPage(1);
                          setFilters(newFilters);
                        }}
                      />
                      {
                        filters[colIndex].length > 0 ?
                          <div className="input-group-append bst-placeholder-append">
                            {col.title}
                          </div>
                          : null
                      }
                      {
                        filters[colIndex] && filters[colIndex].length > 0 ?
                          <div className="input-group-append">
                            <button
                              className="btn btn-sm btn-secondary"
                              type="button"
                              onClick={() => {
                                const newFilters = filters.map((filter, fi) => {
                                  if (fi === colIndex)
                                    return '';

                                  return filter;
                                }) as string[];
                                setEditField(null);
                                setCurrentPage(1);
                                setFilters(newFilters);
                              }}
                            >
                              <FontAwesomeIcon icon="times" />
                            </button>
                          </div>
                          :
                          null
                      }
                    </div>
                  :
                  null
              }
            </div>
          );
        }
        return null;
      })}
    </>
  );
}

export default MobileFilter;