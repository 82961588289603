import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {Badge, Col, Modal, Row} from "react-bootstrap";
import {createGlobalState} from "react-global-hooks";
import style from './Search.module.scss';
import classNames from "classnames";
import {debounce, forEach, uniqBy} from "lodash";
import GlobalSearchTab from "@admin-ui/pages/SearchPage/GlobalSearchTab";
import {
  globalSearchDataLoadingState,
  globalSearchDataState,
  ICleanSearchableObject,
  ICleanSearchableObjectMap,
  ITab,
  ITabStatus,
  SearchTabKeys,
  sortSearchableObjectData
} from "@admin-ui/pages/SearchPage/constant";
import GlobalSearchItem from "@admin-ui/pages/SearchPage/GlobalSearchItem";
import {enumKeys} from "@common/basic";
import {generateSearchRegExp} from "@common/utils/validators/globalSearchValidator";
import {refreshEmailLog, refreshRecentSearch} from "@components/NotificationSidebar/utils";
import {BsBuildingFillAdd, BsEnvelopePlusFill, BsHouseAddFill} from "react-icons/bs";
import {FaDollarSign, FaSearch} from "react-icons/fa";
import {Link} from "react-router-dom";
import {IoPersonAdd} from "react-icons/io5";
import Scrollbar from "react-scrollbars-custom";

export const showSearchModalState = createGlobalState<boolean>(false);

const chunkSize = 20;
const tabKey = 'searchModalTabState'

const GlobalSearchModal = () => {
  const [show, setShow] = showSearchModalState.use();
  const [sortOrder, setSortOrder] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [searchText, setSearchText] = useState('');
  const [tabs, setTabs] = useState<ITab[]>([]);
  const modalRef = useRef<HTMLInputElement>(null);
  const globalSearchData = globalSearchDataState.useValue();
  const globalSearchDataLoadingValues = globalSearchDataLoadingState.useValue();
  const [displayItems, setDisplayItems] = useState<ICleanSearchableObject[]>([]);

  useEffect(() => {
    localStorage.setItem(tabKey, JSON.stringify(tabs))
  }, [tabs]);

  useEffect(() => {
    const allDataLoaded = Object.values(globalSearchDataLoadingValues).every(value => value);
    allDataLoaded && refreshRecentSearch();
  }, [globalSearchDataLoadingValues]);

  useState(() => {
    let storedTabState: ITab[] = []
    storedTabState = JSON.parse(localStorage.getItem(tabKey) || '[]')
    if (storedTabState.length === 0) {
      // By default - All tab should be Active
      storedTabState = SearchTabKeys.map(tab => ({tab, status: ITabStatus.ACTIVE}));
    } else {
      SearchTabKeys.forEach(tabKey => {
        if (!storedTabState.find(tab => tab.tab === tabKey)) {
          storedTabState.push({tab: tabKey, status: ITabStatus.INACTIVE})
        }
      });
    }
    setTabs(storedTabState);
  });

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key === '/') {
        refreshEmailLog()
        setShow(true)
      }
    };
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    const modalElement = modalRef.current;
    if (show && modalElement) {
      const focusableElements = modalElement.querySelectorAll('a.nav-item.nav-link, input, select, div.draggableTab, [tabindex]:not([tabindex="-1"])');
      const firstElement = focusableElements[0] as HTMLInputElement;
      const lastElement = focusableElements[focusableElements.length - 1] as HTMLInputElement;
      firstElement.focus()
      const handleKeyDownAfterShow = (event: KeyboardEvent) => {
        if (event.key === "Tab") {
          if (document.activeElement === lastElement) {
            firstElement.focus();
            event.preventDefault();
          }
        }
      };

      document.addEventListener("keydown", handleKeyDownAfterShow);
      return () => {
        setSearchText('');
        setInputValue('');
        document.removeEventListener("keydown", handleKeyDownAfterShow);
      };
    }
  }, [show]);

  const debouncedHandleInputChange = useCallback(debounce((value: string) => {
    setSearchText(value);
  }, 500), []);

  useEffect(() => {
    debouncedHandleInputChange(inputValue)
  }, [debouncedHandleInputChange, inputValue]);

  const handleSortChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSortOrder(event.target.value);
  };

    const searchResult = (text: string, altText: (string | number)[]) => {
        if (searchText.trim() === '') return false;

        const {regExp, regExpReverse} = generateSearchRegExp(searchText);

      return text.match(regExp) || text.match(regExpReverse) || altText?.some(x => {
            const stringToCheck = `${x ?? ''}`;
            return stringToCheck.match(regExp) || stringToCheck.match(regExpReverse);
        });
    }

  const filteredAndSortedMapData = useMemo(() => {
    const activeTabs = tabs.filter(tab => tab.status === ITabStatus.ACTIVE).map(tab => tab.tab)
    const filteredSearchMap: ICleanSearchableObjectMap = {};
    tabs.forEach(tab => {
      if (tab.tab === 'ALL') return
      filteredSearchMap[tab.tab] = [];
    });
    if (!show) return filteredSearchMap;
    activeTabs.forEach(entityType => {
      if (entityType === 'ALL') return;
      const transformedData: ICleanSearchableObject[] = [];
      for (const entityId in globalSearchData[entityType]) {
        const searchData = globalSearchData[entityType][entityId];
        const {text, altText} = searchData;
        if (searchText) {
          // Check if searchText matches text or altText
          if (searchResult(text, altText)) {
            transformedData.push({
              ...searchData,
              id: entityId,
            });
            enumKeys(searchData.relationships).forEach(type => {
              if (activeTabs.includes(type)) {
                searchData.relationships[type]?.forEach(relationShipId => {
                  const filteredSearchData = globalSearchData[type][relationShipId];
                  filteredSearchMap[type]?.push({
                    ...filteredSearchData,
                    id: relationShipId
                  })
                })
              }
            })
          }
        } else {
          transformedData.push({
            ...searchData,
            id: entityId
          });
        }
      }
      filteredSearchMap[entityType] = [...filteredSearchMap[entityType] || [], ...transformedData];
    });
    return filteredSearchMap;
  }, [tabs, globalSearchData, searchText, show]);

  const sortedAndFilteredData = useMemo(() => {
    const clubbedData: ICleanSearchableObject[] = [];
    forEach(filteredAndSortedMapData, value => {
      value && clubbedData.push(...sortSearchableObjectData(value, sortOrder));
    });
    return uniqBy(clubbedData, item => `${item.id}-${item.entityType}`);
  }, [sortOrder, filteredAndSortedMapData]);

  const renderNewData = () => {
    const endIndex = displayItems.length + chunkSize;
    setDisplayItems(sortedAndFilteredData.slice(0, endIndex));
  };

  useEffect(renderNewData, [sortedAndFilteredData, sortOrder]);

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    if (
      target.scrollTop + target.clientHeight >=
      target.scrollHeight - 20 &&
      displayItems.length < sortedAndFilteredData.length
    ) {
      renderNewData();
    }
  };

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      className={style.searchModal}
      id={'searchModal'}
      size={'lg'}
    >
      <div className={'searchPopup'} ref={modalRef}>
        <Modal.Header className={classNames(style.modalHeader)} closeButton>
          <Modal.Title className={style.modalTitle}>
            <div className={style.searchBarContainer}>
              <input
                value={inputValue}
                placeholder={''}
                className={style.searchBar}
                autoComplete="off"
                tabIndex={0}
                onChange={(e) => setInputValue(e.target.value)}
              />
              <FaSearch/>
              <Badge className={`btn btn-secondary ml-1 ${inputValue.length > 0?'visible':'invisible'}`} onClick={() => setInputValue('')}>Clear</Badge>
            </div>

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col className={style.tabs} md={3} sm={4} xs={3}>
             <Scrollbar noScrollX={true} className={style.customScrollBar}>
               <GlobalSearchTab tabs={tabs} onChange={setTabs}/>
             </Scrollbar>
            </Col>
            <Col className={style.searchContainer} onScroll={handleScroll} md={9} sm={8} xs={9}>
              <div className={style.resultContainer}>
                <div>
                  <span className={'h4'}>Result</span>
                  <span className={'pull-right ml-3'}>
                    Sort by:
                    <select className={'ml-2'} value={sortOrder} onChange={handleSortChange}>
                      <option value={'ASC'}>A-Z</option>
                      <option value={'DESC'}>Z-A</option>
                    </select>
                  </span>
                  <div className={style.addIconContainer}>
                    <Link to={'/contact/create'}><IoPersonAdd onClick={() => setShow(false)}/> </Link>
                    <Link to={'/entity/create'} onClick={() => setShow(false)}><BsBuildingFillAdd/> </Link>
                    <Link to={'/property/create'}><BsHouseAddFill onClick={() => setShow(false)}/> </Link>
                    <Link to={'/loan/create'}><FaDollarSign onClick={() => setShow(false)}/> </Link>
                    <Link to={'/emailTemplate/create'}><BsEnvelopePlusFill onClick={() => setShow(false)}/> </Link>
                  </div>
                </div>
              </div>
              {displayItems.length ? (
                displayItems.map((item, i) =>
                  <GlobalSearchItem key={`${i}-${item.id}-${item.entityType}`} itemData={item}/>
                )
              ) : <h4>No More Records</h4>}
            </Col>
          </Row>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default GlobalSearchModal;