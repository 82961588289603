import {useEffect} from 'react';

import useToNull from '@common/useToNull';
import {
  handleChatQueryParams,
  refreshChatObject,
  refreshData,
  refreshTaskDetails,
  refreshTransactions,
  updateUserAndCompanyLists
} from './utils';

import {selectedChatState, tasksState,} from './globalState';
import isAdminState from "@state/globalState/isAdminState";
import useFromNull from "@common/useFromNull";
import {useRecoilValue} from "recoil";
import profileAtom from "@state/recoil/profileAtom";

const DataLoader = () => {
  const profile = useRecoilValue(profileAtom);
  const isAdmin = isAdminState.useValue();
  const selectedChat = selectedChatState.useValue();
  const tasks = tasksState.useValue();

  // load the users and company list for admin
  useEffect(() => {
    if (isAdmin) {
      updateUserAndCompanyLists();
      refreshData();
    }
  }, [isAdmin]);

  // runs on load
  useEffect(() => {
    handleChatQueryParams();
    refreshTransactions();
    profile?.hasUser && refreshChatObject();
  }, [])

  // update task detail when we get tasks as it depends on it
  useFromNull(() => {
    refreshTaskDetails()
  }, [tasks])

  useToNull(() => {
    console.log('refreshing chat list')
    refreshChatObject();
  }, [selectedChat]);

  return null;
}

export default DataLoader;
