import React from "react";
import { createGlobalState } from "react-global-hooks";
import { v4 as getUuid } from "uuid";
import Loading from "@components/Loading";

export const loadingWindowState = createGlobalState([] as string[]);

// adds a uuid to the queue and returns it.
export const loadingTrigger = () => {
  const uuid = getUuid();
  const newState = [...loadingWindowState.get(), uuid];
  loadingWindowState.set(newState);
  return uuid;
};

export const loadingRelease = (uuid: string) => {
  const newState = [...loadingWindowState.get()];
  const index = newState.indexOf(uuid);
  if (index > -1) {
    newState.splice(index, 1);
  }
  loadingWindowState.set(newState);
};

export const loadingReleaseAll = () => {
  loadingWindowState.set([]);
};

const LoadingOverlay = () => {
  const [loadingArr, setLoadingArr] = loadingWindowState.use();
  const isVisible = loadingArr.length > 0;
  return (
    <div
      style={{
        pointerEvents: isVisible ? "all" : "none",
        transition: "all 0.3s ease",
        opacity: isVisible ? 1 : 0,
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: `rgba(255, 255, 255, 0.5)`,
        zIndex: 1051
      }}
    >
      <Loading />
    </div>
  );
};

export default LoadingOverlay;
