import axios from 'axios';
import {getTaskUrl} from './util';
import {Message} from '@interfaces/task';

export type MarkResolvedMessage = {
  taskId?: number,
  contactId?: number,
  companyId?: number,
  chatId?: number
}
const markChatResolved = async (
  {
    taskId,
    contactId,
    companyId,
    chatId,
  }: MarkResolvedMessage
) => {
  let url = getTaskUrl();
  if (taskId) {
    if (companyId) {
      url += `/entity/${companyId}/task/${taskId}/chat/resolve`;
    } else if (contactId) {
      url += `/contact/${contactId}/task/${taskId}/chat/resolve`;
    } else {
      url += `/contact/task/${taskId}/chat/resolve`;
    }
  } else if (chatId) {
    // normal chat
    url += `/chat/${chatId}/resolve`;
  } else {
    alert('Not supported');
    return;
  }

  const response = await axios.put(url, {});
  return response.data as Message[];
};

export default markChatResolved;