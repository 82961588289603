
type State = {
	token: null | string
}

const state: State = {
	token: null,
};

export const getAccessToken = () => (state.token);

export const setAccessToken = (token: string | null) => {
	state.token = token;
};

export default {
	getAccessToken,
	setAccessToken,
};
