import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { createGlobalState } from 'react-global-hooks';

export type IframeModalObj = {
  title: string,
  url: string,
  onClose?: () => void
} | null;

export const iframeModalState = createGlobalState(null as IframeModalObj | null);

export const openIframeModal = (title: string, url: string) => {
  iframeModalState.set({
    title: title,
    url: url
  })
};

export const iframeModalLinkOnClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
  e.preventDefault();
  //@ts-ignore
  const url = e.target?.getAttribute('href') as string;
  //@ts-ignore
  const title = e.target?.text || 'Modal';
  iframeModalState.set({
    title: title,
    url: url
  })
}

const IframeModal = () => {
  const [iframeObj, setIframeObj] = iframeModalState.use();
  const [iframeHeight, setIframeHeight] = useState(200);
  const handleClose = () => {
    if (iframeObj && iframeObj.onClose) {
      iframeObj.onClose();
    }
    setIframeObj(null);
  };

  const show = iframeObj !== null;

  useEffect(() => {
    const handler = (event: MessageEvent<any>) => {
      try {
        const data = JSON.parse(event.data);
        console.log('received message:', data);
        if (data.source && data.source === 'IFRAME' && data.height) {
          setIframeHeight(data.height);
        }
      } catch (e) {
        // a lot of messages get sent by various things. Ignore this
      }
    }
    window.addEventListener("message", handler);

    // clean up
    return () => window.removeEventListener("message", handler)
  }, []);


  return (
    <Modal
      show={show}
      onHide={handleClose}
      id={'iframe-modal'}
      onEntered={() => {
      }}
    // size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>{show ? iframeObj?.title : null}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          iframeObj ?
            <iframe
              src={iframeObj.url}
              style={{
                border: 0,
                width: '100%',
                height: iframeHeight,
                transition: 'all 0.3s ease'
              }}
              scrolling="no"
            />
            :
            null
        }

      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>

    </Modal>
  );
};

export default IframeModal;
