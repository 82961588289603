import React, {ForwardedRef} from 'react';
import Tooltip from 'react-bootstrap/Tooltip';

const ResetFiltersTooltip = React.forwardRef((props, ref: ForwardedRef<HTMLDivElement>) => {
	return (
		<Tooltip ref={ref} id="reset-filters-btn" {...props}>Reset Filters</Tooltip>
	);
});

export default ResetFiltersTooltip;
