import getAdminApiPath from "@hornet-api/getAdminApiPath";
import axios from "axios";
import {ContactProfile} from "@interfaces/ContactAdmin";

const getContactForProfile = async () => {
  const url = `${getAdminApiPath()}/contact/current`;
  const response = await axios.get(url);
  return response.data as ContactProfile;
};

export default getContactForProfile;