import axios from 'axios';
import getAdminApiPath from '../getAdminApiPath';
import {ContactAssociationCount} from "@interfaces/ContactAdmin";

const getContactAssociatedCount = async (contactId: number | string) => {
  const url = `${getAdminApiPath()}/contact/${contactId}/associatedCount`;
  const response = await axios.get(url);
  return response.data as ContactAssociationCount;
};

export default getContactAssociatedCount;
