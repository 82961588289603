import getDecrypted, {DecryptedObject} from "@hornet-api/encryption/getDecrypted";

/**
 * It takes an object, finds all the ciphers, decrypts them, and replaces the ciphers with the decrypted values
 * @param {any} obj - the object that you want to decrypt
 */
const decryptObject = async <T>(obj: T): Promise<T> => {
  // get the cyphers;
  let objString = JSON.stringify(obj);
  const myRegexp = /(vault:v\d*:[^"]*)/g;
  let ciphers = []; // holds array of cyphers
  let match = myRegexp.exec(objString);
  while (match != null) {
    ciphers.push(match[0]);
    match = myRegexp.exec(objString);
  }
  if(ciphers.length === 0) {
    // we did not find any ciphers, do nothing
  } else {
   // get the cyphers
   const decryptedObj = await getDecrypted(ciphers);
   for(let cipher in decryptedObj){
     objString = objString.split(cipher).join(decryptedObj[cipher]);
   }
  }
  return JSON.parse(objString);
}

export default decryptObject;