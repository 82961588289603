import Navigation from "@components/Navigation";
import React, {useLayoutEffect} from "react";

type Props = {
  children: React.ReactChild | React.ReactChildren;
}

const PublicUserLayout = ({children}: Props) => {
  useLayoutEffect(() => {
    const bodyClasses = [
      'hold-transition',
      'sidebar-mini',
      'role-user',
    ];
    document.body.classList.add(...bodyClasses);
    return () => {
      document.body.classList.remove(...bodyClasses);
    };
  }, []);
  
  return (
    <div className="wrapper">
      <Navigation/>
      <div id="main-container" className="main-container">
        <div className="container-fluid navbar-space">
          {children}
        </div>
      </div>
    </div>
  )
}

export default PublicUserLayout;