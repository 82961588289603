import React, {useCallback, useEffect, useMemo} from 'react';
import Store from './globalState';
import SettingsSaver from './lib/SettingsSaver';


type IPageSizeSettingButtonProps<D> = {
	initialPageSize: number,
	pageSizeOptions: number[],
	store: Store<D>,
	settingsSaver: SettingsSaver<D>
};

const styles = {
	pageSizeDropdown: {
		display: 'inline-block',
		width: 'auto',
		margin: '0 5px'
	},
};

function PageSizeSettingButton<D>(
	{
		initialPageSize,
		pageSizeOptions,
		store,
		settingsSaver
	}: IPageSizeSettingButtonProps<D>
) {

	const [pageSize, setPageSize] = store.pageSizeState.use();
	const [initialPageSizeStored, setInitialPageSizeStored] = store.initialPageSizeState.use();

	const handleOnPageSizeChange = useCallback((event: { target: { value: string } }) => {
		const newVal = parseInt(event.target.value, 10);
		setPageSize(newVal);
		store.currentPageState.set(1);
	}, []);

	useEffect(() => {
		if (initialPageSizeStored !== initialPageSize) {
			setInitialPageSizeStored(initialPageSize);
			handleOnPageSizeChange({target: {value: `${initialPageSize}`}});
		}
	}, [initialPageSize, initialPageSizeStored])

	const pageSizeOptionElements = useMemo(() => {
		return pageSizeOptions.map((value) => {
			return <option key={value} value={value}>{value}</option>;
		});
	}, [pageSizeOptions]);

	return (
		<select
			className="form-control form-control-sm"
			value={pageSize}
			style={styles.pageSizeDropdown}
			onChange={handleOnPageSizeChange}
		>
			{pageSizeOptionElements}
		</select>
	);
}

export default PageSizeSettingButton;
