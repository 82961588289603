import React, {Fragment, useContext, useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import HornetNavbar from "@pages/public/HornetNavbar";
import './style.scss';
import {TaskRequirementTypeEnum} from "@interfaces/GeneratedEnums";
import {Accordion, AccordionContext, Button, Card, Col, ListGroup, Row, useAccordionToggle} from "react-bootstrap";
import {IoChevronDownCircleOutline, IoChevronUpCircleOutline} from "react-icons/io5";
import FileUpload from "@pages/public/FileUpload";
import {loadingRelease, loadingTrigger} from "@components/LoadingOverlay";
import {getPendingContactLoanTask} from "@hornet-api/public/getPendingContactLoanTask";
import {alertApiErrors} from "@common/errors";
import {uploadMediaForTask} from "@hornet-api/public/uploadMediaForTask";
import RegisterPage from "@pages/public/RegisterPage";
import {FaEye, FaTrash} from "react-icons/fa";
import {showFileDocument} from "@pages/public/ViewDocumentModal";
import Form from "react-bootstrap/Form";


type UserPendingTaskUrlParams = {
  userUuid: string;
}

interface Task {
  id: number;
  title: string;
  requirement: keyof typeof TaskRequirementTypeEnum | null;
  description: string;
  hasESignIdentifier: boolean
}

export interface ILoanTask {
  loanAliasId: string;
  primaryProperty: string;
  taskList: Task[];
}

interface ContextAwareToggleProps {
  children: React.ReactNode;
  eventKey: string;
}

export interface ITaskFiles {
  [taskId: number]: File[];
}

const UserPendingTask = () => {
  const [loanTaskList, setLoanTaskList] = useState<ILoanTask[]>([]);
  const [taskFiles, setTaskFiles] = useState<ITaskFiles>({});
  const {userUuid} = useParams() as UserPendingTaskUrlParams;
  const [isNonUserContact, setNonUserContact] = useState(false);

  const areFilesSelected = Object.values(taskFiles).some(files => files.length > 0);

  const loadPendingContactLoanTask = () => {
    const t = loadingTrigger();
    getPendingContactLoanTask(userUuid)
      .then(setLoanTaskList)
      .catch((error) => {
        if (error.response.status === 400 && error.response.data?.message === 'Must register to access this feature') {
          setNonUserContact(true);
        } else {
          alertApiErrors(error)
        }
      })
      .finally(() => loadingRelease(t));
  }

  useEffect(() => {
    const confirmationMessage = "Are you sure you'd like to leave? Attached documents have not been submitted to Hornet and will be lost.";
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (areFilesSelected) {
        e.preventDefault();
        e.returnValue = confirmationMessage;
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [areFilesSelected]);

  useEffect(loadPendingContactLoanTask, [userUuid]);

  const handleFileUploadNew = (taskId: number, files: File[]) => {
    const existingFiles = taskFiles[taskId] || [];
    setTaskFiles({...taskFiles, [taskId]: [...existingFiles, ...files]});
  };

  const handleFileDelete = (taskId: number, index: number) => {
    const updatedFiles = taskFiles[taskId].filter((_, i) => i !== index);
    setTaskFiles({...taskFiles, [taskId]: updatedFiles});
  };

  const submitForm = () => {
    const t = loadingTrigger();
    const formData = Object.keys(taskFiles).map(taskId => ({
      taskId: parseInt(taskId),
      files: taskFiles[parseInt(taskId)]
    }));

    uploadMediaForTask(userUuid, formData)
      .then(loadPendingContactLoanTask)
      .then(() => setTaskFiles({}))
      .catch(alertApiErrors)
      .finally(() => loadingRelease(t));
  }


  return (
    <div id={'main-page'} className={'main-page'}>
      <HornetNavbar/>
      {isNonUserContact ? <RegisterPage/> : (<>
        <div className="container-fluid ml-sm-auto pt-3 px-4">
              {loanTaskList.map(loan => (
                <Accordion key={loan.loanAliasId} defaultActiveKey={loan.loanAliasId}>
                    <ContextAwareToggle eventKey={loan.loanAliasId}>
                      {loan.loanAliasId} - {loan.primaryProperty}
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey={loan.loanAliasId}>
                        <ListGroup>
                          {loan.taskList.map(task => {
                            return (
                              <ListGroup.Item key={task.id}>
                                <Card>
                                  <Card.Header>
                                    <Row>
                                      <Col>
                                        <div className="task-details-container">
                                          <Form.Group>
                                            <Form.Label className="task-description-label">Task</Form.Label>
                                            <div className="task-description-value form-control"
                                                 dangerouslySetInnerHTML={{__html: task.title}}
                                            />
                                          </Form.Group>
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <div className="task-details-container">
                                          <Form.Group>
                                            <Form.Label className="task-description-label">Task Description</Form.Label>
                                            <div
                                              className="task-description-value form-control"
                                              dangerouslySetInnerHTML={{__html: task.description}}
                                            />
                                          </Form.Group>
                                        </div>
                                      </Col>
                                    </Row>
                                  </Card.Header>
                                  {!task.hasESignIdentifier && (
                                    <Card.Body>
                                      <Row>
                                        <Col xs={7}>
                                          <FileUpload onChange={(files) => handleFileUploadNew(task.id, files)}/>
                                        </Col>
                                        <Col xs={5}>
                                          <div className="list">
                                            {taskFiles[task.id]?.map((file: File, index: number) => (
                                              <div key={index} className="list-item">
                                                <span className="item-name">{file.name}</span>
                                                <FaEye
                                                  className="header-icon-style"
                                                  onClick={() => showFileDocument({file})}
                                                />
                                                <FaTrash
                                                  className="header-icon-style"
                                                  onClick={() => handleFileDelete(task.id, index)}
                                                />
                                              </div>
                                            ))}
                                          </div>
                                        </Col>
                                      </Row>
                                    </Card.Body>
                                  )}
                                </Card>
                              </ListGroup.Item>
                            )
                          })}
                        </ListGroup>
                    </Accordion.Collapse>
                </Accordion>
              ))}
              <div className={'d-flex justify-content-center my-5'}>
                <Button
                  variant="primary"
                  disabled={!areFilesSelected}
                  onClick={submitForm}
                >
                  Submit
                </Button>
              </div>
        </div>
      </>)}
    </div>
  );
}

const ContextAwareToggle = ({children, eventKey}: ContextAwareToggleProps) => {
  const currentEventKey = useContext(AccordionContext);
  const decoratedOnClick = useAccordionToggle(eventKey);
  const Icon = currentEventKey === eventKey ? IoChevronUpCircleOutline : IoChevronDownCircleOutline;

  return (
    <Card.Header className={'list-item'} onClick={decoratedOnClick}>
      <Icon className={'header-icon-style task-header-icon'} size={20}/>
      {children}
    </Card.Header>
  );
}

export default UserPendingTask;