import {createGlobalState} from 'react-global-hooks';
import {Preset} from '@hornet-api/smartTables';

export type EditField = {
    index: number,
    field: string,
    value: number | string,
    isLoading: boolean
};

export type MobileView = 'table' | 'visibility' | 'sort' | 'filter';

export default class Store<D> {
    editFieldState = createGlobalState(null as null | EditField);
    hiddenColumnsState = createGlobalState([] as number[]);
    hiddenColumnIdsState = createGlobalState([] as string[]);
    defaultHiddenColumnsState = createGlobalState([] as string[]);
    filtersState = createGlobalState([] as string[]);
    currentPageState = createGlobalState(1);
    dataState = createGlobalState([] as D[]);
    fetchingRemoteDataState = createGlobalState([] as string[]);
    filteredCountState = createGlobalState(0);
    totalState = createGlobalState(0);
    sumRowState = createGlobalState(null as null | D);
    pageSizeState = createGlobalState(10);
    initialPageSizeState = createGlobalState<number | null>(null);
    showHideColumnsVisibleState = createGlobalState(false);
    sortAscendingState = createGlobalState(true);
    sortKeyState = createGlobalState('');
    columnOrderState = createGlobalState([] as number[]);
    mobileViewState = createGlobalState('table' as MobileView);
    windowIsMobileState = createGlobalState(false);
    presetsState = createGlobalState([] as Preset[]);
    selectedPresetIdState = createGlobalState('');
    showActionBarState = createGlobalState(true);
}