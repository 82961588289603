import React, {useCallback} from "react";
import {showSearchModalState} from "@admin-ui/pages/SearchPage/GlobalSearchModal";
import {Link, useHistory} from "react-router-dom";
import classNames from "classnames";
import {Button, Col, Row, Tooltip} from "react-bootstrap";
import FontAwesomeIcon from "@admin/components/icons/FontAwesomeIcon";
import {generateLink, ICleanSearchableObject, IconMap,} from "@admin-ui/pages/SearchPage/constant";
import style from "@admin-ui/pages/SearchPage/Search.module.scss";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {openCallModal} from "@admin-ui/pages/VoiceCallPage/VoiceCallModal";
import {selectedChatState, selectedUserOrCompanyState} from "@components/NotificationSidebar/globalState";
import {openChat, refreshChatObject, refreshRecentSearch} from "@components/NotificationSidebar/utils";
import {FaComment, FaCommentSlash} from "react-icons/fa";
import {AiOutlineStop} from "react-icons/ai";
import {saveRecentGlobalSearchList} from "@hornet-api/recentGlobalSearch/saveRecentGlobalSearchList";

type Props = {
  itemData: ICleanSearchableObject;
}


const GlobalSearchItem = (props: Props) => {

  const {id, text, altText, entityType, others, nestedEntityType} = props.itemData;

  const primaryId = entityType === 'AR' ? others?.contactId : id;
  const history = useHistory();

  const onClick = (link: string) => {
    if (link) {
      history.push(link);
      showSearchModalState.set(false);
    }
  }
  const saveRecentSearchData = useCallback(() => {
    entityType != 'RS' && saveRecentGlobalSearchList({entityType, primaryId: id})
      .then(refreshRecentSearch)
  }, [props]);

  const ActionElement = useCallback(() => (
    <Col sm={2} className={style.linkSection}>
      {
        [nestedEntityType, entityType].includes('CONTACT') && (
          <>
            {
              others && (
                <OverlayTrigger
                  placement="top-start"
                  overlay={<Tooltip id={`tt2-${id}`}>{others.userId ? 'Chat' : 'Not a registered user'}</Tooltip>}>
                  <Button
                    variant={!others.userId ? "secondary" : 'primary'}
                    className="btn-sm mr-1"
                    disabled={!others.userId}
                    onClick={async (event) => {
                      if (others.userId) {
                        event.stopPropagation();
                        saveRecentSearchData();
                        selectedChatState.get()
                        openChat()
                        selectedUserOrCompanyState.set({label: text, value: `contact|${id}`})
                        await refreshChatObject(false, true)
                      }
                    }}
                  >
                    {others.userId ? <FaComment/> : <FaCommentSlash/>}
                  </Button>
                </OverlayTrigger>
              )
            }
            <OverlayTrigger
              placement="top-start"
              overlay={<Tooltip id={`tt-${id}`}>{'Call'}</Tooltip>}>
              <Button
                variant={'warning'}
                className="btn-sm mr-1"
                onClick={(event) => {
                  event.stopPropagation()
                  saveRecentSearchData();
                  openCallModal(id, `${(others && others.phone) || "NEW"}`)
                }}>
                <FontAwesomeIcon icon={'phone'}/>
              </Button>
            </OverlayTrigger>
          </>
        )
      }


      {
        [nestedEntityType, entityType].includes('PROPERTY') && (
          <OverlayTrigger
            placement="top-start"
            overlay={<Tooltip id={`tt-${id}`}>{"Images"}</Tooltip>}>
            <Link
              className="btn btn-sm btn-info mr-1"
              to={`/uploader/show/${id}`}
              target="_blank" rel="noopener noreferrer"
              onClick={(event) => {
                event.stopPropagation();
                saveRecentSearchData();
              }}
            >
              <FontAwesomeIcon icon={'camera'}/>
            </Link>
          </OverlayTrigger>
        )
      }
      {
        [nestedEntityType, entityType].includes('LOAN') && others?.loanStage && (
          <OverlayTrigger
            placement="top-start"
            overlay={<Tooltip
              id={`tt2-${id}`}>{others.loanStage === 'CLOSED' ? 'Loan Status: Closed' : 'Chat'}</Tooltip>}>
            <Button
              variant={others.loanStage === 'CLOSED' ? "secondary" : 'primary'}
              className="btn btn-sm mr-1"
              disabled={others.loanStage === 'CLOSED'}
              onClick={async (event) => {
                event.stopPropagation();
                saveRecentSearchData();
                selectedChatState.get()
                openChat()
                selectedUserOrCompanyState.set({label: text, value: `loan|${id}`})
                await refreshChatObject(false, true)
              }}>
              {others.loanStage !== 'CLOSED' ? <FaComment/> : <FaCommentSlash/>}
            </Button>
          </OverlayTrigger>
        )
      }

      {
        [nestedEntityType, entityType].includes('AR') && !others?.contactId && (
          <OverlayTrigger
            placement="top-start"
            overlay={<Tooltip id={`tt-no-contact-${id}`}>No Contact</Tooltip>}
          >
            <Button
              variant={'secondary'}
              size={'sm'}
              disabled
            >
              <AiOutlineStop/>
            </Button>
          </OverlayTrigger>
        )
      }
    </Col>
  ), [saveRecentSearchData]);

  return (<div className={style.searchItem} onClick={(event) => {
    if (!primaryId) {
      return;
    }
    saveRecentSearchData();
    onClick(generateLink(primaryId, entityType === "RS" && nestedEntityType ? nestedEntityType : entityType));
  }}>
    <div className={`${style.iconContainer} position-relative`}>
      <FontAwesomeIcon icon={IconMap[entityType === "RS" && nestedEntityType ? nestedEntityType : entityType]} size={"lg"}/>
      {entityType === "RS" && (
        <FontAwesomeIcon icon={IconMap[entityType]} size={"sm"} className={style.recentIcon}/>
      )}
    </div>
    <div className={style.SearchContent}>
      <Row>
        <Col sm={10}>
          <div className={style.title} title={`${text}`}>
            {text}
          </div>
          <div className={classNames('mb-0', style.textDescription)}>
            {altText?.map((descriptionText, index) => (
              <span className={style.textDescriptionItem} key={index} title={`${descriptionText}`}>
                {descriptionText}
              </span>
            ))}
          </div>
        </Col>
        <ActionElement/>
      </Row>
    </div>
  </div>)
}
export default GlobalSearchItem;