import {createGlobalState} from 'react-global-hooks';
import sessionToToken from '@hornet-api/sessionToToken';
import {getAccessToken, setAccessToken} from '@common/token';
import sendIframeMessage from '@common/sendIframeMessage';
import {isLegacy} from '@common/isAdmin';
import {canSavePresetsState, rolesThatCanSavePresets, usersThatCanSavePresets} from '@hornet-api/smartTables';
import {disableNumberScroll, getQueryParams} from "@common/basic";
import {getBaseUri} from "@common/baseUri";
import getSystemConfigUser from "@hornet-api/systemConfigs/user/getSystemConfigUser";
import {SystemConfigEnum} from "@interfaces/GeneratedEnums";
import timeZoneState from "@state/globalState/timeZoneState";

export const isInIframeState = createGlobalState(false);
export const isInitializedState = createGlobalState(false);

// handle initialized
let isInitialized = false;
let onInitializedArr: (() => void | Promise<void>)[] = [];

export const onAdminInitialized = (fn: () => void | Promise<void>) => {
  if (isInitialized) {
    // already initialized just call it
    fn();
  } else {
    // add it to initialized
    onInitializedArr.push(fn);
  }
}

const getParentAnchor = (child: HTMLElement | null): HTMLElement | null => {
  if (!child || child.tagName === 'A') return child

  return getParentAnchor(child.parentElement);
}

const interceptClickEvents = (e: MouseEvent) => {
  try {
    const target = getParentAnchor(e.target as HTMLElement | null) as HTMLAnchorElement | null;
    if (target) {
      const href = target.getAttribute('href') as string;
      const linkTarget = target.getAttribute('target') as string;
      // only do valid links
      if (href.includes('/')) {
        if (target.classList.contains('no-intercept')) {
          if (target.classList.contains('toggle-loading')) {
            sendIframeMessage({loading: true})
          }
        } else {
          // todo handle targets
          e.preventDefault();
          e.stopPropagation();
          // send message to parent frame
          sendIframeMessage({
            linkClicked: {
              href: href,
              target: linkTarget
            }
          })
        }
      }
    }
  } catch (e) {
    console.error(e);
  }
}

let previousLocation: null | string = null;

const locationChangeMonitor = () => {
  // send a url change to parent
  if (JSON.stringify(window.location) !== previousLocation) {
    previousLocation = JSON.stringify(window.location);
    sendIframeMessage({
      location: window.location
    });
  }
  // call again in half a second
  setTimeout(() => {
    locationChangeMonitor();
  }, 500)

}

export const initialize = async () => {
  if (isLegacy()) {
    const token = await sessionToToken();
    setAccessToken(token);

    const roles: string[] = [];
    if (window?.role?.isAdmin) roles.push('ROLE_ADMIN');
    if (window?.role?.isSuperAdmin) roles.push('SUPERADMIN');
    // Set if user can edit presets for smart tables on admin
    if (
      window?.role?.username &&
      //@ts-ignore
      (usersThatCanSavePresets.includes(window.role.username) || rolesThatCanSavePresets.some(x => roles.includes(x)))
    ) {
      canSavePresetsState.set(true);
    }
    // send ready message
    sendIframeMessage({
      loaded: true
    });
  }

  // set if in iframe
  const isInIframe = Boolean(window !== window.parent);
  isInIframeState.set(isInIframe);

  //add class to body for iframe if in one.
  try {
    if (isInIframe) {
      document.body.classList.add('inFrame');
      const params = getQueryParams();
      if('auth' in params && params.auth) {
        console.log('REAUTHENTICATE')
        window.location.href = `${getBaseUri()}login/tokenToSessionAuth?access_token=${getAccessToken()}`;
      }
    } else {
      // prevent animation effect
      const mainContainer = document.getElementById('main-container') as HTMLDivElement;
      if(mainContainer) mainContainer.classList.add('noTransition');
      document.body.classList.remove('inFrame');
      window.setTimeout(() => {
        if(mainContainer) mainContainer.classList.remove('noTransition');
      }, 500)
    }
  } catch (e) {
    console.log(e)
  }

  // intercept links
  if (isInIframe) {
    locationChangeMonitor();
    //listen for link click events at the document level
    if (document.addEventListener) {
      document.addEventListener('click', interceptClickEvents);
      //@ts-ignore
    } else if (document.attachEvent) {
      //@ts-ignore
      document.attachEvent('onclick', interceptClickEvents);
    }
    sendIframeMessage({
      location: window.location
    });
  }

  disableNumberScroll();
  isInitialized = true;

  // handle all on initialized functions
  onInitializedArr.forEach((fn) => {
    fn();
  })

  // set initialization as done
  isInitializedState.set(true);

  getSystemConfigUser(SystemConfigEnum.TIME_ZONE_KEY)
    .then(timeZoneConfig => timeZoneState.set(timeZoneConfig?.keyValue || ''))
    .catch(() => timeZoneState.set(''));
}