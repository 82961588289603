import axios from 'axios';
import getAdminApiPath from "@hornet-api/getAdminApiPath";
import {IBudgetDetail} from "@interfaces/loan/admin/budget";
import {sortBudgetChildren} from "./utils";

export const addComment = (id: number, comment: string, isPublic: boolean) => {
  const url = `${getAdminApiPath()}/budget/${id}/note`;
  return axios.post<IBudgetDetail>(url, {
    content: comment,
    isPublic
  })
    .then((response) => sortBudgetChildren(response.data));
};