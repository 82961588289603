import React, {useMemo} from "react";
import {NavigationObjectItem} from "@components/Navigation/getNavigationObject";
import NavLink from "@components/Navigation/NavLink";
import Nav from "react-bootstrap/Nav";
import activeRoleState from "@state/globalState/activeRoleState";
import userRoleGroupsState from "@state/globalState/userRoleGroupsState";
import {roleBasedChildrenRenderer} from "@components/Navigation/Mobile/NavigationMenuRenderer";

type NavLinkProps = {
  nav: NavigationObjectItem,
  linkClick: (e: React.MouseEvent) => void
}

const NavLinkMobile = ({nav, linkClick}: NavLinkProps) => {
  const activeRole = activeRoleState.useValue();
  const userRoleGroups = userRoleGroupsState.useValue();

  const roleBasedChildren = useMemo(() => {
    return roleBasedChildrenRenderer(nav, linkClick, activeRole, userRoleGroups);
  }, [nav, userRoleGroups, activeRole]);

  if (nav.children?.length) {
    return (
      <React.Fragment>
        <li className='group-title'>
          {nav.text}
        </li>
        {roleBasedChildren}
      </React.Fragment>
    )
  } else {
    return (
      <NavLink
        Component={Nav.Link}
        nav={nav}
        classes={{
          Component: nav.to && nav.to === location.pathname ? 'selected' : '',
          CounterBadge: 'ml-2'
        }}
        linkClick={linkClick}
      />
    )
  }
}

export default NavLinkMobile;