import axios from 'axios';
import {getTaskUrl} from './util';

import {UserBase} from '@interfaces/task';

const updateAdminName = async (name: string) => {
  let url = getTaskUrl();
  url += `/updateAdminName`;

  const response = await axios.post(url, {name: name});
  return response.data as UserBase;
};

export default updateAdminName;
