import {checkFirebaseSubscription, removeFirebaseSubscription, saveFirebaseSubscription} from '@hornet-api/firebase';
import {addAlert} from "@components/Alert";
import {initializeApp} from "firebase/app";
import {getMessaging, getToken, onMessage} from "firebase/messaging";


export type FirebaseSubscription = {
  recipientToken: string;
};

export type FirebaseConfigType = {
  apiKey: string,
  authDomain: string,
  projectId: string,
  storageBucket: string,
  messagingSenderId: string,
  appId: string,
  measurementId?: string,
}

export const isFirebaseCompatible = () => {
  // Check if the necessary features are supported
  const indexedDBSupported = 'indexedDB' in window;
  const webSocketsSupported = 'WebSocket' in window;
  const serviceWorkersSupported = 'userAgent' in navigator;
  const notificationSupported = 'Notification' in window;

  if (!indexedDBSupported || !webSocketsSupported || !serviceWorkersSupported ||!notificationSupported) {
    return false;
  }

  // Check if the browser is Safari and its version
  const ua = navigator.userAgent;
  const isSafari = /Safari/.test(ua) && !/Chrome/.test(ua);
  if (isSafari) {
    const safariVersionMatch = ua.match(/Version\/([0-9._]+)/);
    const safariVersion = safariVersionMatch ? parseFloat(safariVersionMatch[1]) : 0;
    console.log("safariVersion :- ", safariVersion)
    return safariVersion > 16.1;
  }
  // If not Safari or Safari version is above the threshold
  return true;
};

export const firebaseSubscriptionRemoved = async () => {
  try {
    const recipientToken = localStorage.getItem('fcm-recipient-token');
    if (recipientToken) {
      await removeFirebaseSubscription({recipientToken});
    }
  }catch (e){}
};

export const enableNotifications = async () => {
  try {
    const recipientToken = localStorage.getItem('fcm-recipient-token');
    if (recipientToken) {
      await saveFirebaseSubscription(recipientToken);
    } else {
      addAlert({
        type: "danger",
        content: "Please enable browser notifications and reload page."
      })
    }
  } catch (err) {
    console.log('Unable to get permission to notify.', err);
  }
};

export const checkSubscription = async () => {
  const recipientToken = localStorage.getItem('fcm-recipient-token');
  if (recipientToken) {
    return await checkFirebaseSubscription({recipientToken});
  }
  return false;
};
export const firebaseNotifications = async (vapidKey: string, firebaseConfig: FirebaseConfigType) => {
  if ('serviceWorker' in navigator) {
    try {
      const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js');
      console.log('Service Worker registered with scope:', registration.scope);
      if (registration.active) {
        registration.active.postMessage({
          type: 'SETUP',
          vapidKey,
          firebaseConfig,
        });
      }
      const app = initializeApp({...firebaseConfig}, "Hornet");
      const fcmMessaging = getMessaging(app);
      const token = await getToken(fcmMessaging, {vapidKey: vapidKey, serviceWorkerRegistration: registration});
      localStorage.setItem('fcm-recipient-token', token);
      onMessage(fcmMessaging, (payload) => {
        console.log("Message received. ", payload);
        if (!payload.data) return
        const {title, icon = 'https://secure.hornet.capital/android-chrome-192x192.png', ...rest} = payload.data;
        new Notification(title, {icon, ...rest});
      });
    } catch (e) {
      console.log("Error FCM", e)
    }
  }
}



