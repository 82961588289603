import axios from 'axios';
import getPublicApiPath from "@hornet-api/getPublicApiPath";
import {IOneTimeLoanPayment} from "@interfaces/oneTimeLoanPayment/user/OneTimeLoanPayment";

const getOneTimeLoanPayment = async (token: string) => {
  const url = `${getPublicApiPath()}/loanPayment/oneTime/${encodeURIComponent(token)}`;
  const response = await axios.get<IOneTimeLoanPayment>(url);
  return response.data;
};

export default getOneTimeLoanPayment;
