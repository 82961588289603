import axios from 'axios';
import getUserApiPath from '../getUserApiPath';
import {MaritalStatusEnum, SexEnum} from '@interfaces/GeneratedEnums';
import {SearchContactItem} from './searchContact';

export interface ContactForm {
  firstName: string,
  middleName: string,
  lastName: string,
  email: string,
  phone: string,
  sex: keyof typeof SexEnum,
  maritalStatus: keyof typeof MaritalStatusEnum,
  isEscrowOfficerActive?: boolean
}

const createContact = async (contact: ContactForm) => {
  const url = `${getUserApiPath()}/contact/create`;
  const response = await axios.post(url, contact);
  return response.data as SearchContactItem;
};

export default createContact;
