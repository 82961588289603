import getAdminApiPath from "@hornet-api/getAdminApiPath";
import axios from "axios";
import {ExternalOauthProviderEnum} from "@interfaces/GeneratedEnums";

export interface IProviderEnumKeyMap {
  provider: keyof typeof ExternalOauthProviderEnum; // example: QUICKBOOKS
  providerConfigKey: string; // example: qb-hornet-management (QuickBooks - Hornet Management)
}

export interface INangoConfig {
  serverHost: string;
  configKeys: IProviderEnumKeyMap[],
}

const fetchNangoConfig = () => {
  const url = `${getAdminApiPath()}/nango/config`;
  return axios.get(url).then((response) => {
    return response.data as INangoConfig;
  })
}

export default fetchNangoConfig;