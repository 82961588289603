import { createGlobalState } from 'react-global-hooks';
import AuthenticationBlob from '@interfaces/AuthenticationBlob';

export const defaultAuthenticationBlob = (): AuthenticationBlob => ({
  active_user: null,
  impersonation_user: null,
});

const authenticationBlobState = createGlobalState(
  defaultAuthenticationBlob() as AuthenticationBlob
);

export default authenticationBlobState;
