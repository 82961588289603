import React, {useEffect, useState} from 'react';

import style from "./ProfilePage.module.scss"
import getContactForProfile from "@hornet-api/contact/getCurrentContact";
import {ContactProfile} from "@interfaces/ContactAdmin";
import {loadingRelease, loadingTrigger} from "@components/LoadingOverlay";
import moment from "moment";
import {Link} from "react-router-dom";
import SecuritySettingsSection from "@legacy/views/UserProfileLegacy/SecuritySettingsSection";

export default function ProfilePage() {
  const dateFormat = 'MM/DD/YYYY';
  const [contact, setContact] = useState<ContactProfile>();
  useEffect(() => {
    getCurrentContact();
  }, [])
  const getCurrentContact = () => {
    const t = loadingTrigger();
    getContactForProfile()
      .then(setContact)
      .finally(() => loadingRelease(t));
  };

  return (
    <div className={style.container}>
      <section className="content-header">
        <div className="row">
          <div className="col">
            <div className="header-title">
              <h1>Profile</h1>
              <small>User Profile</small>
            </div>
          </div>
        </div>
      </section>
      <div className={style.container}>
        <div className="row">
          <div className="col-lg-12 pinpin">
            {contact && <table>
                <tbody>
                <tr>
                    <th>Username</th>
                    <td>
                        <Link to={`/contact/show/${contact.id}`}>{contact.username}</Link>
                    </td>
                </tr>
                <tr>
                    <th>Full Name</th>
                    <td>
                      {contact.firstName}
                      {contact.middleName
                        ? ` ${contact.middleName}`
                        : null}{" "}
                      {contact.lastName}
                    </td>
                </tr>
                <tr>
                    <th>Marital Status</th>
                    <td>{contact.maritalStatus}</td>
                </tr>
                <tr>
                    <th>Sex</th>
                    <td>{contact.sex}</td>
                </tr>
                <tr>
                    <th>Birthday</th>
                    <td>
                      {contact.birthday
                        ? moment(contact.birthday).format(dateFormat)
                        : ""}
                    </td>
                </tr>
                <tr>
                    <td colSpan={2}>
                        <SecuritySettingsSection
                            classFieldName={'margin-right-custom'}
                            classFieldValue={`col-form-label`}
                        />
                    </td>
                </tr>
                </tbody>

            </table>}
          </div>
        </div>
      </div>
    </div>
  );
}
