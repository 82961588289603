import { selector } from 'recoil';
import configurationAtom from './configurationAtom';

const apiUrlSelectorAtom = selector({
	key: 'apiUrlSelectorAtom',
	default: null,
	get: ({ get }) => {
		const configuration = get(configurationAtom);
		if (!configuration) return null;
		return configuration.api_uri_legacy;
	},
});

export default apiUrlSelectorAtom;
