import React, { useMemo } from 'react';
import style from './style.module.scss';

type Props = {
  num: number
}
export const BadgeSpace = () => {
  return <div className={style.badgeSpace} />
}

const Badge = ({
  num
}: Props) => {
  const numDisplay = useMemo(() => {
    if (num > 9) {
      return '9+';
    } else {
      return `${num}`;
    }
  }, [num]);

  if (num === 0) {
    return (<BadgeSpace />);
  }
  return (
    <div className={style.badge}>
      {numDisplay}
    </div>
  );
}

export default Badge;