import React, { useEffect, useCallback, useRef, useMemo } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import SettingsSaver from './lib/SettingsSaver';
import { Column } from './Types';
import Store from './globalState';
import ShowHideColumnsTooltip from './ShowHideColumnsTooltip';
import FontAwesomeIcon from './lib/FontAwesomeIcon';
import style from './style.module.scss';
import classNames from 'classnames';
import {FaBan, FaShapes} from "react-icons/fa";
import Tooltip from "react-bootstrap/Tooltip";

type IShowHideColumnsButtonProps<D> = {
  columns: Column<D>[];
  store: Store<D>;
  settingsSaver: SettingsSaver<D>;
};

function ShowHideActionBarButton<D>({
  columns,
  store,
  settingsSaver,
}: IShowHideColumnsButtonProps<D>) {
  const [showActionBar, setShowActionBar] = store.showActionBarState.use();

  const hasActionBars = useMemo(() => {
    return columns.some((column) => column.actionBar);
  }, [columns]);

  const handleShowHideActionBarClick = useCallback(() => {
    setShowActionBar(!showActionBar);
  }, [setShowActionBar, showActionBar]);

  if (!hasActionBars) {
    return null;
  }

  return (
    <div className={style.showHideActionBarContainer}>
      <OverlayTrigger placement="bottom-end" overlay={<Tooltip id="show-hide-action-bar-tt">{showActionBar ? 'Hide' : 'Show'} Action Bar</Tooltip>}>
        <button
          className={classNames('btn btn-sm btn-warning', showActionBar ? style.showHideActionBarButton : style.showHideActionBarButtonInactive)}
          onClick={handleShowHideActionBarClick}
        >
          <FaShapes />
        </button>
      </OverlayTrigger>
    </div>
  );
}

export default ShowHideActionBarButton;
