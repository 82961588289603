import React from 'react';
import style from './style.module.scss';
import {hornetBlue, hornetOrange, hornetWhite,} from '@common/hornetColors';

export type Props = {
  size?: number,
  shipColor?: string,
  thrusterColor?: string,
  ringColor?: string,
  ringBackgroundColor?: string
}

const HornetIcon = ({
  size = 50,
  ringColor = hornetBlue,
  ringBackgroundColor = 'rgba(0,0,0,0.1)',
  shipColor = hornetBlue,
                      thrusterColor = hornetOrange
}: Props) => {
  const ringBorderWidth = Math.round(size * 0.08);
  return (
    <div
      style={{
        width: size,
        height: size,
        position: 'relative',
        margin: '0 auto'
      }}
    >
      {/* Ring */}
      <div
        className={style.circle}
        style={{
          border: `${ringBorderWidth}px solid ${ringColor}`,
          borderTopColor: ringBackgroundColor,
          borderRightColor: ringBackgroundColor,
          borderBottomColor: ringBackgroundColor,
        }}
      ></div>

      {/* Ship  */}
      <svg
        version="1.1" id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px" y="0px"
        width="100%" viewBox="0 0 600 600"
        className={style.iconPart}

      >
        <path fill={shipColor} opacity="1.000000" stroke="none"
              d="
M384.531189,420.462708
	C361.663757,400.771149 339.024384,381.358978 316.518799,361.792908
	C314.068176,359.662384 312.482483,359.347473 309.795227,361.333374
	C303.371155,366.080658 296.769318,370.604523 290.056763,374.935730
	C287.475098,376.601593 286.708527,378.474304 286.722046,381.444611
	C286.881470,416.437408 286.865295,451.431000 287.038635,486.423676
	C287.054810,489.682617 286.238403,492.105347 283.837341,494.296356
	C280.764435,497.100372 278.034485,500.276917 275.010071,503.137909
	C267.625977,510.123108 261.254242,509.508881 255.372681,501.356415
	C253.511810,498.776978 252.107193,495.972504 250.988907,492.986877
	C245.449081,478.196594 239.795670,463.448639 234.322144,448.634064
	C233.362457,446.036560 232.529419,444.557526 229.543747,446.282776
	C226.701233,447.925293 223.986511,446.622925 221.553772,445.145142
	C211.363922,438.955261 204.341492,430.106293 200.139435,419.004059
	C198.736542,415.297485 199.173325,411.883606 202.018509,408.832916
	C203.829575,406.891113 205.237076,404.576202 207.017731,402.601898
	C209.001923,400.401886 207.529327,399.033569 206.123398,397.548065
	C204.630936,395.971100 203.156769,395.690704 201.431244,397.319275
	C200.103806,398.572174 198.396988,399.448364 197.172119,400.780182
	C192.402313,405.966400 187.081360,405.678864 181.317352,402.763641
	C173.156036,398.636017 166.146591,393.128998 160.792999,385.672272
	C158.127518,381.959717 155.856308,378.112305 158.240219,373.243256
	C159.751389,370.156769 156.627899,370.543762 155.326691,370.050476
	C140.869766,364.570221 126.369057,359.205017 111.864792,353.850403
	C107.770500,352.338898 103.911362,350.436584 100.596092,347.564789
	C95.088142,342.793579 94.244789,337.435974 98.888367,331.811462
	C102.795410,327.079071 107.453995,322.967957 111.769257,318.571350
	C113.416382,316.893188 115.517838,317.466339 117.453011,317.461700
	C152.279282,317.378143 187.105774,317.278076 221.931793,317.360626
	C225.506332,317.369080 227.691727,316.345184 229.629807,313.309631
	C233.748734,306.858246 238.095764,300.529205 242.731415,294.440979
	C245.007507,291.451691 244.612305,289.619293 242.272202,286.937836
	C215.432968,256.183258 188.712524,225.324997 161.964874,194.490494
	C149.628204,180.268845 137.381683,165.967499 124.899857,151.874512
	C122.504036,149.169434 122.821335,147.514038 125.145515,145.240021
	C146.345093,124.497940 167.458115,103.667252 188.698593,82.967308
	C191.417847,80.317253 194.653122,78.168770 197.785538,75.983170
	C199.215927,74.985146 201.195831,73.600639 202.716904,75.252602
	C204.043411,76.693237 202.469131,78.401344 201.610703,79.722679
	C197.876083,85.471115 193.251938,90.510963 188.658813,95.566200
	C184.737305,99.882256 180.861023,104.244644 176.799011,108.425537
	C174.802475,110.480499 174.502808,111.545357 177.543411,112.838936
	C202.329544,123.383827 227.074539,134.025833 251.797836,144.717422
	C253.976395,145.659531 255.393066,145.328827 257.006073,143.647797
	C261.042175,139.441544 265.213470,135.362259 269.402405,131.306259
	C274.155792,126.703735 279.775696,123.538483 286.143921,121.776451
	C290.779755,120.493759 292.053314,121.647018 290.963867,126.400764
	C289.361847,133.391113 285.525787,139.138428 280.747040,144.426239
	C278.140137,147.310822 272.981354,149.806671 273.802734,153.298508
	C274.352142,155.634171 280.123352,156.764420 283.579071,158.389008
	C291.968719,162.333084 291.909393,162.254700 298.829102,155.690201
	C304.849579,149.978745 310.152039,143.353271 317.852051,139.681396
	C321.035126,138.163498 324.791534,135.129379 327.934784,138.167145
	C330.858948,140.993179 327.942780,144.763489 326.791779,147.885956
	C323.993713,155.476608 317.745789,160.532379 312.603912,166.391388
	C311.738129,167.377930 310.913116,168.400208 309.832977,169.687576
	C318.469788,173.458389 326.849213,177.055115 335.167755,180.787491
	C337.562378,181.861908 338.837067,180.522781 340.337616,179.126373
	C349.119690,170.953674 358.263031,163.140640 366.678223,154.608353
	C383.188263,137.868591 402.673798,126.140404 425.000061,119.266541
	C437.167267,115.520493 447.795624,109.338013 457.820343,101.760300
	C466.718048,95.034554 475.464081,88.083046 485.010620,82.265442
	C491.279266,78.445396 497.718994,74.964294 505.137665,73.904449
	C506.931885,73.648125 508.076416,72.429695 509.352325,71.387779
	C517.609619,64.644768 525.778809,57.791039 534.128357,51.164562
	C543.295654,43.889091 553.192749,37.758179 564.148132,33.522327
	C566.658630,32.551655 569.869873,30.123888 571.921448,32.202133
	C573.649780,33.952908 571.484619,37.205780 570.534546,39.624866
	C565.617920,52.143600 558.333679,63.245659 549.866211,73.614670
	C546.810730,77.356361 543.705322,81.057190 540.628662,84.781631
	C535.264099,91.275711 530.250732,97.655220 527.759827,106.232483
	C524.522888,117.378693 516.421326,126.064072 509.298584,135.077957
	C506.097046,139.129517 502.800842,143.106216 499.556915,147.124344
	C492.511078,155.851761 486.498688,165.074982 483.221130,176.027206
	C476.264923,199.271942 464.081390,219.407394 446.772430,236.540161
	C438.492096,244.736176 430.965271,253.689423 422.956512,262.166840
	C421.076233,264.157135 420.728851,265.759613 421.880463,268.299133
	C425.502899,276.287323 428.841919,284.404022 432.541473,293.057648
	C436.634613,289.210205 440.358612,285.698334 444.095551,282.200226
	C448.433807,278.139160 453.443573,275.261963 459.251953,273.930634
	C464.433472,272.743011 466.202881,274.614655 464.592041,279.750214
	C462.923309,285.070038 459.930054,289.664917 456.073364,293.690521
	C451.577972,298.382751 447.174072,303.168152 442.541901,307.721313
	C440.632904,309.597687 439.960114,311.089142 441.346344,313.682465
	C443.615295,317.927124 445.547821,322.365356 447.396362,326.815796
	C448.492126,329.453857 449.491364,329.694733 451.574463,327.684845
	C455.286163,324.103607 459.077637,320.576080 463.095306,317.349274
	C466.891449,314.300415 471.379761,312.372772 476.113312,311.244171
	C480.413513,310.218872 481.899994,311.701477 480.661072,315.927429
	C478.815155,322.223541 475.865906,327.989960 471.159149,332.696594
	C467.035675,336.819855 462.882294,340.914062 458.689240,344.966431
	C457.274384,346.333801 456.409363,347.550446 457.353241,349.715393
	C468.646423,375.618256 479.858612,401.556427 491.268890,427.893555
	C497.712250,421.870544 503.839020,416.074677 510.046051,410.366180
	C513.970764,406.756683 518.026489,403.278015 522.597351,400.497528
	C524.061951,399.606567 525.896179,397.840424 527.482727,399.555817
	C528.960938,401.154083 527.310242,403.001862 526.432495,404.517426
	C523.996033,408.724518 520.813477,412.395874 517.443115,415.846191
	C497.533142,436.228394 477.517609,456.507507 457.618896,476.900665
	C455.042816,479.540771 453.265778,479.822845 450.253571,477.194733
	C428.536133,458.246460 406.638641,439.504547 384.531189,420.462708
z"/>
        <path fill={thrusterColor} opacity="1.000000" stroke="none"
              d="
M96.556419,544.547424
	C72.949493,560.602539 49.631470,576.439941 25.914583,592.548340
	C25.658195,589.920532 27.192324,589.233398 28.134199,588.278564
	C77.596695,538.139954 127.105515,488.046997 176.519867,437.861053
	C179.460175,434.874817 180.311310,434.873077 182.221634,438.896942
	C188.072388,451.220825 196.661331,461.139343 210.448853,467.084351
	C172.306122,493.019745 134.575729,518.674744 96.556419,544.547424
z"/>
        <path fill={thrusterColor} opacity="1.000000" stroke="none"
              d="
M11.460572,576.724609
	C25.517872,555.725769 39.670002,534.969177 53.890640,514.259644
	C80.642349,475.301056 107.432838,436.369110 134.212646,397.429810
	C134.960373,396.342590 135.770050,395.297974 136.888351,393.773376
	C143.227493,408.853882 155.026184,417.755737 169.528198,424.378204
	C117.040321,475.437225 64.899353,526.158813 12.452452,577.016541
	C11.959786,577.097839 11.773055,577.042908 11.460572,576.724609
z"/>
        <path fill="#F79426" opacity="1.000000" stroke="none"
              d="
M11.954710,576.966797
	C11.954563,577.426697 11.762600,577.700623 11.130968,577.818481
	C10.677240,577.328674 10.782283,577.038208 11.170628,576.625977
	C11.477518,576.567688 11.620207,576.674255 11.954710,576.966797
z"/>
        <path fill="#F79426" opacity="1.000000" stroke="none"
              d="
M10.968305,577.966797
	C10.801489,578.275513 10.541507,578.483582 10.281527,578.691589
	C10.267690,578.445862 10.253852,578.200073 10.465655,577.808350
	C10.691298,577.662292 10.875139,577.866028 10.968305,577.966797
z"/>
        <path fill="#F79427" opacity="1.000000" stroke="none"
              d="
M24.289545,593.065857
	C24.130173,592.575256 24.224314,592.107849 24.853338,592.432251
	C24.866375,592.438904 24.658199,592.874512 24.289545,593.065857
z"/>
        <path fill="#F79427" opacity="1.000000" stroke="none"
              d="
M23.966034,592.969971
	C24.158640,593.409912 24.076591,593.702026 23.513031,593.684143
	C23.500923,593.683777 23.500965,593.299133 23.760597,593.057739
	C24.025858,593.021729 23.986134,592.987000 23.966034,592.969971
z"/>
        <path fill={hornetWhite} opacity="1.000000" stroke="none"
              d="
M356.865601,259.863495
	C347.704651,266.253906 338.854645,272.470306 329.107880,277.242126
	C327.678009,277.942139 325.802521,279.482330 324.603821,278.381226
	C322.968628,276.879181 324.791077,274.946075 325.562042,273.454498
	C333.923706,257.276855 344.792389,242.748901 355.653656,228.231339
	C364.928589,215.834198 374.792511,203.907730 384.822144,192.112305
	C386.806000,189.779221 388.835297,189.041855 391.737610,189.378036
	C404.220337,190.823944 412.910767,199.840637 413.232574,211.654358
	C413.297424,214.034836 412.576019,215.624969 410.754059,217.211411
	C393.546448,232.194656 375.758728,246.436966 356.865601,259.863495
z"/>
        <path fill={hornetWhite} opacity="1.000000" stroke="none"
              d="
M423.593750,151.596832
	C424.339050,150.901947 424.874664,150.490509 425.312134,149.992752
	C429.996185,144.662903 435.196198,141.412399 442.793121,143.988022
	C450.416687,146.572693 455.799255,151.158646 458.475739,158.800522
	C460.482635,164.530624 460.150024,169.306412 455.370483,174.146988
	C443.685944,185.980820 432.079834,197.871033 419.296082,209.519653
	C416.326752,194.727966 407.943115,186.030594 392.691925,183.494385
	C402.526825,172.183151 412.668152,161.763229 423.593750,151.596832
z"/>
        <path fill={hornetWhite} opacity="1.000000" stroke="none"
              d="
M486.304626,124.293564
	C486.484802,130.453949 483.993713,135.347549 481.319550,140.143341
	C477.025726,147.843872 471.754730,154.851379 465.527985,162.153275
	C462.422394,148.270782 454.233765,140.105942 439.792450,137.402466
	C451.026031,128.732559 461.499329,120.379913 474.974976,117.292168
	C481.051331,115.899857 484.671906,118.258965 486.304626,124.293564
z"/>
      </svg>
    </div>
  );
}

export default HornetIcon;