import getAdminApiPath from "@hornet-api/getAdminApiPath";
import axios from "axios";
import {PropertyOption} from "@interfaces/Property";

export interface PropertyOptions extends PropertyOption {
  type: string | null;
  category: string | null;
  contact: number | null;
  company: number | null;
  loans: string;
}

export const getPropertyListOptions = async () => {
  const url = `${getAdminApiPath()}/property/listOptions`;
  const response = await axios.get(url);
  return response.data as PropertyOptions[];
};