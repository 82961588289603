import React, {useCallback, useRef} from 'react';
import FontAwesomeIcon from '../../../../components/icons/FontAwesomeIcon';
import Pressable from '../../../../components/buttons/Pressable';
import styles from './styles';

const UploadImageButton = ({
	style,
	onChange
}) => {
	const fileInputRef = useRef();

	const handleOnClick = useCallback((event) => {
		if (event && event.preventDefault) event.preventDefault();
		const fileInput = fileInputRef.current;
		if (fileInput) fileInput.click();
	}, [fileInputRef]);

	const handleOnChange = useCallback(() => {
		const fileInput = fileInputRef.current;
		const files = fileInput.files;

		if (typeof onChange === 'function') onChange(files);
		fileInputRef.current.value = null;
	}, [onChange]);

	const containerStyle = useRef(() => ({ 
		...styles.style1,
		...(style || {})
	}), [style]);

	return(
		<div style={containerStyle}>
			<Pressable className="btn btn-primary" onClick={handleOnClick}>
				<FontAwesomeIcon icon="camera" /> Upload Image
			</Pressable>
			<input
				type="file"
				ref={fileInputRef}
				onChange={handleOnChange}
				accept="image/*"
				style={styles.style2}
				multiple
			/>
		</div>
	);
};

export default UploadImageButton;
