import axios from 'axios';
import {getTaskUrl} from './util';
import {Task} from '@interfaces/task';
import {TaskStatusEnum, TaskTargetEnum,} from '@interfaces/GeneratedEnums';
import {CreateTaskFormData} from "@admin-ui/pages/LoanShowPage/components/AddTaskToLoanModal";
import convertJsonToFormData from "@common/utils/form/convertJsonToFormData";
import getAdminApiPath from "@hornet-api/getAdminApiPath";

export type CreateTaskOptions = {
  contactId?: number,
  companyId?: number,
  title: string,
  description: string,
  taskStatus?: keyof typeof TaskStatusEnum
  loanId?: string,
  target?: keyof typeof TaskTargetEnum
}
const createTask = async ({
  contactId,
  companyId,
  title,
  description,
  taskStatus = 'PENDING',
  loanId,
  target
}: CreateTaskOptions) => {
  let url = getTaskUrl();
  if (companyId) {
    url += `/entity/${companyId}/task`;
  } else if (contactId) {
    url += `/contact/${contactId}/task`;
  } else {
    throw 'Either contactId or companyId must be passed to create task function';
  }
  const data = {
    taskStatus,
    title,
    description,
    target
  };
  if(loanId) {
    //@ts-ignore
    data.loanId = loanId;
  }
  const response = await axios.post(
    url,
    data
  );
  return response.data as Task;
};

export default createTask;

export const createUpdateTaskWithAttachment = async (data: CreateTaskFormData, taskId?: number) => {
  const {owner, loanId, ...createTaskData} = data;
  const loanIdStr = typeof loanId === 'string' ? loanId : loanId?.value
  const [ownerType, ownerId] = (typeof owner === 'string' ? owner : owner?.value)?.split('-') || [];

  const formData = convertJsonToFormData({
    ...createTaskData,
    ownerId,
    ownerType,
    loanId: loanIdStr
  });

  const url = taskId ? `${getAdminApiPath()}/task/withAttachment/${taskId}` : `${getAdminApiPath()}/task/withAttachment`;
  return await axios.post(url, formData).then(({data}) => data);
};
