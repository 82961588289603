import React, {useEffect, useMemo} from "react";
import Toggle from "@components/Toggle";
import {
  openTwoFactorAuthenticationModal,
  twoFactorAuthState
} from "@legacy/views/UserProfileLegacy/TwoFactorAuthenticationModal";
import {profileState} from "@legacy/views/UserProfileLegacy/globalState";
import FontAwesomeIcon from "@admin/components/icons/FontAwesomeIcon";
import {fetchUser2FA} from "@hornet-api/twoFactorAuth/user/fetchUser2FA";
import {loadingRelease, loadingTrigger} from "@components/LoadingOverlay";
import Button from "react-bootstrap/Button";
import disable2FA from "@hornet-api/twoFactorAuth/user/disable2FA";
import {alertApiErrors} from "@common/errors";

type Props = {
  classFieldName: string;
  classFieldValue: string;
};
const SecuritySettingsSection = ({classFieldName, classFieldValue}: Props) => {
  const profile = profileState.useValue();
  const [user2FA, setUser2FA] = twoFactorAuthState.use();

  const fetch2FA = () => {
    const t = loadingTrigger();
    fetchUser2FA()
      .then(setUser2FA)
      .finally(() => loadingRelease(t));
  }

  useEffect(fetch2FA, [])

  const handle2FAChange = (newVal: boolean) => {
    if (newVal) {
      // if user tries to enable it, start the process of setting it up
      openTwoFactorAuthenticationModal();
    } else {
      const t = loadingTrigger();
      disable2FA()
        .then(fetch2FA)
        .catch(alertApiErrors)
        .finally(() => loadingRelease(t));
    }
  }

  const disabledReason = useMemo(() => {
    if (!profile || !user2FA) return 'Loading...';
    if (profile.locked) return 'This Profile is locked for Modification.';
    if (!profile.hasUser) return 'This contact does not have a user.';
    if (user2FA?.isEnabled && !user2FA?.isTurnedOffByAdmin) {
      return "Two Factor Authentication can't be disabled"
    }
    return ''
  }, [profile, profile?.locked, profile?.hasUser, user2FA, user2FA?.isTurnedOffByAdmin]);

  return (<>
    <h3>Security</h3>
    <div className="form-group row">
      <label className={classFieldName}>
        Two Factor Authentication
        {
          !!user2FA?.isEnabled &&
            <i><small>{` ${user2FA.phoneNumber}`}</small></i>
        }
      </label>
      <div className={classFieldValue}>
        <Toggle
          className={'align-middle'}
          isOn={!!user2FA?.isEnabled}
          onChange={handle2FAChange}
          disabledReason={disabledReason}
        />
        {profile && profile.locked && <FontAwesomeIcon className='ml-2' icon='lock'/>}

        {!!user2FA?.isEnabled && profile && (
          <Button
            variant={'success'} size={'sm'} className={'ml-2'}
            onClick={openTwoFactorAuthenticationModal}
            disabled={profile.locked}
          >
            Update 2FA Phone number
            {profile.locked && <FontAwesomeIcon className='ml-2' icon='lock'/>}
          </Button>
        )}
      </div>
    </div>
  </>);
}

export default SecuritySettingsSection;