import React from 'react';
import { useRecoilValue } from 'recoil';
import appReadyStateSelectorAtom from '@state/recoil/appReadyStateSelectorAtom';
import ApplicationLoading from '@components/ApplicationLoading';

const ApplicationLoadingView = () => {
	const readyState = useRecoilValue(appReadyStateSelectorAtom);

	if (readyState) return null;

	return (
		<ApplicationLoading />
	);
};

export default ApplicationLoadingView;
