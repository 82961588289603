import React from 'react';
import errorImage from './crash.png';
import styles from './RedBox.styles.module.scss';

const RedBox = () => (
	<div className={styles.container}>
		<img className={styles.image} src={errorImage} alt="Crash" aria-hidden />
		<div className={styles.heading}>Oh No!</div>
		<div className={styles.body}>
			An error has occurred with this component. The error was automatically
			sent to us. We will have this issue resolved shortly.
		</div>
	</div>
);

export default React.memo(RedBox);
