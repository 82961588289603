import axios from 'axios';
import getUserApiPath from '../getUserApiPath';
import {ACHStatusEnum} from '@interfaces/GeneratedEnums';
import {payerTokenState} from "@pages/OneTimeLoanPaymentPage/global";
import getPublicApiPath from "@hornet-api/getPublicApiPath";

/**
 * Authorize a transaction
 *
 * CLIENT UI:
 * 	Controller: 	server/grails-app/controllers/hornetcrm/api/v1user/task/TransactionController.groovy
 * 	Method:				{@link hornetcrm.api.v1user.task.TransactionController#authorize}
 * 	GSON: 			  server/grails-app/views/v1user/loan/myLoans.gson
 */
const authorizeTransaction = async (
  transactionId: number,
  transactionStatus: keyof typeof ACHStatusEnum,
  scheduledDate?: Date | null | string
) => {
  const token = payerTokenState.get();
  const url = token ?
    `${getPublicApiPath()}/loanPayment/oneTime/${encodeURIComponent(token)}/transaction/authorize`
    : `${getUserApiPath()}/contact/transaction/${transactionId}/authorize`;
  const body: any = {
    transactionStatus: transactionStatus,
    scheduledDate: scheduledDate
  };
  await axios.put(url, body);
};

export default authorizeTransaction;
