import axios from 'axios';
import getUserApiPath from '../getUserApiPath';

const setActiveRoleForUser = async (roleGroupId: number | string) => {
  const url = `${getUserApiPath()}/contact/activeRole/${roleGroupId}`;
  const response = await axios.post(
    url,
    {}
  );
  return response.data as any;
};

export default setActiveRoleForUser;
