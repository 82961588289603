import React, { useMemo } from 'react';

export type FontAwesomeIconSizeType = 'sm' | 'lg' | '2x' | '3x' | '4x' | '5x';

export interface IFontAwesomeIconProps {
	className?: string,
	title?: string,
	icon: string,
	size?: FontAwesomeIconSizeType,
	spin?: boolean,
	fixedWidth?: boolean,
	visible?: boolean,
	[x: string]: unknown
}

const FontAwesomeIcon = ({
	className,
	title = '',
	icon,
	size,
	spin = false,
	fixedWidth = false,
	visible = true,
	...props
}: IFontAwesomeIconProps) => {

	const classNames = useMemo(() => {
		const classes = ['fa'];
		if (icon) classes.push(`fa-${icon}`);
		if (size) classes.push(`fa-${size}`);
		if (spin) classes.push('fa-spin');
		if (fixedWidth) classes.push('fa-fw');
		if (className) classes.push(className);
		return classes.join(' ');
	}, [className, icon, size, spin, fixedWidth]);

	if (!visible) return null;

	return (
		<i {...props} title={title} className={classNames} aria-hidden="true" />
	);
};

export default React.memo(FontAwesomeIcon);
