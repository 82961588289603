import axios from 'axios';
import getUserApiPath from '../getUserApiPath';

const getUserProfile = async () => {
	const profile_url = `${getUserApiPath()}/contact`;
	const response = await axios.get(profile_url);
	return response.data;
};

export default getUserProfile;
