import axios, {AxiosResponse} from "axios";
import {TwoFactorAuth} from "@interfaces/TwoFactorAuth";
import getMetaApiPath from "@hornet-api/getMetaApiPath";

const resendOTP2FA = async () => {
  const url = `${getMetaApiPath()}/2fa/resendOTP`;
  const response: AxiosResponse<TwoFactorAuth> = await axios.put(url, {})
  return response.data
};

export default resendOTP2FA;
