import React, {useCallback, useMemo, useState} from 'react';
import FontAwesomeIcon from '../../components/icons/FontAwesomeIcon';
import {SideBarLinkObject, SubLink} from './sidebarLinkObject';
import {Link} from 'react-router-dom';
import {isLegacy} from '@common/isAdmin';
import {getBaseUri} from '@common/baseUri';

import "./SideBarItem.scss"

interface Props {
	icon: React.ReactNode,
	name: string,
	count?: number,
	linkTo?: string,
	subLinks?: SideBarLinkObject[],
	sideLink?: SubLink,
	frameBreak?: boolean,
	onClick?: () => void
}


const SideBarItem = (props: Props) => {
	// determine if open based on location
	const pathname = window.location.pathname;
	//@ts-ignore
	const isSubLinkSelected = props.subLinks && props.subLinks.filter(value => pathname.includes(value.linkTo)).length > 0;
	//@ts-ignore
	const isSelected = window.location.pathname.includes(props.linkTo);
	const [isOpen, setIsOpen] = useState(isSelected || isSubLinkSelected);

	const {name, count, icon, linkTo, sideLink, subLinks, onClick, frameBreak} = props;

	const handleSidebarItemOnClick = useCallback((event) => {
		// bust out of iframe if frame buster
		if (!isLegacy() && frameBreak && linkTo) {
			let basePath = getBaseUri();
			let absoluteUrl:string;
			if (basePath) {
				if (basePath.endsWith('/')) {
					basePath = basePath.slice(0, -1);
				}
				absoluteUrl = `${basePath}${linkTo}`;
			} else {
				absoluteUrl = linkTo;
			}

			window.location.href = absoluteUrl;
		}
		// allow link to continue
		if (linkTo) return;

		// prevent propagation of link
		event.preventDefault();
		if (subLinks)
			setIsOpen((state) => (!state));

		if (typeof onClick === 'function')
			onClick();

	}, [linkTo, subLinks, onClick, setIsOpen]);

	const iconDisplay = useMemo(() => {
		if (!icon) return null;
		if (typeof icon === 'string') {
			return (
				<div className="icon">
					<FontAwesomeIcon icon={icon}/>
				</div>
			);
		}
		return (
			<div className="icon">
				{icon}
			</div>
		);
	}, [icon]);

	const sideLinksDisplay = useMemo(() => {
		if (!sideLink) return null;
		return (<>
			{
				isLegacy() ?
					<a href={sideLink.linkTo} className="sidelink">
						{/* @ts-ignore */}
						<FontAwesomeIcon icon={sideLink.icon} />
					</a>
					:
					<Link to={sideLink.linkTo} className="sidelink">
						{/* @ts-ignore */}
						<FontAwesomeIcon icon={sideLink.icon} />
					</Link>
			}
		</>);
	}, [sideLink]);

	const subLinkItems = useMemo(() => {
		if (!subLinks) return null;
		return subLinks.map((sublink) => {
			const countBadge = sublink.count ?
				<span className="badge badge-danger ml-2 counter">{sublink.count}</span> : null;
			return (
				<div key={sublink.name} className="sublink-item">
					{
						isLegacy() ?
							<a className="sublink" href={sublink.linkTo}>
								{
									sublink.icon ?
										<div className="sublink-icon">
											{typeof sublink.icon === 'string' ? <FontAwesomeIcon icon={sublink.icon}/> : sublink.icon}
										</div>
										:
										null
								}
								<span className="text">{sublink.name}</span>
								{countBadge}
							</a>
							:
							<Link to={sublink.linkTo || ''} className="sublink">
								{
									sublink.icon ?
										<div className="sublink-icon">
											{typeof sublink.icon === 'string' ? <FontAwesomeIcon icon={sublink.icon}/> : sublink.icon}
										</div>
										:
										null
								}
								<span className="text">{sublink.name}</span>
								{countBadge}
							</Link>
					}
					{
						sublink.sideLink ?
							<>
								{
									isLegacy() ?
										<a href={sublink.sideLink.linkTo} className="sidelink">
											{/* @ts-ignore */}
											<FontAwesomeIcon icon={sublink.sideLink.icon} />
										</a>
										:
										<Link to={sublink.sideLink.linkTo} className="sidelink">
											{/* @ts-ignore */}
											<FontAwesomeIcon icon={sublink.sideLink.icon} />
										</Link>
								}
							</>
							:
							null
					}
				</div>
			);
		});
	}, [subLinks]);

	const subLinkDisplay = useMemo(() => {
		if (!subLinkItems) return null;
		return (
			<>
				<div className={isOpen ? 'caret' : 'caret open'}>
					<FontAwesomeIcon icon="caret-up"/>
				</div>
				<div className={isOpen ? 'sublinks open' : 'sublinks'}>
					{subLinkItems}
				</div>
			</>
		);
	}, [subLinkItems, isOpen]);

	const counterBadge = useMemo(() => {
		if (!count) return null;
		return (
			<span className="badge badge-danger ml-2 counter">{count}</span>
		);
	}, [count]);

	return (
		<div className="sidebar-item">
			{
				isLegacy() ?
					<a href={linkTo || ' #'} onClick={handleSidebarItemOnClick}>
						{iconDisplay}
						<span className="text">{name}</span>
						{counterBadge}
					</a>
					:
					<Link to={linkTo || ' #'} onClick={handleSidebarItemOnClick}>
						{iconDisplay}
						<span className="text">{name}</span>
						{counterBadge}
					</Link>
			}

			{sideLinksDisplay}
			{subLinkDisplay}
		</div>
	);
};

export default SideBarItem;
