import {createGlobalState} from "react-global-hooks";
import {IOneTimeLoanPayment} from "@interfaces/oneTimeLoanPayment/user/OneTimeLoanPayment";
import getOneTimeLoanPayment from "@hornet-api/oneTimeLoanPayment/public/getOneTimeLoanPayment";
import {alertApiErrors} from "@common/errors";

export const payerTokenState = createGlobalState<string | null>(null);
export const isFetchingState = createGlobalState<boolean>(false);
export const oneTimeLoanPaymentState = createGlobalState<IOneTimeLoanPayment | null>(null);

export const loadOneTimeLoanPayment = async () => {
  const token = payerTokenState.get();
  if (!token) {
    return;
  }
  isFetchingState.set(true);
  await getOneTimeLoanPayment(token)
    .then((oneTimeLoanPayment) => {
      oneTimeLoanPaymentState.set(oneTimeLoanPayment);
    })
    .catch(alertApiErrors)
    .finally(() => {
      isFetchingState.set(false);
    });
}

export const clearData = () => {
  payerTokenState.set(null);
  oneTimeLoanPaymentState.set(null);
  isFetchingState.set(false);
}