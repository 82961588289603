/* eslint-disable no-console */
/* eslint-disable curly */
/* eslint-disable no-magic-numbers */
import React, {useCallback, useEffect, useRef} from 'react';
import {useRecoilState, useRecoilValue} from 'recoil';
import getUserProfile from '@hornet-api/profile/getUserProfile';
import isUserRoleSelectorAtom from '@state/recoil/authentication/roles/isUserRoleSelectorAtom';
import profileAtom from '@state/recoil/profileAtom';
import apiUrlSelectorAtom from '@state/recoil/apiUrlSelectorAtom';
import useBearerTokenRef from '@state/recoil/authentication/useBearerTokenRef';
import * as Auth from '@server-api/index';
import {getAxiosErrors} from '@common/errors';
import getRegistrationRoleGroups from "@hornet-api/public/getRegistrationRoleGroups";
import isMaintenanceState from "@state/globalState/isMaintenanceState";

const FIVE_MINUTES = 300000;
const ONE_MINUTE = 60000;

const ProfileRefreshMonitor = () => {
	const lastRefresh = useRef(null);
	const { bearerTokenRef, hasBearerToken } = useBearerTokenRef();
	const isUserRole = useRecoilValue(isUserRoleSelectorAtom);
	const [profile, setProfile] = useRecoilState(profileAtom);
	const apiUrl = useRecoilValue(apiUrlSelectorAtom);

	const checkForMaintenanceMode = useCallback(() => {
		getRegistrationRoleGroups()
			.then(() => setProfile(null))
			.catch(() => isMaintenanceState.set(true));
	}, [])

	useEffect(() => {
		let interval = null;
		if (isUserRole && hasBearerToken) {
			console.info('Profile Monitoring Service started');
			interval = setInterval(() => {
				console.group('Profile Monitoring Service');
				if (lastRefresh.current === null) {
					lastRefresh.current = Date.now();
					console.info('Initialized Service');
					console.groupEnd();
					return;
				}

				const timespan = Date.now() - lastRefresh.current;
				console.info('Last transcription was received', new Date(lastRefresh.current));
				console.info('Next transcription should be pulled around', new Date(lastRefresh.current + FIVE_MINUTES));
				if (timespan >= FIVE_MINUTES) {
					lastRefresh.current = Date.now();
					console.info('Requesting new transcription');
					getUserProfile()
						.then((profile) => {
							setProfile(profile);
						})
						.catch((e) => {
							const errors = getAxiosErrors(e);
							if(errors.includes('INVALID_USERNAME_OR_PASSWORD')){
								console.info('Invalid username or password. Refreshing token')
								// refresh token
								Auth.refreshUserToken().then(() => {
									console.info('Token refreshed');
								}).then((e) => {
									console.error(e);
								});
							} else {
								checkForMaintenanceMode();
							}
						});
				} else {
					console.info('Not enough time has passed, skipping');
				}
				console.groupEnd();

			}, [ONE_MINUTE]);
		} else {
			if(profile !== null) {
				checkForMaintenanceMode();
			}
		}
		return () => {
			if (interval) {
				console.info('Profile Monitoring Service stopping');
				clearInterval(interval);
				interval = null;
			}
			lastRefresh.current = null;
		};
	}, [isUserRole, hasBearerToken, bearerTokenRef, lastRefresh, setProfile, apiUrl]);

	return null;
};

export default React.memo(ProfileRefreshMonitor);
