/* eslint-disable no-magic-numbers */
import axios from 'axios';
import getUserApiPath from '../getUserApiPath';

const getReporting = async () => {
	const report_url = `${getUserApiPath()}/report`;
	const response = await axios.get(report_url);
	const data = response.data;
	return data.reduce((acc, item) => {
		try {
			const name = item.category
				.split('_')
				.map((item, idx) => {
					let name = item.toLowerCase();
					if (idx > 0) name = name[0].toUpperCase() + name.slice(1);
					return name;
				})
				.join('');
			const keyPaths = item.url.split('/');
			const key = keyPaths[keyPaths.length - 1];
			return {
				...acc,
				[`${name}Key`]: key,
			};
		} catch (e) {
			return {
				...acc,
				__parsingErrors: [
					...[acc.__parsingErrors ?? []],
					{
						error: e.message,
						item,
					},
				],
			};
		}
	}, []);
};

export default getReporting;
