import {Column} from '../Types';
import _ from "lodash";


const exportCsv = <D>(
	columns: Column<D>[],
	hiddenColumns: number[],
	sortedData: D[],
	columnOrder: number[],
	filename = 'data.csv'
) => {
	// let exportCols = columns.filter(col => col.field && !(hiddenColumns.includes(col.field)));
	// to escape quotes in csv prepend another quote
	let csvContent = '';
	// add header row
	/*const headerArr = columnOrder.map((index) => {
		const col = columns[index];
		if (!hiddenColumns.includes(col.field)) {
			return `"${`${col.title}`.split('"').join('""')}"`;
		}
	}).filter(x => x);*/
	//@ts-ignore
	const headerArr = columnOrder.reduce((prev, next) => {
		const col = columns[next];
		if (!hiddenColumns.includes(next) && col.field !== 'actions') {
			return [
				...prev,
				`"${`${col.title}`.split('"').join('""')}"`,
			];
		}
		return prev;
	}, []);

	//@ts-ignore
	csvContent += headerArr.join(',') + '\r\n';
	// add all the data rows
	for (let i = 0; i < sortedData.length; i++) {
		/*const colArr = columnOrder.map(index => {
			const col = columns[index];
			if (!hiddenColumns.includes(col.field)) {
				if (col.lookup)
					return `"${`${col.lookup[sortedData[i][col.field]] || ''}`.split('"').join('""')}"`;

				// handle pipe separated fields
				const fieldText = col.field ? col.field.split('|').map(f => sortedData[i][f]).join(' | ') : '';
				return `"${`${fieldText || ''}`.split('"').join('""')}"`;
			}
		}).filter(x => x);*/
		//@ts-ignore
		const colArr = columnOrder.reduce((prev, next) => {
			const col = columns[next];
			if (!hiddenColumns.includes(next) && col.field !== 'actions') {
				if (col.lookup) {
					return [
						...prev,
						`"${`${col.lookup[_.get(sortedData[i], col.field)] || ''}`.split('"').join('""')}"`,
					];
				}
				// handle pipe separated fields
				const useField = col.exportField || col.field;
				let fieldText = useField ? useField.split('|').map(f => _.get(sortedData[i], f)).join(' | ') : '';
				if (fieldText.startsWith(' | ')) {
					fieldText = fieldText.slice(3);
				}
				if (fieldText.endsWith(' | ')) {
					fieldText = fieldText.slice(0, -3);
				}
				return [
					...prev,
					`"${`${fieldText || ''}`.split('"').join('""')}"`,
				];
			}
			return prev;
		}, []);
		//@ts-ignore
		csvContent += colArr.join(',') + '\r\n';
	}
	const link = document.createElement('a');
	link.style.display = 'none';
	const blob = new Blob([csvContent], { type: 'text/csv' });
	const url = window.URL.createObjectURL(blob);
	link.href = url;
	link.className = 'no-intercept'
	// link.setAttribute("download", `${name.split(' ').join('_')}.csv`);
	link.setAttribute('download', filename);
	document.body.appendChild(link);
	link.click();
	window.URL.revokeObjectURL(url);
};

export default exportCsv;
