import {ContactSelectorProps} from '@components/forms/react-hook-form-bootstrap/interfaces';
import {useForm} from 'react-hook-form';
import {Col,Form} from 'react-bootstrap';
import classNames from 'classnames';
import style from './style.module.scss';
import AsyncSelect from 'react-select/async';
import React, {useEffect, useMemo, useState} from 'react';
import searchContact, {SearchContactItem} from '@hornet-api/contact/searchContact';
import {getErrorMessage} from './utils';
import {openCreateContactModal} from '@components/modals/CreateContactModal';
import {Controller} from "@components/forms/react-hook-form-bootstrap/Controller";
import {FaExclamation, FaTimes} from "react-icons/fa";
import InputGroup from "react-bootstrap/InputGroup";

interface Option {
  readonly value: string;
  readonly label: string;
}

interface Options {
  [val: string]: string;
}

interface SaveVal {
  id: number | null;
  name: string | null;
}

const createOption = (contact: SearchContactItem) => {
  return {
    value: `${contact.id}`,
    label: [contact.firstName, contact.middleName, contact.lastName]
      .filter((x) => x && x.length > 0)
      .join(' '),
  } as Option;
};

const optToVal = (opt: Option): SaveVal => {
  return {
    id: Number(opt.value) || null,
    name: opt.label || null,
  };
};

const valToOpt = (val: SaveVal): Option => {
  return {
    value: `${val.id}`,
    label: val.name,
  } as Option;
};

const ContactSelector = ({
                           name,
                           rules,
                           label,
                           shouldUnregister,
                           defaultValue = null,
                           control,
                           onChange,
                           disabled = false,
                           isEscrowOfficerActive = false,
                           getDefaultFunction,
                         }: ContactSelectorProps) => {
  const dv = getDefaultFunction ? getDefaultFunction(name, defaultValue) : defaultValue;
  const [inputVal, setInputVal] = useState(null as null | SaveVal);
  const [options, setOptions] = useState([] as Option[]);
  const controlObj = control ?? useForm<any>().control;

  const loadOptions = (inputValue: string) => {
    return new Promise<Option[]>((resolve) => {
      searchContact(inputValue, isEscrowOfficerActive).then((res) => {
        let newOptions: Option[] = res.map((contact) => {
          return createOption(contact);
        }).sort((a, b) => {
          if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
          if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;
          return 0;
        });
        // add to the beginning of the list
        newOptions.push({
          value: '-1',
          label: '** NEW CONTACT **',
        });
        setOptions(newOptions);
        resolve(newOptions);
      });
    });
  };
  useEffect(() => {
    loadOptions('').then((newOptions) => {
      if (dv?.id) {
        const selectedOption = newOptions.find(x => x.value == `${dv.id}`);
        if (selectedOption) {
          const v = optToVal(selectedOption);
          setInputVal(v);
        }

      }

    });
  }, [])

  if (options.length === 0) {
    return null;
  }
  return (
    <>
      <Controller
        name={name}
        control={controlObj}
        defaultValue={dv}
        rules={rules}
        shouldUnregister={shouldUnregister}
        render={({
                   field,
                   fieldState,
                   formState,
                 }) => {
          const isRequired = Boolean(rules?.required);
          const isInvalid = Boolean(fieldState.error);
          const [isFocus, setIsFocus] = useState<boolean>(false);
          const selectClassName: string = useMemo(() => {
            return classNames(`flex-grow-1 ${isInvalid ? 'is-invalid' : ''}`, isFocus ? 'focused' : '')
          }, [isInvalid, isFocus]);
          return (
            <Form.Group
              className={classNames(style.formInput, 'mb-3 floating-form-group row')}
              controlId={`form-${field.name}`}
            >
              <div id={`${name}-scrollTo`}/>
              <Col className={'async-box-wrapper'}>
                <InputGroup className={selectClassName}>
                  <AsyncSelect
                    inputId={`form-${field.name}`}
                    loadOptions={loadOptions}
                    placeholder={'Search...'}
                    className={'async-select'}
                    cacheOptions
                    defaultOptions={options}
                    onInputChange={(newVal) => {
                    }}
                    value={inputVal ? valToOpt(inputVal) : undefined}
                    options={options}
                    onBlur={() => {
                      setIsFocus(false)
                    }}
                    onFocus={() => {
                      setIsFocus(true)
                    }}
                    onChange={(opt) => {
                      let val = opt ? opt.value : '';
                      if (val === '-1') {
                        openCreateContactModal({
                          callback: async (c) => {
                            console.log('c', c)
                            // setInputVal()
                            const opt = createOption(c);
                            setOptions([opt, ...options]);
                            const v = optToVal(opt);
                            setInputVal(v);
                            field.onChange(v);
                          },
                          isEscrowOfficerActive: isEscrowOfficerActive ? isEscrowOfficerActive : null
                        });
                      } else {
                        if (opt) {
                          const v = optToVal(opt);
                          if (onChange) {
                            onChange(JSON.stringify(v));
                          }
                          field.onChange(v);
                          setInputVal(v);
                        }
                      }
                    }}
                    isDisabled={disabled}
                  />
                  {
                    <Form.Label className={classNames('floating-label', field.value?.id > 0 ? 'has-value' : '')}>
                      <span className={fieldState.error ? 'text-danger' : ''}>{label}</span>
                    </Form.Label>
                  }

                  {
                    isInvalid && <div className="border border-danger rounded-circle invalid-circle">
                          <FaExclamation className={'text-danger'} size={11}/>
                      </div>
                  }
                  {
                    !disabled && field.value?.id>0 ?
                      <div className="cross-clear" onClick={() => {
                        const v = optToVal({value: '', label: ''});
                        if (onChange) {
                          onChange(JSON.stringify(v));
                        }
                        field.onChange(v);
                        setInputVal(v);
                      }}><FaTimes size={20}/></div>
                      : <div className="cross-clear-hide"></div>
                  }
                </InputGroup>
                {fieldState.error ? (
                  <Form.Control.Feedback type="invalid" style={{display: 'block'}}>
                    {getErrorMessage(fieldState.error)}
                  </Form.Control.Feedback>
                ) : null}

              </Col>
            </Form.Group>
          );
        }}
      />
    </>
  );
};

export default ContactSelector;
