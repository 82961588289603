import { createGlobalState } from 'react-global-hooks';
const localStoreName = 'rgh-admin-sidebar-expanded';
import { isLegacy } from '@common/isAdmin';

const storedValue = localStorage.getItem(localStoreName);
let initialValue = true;
if (storedValue)
	initialValue = storedValue === '1';


export const sidebarExpandedState = createGlobalState(initialValue);

const updateMain = (isCollapsed: boolean) => {
	// only need to do this on legacy
	if (isLegacy()) {
		//@ts-ignore
		if(typeof $ !== 'undefined'){
			if(isCollapsed)
				//@ts-ignore
				$('.main-container').removeClass('collapsed');
			else
				//@ts-ignore
				$('.main-container').addClass('collapsed');
		} else {
			console.log('jquery not detected')
		}
	}
};

// fire before and after listener as sometimes listener already fired event, and we miss it.
updateMain(initialValue);
document.addEventListener('DOMContentLoaded', function(){
	updateMain(initialValue);
});
// add logic to adjust main container width on change
sidebarExpandedState.onChange((newVal) => {
	updateMain(newVal);
	localStorage.setItem(localStoreName, newVal ? '1' : '0');
});
