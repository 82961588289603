import { selector } from 'recoil';
import activeUserSelectorAtom from './activeUserSelectorAtom';

const accessTokenExpirationSelectorAtom = selector({
	key: 'accessTokenExpirationSelectorAtom',
	default: null,
	get: ({ get }) => {
		const user = get(activeUserSelectorAtom);
		if (!user) return null;
		return user.exp;
	},
});

export default accessTokenExpirationSelectorAtom;
