import axios from 'axios';
import getAdminApiPath from '../getAdminApiPath';
import Note from '@interfaces/Note';

const getNotes = async (contactId: number | string) => {
	const url = `${getAdminApiPath()}/contact/${contactId}/notes`;
	const response = await axios.get(url);
	return response.data as Note[];
};

export default getNotes;
