import React from 'react';
import FontAwesomeIcon from './lib/FontAwesomeIcon';
import Store from './globalState';
import {Column} from './index';
import {Reorder, useMotionValue, useDragControls} from 'framer-motion';


type ItemProps<D> = {
  colIndex: number,
  col: Column<D>,
  store: Store<D>,
}

export function Item<D>(
  {
    colIndex,
    col,
    store,
  }: ItemProps<D>
) {
  const y = useMotionValue(0);
  const dragControls = useDragControls();
  const [hiddenColumns, setHiddenColumns] = store.hiddenColumnsState.use();

  return (
    <Reorder.Item
      value={colIndex}
      style={{y}}
      dragListener={false}
      dragControls={dragControls}
    >
      <div
        key={colIndex}
        className='bst-m-vis-item'
        style={{
          userSelect: 'none'
        }}
      >
        <div
          className='side-icon'
          onPointerDown={(event) => dragControls.start(event)}
        >
          <FontAwesomeIcon icon={'ellipsis-v'} />
          <FontAwesomeIcon icon={'ellipsis-v'} />
        </div>
        <div
          style={{
            flexGrow: 1
          }}
        >
          {col.title}
        </div>
        <div className='toggle-holder'>
          <div className={`toggle-bg${!hiddenColumns.includes(colIndex) ? ' active' : ''}`}
            onClick={() => {
              let newHiddenColumns: number[];
              if (hiddenColumns.includes(colIndex)) {
                // remove
                newHiddenColumns = hiddenColumns.filter(x => x !== colIndex);
              } else {
                // add
                newHiddenColumns = [...hiddenColumns, colIndex];
              }
              setHiddenColumns(newHiddenColumns);
            }}
          >
            <div className='toggle-nob'/>
          </div>
        </div>
      </div>
    </Reorder.Item>
  );
}


type Props<D> = {
  store: Store<D>,
  columns: Column<D>[],
};

function MobileVisibility<D>(
  {
    store,
    columns,
  }: Props<D>
) {
  const {columnOrderState} = store;
  const [columnOrder, setColumnOrder] = columnOrderState.use();

  return (
    <div className='drag-reorder-grp'>
      <Reorder.Group
        axis="y"
        onReorder={(newOrder) => {
          setColumnOrder(newOrder);
        }}
        values={columnOrder}
      >

        {
          columnOrder.map((colIndex) => {
            const col = columns[colIndex];
            return (
              <Reorder.Item key={colIndex} value={colIndex}>
                <Item
                  key={colIndex}
                  col={col}
                  colIndex={colIndex}
                  store={store}
                />
              </Reorder.Item>
            )
          })
        }
      </Reorder.Group>
    </div>
  );
}

export default MobileVisibility;