import {Card,Button }from "react-bootstrap";
import {FaDownload, FaEye} from "react-icons/fa";
import style from "@admin-ui/pages/PropertyPage/PropertyPage.module.scss";
import React from "react";
import {openShowDocumentModal} from "@components/ShowDocumentModal";
import {loadingRelease, loadingTrigger} from "@components/LoadingOverlay";
import fileDownloader from "@common/utils/fileDownloader";
import {alertDownloadDocError} from "@common/errors";
import {downloadPropertyFile, viewPropertyFile} from "@hornet-api/properties/document";

type Props = {
  propertyId: number
  hasLegalAddressDocument: boolean
  legalAddress: string | null
  filename: string

}

const onDownloadClick = (propertyId: number, fileName: string) => {
  const t = loadingTrigger();
  fileDownloader(downloadPropertyFile(propertyId), fileName)
    .catch(alertDownloadDocError)
    .finally(() => loadingRelease(t));
}

export const LegalAddress = ({propertyId, hasLegalAddressDocument, legalAddress, filename}: Props) => {

  return <Card>
    <Card.Header>Legal Address
      {
        hasLegalAddressDocument &&
          <>
              <Button
                  variant={"primary"}
                  className={'pull-right ml-1'}
                  onClick={() => {
                    openShowDocumentModal({
                      url: viewPropertyFile(propertyId),
                      name: filename
                    })
                  }}
              >
                  <FaEye/> View Document</Button>
              <Button variant={"success"}
                      className={'pull-right'}
                      onClick={() => onDownloadClick(propertyId, filename)}
              >
                  <FaDownload/> Download Document
              </Button>
          </>
      }
    </Card.Header>
    {
      legalAddress &&
        <Card.Body>
            <pre className={style.pre}>{legalAddress}</pre>
        </Card.Body>
    }
  </Card>
}