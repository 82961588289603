import axios, {AxiosResponse} from 'axios';
import getUserApiPath from '../getUserApiPath';
import {PhoneNumber} from "@hornet-api/profile/profileInterfaces";

const getPersonalPhoneNumbers = async () => {
	const profile_url = `${getUserApiPath()}/contact/phone`;
	const response: AxiosResponse<{ phoneNumbers: PhoneNumber[] }> = await axios.get(profile_url);
	return response.data.phoneNumbers as PhoneNumber[];
};

export default getPersonalPhoneNumbers;
