import {timeFormat} from "@legacy/views/UserProfileLegacy/utils";
import {Button} from "react-bootstrap";
import React, {useEffect, useRef, useState} from "react";

interface Props {
  onCountdownChange?: (countdown: number) => void;
  resendLockedForSeconds?: number | null;
  resendOtp: () => void
}

const ResendOtpButton = ({resendLockedForSeconds, resendOtp, onCountdownChange}: Props) => {
  const [countdown, setCountdown] = useState<number>(0);
  const timerRef = useRef<NodeJS.Timer>();

  useEffect(() => {
    if (resendLockedForSeconds === countdown) return;

    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    setCountdown(resendLockedForSeconds || 0);
  }, [resendLockedForSeconds]);

  useEffect(() => {
    onCountdownChange && onCountdownChange(countdown);
    if (countdown > 0) {
      timerRef.current = setTimeout(() => setCountdown(countdown - 1), 1000);
    }
  }, [countdown]);

  return (
    <Button
      disabled={countdown > 0}
      onClick={resendOtp}
      variant={'link'}
      className={'pl-0 mb-3'}
    >
      {`Resend Authorization Code${countdown > 0 ? ` in ${timeFormat(countdown)}` : ''}`}
    </Button>
  );
}

export default ResendOtpButton;