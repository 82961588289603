import axios from 'axios';
import {getBaseUri} from '@common/baseUri';

export type RegistrationData = {
  firstName: string,
  lastName: string,
  username: string,
  email: string,
  password: string,
  password2: string
  roles: string | number,

}

const registerUser = async (data:RegistrationData) => {
	const url = `${getBaseUri()}api/public/register`;
	const response = await axios.post(url, data);
	return response.data;
};

export default registerUser;
