import {IBudgetDetail} from "@interfaces/loan/admin/budget";

export const sortBudgetChildren = (budget: IBudgetDetail): IBudgetDetail => ({
  ...budget,
  lineItems: (budget.lineItems || [])
    .map(lineItem => ({
      ...lineItem,
      drawRequests: (lineItem.drawRequests || []).sort((dr1, dr2) => dr1.id - dr2.id)
    }))
    .sort((lineItem1, lineItem2) => lineItem1.id - lineItem2.id),
  comments: (budget.comments || [])
    .sort((com1, com2) => com2.id - com1.id)
});
