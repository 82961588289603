import axios from 'axios';
import getMetaApiPath from "@hornet-api/getMetaApiPath";
type ServerResponse={
  vapidKey: string;
}
const getFirebaseVapidKey = async () => {
  const url = `${getMetaApiPath()}/fcm/vapidKey`;
  const response = await axios.get<ServerResponse>(url);
  return response.data.vapidKey;
};

export default getFirebaseVapidKey;
