import React, {useState, useRef, useMemo} from 'react';
import Store from './globalState';
import FontAwesomeIcon from './lib/FontAwesomeIcon';
import Calendar from 'react-calendar';
import style from './style.module.scss';
import moment from 'moment';

export const filterDateFormat = 'MM/DD/YYYY';


type Props<D> = {
  colIndex: number,
  store: Store<D>,
  align?: 'right' | 'left',
};

type DateRangeObj = {
  title: string,
  setDate: () => void
};

function FilterDate<D>(
  {
    colIndex,
    store,
    align = 'left'
  }: Props<D>
) {
  const [filters, setFilters] = store.filtersState.use();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedRange, setSelectedRange] = useState(filters[colIndex].split('-').length > 1 ? 1 : 0);
  const popupRef = useRef<HTMLDivElement>(null);

  const [startDate, endDate] = useMemo(() => {
    const thisFilter = filters[colIndex] || '';
    const filterSplit = thisFilter.split('-');
    let s = filterSplit[0];
    let e = filterSplit.length > 1 ? filterSplit[1] : '';
    return [s, e]
  }, [colIndex, filters]);

  const setFilter = (start: string, end: string) => {
    const newFilters = [...filters];
    newFilters[colIndex] = `${start}-${end}`;
    setFilters(newFilters);
    store.selectedPresetIdState.set('');
  }

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value.trim();
    if (name === 'start') {
      setFilter(value, endDate);
    }
    if (name === 'end') {
      setFilter(startDate, value);
    }
  }

  const isDate = (d: string) => {
    return moment(d, filterDateFormat).isValid();
  }

  const dateRangeOptionArray: DateRangeObj[] = [
    {
      title: 'All Dates',
      setDate: () => {
        const newFilters = [...filters];
        newFilters[colIndex] = '';
        setFilters(newFilters);
      }
    },
    {
      title: 'Custom',
      setDate: () => {
        // TODO only use this if current values do not exist
        setFilter(
          moment().startOf('year').format(filterDateFormat),
          moment().format(filterDateFormat)
        );
      }
    },
    {
      title: 'Today',
      setDate: () => {
        setFilter(
          moment().startOf('day').format(filterDateFormat),
          moment().endOf('day').format(filterDateFormat)
        );
      }
    },
    {
      title: 'Yesterday',
      setDate: () => {
        setFilter(
          moment().subtract(1, 'day').startOf('day').format(filterDateFormat),
          moment().subtract(1, 'day').endOf('day').format(filterDateFormat)
        );
      }
    },
    {
      title: 'This Week',
      setDate: () => {
        setFilter(
          moment().startOf('week').format(filterDateFormat),
          moment().endOf('week').format(filterDateFormat)
        );
      }
    },
    {
      title: 'Last Week',
      setDate: () => {
        setFilter(
          moment().subtract(1, 'week').startOf('week').format(filterDateFormat),
          moment().subtract(1, 'week').endOf('week').format(filterDateFormat)
        );
      }
    },
    {
      title: 'This Month',
      setDate: () => {
        setFilter(
          moment().startOf('month').format(filterDateFormat),
          moment().endOf('month').format(filterDateFormat)
        );
      }
    },
    {
      title: 'Last Month',
      setDate: () => {
        setFilter(
          moment().subtract(1, 'month').startOf('month').format(filterDateFormat),
          moment().subtract(1, 'month').endOf('month').format(filterDateFormat)
        );
      }
    },
    {
      title: 'This Quarter',
      setDate: () => {
        setFilter(
          moment().startOf('quarter').format(filterDateFormat),
          moment().endOf('quarter').format(filterDateFormat)
        );
      }
    },
    {
      title: 'Last Quarter',
      setDate: () => {
        setFilter(
          moment().subtract(1, 'quarter').startOf('quarter').format(filterDateFormat),
          moment().subtract(1, 'quarter').endOf('quarter').format(filterDateFormat)
        );
      }
    },
    {
      title: 'This Year',
      setDate: () => {
        setFilter(
          moment().startOf('year').format(filterDateFormat),
          moment().endOf('year').format(filterDateFormat)
        );
      }
    },
    {
      title: 'Last Year',
      setDate: () => {
        setFilter(
          moment().subtract(1, 'year').startOf('year').format(filterDateFormat),
          moment().subtract(1, 'year').endOf('year').format(filterDateFormat)
        );
      }
    },
  ];

  // useEffect(() => {
  //   // automatically update when selected range changes
  //   dateRangeOptionArray[selectedRange].setDate();
  // }, [selectedRange]);

  return (
    <div
      className="input-group"
      style={{
        position: 'relative'
      }}
    >
      <input
        className={`form-control form-control-sm ${filters[colIndex].length > 0 ? 'border-right-0' : ''}`}
        type="text"
        value={filters[colIndex]}
        onChange={(event) => {
          const newFilters = filters.map((filter, fi) => {
            if (fi === colIndex)
              return event.target.value;

            return filter;
          }) as string[];
          store.editFieldState.set(null);
          store.currentPageState.set(1);
          setFilters(newFilters);
          store.selectedPresetIdState.set('');
        }}
        onClick={() => {
          if (!isOpen) {
            setIsOpen(true);
            const listenerFunction = (event: MouseEvent) => {
              try {
                //@ts-ignore
                const isClickInside = popupRef.current.contains(event.target);
                if (!isClickInside) {
                  setIsOpen(false);
                  document.removeEventListener('click', listenerFunction);
                }
              } catch (e) {

              }
            };
            // remove any lingering listeners
            document.removeEventListener('click', listenerFunction);
            // bind un-click listener
            if (popupRef.current) {
              // timeout needed to prevent double firing
              setTimeout(() => {
                document.addEventListener('click', listenerFunction);
              }, 500);
            }
          }
        }}
      />
      {
        // cancel button
        filters[colIndex] && filters[colIndex].length > 0 ?
          <div className="input-group-append bg-white border-left-0">
            <span
              className="input-group-text bg-transparent"
              onClick={() => {
                const newFilters = filters.map((filter, fi) => {
                  if (fi === colIndex)
                    return '';

                  return filter;
                }) as string[];
                store.editFieldState.set(null);
                store.currentPageState.set(1);
                setFilters(newFilters);

                // set the date range back to all
                setSelectedRange(0);
                dateRangeOptionArray[0].setDate();
              }}
            >
              <FontAwesomeIcon icon="times"/>
            </span>
          </div>
          :
          null
      }
      <div
        ref={popupRef}
        className={style.dateFilterBox}
        style={{
          display: isOpen ? 'block' : 'none',
          right: align === 'right' ? 0 : 'auto',
          left: align === 'left' ? 0 : 'auto',
        }}
      >
        <div className="">
          <label className={style.dateFilterLabel}>Quick Range</label>
          <select
            className="form-control  form-control-sm"
            value={selectedRange}
            onChange={(e) => {
              const val = Number(e.target.value);
              setSelectedRange(val);
              dateRangeOptionArray[val].setDate();
            }}
          >
            {
              dateRangeOptionArray.map((obj, index) => {
                return (
                  <option key={index} value={index}>{obj.title}</option>
                )
              })
            }
          </select>
        </div>
        <div className='row' style={{ marginBottom: 10 }}>
          <div className='col-6'>
            <label className={style.dateFilterLabel}>From</label>
            <input
              className="form-control form-control-sm"
              name='start'
              value={startDate}
              onChange={onInputChange}
            />
          </div>
          <div className='col-6'>
            <label className={style.dateFilterLabel}>To</label>
            <input
              className="form-control form-control-sm"
              name='end'
              value={endDate}
              onChange={onInputChange}
            />
          </div>
        </div>
        <Calendar
          onChange={(x: any) => {
            if (Array.isArray(x)) {
              setFilter(
                moment(x[0] as Date).format(filterDateFormat),
                moment(x[1] as Date).format(filterDateFormat),
              );
              setSelectedRange(1);
            } else {
              console.log('Bad Value', x)
            }
          }}
          selectRange={true}
          value={[
            isDate(startDate) ? moment(startDate, filterDateFormat).toDate() : moment().toDate(),
            isDate(endDate) ? moment(endDate, filterDateFormat).toDate() : moment().toDate()
          ]}
          calendarType={'US'}
        />
      </div>
    </div>
  );
}

export default FilterDate;