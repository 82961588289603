import React, {useEffect, useMemo, useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import {SubmitHandler, useForm} from "react-hook-form";
import {
  convertFormToServerDataForContact,
  convertServerDataToFormDataForProfile,
  defaultFormData,
  ICompanyContacts,
  IPayoffRequestForm
} from "@legacy/views/UserLoanView/UserPayoffRequest/constant";
import {Button, Card, Form} from "react-bootstrap";
import {
  Date as DateField,
  SearchableSelect,
  Select,
  SmartInput,
  TextBox
} from "@components/forms/react-hook-form-bootstrap";
import {isEmailValid} from "@common/basic";
import {SmartAddress} from "@components/forms/react-hook-form-bootstrap/SmartAddress";
import {useRecoilValue} from "recoil";
import profileAtom from "@state/recoil/profileAtom";
import {loadingRelease, loadingTrigger} from "@components/LoadingOverlay";
import {alertApiErrors} from "@common/errors";
import {CompactLoan} from "@interfaces/Loan";
import {getCompactLoanData} from "@hornet-api/loans/user/payoffRequest/getCompactLoanData";
import {getCompactLoanDataForPublic} from "@hornet-api/public/getCompactLoanData";
import {addAlert} from "@components/Alert";
import {savePayoffRequestForPublic} from "@hornet-api/public/savePayoffRequestForPublic";
import {savePayoffRequest} from "@hornet-api/loans/user/payoffRequest/savePayoffRequest";
import {PayoffRequestReasonEnum} from "@interfaces/GeneratedEnums";
import {getPrimaryContacts} from "@hornet-api/loans/user/payoffRequest/getPrimaryContacts";

type PayoffRequestUrlParams = {
  loanId: string;
}

const UserPayoffRequestForm = () => {
  const {loanId} = useParams() as PayoffRequestUrlParams;

  const profile = useRecoilValue(profileAtom);
  const [loanDetails, setLoanDetails] = useState<CompactLoan>();
  const [isEscrowFormActive, setEscrowFormActive] = useState<boolean>(false);
  const [signerContacts, setSignerContacts] = useState<ICompanyContacts[]>([]);
  const history = useHistory();

  const {control, handleSubmit, reset, watch, setValue} = useForm<IPayoffRequestForm>({defaultValues: defaultFormData});

  const payoffWatch = watch('payoffReason')
  const escrowOfficerWatch = watch('escrowOfficer');
  const selectAuthorisedSignerWatch = watch("selectAuthorisedSigner");

  const hasProfile = useMemo(() => {
    return !!profile
  }, [profile]);

  useEffect(() => {
      setEscrowFormActive(escrowOfficerWatch?.value === -1);
  }, [escrowOfficerWatch]);

  useEffect(() => {
    const t = loadingTrigger();
    (profile ? getCompactLoanData(loanId) : getCompactLoanDataForPublic(loanId))
      .then(setLoanDetails)
      .catch(alertApiErrors)
      .finally(() => loadingRelease(t));
  }, [loanId]);

  useEffect(() => {
    if (loanDetails?.isEscrowOfficer) {
      const t = loadingTrigger();
      getPrimaryContacts(loanId)
        .then(setSignerContacts)
        .catch(alertApiErrors)
        .finally(() => loadingRelease(t));
    } else {
      setValue("selectAuthorisedSigner", null);
    }
  }, [loanDetails]);

  useEffect(() => {
    if (loanDetails?.isEscrowOfficer && selectAuthorisedSignerWatch?.value) {
      const matchedSigner = signerContacts.find(signer => signer.id === selectAuthorisedSignerWatch.value);
      if (matchedSigner) {
        setValue("authorisedSigner.id", matchedSigner.id);
        setValue("authorisedSigner.firstName", matchedSigner.firstName);
        setValue("authorisedSigner.lastName", matchedSigner.lastName);
        setValue("authorisedSigner.email", matchedSigner.email);
        loanDetails.primaryBorrowerType === 'ENTITY' && setValue("authorizedSignerTitle", matchedSigner.position);
      }
    }
  }, [selectAuthorisedSignerWatch]);

  useEffect(() => {
    if (!loanDetails) return;
    reset(convertServerDataToFormDataForProfile(profile, loanDetails));
  }, [hasProfile, loanDetails]);

  const escrowOfficersOptions = useMemo(() => {
    const newEscrowOption = {label: "** Add New Escrow Officer **", value: -1}
    if (!loanDetails) return [newEscrowOption];
    const escrowOfficerList = loanDetails.escrowOfficers.map(escrowOfficer => ({label: escrowOfficer.name, value: escrowOfficer.id}))
    escrowOfficerList.push(newEscrowOption);
    return escrowOfficerList;
  }, [loanDetails])

  const companyContactsOption = useMemo(() => {
    return signerContacts.map((ccp) => ({
      label: `${ccp.lastName}, ${ccp.firstName}` + (ccp.position ? ` - ${ccp.position}` : "") + (ccp.isAllowedToSignDocuments ? "" : " (Cannot Sign)"),
      value: ccp.id,
      isDisabled: !ccp.isAllowedToSignDocuments,
    }))
  }, [signerContacts])

  useEffect(() => {
    if (loanDetails?.isEscrowOfficer && companyContactsOption.length === 1) {
      setValue("selectAuthorisedSigner", companyContactsOption[0]);
      setValue("authorisedSigner.id", signerContacts[0].id);
      setValue("authorisedSigner.firstName", signerContacts[0].firstName);
      setValue("authorisedSigner.lastName", signerContacts[0].lastName);
      setValue("authorisedSigner.email", signerContacts[0].email);
    }
  }, [loanDetails, companyContactsOption]);

  const onSubmit: SubmitHandler<IPayoffRequestForm> = async (data) => {
    const formData = profile ? convertFormToServerDataForContact(data) : null
    if (!formData) {
      addAlert({
        type: "danger",
        content: "We are working on Public Page"
      })
      return
    }

    const t = loadingTrigger();
    (profile ? savePayoffRequest(loanId, formData) : savePayoffRequestForPublic(loanId, formData))
      .then(data => {
        if (data.esignUrl) {
          const url = `/e-sign/${data.esignUrl}?returnTo=${encodeURIComponent("/userLoan/myLoans")}`;
          history.push(url);
        } else {
          addAlert({
            type: 'success',
            content: 'Task Link Sent to the Primary Borrower'
          })
          history.push("/userLoan/myLoans");
          return
        }
      })
      .catch(alertApiErrors)
      .finally(() => loadingRelease(t));
  }

  return (<div className={'container'}>
    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <Card.Body>
          <TextBox
            name={'requesterName'}
            label={"Requester's Name"}
            control={control}
            rules={{required: true}}
            disabled={hasProfile}
          />

          <TextBox
            name={'requesterEmail'}
            label={"Requester's Email"}
            control={control}
            rules={{
              required: true,
              validate: {
                validEmail: (email?: string) => email && isEmailValid(email) ? true : 'The Email field has an invalid value.'
              }
            }}
            disabled={hasProfile}
          />

          <SmartAddress
            name={'subjectProperty'}
            label={'Subject Property'}
            control={control}
            disabled
          />

          <TextBox
            name={'loanAliasId'}
            label={"Loan"}
            control={control}
            disabled
          />

          <TextBox
            name={'loanServicer'}
            label={"Loan Servicer"}
            control={control}
            rules={{required: true}}
            disabled={!!loanDetails?.loanServicer}
          />

          <DateField
            name={'requestedPayoffDate'}
            label={'Requested Payoff Date'}
            control={control}
            rules={{required: true}}
          />

          <Select
            name={'payoffReason'}
            label={"Why are you requesting a payoff? "}
            control={control}
            rules={{required: true}}
            options={PayoffRequestReasonEnum}
          />

          {
            payoffWatch === "SALE" && (
              <>
                <TextBox
                  name={'buyerName'}
                  label={"Buyer's Name"}
                  control={control}
                />

                <SmartInput
                  name={'salePrice'}
                  label={'Sale Price'}
                  control={control}
                  type={'currency'}
                />
              </>
            )
          }

          {
            payoffWatch === "OTHER" && (
              <TextBox
                name={'otherReason'}
                label={"Other Reason"}
                control={control}
                rules={{required: true}}
              />
            )
          }

          <TextBox
            name={'primaryBorrower'}
            label={'Primary Borrower'}
            control={control}
            disabled
          />

          {
            loanDetails?.isEscrowOfficer && (
              <>
                {loanDetails.primaryBorrowerType === 'ENTITY' && (
                  <SearchableSelect
                    name={'selectAuthorisedSigner'}
                    label={'Select Authorised Signer'}
                    control={control}
                    options={companyContactsOption}
                    rules={{required: true}}
                  />
                )}
                
                <TextBox
                  name={'authorisedSigner.firstName'}
                  label={'Signer First Name'}
                  control={control}
                  rules={{required: true}}
                  disabled
                />

                <TextBox
                  name={'authorisedSigner.lastName'}
                  label={'Signer Last Name'}
                  control={control}
                  rules={{required: true}}
                  disabled
                />

                <TextBox
                  name={'authorisedSigner.email'}
                  label={"Authorized Signer's Email"}
                  control={control}
                  rules={{
                    required: true,
                    validate: {
                      validEmail: (email?: string) => email && isEmailValid(email) ? true : 'The Email field has an invalid value.'
                    }
                  }}
                  disabled
                />

              </>
            )
          }



          {
            (loanDetails?.contactSignerTitle || (loanDetails?.isEscrowOfficer && loanDetails.primaryBorrowerType === 'ENTITY')) && (
              <TextBox
                name={'authorizedSignerTitle'}
                label={"Authorized Signer Title"}
                control={control}
              />
            )
          }

          {
            !isEscrowFormActive ? (
              <SearchableSelect
                name={'escrowOfficer'}
                label={'Escrow Officer'}
                control={control}
                options={escrowOfficersOptions}
                rules={{required: true}}
              />
            ) : (
              <>
                <strong>Escrow Officer</strong>
                <TextBox
                  name={'newEscrowOfficer.firstName'}
                  label={"Escrow Officer First Name"}
                  control={control}
                  rules={{required: true}}
                />
                <TextBox
                  name={'newEscrowOfficer.lastName'}
                  label={"Escrow Officer Last Name"}
                  control={control}
                  rules={{required: true}}
                />
                <TextBox
                  name={'newEscrowOfficer.email'}
                  label={"Escrow Officer Email"}
                  control={control}
                  rules={{
                    required: true,
                    validate: {
                      validEmail: (email?: string) => email && isEmailValid(email) ? true : 'The Email field has an invalid value.'
                    }
                  }}
                />
              </>
            )
          }
        </Card.Body>
        <Card.Footer>
          <Button
            type='submit'
            className='pull-right'
          >
            Submit
          </Button>
        </Card.Footer>
      </Card>
    </Form>
  </div>)
}

export default UserPayoffRequestForm;