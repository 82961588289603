import style from "@admin-ui/pages/UploaderPage/UploaderPage.module.scss";
import React, {useMemo} from "react";
import {IPicture} from "@admin/api/types/IPicture";
import classNames from "classnames";

type ImageGridProps = {
  focussedImage: IPicture;
  setFocussedImage: (image: IPicture) => void | Promise<void>;
  images: IPicture[];
  primaryImageId: number | null;
};

const ImageGrid: React.FC<ImageGridProps> = ({
                                               focussedImage,
                                               setFocussedImage,
                                               images,
                                               primaryImageId,
                                             }: ImageGridProps) => {
  const sortedImages = useMemo(() => images ?
      images.sort((a, b) => a.id - b.id)
      : []
    , [images]);
  return (
    <div className={style.imageGridContainer}>
      {sortedImages?.map((image, index) => (
        <div
          key={index}
          id={`img-${image.id}`}
          className={classNames(
            style.imageWrapper,
            image.id === focussedImage?.id ? style.selectedImage : ""
          )}
          onClick={() => setFocussedImage(image)}
        >
          <div
            role="button"
            className={style.hornetImage}
            style={{backgroundImage: `url("${image.thumbnailUrl || image.url}")`}}
          >
            {primaryImageId === image.id && (
              <div className={style.primary}>
                <i className="fa fa-bookmark fa-fw"></i>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ImageGrid;
