import {createGlobalState} from "react-global-hooks";
import React, {useEffect} from "react";
import {
  companyListState,
  fundListState,
  loanListState,
  propertyListState,
  userListState
} from "@components/NotificationSidebar/globalState";
import {FaBuilding, FaFileInvoiceDollar, FaHome, FaMoneyBill, FaUser} from "react-icons/fa";
import {generateSearchRegExp} from "@common/utils/validators/globalSearchValidator";

const globalSearchState = createGlobalState<SearchItem[]>([]);

interface SearchItem {
  type: "contact" | "company" | "loan" | "fund" | "property";
  name: string;
  alt: string | null;
  id: number | string;
}

const searchResult = (searchVal: string) => {
  const searchPool = globalSearchState.get();
  if (searchVal.trim() === '') return [];
  const {regExp, regExpReverse} = generateSearchRegExp(searchVal);

  return searchPool.filter(x => {
    return (
      (x.alt && x.alt.match(regExp)) ||
      (x.name && x.name.match(regExp)) ||
      (x.alt && x.alt.match(regExpReverse)) ||
      (x.name && x.name.match(regExpReverse))
    );
  });
}

const linkMap = {
  'company': (item: SearchItem) => `/entity/show/${item.id}`,
  'contact': (item: SearchItem) => `/contact/show/${item.id}`,
  'loan': (item: SearchItem) => `/loan/show/${item.id}`,
  'fund': (item: SearchItem) => `/investor/show/${item.id}`,
  'property': (item: SearchItem) => `/property/show/${item.id}`,
};

const getLink = (item: SearchItem) => {
  const linkItem = linkMap[item.type]
  return linkItem ? linkItem(item) : '';
};

const iconMap = {
  'company': <FaBuilding/>,
  'contact': <FaUser/>,
  'loan': <FaFileInvoiceDollar/>,
  'fund': <FaMoneyBill/>,
  'property': <FaHome/>,
}

const getIcon = (item: SearchItem) => (iconMap[item.type] || '');

const GlobalSearchState = () => {
  const userList = userListState.useValue();
  const companyList = companyListState.useValue();
  const loanList = loanListState.useValue();
  const fundList = fundListState.useValue();
  const propertyList = propertyListState.useValue();

  useEffect(() => {
    if (!userList || !companyList || !loanList || !propertyList || !fundList) {
      globalSearchState.set([]);
      return;
    }

    let arr: SearchItem[] = [
      ...userList.map(x => ({
        type: 'contact' as const,
        name: x.name!,
        alt: x.username,
        id: x.id,
      })),
      ...companyList.map(x => ({
        type: 'company' as const,
        name: x.name!,
        alt: null,
        id: x.id,
      })),
      ...loanList.map(x => ({
        type: 'loan' as const,
        name: x.id,
        alt: x.primaryProperty,
        id: x.id,
      })),
      ...propertyList.map(x => ({
        type: 'property' as const,
        name: x.address,
        alt: null,
        id: x.id,
      })),
      ...fundList.map(x => ({
        type: 'fund' as const,
        name: x.label,
        alt: null,
        id: x.value!,
      })),
    ];

    arr.sort((a, b) => (a.name || '').toLowerCase().localeCompare((b.name || '').toLowerCase()));

    globalSearchState.set(arr);

  }, [userList, companyList, loanList, fundList, propertyList]);

  return null;
};

export default GlobalSearchState;
export {globalSearchState, searchResult, getLink, getIcon};
export type {SearchItem};