import React, { useState } from 'react';

const ErrorTest = () => {
  const [text, setText] = useState('');
  return (
    <div className='container'>
      <h1>Sent test messages to rollbar</h1>
      <div>
        <div className="form-group row">
          <label
            className="col-4 col-form-label"
          >Message:</label>

          <div className="col-8">
            <input
              type="text"
              name="position"
              className="form-control"
              value={text}
              onChange={(e) => {
                setText(e.target.value);
              }}
            />
          </div>
        </div>
      </div>
      <button
        className='btn btn-danger'
        onClick={() => {
          throw new Error(text);
        }}
      >
        Send Error
      </button>
      <button
        className='btn btn-secondary'
        onClick={() => {
          console.info(`sending "${text}" to rollbar`);
          (window as any).rollbar.info(text)
        }}
      >
        Send Info
      </button>
    </div>
  );
}

export default ErrorTest;