import axios from 'axios';
import getAdminApiPath from "@hornet-api/getAdminApiPath";

/**
 * Fetches pending-authorization draw-request count
 *
 * Used to refresh Budget Count on the left sidebar for admins
 */
export const getSubmittedBudgetCount = async () => {
  const url = `${getAdminApiPath()}/budget/countSubmitted`;
  const response = await axios.get(url);
  return response.data as { total: number };
};
