import isAdmin, {isLegacy} from '@common/isAdmin';
import getUserApiPath from '@hornet-api/getUserApiPath';
import {getCleanBaseUri} from '@common/baseUri';
import isAdminState from "@state/globalState/isAdminState";

export const   getTaskUrl = () => {
  const isAdminUser = isAdminState.get()
  if (isAdminUser && isLegacy()) {
    return `/api/v1/admin`;
  } else if (isAdminUser || isAdmin()) {
    return `${getCleanBaseUri()}/api/v1/admin`;
  } else {
    return getUserApiPath();
  }
}