import React, { useMemo } from 'react';
import classNames from 'classnames';
import style from './Widget.module.scss';
import { FaCircleNotch } from 'react-icons/fa';
import { windowIsMobileState } from '@state/globalState/windowSize';

type Props = {
  title: string;
  value: string | number | null | undefined;
  isLoading?: boolean;
};
const Widget = ({ title, value, isLoading = false }: Props) => {
  const isMobile = windowIsMobileState.useValue();

  const displayValue = useMemo(() => {
    if (isLoading) {
      return (
        <span className={style.loading}>
          <FaCircleNotch className={'spin'} />
        </span>
      );
    } else {
      return value ?? <span className={style.empty}>(empty)</span>;
    }
  }, [isLoading, value]);
  return (
    <div className={isMobile ? 'col-12' : 'col'}>
      <div className={classNames('card', style.widget)}>
        <div className="card-body text-center">
          <div className={style.title}>{title}</div>
          <div className={style.value}>{displayValue}</div>
        </div>
      </div>
    </div>
  );
};

export default Widget;
