import {getTaskUrl} from "@hornet-api/task/util";
import axios from "axios";
import {LoanStageEnum} from "@admin/enum/LoanStageEnum";

export type LoanItems = {
  id: string;
  loanStage:keyof typeof LoanStageEnum;
  originatorContact: string | null;
  originatorEntity: string | null;
  lenderContact: string | null;
  lenderEntity: string | null;
  escrowOfficers: string | null;
  brokerEntity: string | null;
  brokerContact: string | null;
  guarantor: string | null;
  fullAddress: string;
  primaryBorrowerContactName: string | null;
  primaryBorrowerEntityName: string | null;
  loanBorrowerContactIds: string | null;
  loanBorrowerEntityIds: string | null;
}
export const getGlobalLoanList = async () => {
  const url = `${getTaskUrl()}/loan/getLoanList`;
  return axios.get<LoanItems[]>(url).then(res => res.data);
};