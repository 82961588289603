import getAdminApiPath from "@hornet-api/getAdminApiPath";
import axios from "axios";

export interface LpaData {
  id: number;
  aliasId: string;
  investor: string;
  contact: number | null;
  company: number | null;
  primaryProperty: number | null;
  purchasePrice: string;
  effectiveDate: string | null;
}

export const getLpaList = async () => {
  let url = `${getAdminApiPath()}/loanParticipationAgreement/listOptions`;

  const response = await axios.get(url);
  return response.data as LpaData[];
};