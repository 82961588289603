import React from 'react';
import HornetIcon, {Props as HornetIconProps} from './HornetIcon';
import style from './style.module.scss';

export interface LoadingProps extends HornetIconProps {
  fontColor?: string,
  text?: string | JSX.Element,
}

const Loading = (props: LoadingProps) => {

  return (
    <div
      className={style.loadingBox}
    >
      <div
        className={style.iconBox}
      >
        <HornetIcon {...props} />
        {
          props.text ?
            <div className={style.text}>{props.text}</div>
            :
            null
        }
      </div>
    </div>
  )
}

export default Loading;