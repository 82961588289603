import React from 'react';
import axios, {AxiosError} from 'axios'
import {addAlert} from '@components/Alert';
import _ from "lodash";

export const getAxiosErrors = (e: Error | AxiosError | unknown): string[] => {
  // @ts-ignore
  if (e?.response?.status === 440) {
    return [];
  }
  if (!axios.isAxiosError(e)) {
    return ['Something went wrong'];
  }  //@ts-ignore
  if (e.response?.data?.errors) {
    //@ts-ignore
    return e.response.data.errors.map(x => x.message as string);
    //@ts-ignore
  } else if (e.response?.data?.message) {
    //@ts-ignore
    const newError = e.response.data.message;
    return [newError];
  } else {
    return ['Something went wrong']
  }
}

/**
 * Pass errors here to have them automatically displayed
 */
export const alertApiErrors = (e: Error | AxiosError | unknown) => {
  // @ts-ignore
  if (e?.response?.status === 440) {
    return;
  }
  if (!axios.isAxiosError(e)) {
    addAlert({
      type: 'danger',
      content: _.get(e, 'message') || 'Something went Wrong'
    });
    console.error(e);
  } else {
    //@ts-ignore
    if (e.response?.data?.errors) {
      //@ts-ignore
      const newErrors = e.response.data.errors.map(x => x.message as string);
      addAlert({
        type: 'danger',
        //@ts-ignore
        content: <>{newErrors.map((x, i) => <React.Fragment key={i}>{i > 0 ? <br /> : null}{x}</React.Fragment>)}</>
      });
      //@ts-ignore
    } else if (e.response?.data?.message) {
      //@ts-ignore
      const newError = e.response.data.message;
      addAlert({
        type: 'danger',
        content: newError
      });
    } else {
      console.error(e);
      addAlert({
        type: 'danger',
        content: 'Something went Wrong'
      });
    }
  }
};

export const alertDownloadDocError = async (error: any) => {
  let responseData = error.response.data
  if (responseData instanceof Blob) {
    const isJson = responseData.type === 'application/json'
    responseData = await responseData.text()
    if (isJson) {
      responseData = JSON.parse(responseData);
    }
  }
  if (error.response.status === 404) {
    addAlert({
      type: "danger",
      content: responseData.hasOwnProperty('message') ? responseData.message : 'Not Found'
    });
  } else if (error.response.status === 400) {
    addAlert({
      type: "danger",
      content: responseData.hasOwnProperty('message') ? responseData.message : 'Bad request'
    });
  } else {
    alertApiErrors(error);
  }
}