import React, {JSXElementConstructor, ReactElement, ReactNode, useMemo, useState} from 'react';
import classNames from 'classnames';
import {Form, InputGroup} from "react-bootstrap";
import {FormControlElement} from "@components/forms/react-hook-form-bootstrap/SmartInput";
import {FaExclamation, FaTimes} from "react-icons/fa";

type Props = {
  className?: string;
  name?: string;
  value: string;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  error?: string | null;
  onChange?: (e: React.ChangeEvent<FormControlElement>) => void;
  label?: string | ReactElement<any, string | JSXElementConstructor<any>> | undefined
  showOnly?: boolean
  showClearBtn?: boolean
  description?: ReactNode
};

const InputSSN = React.forwardRef(
  (
    {
      name,
      className,
      error,
      value,
      required,
      disabled,
      placeholder,
      onChange,
      label,
      showOnly,
      showClearBtn = true,
      description
    }: Props,
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    value = value.trim().replace(/[^\d]/g, '');

    const invalidReason = useMemo(() => {
      if (error === null) {
        return null;
      }
      if (error) {
        return error;
      }
      if (!value) {
        return null;
      }
      if (value.length < 1) {
        return null;
      }
      if (value.length !== 9) {
        return 'This is not a valid SSN number';
      }
    }, [value, error]);
    const [isFocus, setIsFocus] = useState<boolean>(false);

    const displayValue = useMemo(() => {
      let dv = value;
      if (dv.length < 4) return dv;
      if (dv.length < 6) return `${dv.slice(0, 3)}-${dv.slice(3)}`;
      return `${dv.slice(0, 3)}-${dv.slice(3, 5)}-${dv.slice(5, 9)}`;
    }, [value]);

    if (showOnly) {
      return  <InputGroup><div className={className}>{displayValue}</div></InputGroup>
    }
    return (
      <>
        <div className="input-group">
          <Form.Control
            ref={ref}
            type="text"
            name={name}
            disabled={disabled}
            required={required}
            className={invalidReason ? classNames(className, 'is-invalid') : className}
            value={displayValue}
            placeholder={placeholder ? placeholder : 'XXX-XX-XXXX'}
            onChange={(e) => {
              if (onChange && !disabled) {
                // remove any non digits
                e.target.value = e.target.value.trim().replace(/[^\d]/g, '').slice(0, 9);
                onChange(e);
              }
            }}
            onBlur={() => {
              setTimeout(() => {
                setIsFocus(false);
              }, 200);
            }}
            onFocus={() => {
              setIsFocus(true)
            }}
          />
          {
            label &&
              <Form.Label className={classNames(value ? 'has-value' : '')}>
                  <span className={invalidReason ? 'text-danger' : ''}>{label}</span>
              </Form.Label>
          }
          {
            !showOnly && showClearBtn && <>
              {
                invalidReason && <div className="border border-danger rounded-circle invalid-circle">
                      <FaExclamation className={'text-danger'} size={11}/>
                  </div>
              }
              {
                isFocus && value && name && showClearBtn ?
                  <div className="cross-clear"
                       onClick={() => onChange && onChange({target: {value: ''}} as React.ChangeEvent<FormControlElement>)}>
                    <FaTimes size={20}/>
                  </div> : <div className="cross-clear-hide"></div>
              }
              {
                invalidReason ?
                  <div className="invalid-feedback">
                    {invalidReason}
                  </div>
                  :
                  null
              }
              </>
          }
        </div>
        {description && <small>{description}</small>}
      </>
    );
  }
);

export default InputSSN;
