import axios from 'axios';
import getApiPath from '../getApiPath';
import {payerTokenState} from "@pages/OneTimeLoanPaymentPage/global";
import getPublicApiPath from "@hornet-api/getPublicApiPath";

export type DecryptedObject = {
  [cipher: string]: string
}
const getDecrypted = async (ciphers: string[]) => {
  const token = payerTokenState.get();
  const url = token ? `${getPublicApiPath()}/loanPayment/oneTime/${encodeURIComponent(token)}/decrypt` : `${getApiPath()}/meta/decrypt`;
  const response = await axios.post(url, {cipherText: ciphers});

  return response.data as DecryptedObject;
};

export default getDecrypted;
