import React, { useEffect } from 'react';
import {
  windowScreenSizeState,
  windowWidthState,
  windowHeightState,
  WindowScreenSizeEnum,
  WindowScreenSize,
  windowIsMobileState
} from '@state/globalState/windowSize';

// this class prevents rapid firing which will lock up browser while resizing
// it also updates the states
class Resizer {
  private isResizing = false;
  private triggerAgain = false;
  private timeoutMs = 500;

  private updateStates = () => {
    try {
      // window width. 
      if (windowWidthState.get() !== window.innerWidth) {
        windowWidthState.set(window.innerWidth);
      }

      // window height. 
      if (windowHeightState.get() !== window.innerHeight) {
        windowHeightState.set(window.innerHeight);
      }

      // window size. 
      if (windowHeightState.get() !== window.innerHeight) {
        let minSize: WindowScreenSize = 'xs';
        for (let key in WindowScreenSizeEnum) {
          //@ts-ignore
          if (window.innerWidth > WindowScreenSizeEnum[key]) {
            //@ts-ignore
            minSize = key;
          }
        }
        if (minSize !== windowScreenSizeState.get()) {
          windowScreenSizeState.set(minSize);
        }
      }

      // mobile
      if (window.innerWidth < WindowScreenSizeEnum['lg']) {
        // is mobile
        if (!windowIsMobileState.get()) {
          windowIsMobileState.set(true);
        }
      } else {
        // is not mobile
        if (windowIsMobileState.get()) {
          windowIsMobileState.set(false);
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  public handleResize = () => {
    if (this.isResizing) {
      // already in resize mode set flag to trigger again
      this.triggerAgain = true;
    } else {
      this.updateStates();
      setTimeout(() => {
        this.isResizing = false;
        if (this.triggerAgain) {
          this.triggerAgain = false;
          this.handleResize();
        }
      }, this.timeoutMs);
    }
  }
}

const resizer = new Resizer();

const ResizeMonitor = () => {

  useEffect(() => {
    // do a handle of resize on start
    resizer.handleResize();
    window.addEventListener('resize', (ev) => {
      resizer.handleResize();
    });
  });
  return null;
}

export default ResizeMonitor;