import {FieldError, FieldErrors} from "react-hook-form";
import ReactQuill from "react-quill";

export const getErrorMessage = (error?: FieldError) => {
  if (!error) {
    return null;
  }
  if (error.message) {
    return error.message;
  }
  if (error.type === 'required') {
    return 'This field is required';
  }
  if (error.type === 'minLength') {
    return 'This field is required';
  }
  return `${error.type}: ${error.message}`;
};

export type QuillRefs<T extends string> = {
  [p in T]?: ReactQuill
}

export const onInvalidHandler = <T extends Record<string, any>, Q extends string>(errs: FieldErrors<T>, quillRefs: QuillRefs<Q> = {}) => {
  const firstError = (Object.keys(errs) as Array<keyof typeof errs>)[0];
  let fieldName = firstError;
  if (firstError) {
    fieldName = errs[firstError]?.hasOwnProperty('type') ?
      firstError : `${firstError}.${Object.keys(errs[firstError] || {})[0]}`;
  }

  if (fieldName) {
    // setFocus(fieldName); // this fails, do it ourselves
    let foundInput = (
      document.querySelector(`input[name='${fieldName}']`) ||
      document.querySelector(`[name^='${fieldName}']`) ||
      quillRefs[`${fieldName}` as Q]
    ) as HTMLInputElement | ReactQuill | null;
    setTimeout(() => {
      if (foundInput && foundInput.focus) {
        foundInput.focus();
        return
      } else {
        console.error('-->> could not find input');
      }
    }, 0);

  }
}
