export const otpCountdown = (resendLockedForSeconds?: number | null, onCountdownChange?: (countdown: number) => void) => {
  if (!resendLockedForSeconds) {
    return 0;
  }

  let countdown = resendLockedForSeconds;

  const timerInterval = setInterval(() => {
    countdown--;
    if (countdown >= 0) {
      onCountdownChange && onCountdownChange(countdown);
    } else {
      clearInterval(timerInterval);
    }
  }, 1000);

  return timerInterval;
}