import axios from 'axios';
import getAdminApiPath from '../getAdminApiPath';
import {VoiceCallToken} from "@interfaces/VoiceCall";

export interface GenerateVoiceTokenSever {
  callerId: string
  toPhoneNumber: string
  contactId: number | string

}

const generateVoiceToken = async (data: GenerateVoiceTokenSever) => {
  const url = `${getAdminApiPath()}/voice/generate-voice-token`;
  const response = await axios.post(url, data);
  return response.data as VoiceCallToken;
};

export default generateVoiceToken;
