import React, {ForwardedRef} from 'react';
import Tooltip from 'react-bootstrap/Tooltip';

const ShowHideColumnsTooltip = React.forwardRef((props, ref: ForwardedRef<HTMLDivElement>) => {
	return (
		<Tooltip ref={ref} id="show-hide-tool-btn" {...props}>Show/Hide Columns</Tooltip>
	);
});

export default (ShowHideColumnsTooltip);
