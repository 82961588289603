import isAdminState from "@state/globalState/isAdminState";

export const isClient = () => {
  if((window as any).isHornetReactClient) {
    return true;
  } else {
    return false;
  }
}

export const isLegacy = () => {
  if((window as any).isHornetReactClient) {
    return false;
  } else {
    return true;
  }
}

const isAdmin = () => {
  // return !isClient();
  return isAdminState.get();
}



export default isAdmin;