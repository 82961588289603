import activeRoleState, {ActiveRole} from '@state/globalState/activeRoleState';
import Contact from '@interfaces/Contact';
import userRoleGroupsState from "@state/globalState/userRoleGroupsState";
import setActiveRoleForUser from "@hornet-api/roles/setActiveRoleForUser";
import RoleGroupRoles from "@interfaces/RoleGroupRoles";

const isRole = (activeRole: ActiveRole | null, role: ActiveRole | null) => {
  return activeRole === role;
};

export const isInvestor = (activeRole: ActiveRole | null) => {
  let investorGroup = userRoleGroupsState.get()?.find((group) => ['Non Accredited Investor', 'Accredited Investor'].indexOf(group.name || '-') > -1);
  return investorGroup && investorGroup.id === activeRole;
};

export const isAccreditedInvestor = (activeRole: ActiveRole | null) => {
  let investorGroup = userRoleGroupsState.get()?.find((group) => ['Accredited Investor'].indexOf(group.name || '-') > -1);
  return investorGroup && investorGroup.id === activeRole;
};

const isBorrowerRoleGroup = (group: RoleGroupRoles) => group.name === 'Borrower';

export const getBorrowerRole = () => userRoleGroupsState.get()?.find(isBorrowerRoleGroup);

export const isBorrower = (activeRole: ActiveRole | null) => getBorrowerRole()?.id === activeRole;

export const isEscrowOfficer = (activeRole: ActiveRole | null) => {
  let investorGroup = userRoleGroupsState.get()?.find((group) => group.name === 'Escrow Officer');
  return investorGroup && investorGroup.id === activeRole;
};

const isInvestorRoleGroup = (group: RoleGroupRoles) => (group.name || '').indexOf('Investor') > -1;

export const getInvestorRole = () => {
  return userRoleGroupsState.get()?.find(isInvestorRoleGroup);
}

export const setDefaultRole = (profile: Contact) => {
  console.log('set active role')
  if (activeRoleState.get()) return;
  const setActiveRole = activeRoleState.set;
  if(!profile) {
    setActiveRole(null);
    return;
  }
  const userRoleGroups = userRoleGroupsState.get();

  if(!userRoleGroups) {
    setActiveRole(null);
    return;
  }

  // check local storage for active role
  // const activeRoleString = localStorage.getItem('activeRole');
  const activeRoleString = profile.activeRoleGroup ?? null;
  if (activeRoleString) {
    const activeRoleId = Number(activeRoleString);
    const savedRole = userRoleGroups.find((group) => group.id === activeRoleId);

    if (savedRole) {
      setActiveRole(savedRole.id);
      return;
    } else {
      console.log(
        `active role (${activeRoleId}) not found in user role groups (${userRoleGroups
          .map((group) => group.id)
          .join(', ')})`
      );
    }
  }

  // default to investor or borrower or any first role or null
  let defaultRoleGroupId = (
    userRoleGroups.find(isInvestorRoleGroup) ||
    userRoleGroups.find(isBorrowerRoleGroup) ||
    (userRoleGroups.length > 0 ? userRoleGroups[0] : null)
  )?.id; // <<-- get the id

  if (defaultRoleGroupId) {
    // let the backend know we updated their role
    setActiveRoleForUser(defaultRoleGroupId).then(() => {
      if (defaultRoleGroupId) {
        setActiveRole(defaultRoleGroupId);
      }
    });
    return;
  }

  // didnt find any roles
  setActiveRole(null);
};


export default isRole;
