import { getBaseUri } from '@common/baseUri';

const metaPath = `/api/v1/meta`;

const getMetaApiPath = () => {
	let basePath = getBaseUri();
	if(basePath) {
		if(basePath.endsWith('/')) {
			basePath = basePath.slice(0, -1);
		}
		return `${basePath}${metaPath}`;
	} else {
		// may happen if on admin for now
		return metaPath;
	}
};

export default getMetaApiPath;
