/* eslint-disable no-magic-numbers */
import axios from 'axios';
import getUserApiPath from '../getUserApiPath';
import {payerTokenState} from "@pages/OneTimeLoanPaymentPage/global";

export type EntityListLightObj = {
  id: number;
  name: string;
};

const nameSort = (a: any, b: any) => {
  if (a.name < b.name) return -1;
  if (a.name > b.name) return 1;
  return 0;
};

const getEntitiesListLight = async () => {
  const payerToken = payerTokenState.get();
  if (payerToken) return [];
  const url = `${getUserApiPath()}/contact/entity/list`;
  const response = await axios.get(url);
  let data = response.data as EntityListLightObj[];
  // sometimes companies are null in array.
  data = data.filter((e) => e !== null);
  data.sort(nameSort);

  return data as EntityListLightObj[];
};

export default getEntitiesListLight;
