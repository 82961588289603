import axios from 'axios';
import { getCleanBaseUri } from '@common/baseUri';
import Feature from '@interfaces/Feature';

const getFeatures = async () => {
  const url = `${getCleanBaseUri()}/api/public/features`;
  const response = await axios.get(url);
  return response.data as Feature[];
};

export default getFeatures;
