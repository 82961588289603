import React, {ForwardedRef} from 'react';
import Tooltip from 'react-bootstrap/Tooltip';

const ReloadTableDataTooltip = React.forwardRef((props, ref: ForwardedRef<HTMLDivElement>) => {
	return (
		<Tooltip ref={ref} id="reload-table-data-btn" {...props}>Reload Data</Tooltip>
	);
});

export default ReloadTableDataTooltip;
