import axios from "axios";
import getAdminApiPath from "@hornet-api/getAdminApiPath";
import {GlobalSearchEntityTypeEnum} from "@interfaces/GeneratedEnums";

export interface IRecentSearch {
  id?: number;
  entityType: keyof typeof GlobalSearchEntityTypeEnum;
  primaryId: string | number;
}

export const getRecentGlobalSearchList = async () => {
  const url = `${getAdminApiPath()}/recentSearch`;
  return axios.get<IRecentSearch[]>(url).then(res => res.data);
}