import React from "react";
import {Profile} from '@hornet-api/profile/profileInterfaces';

export const getCleanName = (profile: Profile | null) => {
  if (!profile) {
    return null;
  }
  if (
    (profile.firstName === null || profile.firstName === '') &&
    (profile.middleName === null || profile.middleName === '') &&
    (profile.lastName === null || profile.lastName === '')
  ) {
    // all name fields empty
    return (
      <span style={{
        cursor: profile.locked ? 'not-allowed' : 'pointer',
        color: '#ccc'
      }}>
    (empty)
    </span>
    );
  }
  let nameStr = profile.firstName;
  if (profile.middleName && profile.middleName !== '')
    nameStr += ` ${profile.middleName}`;

  if (profile.lastName && profile.lastName !== '')
    nameStr += ` ${profile.lastName}`;

  return (
    <span style={{
      cursor: profile.locked ? 'not-allowed' : 'pointer',
      fontWeight: 600
    }}>
  {nameStr}
  </span>
  );
};

function pad(num: number) {
  return ("0" + num).slice(-2);
}

export const timeFormat = (seconds: number) => {
  let minutes = Math.floor(seconds / 60);
  seconds = seconds % 60;
  const hours = Math.floor(minutes / 60)
  minutes = minutes % 60;
  return `${hours ? `${pad(hours)}:` : ''}${pad(minutes)}:${pad(seconds)}`;
}
