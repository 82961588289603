import { createGlobalState } from 'react-global-hooks';
import RoleGroupRoles from "@interfaces/RoleGroupRoles";
import getUserRoleGroups from "@hornet-api/getUserRoleGroups";

const userRoleGroupsState = createGlobalState(null as null | RoleGroupRoles[]);

export const updateUserRoleGroups = async () => {
  try {
    const groups = await getUserRoleGroups();
    userRoleGroupsState.set(groups);
  } catch (e) {
    console.log(e);
    userRoleGroupsState.set(null)
  }
}

export default userRoleGroupsState;
