/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-onchange */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-console */
import React, {useEffect, useRef, useState} from 'react';
import {objCopy} from './lib/basic';
import {Column} from './index';
import Calendar from 'react-calendar';
import 'moment-timezone';
import moment from 'moment';
import 'react-calendar/dist/Calendar.css';
import FontAwesomeIcon from './lib/FontAwesomeIcon';
import style from './style.module.scss';
import classNames from 'classnames';
import _ from "lodash";
import BaseSelectBox from "@components/forms/BaseSelectBox";
import {Lookup} from "@components/BootstrapSmartTable/Types";
import decryptObject from "@common/decryptObject";
import {formatDateForServer} from '@common/basic';
import {alertApiErrors} from "@common/errors";


type EditCellProps<D> = {
  col: Column<D>
  row: D,
  xPos: number,
  children: React.ReactNode;
  isRemote?: boolean;
  refreshTableData?: () => void
}

type Option = {
  value: string, label: string
}

export default function EditCell<D>(props: EditCellProps<D>) {
  const {col, row, isRemote, refreshTableData} = props;
  const [isEditing, setIsEditing] = useState(false);
  const [lookup, setLookup] = useState<Lookup | undefined | Option[]>(col.lookup);
  const [isDecrypting, setIsDecrypting] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isLoadingLookup, setIsLoadingLookup] = useState(false);
  const [editValue, setEditValue] = useState(_.get(row, col.editField || col.field) as string | number | Option);
  const [customEditRow, setCustomEditRow] = useState(objCopy(row)); // only used for custom edit
  const editBoxRef = useRef();
  const editButtonRef = useRef();
  const inputRef = useRef();

  useEffect(() => {
    setCustomEditRow(objCopy(row));
  }, [row]);

  const listenerFunction = (event: MouseEvent) => {
    console.log('click');
    //@ts-ignore
    const isClickInside = editBoxRef.current.contains(event.target);
    if (!isClickInside) {
      setIsEditing(false);
      document.removeEventListener('click', listenerFunction);
    }
  };

  const activateEdit = async () => {
    console.log('activate edit');
    // remove any lingering listeners
    document.removeEventListener('click', listenerFunction);
    // bind un-click listener
    if (editBoxRef.current)
      document.addEventListener('click', listenerFunction);
    setIsLoadingLookup(true);
    if (col.lookupEdit) {
      if (typeof col.lookupEdit == 'function') {
        setLookup(await col.lookupEdit(row));
      } else {
        setLookup(col.lookupEdit)
      }
    } else {
      setLookup(col.lookup)
    }
    setIsLoadingLookup(false);

    // timeout needed to make sure pointer events fire first
    setTimeout(async () => {
      setIsDecrypting(true);
      let value = _.get(row, col.editField || col.field);
      try {
        value = await decryptObject(value);
      } catch (e) {
        console.error('#### problem decrypting value', e);
      } finally {
        setIsDecrypting(false);
      }
      setEditValue(value);
      setIsEditing(true);
      setTimeout(() => {
        if (inputRef.current) {
          try {
            //@ts-ignore
            inputRef.current.focus();
          } catch (oe) {
            /* do nothing */
          }
        }
      }, 100);
    }, 100);
  };

  const deactivateEdit = () => {
    console.log('deactivate edit');
    document.removeEventListener('click', listenerFunction);
    document.removeEventListener('click', listenerFunction);
    setIsEditing(false);
  };

  const editFieldSubmit = async (setNull: boolean = false) => {
    let saveValue = editValue;
    if (col.date && (!saveValue || `${saveValue}`.length === 0))
      saveValue = formatDateForServer(new Date(Date.now()), col.skipTime);
    if (setNull)
      // @ts-ignore
      saveValue = null;

    setIsSaving(true);
    let newRow = objCopy(row);
    if (col.customEdit) {
      newRow = objCopy(customEditRow)
    } else {
      _.set(newRow as Object, col.editField || col.field, saveValue)
    }
    try {
      if (col.onEdit) {
        await col.onEdit(newRow, row);
      }
      setIsSaving(false);
      deactivateEdit();
      if (isRemote && refreshTableData) {
        refreshTableData()
      }
    } catch (e) {
      console.error('#### error editing row', e);
      setIsSaving(false);
      alertApiErrors(e)
    }

  };

  const getSelect = () => {
    return (
      <form onSubmit={(event) => {
        event.preventDefault();
        editFieldSubmit();
      }}>
        <div style={col.isSearchableLookup ? {minWidth: '300px'} : {}}>
          {
            col.isSearchableLookup ?
              <BaseSelectBox
                value={editValue}
                onChange={(selectedOption: Option) => {
                  setEditValue(selectedOption)
                }}
                isSearchable
                //@ts-ignore
                options={lookup}
                name="props"
                menuPlacement={"top"}
                defaultMenuIsOpen={true}
              />
              :
              <select
                className="form-control form-control-sm"
                value={(editValue || '') as string}
                style={{
                  minWidth: 100
                }}
                onChange={(event) => {
                  setEditValue(event.target.value);
                }}
              >
                {
                  col.removeBlankOption ? null : <option value={''}></option>
                }
                {
                  //@ts-ignore
                  Object.keys(lookup).map((key, index) => {
                    return (
                      //@ts-ignore
                      <option key={index} value={key}>{lookup[key]}</option>
                    );
                  })
                }
              </select>
          }
          {/* {getAddonButtons()} */}
        </div>
      </form>
    );
  };

  const getDateInput = () => {
    if (isEditing) {
      let v = editValue && `${editValue}`.length > 0 ? moment(editValue as string).toDate() : moment().toDate();
      return (
        <>
          <Calendar
            onChange={(date: Date | Date[]) => {
              const dateItem = Array.isArray(date) ? date[0] : date;
              setEditValue(formatDateForServer(dateItem, col.skipTime));
            }}
            value={v}
            calendarType={'US'}
          />
          {
            col.nullable ?
              <div
                className={style.calendarSetNullBox}
              >
                <a
                  href='#'
                  onClick={(e) => {
                    e.preventDefault();
                    editFieldSubmit(true);
                  }}
                >
                  Set Empty
                </a>
              </div>
              :
              null
          }
        </>
      );
    }
    return null;

  };

  const getInput = () => {
    if (col.customEdit) {
      return col.customEdit(customEditRow, (newRow) => setCustomEditRow(newRow));
    } else if (col.lookup || col.lookupEdit) {
      return getSelect();
    } else if (col.date) {
      return getDateInput();
    }

    return (
      <form onSubmit={(event) => {
        event.preventDefault();
        editFieldSubmit();
      }}>
        <input
          type="text"
          value={(editValue || '') as string}
          className="form-control form-control-sm"
          //@ts-ignore
          ref={inputRef}
          style={{
            minWidth: 100
          }}
          onChange={(event) => {
            setEditValue(event.target.value);
          }}
        />
      </form>
    );

  };

  const waitBeforeEditing = isLoadingLookup || isDecrypting;

  return (
    <>
      {props.children}
      <div
        className="edit-box"
        //@ts-ignore
        ref={editBoxRef}
        style={{
          opacity: isEditing ? 1 : 0,
          pointerEvents: isEditing ? 'all' : 'none'
        }}
      >
        {isEditing && getInput()}
        <div
          className={classNames(style.editAccept, "accept-btn-box")}
          style={{
            background: isSaving ? '#ACA9B4' : '#007BC0'
          }}
          //@ts-ignore
          ref={editButtonRef}
          onClick={() => {
            editFieldSubmit();
          }}
        >
          <FontAwesomeIcon
            icon={isSaving ? 'circle-o-notch' : 'check'}
            fixedWidth={isSaving}
            spin={isSaving}
          />
        </div>
        <div
          className={style.editCancel}
          onClick={() => {
            document.removeEventListener('click', listenerFunction);
            setIsEditing(false);
          }}
        >
          <FontAwesomeIcon icon={'times'}/>
        </div>
      </div>
      {
        !isEditing && (
          <div className="col-edit-btn-box">
            <div
              className="col-edit-btn"
              onClick={() => {
                !waitBeforeEditing && activateEdit();
              }}
              style={waitBeforeEditing ? {
                background: '#ACA9B4'
              } : {}}
            >
              <div className="col-edit-icon">
                <FontAwesomeIcon
                  icon={waitBeforeEditing ? 'circle-o-notch' : 'pencil'}
                  spin={waitBeforeEditing}
                />
              </div>
            </div>
          </div>
        )
      }
    </>
  );
}