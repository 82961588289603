import { isInIframeState } from '@admin/globalState';
import sendIframeMessage from '@common/sendIframeMessage';
import { activateTask } from '@components/NotificationSidebar/utils';

export const openTask = (taskId: number) => {
  if (isInIframeState.get()) {
    // legacy side
    sendIframeMessage({
      openTask: taskId,
    });
  } else {
    activateTask(taskId);
  }
};
