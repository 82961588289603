import getScrollbarSize from 'dom-helpers/scrollbarSize';

type PrevSettings = {
  paddingRight: string,
  overflow: string;
}

let prevSettings = null as null | PrevSettings;

export const setBodyNonScrollable = () => {
  const isOverflowed = window.document.body.scrollHeight - window.innerHeight > 0;
  if (isOverflowed) {
    if (!prevSettings) {
      // save previous settings
      prevSettings = {
        paddingRight: document.body.style.paddingRight,
        overflow: document.body.style.overflow
      }
    }
    document.body.style.paddingRight = `${getScrollbarSize()}px`;
    document.body.style.overflow = `hidden`;
  }

}

export const setBodyScrollable = () => {
  // restore previous settings
  if (prevSettings) {
    document.body.style.paddingRight = prevSettings.paddingRight;
    document.body.style.overflow = prevSettings.overflow;
    prevSettings = null;
  }
}
