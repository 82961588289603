import {Button} from 'react-bootstrap';
import {FaMicrophone, FaMicrophoneSlash, FaPause, FaPlay, FaRecordVinyl, FaStop} from 'react-icons/fa';
import {RecordingStatusType} from "@admin-ui/pages/VoiceCallPage/VoiceCallModal";
import {ButtonVariant} from "react-bootstrap/types";
import React, {useEffect, useState} from "react";

interface Props {
  isMute: boolean;
  recordingStatus: RecordingStatusType;
  setRecordingStatus: (recordingStatus: RecordingStatusType) => void;
  isRecordingEnabled: boolean
  setIsMute: (isMute: boolean) => void;
}

interface TwilioActionBtnInput {
  variant: ButtonVariant
  isDisabled: boolean
  icon: JSX.Element
  recordingStatus: RecordingStatusType;
  className: string
}

const VoiceCallOperationalToolBar = (
  {
    isRecordingEnabled,
    recordingStatus,
    setRecordingStatus,
    isMute,
    setIsMute
  }: Props
) => {

  const [isCallReadyForRecording, setIsCallReadyForRecording] = useState<boolean>(false)
  useEffect(() => {
    setTimeout(() => setIsCallReadyForRecording(true), 3000);
  }, []);

  const twilioActionBtn = (
    {
      variant,
      isDisabled,
      icon,
      recordingStatus,
      className
    }: TwilioActionBtnInput
  ) => <Button variant={isDisabled ? "outline-dark" : variant}
               className={className}
               disabled={isDisabled}
               onClick={() => setRecordingStatus(recordingStatus)}>{icon}</Button>;

  const getActionBtn: TwilioActionBtnInput[] = [
    {
      variant: "outline-success",
      isDisabled: !isCallReadyForRecording || recordingStatus === "STOP_RECORDING",
      icon: recordingStatus === 'PAUSE_RECORDING' ?
        <FaPlay/>
        :
        <FaPause/>,
      recordingStatus: recordingStatus === 'PAUSE_RECORDING' ? "RESUME_RECORDING" : "PAUSE_RECORDING",
      className: 'ml-1'
    },
    {
      variant: "outline-danger",
      isDisabled: !isCallReadyForRecording || recordingStatus === "STOP_RECORDING",
      icon: <FaStop/>,
      recordingStatus: "STOP_RECORDING",
      className: 'ml-1'
    },
    {
      variant: "outline-danger",
      isDisabled: !isCallReadyForRecording || recordingStatus !== "STOP_RECORDING",
      icon: <FaRecordVinyl/>,
      recordingStatus: "START_RECORDING",
      className: 'ml-3'
    }
  ];
  return (
    <>
      <Button
        title={isMute ? 'Click to Unmute' : 'Click to Mute'}
        variant={isMute ? 'warning' : 'outline-warning'}
        onClick={() => setIsMute(!isMute)}
        className="mr-2"
      >
        {
          isMute ?
            <FaMicrophoneSlash size="20"/>
            :
            <FaMicrophone size="20"/>
        }
      </Button>
      {
        isRecordingEnabled &&
        getActionBtn.map(twilioActionBtn)
      }
    </>
  );
};

export default VoiceCallOperationalToolBar;
