import { selector } from 'recoil';
import activeUserSelectorAtom from './activeUserSelectorAtom';

const accessTokenSelectorAtom = selector({
	key: 'accessTokenSelectorAtom',
	default: null,
	get: ({ get }) => {
		const user = get(activeUserSelectorAtom);
		if (!user) return null;
		return user.token;
	},
});

export default accessTokenSelectorAtom;
