import React, {useEffect, useMemo, useRef, useState} from 'react';
import {getBaseUri} from '@common/baseUri';
import {useHistory, useLocation} from 'react-router-dom';
import useOnMessage from '@common/useOnMessage';
import {loadingRelease, loadingTrigger} from '@admin-ui/components/AdminLoadingOverlay';
import impersonateUser from "@common/impersonateUser";
import {openTask} from "@common/tasks";

// list of links that we break out of frame for
const frameBreakLinks: string[] = [];

type LinkClicked = {
  href: string,
  target: string | null,
}

const prefix = 'adminFramer'

const AdminFramer = () => {
  const [url, setUrl] = useState('/admin/index');
  const [step, setStep] = useState(0);
  const location = useLocation();
  const history = useHistory();
  const triggerRef = useRef(null as null | string)
  const pathNameRef = useRef('' as string);
  // const sidebarExpanded = sidebarExpandedState.useValue();

  // unset trigger on unmount
  useEffect(() => {
    return () => {
      if (triggerRef.current) {
        loadingRelease(triggerRef.current);
        triggerRef.current = null;
      }
    }
  }, [])


  useOnMessage((message) => {
    try {
      if ('linkClicked' in message) {
        // release the trigger first
        if (triggerRef.current) {
          loadingRelease(triggerRef.current);
          triggerRef.current = null;
        }
        triggerRef.current = loadingTrigger(prefix);

        const clicked = message.linkClicked as LinkClicked;
        const raw = message.raw as MessageEvent;
        // console.log('source', raw.origin);
        // replace origin url if it is in the url
        const navTo = clicked.href.replace(raw.origin, '');
        if (frameBreakLinks.includes(navTo)) {
          const toUrl = `${raw.origin}${navTo}`;
          if (clicked.target) {
            window.open(toUrl, clicked.target);
          } else {
            // go directly to link (frame busting)
            window.location.href = toUrl;
          }
        } else {
          // navigate using react router
          history.push(navTo);
          // force reload if same link clicked
          if (message.linkClicked.href === window.location.pathname) {
            setStep(step + 1)
          }
        }
      }
      let isLoaded = false;
      if ('location' in message) {
        isLoaded = true;
        const loc = message.location as Location;
        const newLocation = `${loc.pathname}${loc.search}`;
        const currentLocation = `${window.location.pathname}${window.location.search}`;
        if (newLocation !== currentLocation) {
          console.log('Location Change', {
            newLocation: newLocation,
            currentLocation: currentLocation,
          })
          // change with current history without navigating
          window.history.replaceState({}, '', `${newLocation}`)
        }
      }
      if ('loaded' in message || isLoaded) {
        if (triggerRef.current) {
          loadingRelease(triggerRef.current);
          triggerRef.current = null;
        }
      }
      if ('loading' in message) {
        if (triggerRef.current) {
          loadingRelease(triggerRef.current);
        }
        triggerRef.current = loadingTrigger(prefix);
      }
      if ('impersonate' in message) {
        impersonateUser(message.impersonate);
      }
      if ('openTask' in message) {
        openTask(message.openTask);
      }
      if ('reload' in message) {
        window.location.reload();
      }
    } catch (e) {
      console.error(e)
    }
    console.log('message', message);
  }, true);

  useEffect(() => {
    setUrl(`${location.pathname}${location.search}`);
    if (location.pathname !== pathNameRef.current) {
      if (triggerRef.current) {
        loadingRelease(triggerRef.current);
        triggerRef.current = null;
      }
      triggerRef.current = loadingTrigger(prefix);
      pathNameRef.current = location.pathname;
    }
  }, [location]);

  const absoluteUrl = useMemo(() => {
    let basePath = getBaseUri();
    if (basePath) {
      if (basePath.endsWith('/')) {
        basePath = basePath.slice(0, -1);
      }
      return `${basePath}${url}`;
    } else {
      return url;
    }
  }, [url]);

  return (<>
    <iframe
      src={absoluteUrl}
      key={absoluteUrl + step}
      allow="clipboard-write; clipboard-read"
      style={{
        height: '100%',
        width: '100%',
        border: 0,
      }}
    />
  </>);
};

export default AdminFramer;