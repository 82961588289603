import axios from "axios";
import getUserApiPath from "@hornet-api/getUserApiPath";

/**
 * When id is null, it marks all the announcement messages as read/unread.
 * When id is given, only the specific message is affected.
 * @param id
 * @param isRead
 */
const changeAnnouncementReadStatus = async (id: number | null, isRead: boolean) => {
  const url = `${getUserApiPath()}/chat/announcement/message/${id || ''}`;

  await axios.post(url, {isRead});
}

export default changeAnnouncementReadStatus;



