import getAdminApiPath from "@hornet-api/getAdminApiPath";
import axios from "axios";
import {InvestmentClassEnum, SubscriptionAgreementStatusEnum} from "@interfaces/GeneratedEnums";

export type InvestorItem = {
  id: number;
  class: keyof typeof InvestmentClassEnum;
  status: keyof typeof SubscriptionAgreementStatusEnum;
  company: number | null;
  contacts: string;
  investor: string; // It could be Entity or multiple contacts
  totalInvestment: number;
}
export const getInvestorData = async () => {
  const url = `${getAdminApiPath()}/investor/listData`;
  const response = await axios.get(url);
  return response.data as InvestorItem[];
}