import axios from 'axios';
import getAdminApiPath from '../getAdminApiPath';
import ContactAdmin from '@interfaces/ContactAdmin';

const getContact = async (contactId: number | string) => {
	const url = `${getAdminApiPath()}/contact/${contactId}`;
	const response = await axios.get(url);
	return response.data as ContactAdmin;
};

export default getContact;
