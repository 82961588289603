import {PropertyRaw} from "@interfaces/Property";
import style from "@admin-ui/pages/UploaderPage/UploaderPage.module.scss";
import React from "react";

type AddressProp = {
  property: PropertyRaw
}


const AddressCard = ({property}: AddressProp) => {
  const {address1, address2, city, state, zip} = property;
  return <div className="propertyaddress" style={style}>

    <div>{address1}</div>
    {address2}
    <div>
      <span>{city},&nbsp;</span>
      <span>{state}&nbsp;</span>
      <span>{zip}</span>
    </div>
  </div>;
}
export default AddressCard;