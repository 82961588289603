import React, {useCallback} from 'react';
import {useDropzone} from 'react-dropzone';
import {BiUpload} from 'react-icons/bi';
import Button from 'react-bootstrap/Button';
import ViewDocumentModal from "@pages/public/ViewDocumentModal";

interface Props {
  disabled?: boolean;
  onChange: (selectedFiles: File[]) => void;
}

const FileUpload: React.FC<Props> = ({onChange, disabled = false}) => {
  const onDrop = useCallback((acceptedFiles: File[]) => {
    onChange(acceptedFiles);
  }, [onChange]);

  const {getRootProps, isDragActive} = useDropzone({onDrop, disabled: disabled});

  return (
    <div>
      <div {...getRootProps()} className={`drop-zone ${isDragActive ? 'active' : ''}`}>
        {isDragActive ? (
          <p>Drop the files here ...</p>
        ) : (
          <>
            <div style={disabled ? {opacity: 0.5} : undefined}>
              <BiUpload size={60}/>
              <div>Drag & Drop</div>
              <div>OR</div>
              <Button variant="outline-secondary" disabled={disabled}>Browse</Button>
            </div>
          </>
        )}
      </div>
      <ViewDocumentModal/>
    </div>
  );
};

export default FileUpload;
