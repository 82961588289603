import React, {useEffect, useMemo, useState} from 'react';
import Button from 'react-bootstrap/Button';
import {Card, Col, Form, Row} from "react-bootstrap";
import {SubmitHandler, useForm} from "react-hook-form";

import {Call, Device} from '@twilio/voice-sdk';

import {loadingRelease, loadingTrigger} from "@components/LoadingOverlay";
import {useLocation, useParams} from "react-router-dom";
import {InitCallData, VoiceCallFormData} from "@interfaces/VoiceCall";
import generateVoiceToken, {GenerateVoiceTokenSever} from "@hornet-api/voiceCall/generateVoiceToken";
import {Select, TextBox} from "@components/forms/react-hook-form-bootstrap";
import prepareInitCallData from "@hornet-api/voiceCall/prepareInitCallingData";
import {alertApiErrors} from "@common/errors";
import updateVoiceCallActions, {VoiceCallState} from "@hornet-api/voiceCall/updateVoiceCallActions";
import VoiceCallOperationalToolBar from "@admin-ui/pages/VoiceCallPage/VoiceCallOperationalToolBar";
import VolumeProgressBar from "@admin-ui/pages/VoiceCallPage/VolumeProgressBar";
import {VoiceCallActionEnum} from "@interfaces/GeneratedEnums";
import {FaCircle, FaPhone, FaPhoneSlash} from "react-icons/fa";
import style from './voiceCallPage.module.scss';
import getPhoneNumberList from "@hornet-api/contact/phoneNumber/getPhoneNumberList";
import PhoneNumber from "@interfaces/PhoneNumber";
import classNames from "classnames";


export type RecordingStatusType = "PAUSE_RECORDING" | "RESUME_RECORDING" | "STOP_RECORDING" | "START_RECORDING";
type VoiceCallParams = {
  contactId: string
}
export const openCallModal = (contactId: number | string, defaultNumber: string) => {
  const height = 440;
  const width = 500;
  const left = (screen.width - width) / 2;
  const top = (screen.height - height) / 4;
  const windowFeatures = `toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=no,resizable=no,copyhistory=no,left=${left},top=${top},width=${width},height=${height}`;
  const handle = window.open(
    `/voice-call/${contactId}?defaultNumber=${encodeURIComponent(defaultNumber)}`,
    "Calling Window",
    windowFeatures,
  );
  if (!handle) {
    alertApiErrors({message: "Not able to open a window."});
  }
}
const VoiceCallModal = () => {
  const {contactId} = useParams() as VoiceCallParams;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const defaultNumber = queryParams.get('defaultNumber');

  const [hasMicAccess, setHasMicAccess] = useState(false);
  const [device, setDevice] = useState(null as null | Device);
  const [call, setCall] = useState(null as null | Call);
  const [toPhoneNumbers, setToPhoneNumbers] = useState<PhoneNumber[]>([]);

  const [initCallData, setInitCallData] = useState(null as null | InitCallData);
  const [overrideCallWith, setOverrideCallWith] = useState(null as null | string);

  const [errorMessage, setErrorMessage] = useState(null as null | string);
  const [voiceCallStateServer, setVoiceCallStateServer] = useState(null as null | VoiceCallState);
  const [voiceCallLogId, setVoiceCallLogId] = useState(null as null | number);
  const [isMute, setIsMute] = useState(false);
  const [recordingStatus, setRecordingStatus] = useState<RecordingStatusType>("STOP_RECORDING");


  const {
    control,
    handleSubmit,
    reset,
    watch
  } = useForm<VoiceCallFormData>();
  const phoneNumberSelection = watch('selectPhonePhoneNumber');

  const phoneNumberOptions = useMemo(() => {
    const options = toPhoneNumbers?.reduce((options: {
      [p: string]: string
    }, {phoneNumber}) => {
      options[`${phoneNumber}`] = phoneNumber;
      return options;
    }, {}) || {};
    options.NEW = 'Other';
    return options;
  }, [toPhoneNumbers]);

  const mircoPhoneAccess = () => {
    navigator.mediaDevices.getUserMedia({audio: true})
      .then(() => {
        setHasMicAccess(true);
      })
      .catch(() => {
        alertApiErrors({message: 'Error accessing microphone'});
      });
  }

  useEffect(() => {
    mircoPhoneAccess();
    if (contactId) {
      const t = loadingTrigger();
      Promise.all([
        getPhoneNumberList(contactId).then(setToPhoneNumbers),
        prepareInitCallData().then((data) => {
          setInitCallData(data);
          setErrorMessage(data.errorMessage);
          setOverrideCallWith(data.overrideCallWith);
          const callFormData = {
            callerId: data.defaultCallerId || Object.keys(data.callerIds)[0],
            selectPhonePhoneNumber: defaultNumber || '',
            toPhoneNumber: ''
          };
          reset({...callFormData});
        })
      ])
        .catch(alertApiErrors)
        .finally(() => loadingRelease(t));
      setRecordingStatus("STOP_RECORDING");
      setVoiceCallLogId(null);
    }
  }, [contactId]);


  useEffect(() => {
    call && call.mute(isMute);
    updateCallActionsOnServer(isMute ? "MUTE" : "UNMUTE");
  }, [isMute]);

  const handleRecordingStatus = (recordingStatus: RecordingStatusType) => {
    voiceCallStateServer?.callSid && updateCallActionsOnServer(recordingStatus);
  };

  const updateCallActionsOnServer = (actionStatus: keyof typeof VoiceCallActionEnum) => {
    if (voiceCallStateServer && voiceCallLogId) {
      updateVoiceCallActions(voiceCallLogId, {...voiceCallStateServer, actionStatus: actionStatus})
        .then((data) => {
          setVoiceCallStateServer(data);
          switch (actionStatus) {
            case "PAUSE_RECORDING":
            case "RESUME_RECORDING":
            case "STOP_RECORDING":
            case "START_RECORDING":
              setRecordingStatus(actionStatus);
              break
            case "END_CALL":
              setRecordingStatus("START_RECORDING");
              setVoiceCallStateServer({
                callSid: undefined,
                recordSid: undefined,
                actionStatus: 'START_RECORDING'
              });
              break
          }
        })
        .catch(alertApiErrors);
    }
  };


  const generateTokenAndInitiateCall = async (input: GenerateVoiceTokenSever) => {
    const data = await generateVoiceToken(input);
    setVoiceCallLogId(data.voiceCallLogId);
    if (device == null) {
      const deviceObject = new Device(data.token, {
        logLevel: 1,
        codecPreferences: [
          Call.Codec.Opus,
          Call.Codec.PCMU
        ]
      });
      await deviceObject.register();
      await connectToCall(deviceObject, input.toPhoneNumber);
    } else {
      device.updateToken(data.token);
      await connectToCall(device, input.toPhoneNumber);
    }
  }
  const connectToCall = async (deviceObject: Device, toPhoneNumber: string) => {
    const callObject = await deviceObject.connect({
      params: {
        To: toPhoneNumber
      }
    });
    callObject.on("accept", acceptCallBack);
    callObject.on("disconnect", disconnectCallBack);
    callObject.on("cancel", disconnectCallBack);
    setDevice(deviceObject);
    setCall(callObject);
  }

  const onVoiceCall: SubmitHandler<VoiceCallFormData> = (data) => {
    if (call) {
      setIsMute(false)
      hangUp();
      return
    }
    if (data) {
      setRecordingStatus("STOP_RECORDING");
      generateTokenAndInitiateCall({
        callerId: data.callerId,
        toPhoneNumber: overrideCallWith || (data.selectPhonePhoneNumber === "NEW" ? data.toPhoneNumber : data.selectPhonePhoneNumber),
        contactId: contactId || '',
      }).catch(alertApiErrors);
    }
  }

  const acceptCallBack = (call: Call) => {
    setVoiceCallStateServer({
      callSid: call.parameters.CallSid,
      recordSid: undefined,
      actionStatus: "START_CALL"
    });
  };
  const disconnectCallBack = () => {
    updateCallActionsOnServer("END_CALL");
    setCall(null);
  };

  const handleClose = () => {
    if (call){
      if(confirm("Are you sure you want to end this call?")) {
        hangUp();
        open("/", '_self')?.close();
      }
    }else{
      open("/", '_self')?.close();
    }
  };

  const hangUp = () => {
    call?.disconnect();
  }

  return (<div className={style.container}>
      <section className="content">
        <div className={classNames("row", style.cardSize)}>
          <div className="col-sm-12 col-lg-6 pinpin mt-5">
            <Card>
              <Card.Header>
                Voice Call
                {!hasMicAccess && <div className={'text-danger text-right'}>No microphone access</div>}
                <small className={'pull-right'}>
                  {
                    call &&
                    recordingStatus !== "STOP_RECORDING" &&
                      <span className={`ml-2 text-danger ${style.callBlinker}`}>
                      <FaCircle title="Recording in progress" size="10"/>
                    </span>
                  }
                </small>
              </Card.Header>
              <Card.Body>
                {
                  errorMessage &&
                    <Row>
                        <Col>
                            <div className="text-danger text-sm-left">{errorMessage}</div>
                        </Col>
                    </Row>
                }
                <Form noValidate onSubmit={handleSubmit(onVoiceCall)}>
                  <Row>
                    <Col>
                      <Select
                        name={'callerId'}
                        label={'From'}
                        control={control}
                        rules={{
                          required: true
                        }}
                        disabled={!!call}
                        options={initCallData?.callerIds || []}
                      />
                    </Col>
                    <Col>
                      <Select
                        name={'selectPhonePhoneNumber'}
                        label={'To'}
                        control={control}
                        rules={{
                          required: true,
                        }}
                        disabled={!!call}
                        options={phoneNumberOptions || []}
                      />
                    </Col>
                  </Row>
                  {
                    phoneNumberSelection === "NEW" &&
                      <TextBox
                          name={'toPhoneNumber'}
                          label={'Phone'}
                          type={'tel'}
                          control={control}
                          rules={{required: true}}
                      />
                  }
                  {
                    call && voiceCallLogId &&
                      <>
                          <Row className={'mb-4'}>
                              <Col className='col-2'><label>Actions</label></Col>
                              <Col className='col-10'>
                                  <VoiceCallOperationalToolBar
                                      isRecordingEnabled={initCallData!.isRecordingEnabled}
                                      isMute={isMute}
                                      setIsMute={setIsMute}
                                      recordingStatus={recordingStatus}
                                      setRecordingStatus={handleRecordingStatus}/>
                              </Col>
                          </Row>
                          <VolumeProgressBar onGoingCall={call}/>
                      </>
                  }
                  <Row>
                    <Col className={'text-right'}>
                      <Button
                        variant={"primary"}
                        onClick={handleClose}
                        type="button"
                        className={'mr-4'}
                      >
                        Close
                      </Button>
                      <Button
                        disabled={errorMessage !== null}
                        variant={call ? "danger" : "success"}
                        type="submit"
                      >
                        {
                          hasMicAccess && call ?
                            <>Hang Up <FaPhoneSlash className='ml-2 pb-1'/></> :
                            <>Call <FaPhone className='ml-2 pb-1'/></>

                        }
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </div>
        </div>
      </section>
    </div>
  );
};

export default VoiceCallModal;
