import React from "react";
import {Table} from 'react-bootstrap';
import {Attachment} from "@interfaces/task";
import Attachments from "@admin-ui/pages/LoanShowPage/components/Attachments";

type Props = {
  taskId: number;
  files: Attachment[];
  setFiles: (files: Attachment[]) => void;
  isDeletable: boolean;
  isShow: boolean;
};

const ViewDownloadTaskFiles = ({files, taskId, setFiles, isDeletable, isShow}: Props) => {

  return (
    <Table striped bordered>
      <thead>
      <tr>
        <th>Filename</th>
        <th style={{width: `1px`}}>Action</th>
      </tr>
      </thead>
      <tbody>
      {files.map((file) => (
        <Attachments taskId={taskId} file={file} files={files} setFiles={setFiles} isDeletable={isDeletable}
                     isShow={isShow}/>
      ))}
      </tbody>
    </Table>
  );
};

export default ViewDownloadTaskFiles;
