import React, {ReactEventHandler, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import style from './SearchBox.module.scss';
import classNames from 'classnames';
import {isLegacy} from '@common/isAdmin';
import {Link, useHistory} from 'react-router-dom';
import {getIcon, getLink, searchResult} from "@admin/shared/AdminSidebar/GlobalSearchState";
import {FaSearch} from "react-icons/fa";
import {recentSearchTextState} from "@admin/shared/AdminSidebar/RecentSearch";
import userRoleGroupsState from "@state/globalState/userRoleGroupsState";
import {showSearchModalState} from "@admin-ui/pages/SearchPage/GlobalSearchModal";


export const SEARCH_TERM_MIN_LENGTH = 1;

const searchIconStyle: React.CSSProperties = {
  textAlign: 'left',
  lineHeight: '25px'
}

const IS_SEARCH_TOP_BAR_ENABLED = false;

export default function SearchBox() {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isMouseOver, setIsMouseOver] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null as null | number);
  const [searchVal, setSearchVal] = useState('');
  const searchInputRef = useRef<HTMLInputElement>(null);
  const dropdownRef = useRef<HTMLInputElement>(null);
  const [highLightedRef, setHighLightedRef] = useState<HTMLAnchorElement | null>(null);
  const userRoleGroups = userRoleGroupsState.useValue();
  const history = useHistory();
  const recentSearchGlobalValue = recentSearchTextState.useValue();

  const hasMultipleRoles = useMemo(() => {
    return (userRoleGroups?.length || 0) > 1;
  }, [userRoleGroups]);

  const handleOnSearchIconClick: ReactEventHandler<HTMLElement> = (event) => {
    event.preventDefault()
    showSearchModalState.set(true)
  }

  const handleOnSearchTextChange = useCallback(
    (event) => {
      setSearchVal(event.target.value);
      setSelectedIndex(null);
      setIsSearchOpen(true);
    },
    [setSearchVal]
  );

  const handleOnSearchTextBlur = useCallback(() => {
    setTimeout(() => {
      setSelectedIndex(null);
      setIsSearchOpen(false);
      setHighLightedRef(null);
    }, 100);
  }, [setIsSearchOpen]);

  const handleOnSearchFocus = useCallback(() => {
    setIsSearchOpen(true);
  }, [setIsSearchOpen]);

  useEffect(() => {
    setSearchVal(recentSearchGlobalValue)
    setSelectedIndex(null);
    setIsSearchOpen(true);
  }, [recentSearchGlobalValue]);

  const submitFrom = (searchVal: string, e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && highLightedRef) {
      e.preventDefault()
      highLightedRef.click()
      return
    }
    searchVal = searchVal?.trim();
    if (searchVal?.length >= SEARCH_TERM_MIN_LENGTH) {
      history.push(`/globalSearch?q=${encodeURIComponent(searchVal)}`);
      setIsSearchOpen(false);
    }
  }

  useEffect(() => {
    if (isSearchOpen && IS_SEARCH_TOP_BAR_ENABLED) {
      setTimeout(() => {
        const elem = searchInputRef.current as HTMLInputElement;
        elem.focus();
        elem.select();
      }, 100);
    }
  }, [isSearchOpen, searchInputRef, IS_SEARCH_TOP_BAR_ENABLED]);

  const searchResults = useMemo(() => {
    return searchResult(searchVal);
  }, [searchVal]);

  const setHighlightRefAndIndex = (newIndex: number) => {
    if (newIndex !== null && dropdownRef.current) {
      const children = dropdownRef.current.children;
      if (children[newIndex]) {
        setHighLightedRef(children[newIndex] as HTMLAnchorElement)

        // handling scrolling with up and down arrow by getting container and child rectangular dimensions
        const containerRect = dropdownRef.current.getBoundingClientRect();
        const childRect = children[newIndex].getBoundingClientRect();
        if (childRect.bottom > containerRect.bottom) {
          dropdownRef.current.scrollTop += childRect.bottom - containerRect.bottom;
        } else if (childRect.top < containerRect.top) {
          dropdownRef.current.scrollTop -= containerRect.top - childRect.top;
        }
      }
    }
    setSelectedIndex(newIndex);
  }

  // setup keyboard event
  useEffect(() => {
    const fn = (event: KeyboardEvent) => {
      if (searchInputRef.current && searchInputRef.current === document.activeElement) {
        const maxIndex = searchResults ? searchResults.length - 1 : 0
        switch (event.key) {
          case 'ArrowDown':
            event.preventDefault();
            if (searchResults && searchResults.length > 0) {
              let newIndex = selectedIndex !== null ? selectedIndex + 1 : 0;
              if (newIndex > maxIndex) {
                newIndex = maxIndex;
              }
              console.log({
                selectedIndex: selectedIndex,
                newIndex: newIndex,
                maxIndex: maxIndex,
              });
              setHighlightRefAndIndex(newIndex);
            }
            break;
          case 'ArrowUp':
            event.preventDefault();
            if (searchResults && searchResults.length > 0) {
              let newIndex = selectedIndex !== null ? selectedIndex - 1 : maxIndex;
              if (newIndex < 0) {
                newIndex = 0;
              }
              setHighlightRefAndIndex(newIndex);
            }
            break;
          case 'Enter':
            if (selectedIndex !== null) {
              event.preventDefault();

              if (dropdownRef.current) {
                const children = dropdownRef.current.children;
                if (children[selectedIndex]) {
                  (children[selectedIndex] as HTMLAnchorElement).click();
                  setSelectedIndex(null);
                  // setSearchVal('');
                  if (searchInputRef.current) searchInputRef.current.blur();
                }
              }
              setHighLightedRef(null)
            }
            break;
          case 'Escape':
            setSelectedIndex(null);
            setSearchVal('');
            break;
        }
      }
    };
    document.addEventListener('keyup', fn);
    return () => {
      document.removeEventListener('keyup', fn);
    };
  }, [searchResults, searchInputRef, selectedIndex]);

  return (
    <div className={`search-btn mt-1 ${style.searchBar} ${hasMultipleRoles ? style.reduced : ''}`}>
      <div className="search-icon w-100" style={searchIconStyle}>
        <div className="input-group position-relative">
          <input
            type="search"
            name="search"
            className={`form-control ${style.searchInput}`}
            id="search-input"
            ref={searchInputRef}
            placeholder="Search (Ctrl + /)"
            autoComplete="off"
            value={searchVal}
            onChange={handleOnSearchTextChange}
            onBlur={handleOnSearchTextBlur}
            onFocus={handleOnSearchFocus}
            onKeyDown={(event) => {
              if (event.key == 'Enter') {
                event.preventDefault()
                submitFrom(searchVal, event)
              }
            }}
          />
          {!IS_SEARCH_TOP_BAR_ENABLED && (
            <div
              className={'position-absolute w-100 h-100'}
              onClick={handleOnSearchIconClick}
            />
          )}
          <div className="input-group-append">
            <div role="button" className={`btn btn-secondary ${style.searchIconBtn}`} onClick={handleOnSearchIconClick}>
              <FaSearch/>
            </div>
          </div>
        {searchResults && (isSearchOpen || isMouseOver) ? (
          <div
            className={`mt-2 ${style.dropdown}`}
            ref={dropdownRef}
            onMouseEnter={() => setIsMouseOver(true)}
            onMouseLeave={() => setIsMouseOver(false)}
          >
            {searchResults.map((res, index) => {
              if (isLegacy()) {
                return (
                  <a
                    href={getLink(res)}
                    className={
                      selectedIndex === index ? classNames(style.item, style.active) : style.item
                    }
                    key={index}
                  >
                    {getIcon(res)} {res.name} {res.alt ? <small>({res.alt})</small> : null}
                  </a>
                );
              } else {
                return (
                  <Link
                    to={getLink(res)}
                    className={
                      selectedIndex === index ? classNames(style.item, style.active) : style.item
                    }
                    key={index}
                  >
                    {getIcon(res)} {res.name} {res.alt ? <small>({res.alt})</small> : null}
                  </Link>
                );
              }
            })}
          </div>
        ) : null}
        </div>
      </div>
    </div>
  );
}
