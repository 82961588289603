import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import FontAwesomeIcon from './lib/FontAwesomeIcon';
import ReloadTableDataTooltip from "@components/BootstrapSmartTable/ReloadTableDataTooltip";

type IReloadButtonProps = {
	reload: () => void,
};

const styles = {
	reloadButton: {
		marginRight: 5
	}
};

const ReloadTableDataButton = (
	{
		reload,
	}: IReloadButtonProps
) => {

	const handleOnReloadClick = () => {
		reload();
	};

	return (
		<OverlayTrigger
			placement="bottom-end"
			overlay={<ReloadTableDataTooltip/>}
		>
			<button
				className="btn btn-sm btn-light"
				style={styles.reloadButton}
				onClick={handleOnReloadClick}
			>
				<FontAwesomeIcon icon="refresh"/>
			</button>
		</OverlayTrigger>
	);
};

export default ReloadTableDataButton;
