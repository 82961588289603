import React, {useEffect, useRef, useState} from 'react';
import {Chat, ChatMessage, Task} from '@interfaces/task';
import isAdminState from "@state/globalState/isAdminState";
import {FaCircleNotch} from 'react-icons/fa';
import style from './style.module.scss';

import sendMessage, {SendMessageOptions} from '@hornet-api/task/sendMessage';

type Props = {
  task?: Task,
  chat?: Chat,
  messageToEdit?: ChatMessage | null,
  onSubmitted?: () => void | Promise<void>,
  isAnnouncement?: null | string
}
const MessageInput = React.forwardRef(
  ({
  task,
  chat,
  onSubmitted,
  isAnnouncement = null,
  messageToEdit
}: Props,
   ref: React.ForwardedRef<HTMLTextAreaElement>
   ) => {
  const [isLoading, setIsLoading] = useState(false)
  const [text, setText] = useState('');
  const isAdmin = isAdminState.useValue();
    const bottomRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [task]);

  useEffect(() => {
    setText(messageToEdit?.content || '')
  }, [messageToEdit])

  const onSubmit = async (e?: React.FormEvent) => {
    if (e) e.preventDefault();
    if (isLoading) return;

    let opts: SendMessageOptions = {
      content: text
    };
    if (task) {
      opts.taskId = task.id;
      if (isAdmin) {
        if ('contact' in task) opts.contactId = task.contact;
        if ('entity' in task) opts.companyId = task.entity;
      } else if ('entity' in task) {
        opts.contactCompanyId = task.entity;
      }
    } else if (chat) {
      if (isAdmin) {
        if ('contact' in chat) opts.contactId = chat.contact;
        if ('entity' in chat) opts.companyId = chat.entity;
        if ('loan' in chat) opts.loanId = chat.loan;
      } else if ('entity' in chat) {
        opts.contactCompanyId = chat.entity;
      } else if ('loan' in chat) {
        opts.loanId = chat.loan;
      }
    } else {
      alert('Not supported');
      return;
    }
    if (messageToEdit && isAdmin) {
      opts.id = messageToEdit.id
    }
    opts.isAnnouncement = isAnnouncement;
    setIsLoading(true);
    await sendMessage(opts);
    // callback when done
    setText('');
    if (onSubmitted) {
      await onSubmitted();
    }
    setIsLoading(false);
  };

  return (<>
    <div className={`border-top ${style.messageInput}`}>
      <form onSubmit={onSubmit}>
        <textarea
          rows={3}
          value={text}
          className="form-control"
          placeholder='Write message here..'
          onChange={(e) => {
            setText(e.target.value);
          }}
          ref={ref}
          onKeyDown={(e) => {
            //handle enter to submit and shift enter to do a new line
            const keyCode = e.which || e.keyCode;

            // 13 represents the Enter key
            if (keyCode === 13 && !e.shiftKey) {
              e.preventDefault();
              onSubmit()
            }
          }}
        />
        <div className={style.btnBox} ref={bottomRef}>
          <button
            type="submit"
            className='btn btn-primary btn-sm'
            disabled={isLoading}
          >
            {isLoading ? <FaCircleNotch className='spin' /> : 'Send Message'}
          </button>
        </div>
      </form>
    </div>
  </>);
})

export default MessageInput;