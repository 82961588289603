import React, {useMemo} from 'react';
import {Column} from './index';
import {objCopy} from './lib/basic';

type ShowHideColumnsProps<D> = {
	columns: Column<D>[],
	hiddenColumns: number[],
	isVisible: boolean,
	onChange: (newHiddenColumns: number[]) => void
}

export default function ShowHideColumns<D>(props: ShowHideColumnsProps<D>) {
	const {columns, hiddenColumns, isVisible, onChange} = props;
	const sortedColumns = useMemo(() => {
		return [...columns].sort((a, b) => {
			if (a.title.toLowerCase() > b.title.toLowerCase()) return 1;
			if (a.title.toLowerCase() < b.title.toLowerCase()) return -1;
			return 0;
		})
	}, [columns])
	return (
		<div
			className="text-dark text-left"
			style={{
				position: 'absolute',
				top: '100%',
				right: 0,
				background: '#fff',
				padding: '15px 20px',
				borderRadius: 5,
				boxShadow: '0 2px 10px rgba(0,0,0,0.2)',
				zIndex: 2000,
				whiteSpace: 'nowrap',
				pointerEvents: isVisible ? 'all' : 'none',
				opacity: isVisible ? 1 : 0,
				transition: 'all ease 0.3s'
			}}
		>
			<div
				style={{
					fontSize: '0.8em',
					textAlign: 'center',
					marginBottom: 10
				}}
			>
				<a
					href="#"
					onClick={(event) => {
						event.preventDefault();
						onChange([]);
					}}
				>
					Select All
				</a> / <a
					href="#"
					onClick={(event) => {
						event.preventDefault();
						onChange(columns.map((col, i) => i));
						
					}}
				>
					Select None
				</a>
			</div>
			{
				sortedColumns.map((col) => {
					if (col.field && col.field !== 'actions') {
						// get unsorted index
						const index = columns.findIndex(c => c === col);
						return (
							<div className="form-check" key={index}>
								<input
									type="checkbox"
									className="form-check-input"
									id={`col-vis-check${index}`}
									checked={!(hiddenColumns.includes(index))}
									onChange={(event) => {
										if (event.target.checked) 
											onChange(hiddenColumns.filter(val => val !== index));
										else {
											const newHiddenCols = objCopy(hiddenColumns);
											newHiddenCols.push(index);
											onChange(newHiddenCols);
										}
									}}
								/>
								<label
									className="form-check-label"
									htmlFor={`col-vis-check${index}`}
								>
									{col.title}
								</label>
							</div>
						);
					}
					return null;
				})
			}
		</div>

	);
}
