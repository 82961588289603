import React from 'react';
import {Link} from "react-router-dom";
import {Navbar} from "react-bootstrap";
import hornetCapitalLogo from "@images/hornet_logo_regular.svg";

const HornetNavbar = () => {
  return (
    <Navbar bg="light" expand="lg" className="main-top-navbar d-flex justify-content-center">
      <Link className="navbar-brand" to="/">
        <img src={hornetCapitalLogo} alt="Hornet Capital"/>
      </Link>
    </Navbar>
  );
}

export default HornetNavbar;