import axios from 'axios';
import {getTaskUrl} from './util';
import {Task} from '@interfaces/task';
import {TaskStatusEnum, TaskTargetEnum,} from '@interfaces/GeneratedEnums';

export type CreateTaskOptions = {
  taskId: number,
  contactId?: number,
  companyId?: number,
  contactCompanyId?: number,
  title?: string,
  description?: string,
  taskStatus?: keyof typeof TaskStatusEnum
  target?: keyof typeof TaskTargetEnum
}
const updateTask = async ({
  taskId,
  contactId,
  companyId,
  contactCompanyId,
  title,
  description,
  taskStatus,
  target
}: CreateTaskOptions) => {
  console.log({
    taskId,
    contactId,
    companyId,
    title,
    description,
    taskStatus,
    target
  })
  let url = getTaskUrl();

  // there is a special endpoint for only task status
  const tail = taskStatus && !title && !description ? '/status' : '';

  if (companyId) {
    url += `/entity/${companyId}/task/${taskId}${tail}`;
  } else if (contactId) {
    url += `/contact/${contactId}/task/${taskId}${tail}`;
  } else if (contactCompanyId){
    url += `/contact/entity/${contactCompanyId}/task/${taskId}${tail}`;
  } else {
    url += `/contact/task/${taskId}${tail}`;
  }
  const data: any = {};
  if (title) data.title = title;
  if (description) data.description = description;
  if (taskStatus) data.taskStatus = taskStatus;
  if (target) data.target = target;

  const response = await axios.put(url, data);
  return response.data as Task;
};

export default updateTask;
