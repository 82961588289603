import axios, {AxiosResponse} from "axios";
import {loadingRelease, loadingTrigger} from "@components/LoadingOverlay";
import {alertDownloadDocError} from "@common/errors";


export const downloadFileByUrl = (url: string, downloadAs: string = "file") => {
  const t = loadingTrigger();
  fileDownloader(url, downloadAs)
    .catch(alertDownloadDocError)
    .finally(() => loadingRelease(t));
}

/**
 * It is suggested to send the downloadAs parameter, because Content-Disposition header is not exposed
 * in cors implementation
 *
 * @param url to get the file from
 * @param downloadAs the name of the file being downloaded
 * @param returnFile
 */
const fileDownloader = async (url: string, downloadAs: string = "file", returnFile: boolean = false) => {
  const response = await axios.get(url, {
    responseType: 'blob'
  });
  return downloadFile(response, downloadAs, returnFile);
}

export const downloadFile = (response: AxiosResponse, downloadAs: string, returnFile: boolean = false): AxiosResponse | File | true => {
  if (response.headers["content-type"].indexOf('application/json;') > -1) {
    return response;
  }
  // This doesn't handle UTF-8 fileNames, we can use npm content-disposition for this
  let fileName = response.headers["content-disposition"]?.split("Filename=")[1] || downloadAs;
  if (fileName.startsWith('"')) {
    fileName = fileName.slice(1)
  }
  if (fileName.endsWith('"')) {
    fileName = fileName.slice(0, -1)
  }
  if (returnFile) {
    return new File([response.data as BlobPart], fileName);
  }
  if (window.navigator && window.navigator.hasOwnProperty('msSaveOrOpenBlob')) { // IE variant
    //@ts-ignore
    window.navigator.msSaveOrOpenBlob(new Blob([response.data as BlobPart],
        {type: response.headers["content-type"]}
      ),
      fileName
    );
  } else {
    const url = window.URL.createObjectURL(new Blob([response.data as BlobPart],
      {type: response.headers["content-type"]}));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('class', 'no-intercept');
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
  return true;
}

export default fileDownloader;